import { atom, AtomEffect } from 'recoil'
import { registerEvent } from '@/api/analyticsEvent'
import { PaidUserAnalyticsEventType } from 'common/src/api/websiteFrontendVsWebsiteBackend/analyticsEvent/types'

const fireAnalyticsEventOnInactivityChangeEffect: AtomEffect<boolean> = ({
    onSet,
}) => {
    onSet((isNewlyPaused, wasPaused) => {
        // newly paused
        if (!wasPaused && isNewlyPaused) {
            registerEvent(
                PaidUserAnalyticsEventType.STUDY_TIMER_PAUSED_FROM_INACTIVITY
            )
            return
        }

        // manually set to unpaused
        if (wasPaused && !isNewlyPaused) {
            registerEvent(
                PaidUserAnalyticsEventType.STUDY_TIMER_MANUALLY_UNPAUSED,
                { fromActivityMonitor: true }
            )
            return
        }
    })
}

export const isPausedFromInactivityModalOpenAtom = atom<boolean>({
    key: 'isPausedFromInactivityModalOpen',
    default: false,
    effects: [fireAnalyticsEventOnInactivityChangeEffect],
})
