import React, { ReactElement, useCallback, useEffect, useRef } from 'react'
import styles from './LoadingComponent.module.scss'
import notification from 'antd/lib/notification'
import Modal from 'antd/lib/modal/Modal'
import { NotificationKeys } from '@/utils/notificationKeys'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { logger } from '@/logging/FrontendLogger'
import { registerEvent } from '@/api/analyticsEvent'
import { GeneralAnalyticsEventType } from 'common/src/api/websiteFrontendVsWebsiteBackend/analyticsEvent/types'
import {
    LoadingImage,
    LoadingType,
} from '@/components/static/images/owlBranding/loadingImage'

interface CoreLoadingComponentProps {
    loadingType?: LoadingType
    numSkeletonRows?: number
}

interface LoadingComponentProps extends CoreLoadingComponentProps {
    minWidthPx?: number
    timeoutConfig?: {
        timeoutInMs: number
        descriptionForBackendError: string
    }
    maxHeightPx?: number
    hideTitle?: boolean
    styleOverrides?: { padding: number }
}

const buildStyleProps = (props: {
    minWidthPx?: number
    styleOverrides?: { padding: number }
}): { minWidth?: number; padding?: number } | undefined => {
    if (!props.minWidthPx && !props.styleOverrides) {
        return undefined
    }
    const output: { minWidth?: number; maxHeight?: number; padding?: number } =
        {}
    if (props.minWidthPx) {
        output.minWidth = props.minWidthPx
    }

    if (props.styleOverrides) {
        output.padding = props.styleOverrides.padding
    }

    return output
}

export const LoadingComponent: React.FC<LoadingComponentProps> = (
    props
): ReactElement => {
    const timeoutCallback = useCallback(() => {
        logger.error(
            `${props.timeoutConfig.descriptionForBackendError} is taking too long to load with timeout: ${props.timeoutConfig.timeoutInMs}`
        )
        registerEvent(GeneralAnalyticsEventType.ERROR, props.timeoutConfig)
        notification.destroy(NotificationKeys.LOADING_TAKING_TOO_LONG)
        notification.info({
            message: 'Uh-oh',
            description:
                'This is taking an unusually long time to load. You may have better success refreshing the page.',
            key: NotificationKeys.LOADING_TAKING_TOO_LONG,
            className: reusableCssClass.clickMe,
            onClick: () =>
                notification.destroy(NotificationKeys.LOADING_TAKING_TOO_LONG),
            duration: null,
        })
    }, [props.timeoutConfig])
    const timeoutIDRef = useRef<NodeJS.Timeout | null>(null)
    const cancel = useCallback(() => {
        const timeoutID = timeoutIDRef.current
        if (timeoutID) {
            timeoutIDRef.current = undefined
            clearTimeout(timeoutID)
        }
    }, [timeoutIDRef])

    useEffect(() => {
        if (props.timeoutConfig === undefined) return
        timeoutIDRef.current = setTimeout(
            timeoutCallback,
            props.timeoutConfig.timeoutInMs
        )
        return cancel
    }, [timeoutCallback, cancel, props.timeoutConfig])

    return (
        <div
            className={styles.loadingContainerContainer}
            style={buildStyleProps({
                minWidthPx: props.minWidthPx,
                styleOverrides: props.styleOverrides,
            })}
        >
            <div className={styles.loadingContainer}>
                <LoadingImage
                    loadingType={props.loadingType}
                    numSkeletonRows={props.numSkeletonRows}
                    maxHeightPx={props.maxHeightPx}
                    hideTitle={props.hideTitle}
                />
            </div>
        </div>
    )
}

export const FullScreenLoadingComponent: React.FC<CoreLoadingComponentProps> = (
    props
): ReactElement => {
    return (
        <Modal
            open={true}
            closable={false}
            footer={null}
            // These are not in the antd documentation, but as you would expect, prevents a weird animation from happening (using this modal in a non-standard way, kind of a hack right now)
            transitionName={'none'}
            maskTransitionName={'none'}
        >
            <div className={styles.loadingContainerCoverEntireScreen}>
                <LoadingComponent
                    loadingType={props.loadingType}
                    numSkeletonRows={props.numSkeletonRows}
                />
            </div>
        </Modal>
    )
}
