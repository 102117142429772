import { OutlineOrSummary } from 'common/src/commentThread/types'
import { ContentPath } from 'common/src/ContentPath'
import { getOutlinePagePathnameForContentPath } from '@/components/outlines/utils'
import { UrlObject } from 'url'
import { ParsedUrlQuery } from 'querystring'
import { AnnotationSelection } from 'magic-text-box-lexical/src/context/AnnotatedContentContext/AnnotatedContent.types'

// for a wee bit o' type safety
export type AnnotationType = 'comment' | 'star' | 'link'
export interface OutlineContentAnnotationSelectionQuery extends ParsedUrlQuery {
    type: OutlineOrSummary
    startBlockKey: string
    startBlockOffset: string
    endBlockKey: string
    endBlockOffset: string
    annotationType: AnnotationType
}
export const buildHrefForOutlineAnnotationSelection = (
    contentPath: ContentPath,
    type: OutlineOrSummary,
    annotationSelection: AnnotationSelection,
    annotationType: AnnotationType
): UrlObject => {
    return {
        pathname: getOutlinePagePathnameForContentPath(contentPath),
        query: convertAnnotationSelectionToQuery(
            annotationSelection,
            type,
            annotationType
        ),
    }
}

export const convertAnnotationSelectionToQuery = (
    annotationSelection: AnnotationSelection,
    type: OutlineOrSummary,
    annotationType: AnnotationType
): OutlineContentAnnotationSelectionQuery => ({
    type,
    startBlockKey: annotationSelection.start.textNodeId,
    startBlockOffset: annotationSelection.start.characterIndex.toString(),
    endBlockKey: annotationSelection.end.textNodeId,
    endBlockOffset: annotationSelection.end.characterIndex.toString(),
    annotationType,
})

export const convertQueryToAnnotationSelection = (
    query: OutlineContentAnnotationSelectionQuery
): AnnotationSelection => ({
    start: {
        textNodeId: query.startBlockKey,
        characterIndex: parseInt(query.startBlockOffset),
    },
    end: {
        textNodeId: query.endBlockKey,
        characterIndex: parseInt(query.endBlockOffset),
    },
})
