import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useCallback, useEffect, useRef } from 'react'
import { useInterval } from '@/hooks/static/useInterval'
import {
    getCurrentStudyTimeStatus,
    updateStudyTimeRecordForDate2,
} from '@/api/studyTime'
import { isPausedFromInactivityModalOpenAtom } from '@/atoms/isTimerPaused'
import { useActivityMonitor } from '@/hooks/useActivityMonitor'
import { useLogger } from '@/hooks/useLogger'
import {
    fetchAndBuildStudyTimeRecordsMap,
    studyTimerAtom,
    studyTimeRecordsAtom,
} from '@/atoms/accountMaintenance/studyTimeRecords'
import {
    StudyTimeAction,
    UPDATE_STUDY_TIME_INTERVAL_IN_SECONDS,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/studyTime/types'
import { getTimeZone } from '@/components/utils/getTimeZone'
import { GetCurrentStudyTimeStatusResponse } from 'common/src/api/websiteFrontendVsWebsiteBackend/studyTime/responses'
import { frontendDisplayedCourseSelector } from '@/atoms/accountMaintenance/userInfo'

export const useStudyTimer = (): void => {
    const logger = useLogger(useStudyTimer.name)
    const getIsInactive = useActivityMonitor()

    const courseName = useRecoilValue(frontendDisplayedCourseSelector)

    const [
        isPausedFromInactivityModalOpen,
        setIsPausedFromInactivityModalOpen,
    ] = useRecoilState<boolean>(isPausedFromInactivityModalOpenAtom)
    const [studyTimer, setStudyTimer] = useRecoilState(studyTimerAtom)
    const setStudyTimeRecords = useSetRecoilState(studyTimeRecordsAtom)

    // on course change -> reset
    useEffect(() => {
        fetchAndBuildStudyTimeRecordsMap(courseName).then((map) =>
            setStudyTimeRecords(map)
        )
    }, [courseName])

    const updateBackendWithHeartbeat = useCallback(
        async (courseName?: string): Promise<void> => {
            if (!courseName) {
                logger.info(`Not updating backend timer because no course name`)
                return
            }
            const response = await updateStudyTimeRecordForDate2({
                courseName,
                action: StudyTimeAction.HEARTBEAT,
                timeZone: getTimeZone(),
                isFirstRequest: isFirstIntervalRun.current,
            })
            return response.data.payload
        },
        [logger]
    )

    const updateFrontendWithBackend = useCallback(
        async (
            isFirstRequest: boolean,
            courseName?: string
        ): Promise<GetCurrentStudyTimeStatusResponse> => {
            if (!courseName) {
                logger.info(
                    `Not updating frontend with backend because no course name`
                )
                return
            }

            if (!studyTimer) {
                logger.error(
                    `No study timer? Doing nothing. This should not happen`
                )
                return
            }

            if (studyTimer.isPaused) {
                logger.info(`Study timer is paused. Not fetching`)
                return
            }
            const response = await getCurrentStudyTimeStatus(
                courseName,
                getTimeZone(),
                isFirstRequest
            )

            if (!response?.data?.success) {
                logger.info(
                    `Did not get a successful response, not updating frontend`
                )
                return
            }

            logger.info(
                `Received payload ${JSON.stringify(
                    response.data.payload
                )} from backend. Updating frontend`
            )
            setStudyTimer(response.data.payload)
            return response.data.payload
        },
        [logger, setStudyTimer, studyTimer]
    )

    const isFirstIntervalRun = useRef<boolean>(true)
    useInterval(() => {
        updateFrontendWithBackend(isFirstIntervalRun.current, courseName).then(
            (response) => {
                try {
                    if (!response) {
                        logger.info(`No response. Not sending heartbeat`)
                        return
                    }

                    logger.info(`Frontend updated with backend`)

                    // no reason to have the modal open if we are not paused
                    if (!response.isPaused && isPausedFromInactivityModalOpen) {
                        setIsPausedFromInactivityModalOpen(false)
                    }

                    const isInactive = getIsInactive()
                    // if we are paused due to inactivity and the modal is not already open, open the modal
                    if (
                        response.isPaused &&
                        response.reason === 'INACTIVITY' &&
                        !isPausedFromInactivityModalOpen &&
                        isInactive
                    ) {
                        logger.info(
                            `Reversing heartbeats, then setting PausedFromInactivityModal to Open`
                        )
                        updateStudyTimeRecordForDate2({
                            action: StudyTimeAction.REVERSE_HEARTBEATS,
                            timeZone: getTimeZone(),
                            isFirstRequest: false,
                            courseName,
                        }).then(() => {
                            setIsPausedFromInactivityModalOpen(true)
                        })
                        return
                    }

                    // if we are paused due to manual, do not send a heartbeat
                    if (response.isPaused && response.reason === 'MANUAL') {
                        logger.info(
                            `Paused due to manual, not sending heartbeat`
                        )
                        return
                    }

                    // if we are inactive, do not send heartbeats so backend can realize it's inactive in the next cycle
                    if (isInactive) {
                        logger.info(
                            `Frontend is inactive, not sending heartbeat`
                        )
                        return
                    }

                    if (isPausedFromInactivityModalOpen) {
                        logger.info(
                            `isPausedFromInactivityModalOpen is open, not sending heartbeat`
                        )
                        return
                    }

                    updateBackendWithHeartbeat(courseName).then(() =>
                        logger.info(`Backend heartbeat triggered`)
                    )
                } finally {
                    isFirstIntervalRun.current = false
                }
            }
        )
    }, UPDATE_STUDY_TIME_INTERVAL_IN_SECONDS * 1_000) // always run the interval so we can bring all tabs in sync with backend
}
