import {
    JEPNotification,
    JEPNotificationCommentMetadata,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/notifications/types'
import { ContentType } from 'common/src/commentThread/types'
import { invalidateNotecardCommentThread } from '@/atoms/contentCommentThreads/notecardCommentThreads'
import { invalidatePracticeProblemCommentThread } from '@/atoms/contentCommentThreads/practiceProblemCommentThreads'
import { UrlObject } from 'url'
import { getIndividualNotecardPagePathForNotecardID } from '@/components/notecards/utils'
import { getIndividualProblemPagePathForPracticeProblemID } from '@/components/practiceProblems/utils'
import { buildHrefForOutlineAnnotationSelection } from '@/utils/buildHrefForOutlineAnnotationSelection'
import { decodeOutlineCommentData } from 'common/src/commentThread/OutlineCommentData'
import { jepAssertNever } from 'common/src/utils/jepAssertNever'

export interface NotificationListItemProps {
    setIsModalVisible: (isVisible: boolean) => void
    notification: JEPNotification
}

export const invalidateContentCommentThread = (
    commentNotificationMetadata: JEPNotificationCommentMetadata
): void => {
    switch (commentNotificationMetadata.contentType) {
        case ContentType.NOTECARD: {
            invalidateNotecardCommentThread(
                commentNotificationMetadata.contentId
            )
            break
        }
        case ContentType.PRACTICE_PROBLEM: {
            invalidatePracticeProblemCommentThread(
                commentNotificationMetadata.contentId
            )
            break
        }
        case ContentType.OUTLINE:
        case ContentType.SUMMARY: {
            // invalidateOutlineCommentThread(
            //     commentNotificationMetadata.contentId
            // )
            break
        }
        default:
            jepAssertNever(
                commentNotificationMetadata.contentType,
                'Unhandled contenType to invalidateContentCommentThread'
            )
    }
}

export const buildHrefForCommentContentTypeAndId = (
    contentType: ContentType,
    contentId: string
): UrlObject | string => {
    switch (contentType) {
        case ContentType.PRACTICE_PROBLEM:
            return {
                pathname:
                    getIndividualProblemPagePathForPracticeProblemID(contentId),
                query: { isReviewMode: true },
            }

        case ContentType.NOTECARD:
            return getIndividualNotecardPagePathForNotecardID(contentId)
        case ContentType.SUMMARY:
        case ContentType.OUTLINE: {
            const { contentPath, type, annotationSelection } =
                decodeOutlineCommentData(contentId)
            return buildHrefForOutlineAnnotationSelection(
                contentPath,
                type,
                annotationSelection,
                'comment'
            )
        }
        default:
            jepAssertNever(
                contentType,
                'Unhandled contentType to buildHrefForCommentContentTypeAndId'
            )
    }
}
