import {
    ContentIDToNameMap,
    ContentTreeMap,
} from 'common/src/ContentTree/types'

export type SetTopicMap<V = Set<string>> = ContentTreeMap<V>

export enum SelectedTopicMapOperation {
    add = 'add',
    delete = 'delete',
}

export interface BaseSetCreator {
    topicMap: SetTopicMap
    selectedTopicMap: SetTopicMap
    depth: number
    contentIDToNameMap: ContentIDToNameMap
    starredSet: Set<string> // values are ids
}
