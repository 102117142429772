import { CancelToken } from 'axios'
import { APIResponseWrapper } from '@/api/types'
import { GenericContentRequest } from 'common/src/api/core/courses/requests'
import { ApiMethod } from 'common/src/api/types'
import { JEPNotification } from 'common/src/api/websiteFrontendVsWebsiteBackend/notifications/types'
import { GetNotificationsRequest } from 'common/src/api/websiteFrontendVsWebsiteBackend/notifications/requests'
import { callBackend } from '../../server/callBackend'

const PREFIX = '/wb/notification/'

export const getNotifications = async (
    courseName: string,
    maxNotificationCount: number | null,
    cancelToken: CancelToken = null
): APIResponseWrapper<JEPNotification[]> => {
    return await callBackend<GetNotificationsRequest, JEPNotification[]>(
        PREFIX + 'getNotifications',
        ApiMethod.GET,
        {
            courseName,
            maxNotificationCount,
        },
        cancelToken
    )
}

export const markNotificationsAsSeen = async (
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<void> => {
    return await callBackend<GenericContentRequest, null>(
        PREFIX + 'markNotificationsAsSeen',
        ApiMethod.POST,
        {
            courseName,
        },
        cancelToken
    )
}
