import {
    GoogleLoginRequest,
    LoginRequest,
    ResetPasswordRequest,
    SendPasswordRecoveryEmailRequest,
    SignUpRequest,
    VerifyEmailRequest,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/authentication/requests'
import { ApiMethod, EmptyRequest, EmptyResponse } from 'common/src/api/types'
import { CancelToken } from 'axios'
import { APIResponseWrapper } from '@/api/types'
import {
    GoogleUserInfoResponse,
    UserInfoResponse,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/responses'
import { callBackend } from '../../server/callBackend'
import { CourseConfigType } from 'common/src/courseConfig'

const PREFIX = '/wb/authentication/'

export const logout = async (
    cancelToken: CancelToken = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<EmptyRequest, EmptyResponse>(
        PREFIX + 'logout',
        ApiMethod.POST,
        null,
        cancelToken
    )
}

export const login = async (
    email: string,
    password: string
): APIResponseWrapper<UserInfoResponse> => {
    return await callBackend<LoginRequest, UserInfoResponse>(
        PREFIX + 'login',
        ApiMethod.POST,
        {
            email,
            password,
        }
    )
}

export const signUp = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    courseConfigType: CourseConfigType
): APIResponseWrapper<UserInfoResponse> => {
    return await callBackend<SignUpRequest, UserInfoResponse>(
        PREFIX + 'signUp',
        ApiMethod.POST,
        {
            email,
            password,
            firstName,
            lastName,
            courseConfigType,
        }
    )
}

export const googleLogin = async (
    tokenID: string,
    courseConfigType: CourseConfigType
): APIResponseWrapper<GoogleUserInfoResponse> => {
    return await callBackend<GoogleLoginRequest, GoogleUserInfoResponse>(
        PREFIX + 'googleLogin',
        ApiMethod.POST,
        {
            tokenID,
            courseConfigType,
        }
    )
}

export const completeEmailVerification = async (
    userID: string,
    token: string
): APIResponseWrapper<UserInfoResponse> => {
    return await callBackend<VerifyEmailRequest, UserInfoResponse>(
        PREFIX + 'completeEmailVerification',
        ApiMethod.POST,
        {
            userID,
            token,
        }
    )
}

export const sendPasswordRecoveryEmail = async (
    email: string
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<SendPasswordRecoveryEmailRequest, EmptyResponse>(
        PREFIX + 'sendPasswordRecoveryEmail',
        ApiMethod.POST,
        {
            email,
        }
    )
}

export const resetPassword = async (
    userID: string,
    token: string,
    password: string
): APIResponseWrapper<UserInfoResponse> => {
    return await callBackend<ResetPasswordRequest, UserInfoResponse>(
        PREFIX + 'resetPassword',
        ApiMethod.POST,
        {
            userID,
            token,
            password,
        }
    )
}
