import React, { ReactElement, useCallback, useRef, useState } from 'react'
import Input from 'antd/lib/input'
import Form, { FormInstance } from 'antd/lib/form'
import { signUp } from '@/api/authentication'
import { frontendLogin } from '@/auth/frontendLogin'
import styles from '../Authenticator.module.scss'
import {
    clearAuthenticationError,
    handleAuthenticationError,
} from '@/components/static/Authenticator/handleAuthenticationError'
import {
    GenericButton,
    GenericButtonType,
} from '@/components/static/ui/Button/GenericButton/GenericButton'
import { useRouter } from 'next/router'
import { ISignUpForm } from '@/components/static/Authenticator/SignUpForm/SignUpForm.types'
import { AuthenticatorRoutingProps } from '@/components/static/Authenticator/Authenticator.types'
import {
    handleAuthenticatorClick,
    validateConfirmPasswordPromise,
    validatePasswordPromise,
} from '@/components/static/Authenticator/utils'
import { GoogleLoginComponent } from '../GoogleLoginComponent/GoogleLoginComponent'
import { CourseConfigType } from 'common/src/courseConfig'

interface SignUpFormProps {
    setIsModalVisible: (isModalVisible: boolean) => void
    authenticatorPayload: AuthenticatorRoutingProps
    courseConfigType: CourseConfigType
}

export const SignUpForm: React.FC<SignUpFormProps> = (props): ReactElement => {
    const formRef = useRef<FormInstance<ISignUpForm>>(null)
    const router = useRouter()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const handleSubmit = useCallback(
        async (values: ISignUpForm): Promise<void> => {
            setIsLoading(true)
            clearAuthenticationError()
            const response = await signUp(
                values.signUpEmail,
                values.signUpPassword,
                values.firstName,
                values.lastName,
                props.courseConfigType
            )
            if (!response.data.success) {
                handleAuthenticationError(response.data.error, {
                    message: 'Issue signing up',
                    description:
                        'Something went wrong trying to sign up. Please refresh the page and try again.',
                })
            } else {
                await frontendLogin(response.data.payload)
                await handleAuthenticatorClick(
                    props.authenticatorPayload,
                    router,
                    null
                )
                props.setIsModalVisible(false)
            }
            setIsLoading(false)
        },
        [props, router]
    )

    const validateConfirmPassword = useCallback(
        (_: unknown, confirmPassword: string): Promise<void> => {
            const otherPassword =
                formRef.current.getFieldValue('signUpPassword')
            return validateConfirmPasswordPromise(
                confirmPassword,
                otherPassword
            )
        },
        []
    )

    // TODO: When we update antd, we MAY be able to take advantage of `await formRef.current.validateFields({ validateOnly: true })`
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const handleChange = useCallback(async (): Promise<void> => {
        clearAuthenticationError()
    }, [])

    return (
        <div className={styles.signUpForm}>
            <GoogleLoginComponent
                buttonText={'Sign up with Google'}
                setIsModalVisible={props.setIsModalVisible}
                payload={props.authenticatorPayload}
                courseConfigType={props.courseConfigType}
            />
            <div className={styles.googleSignupDivider}>OR</div>
            <Form onFinish={handleSubmit} onChange={handleChange} ref={formRef}>
                <Form.Item
                    name={'signUpEmail'}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email address',
                        },
                        {
                            type: 'email',
                            message: 'Please enter a valid email address',
                        },
                    ]}
                >
                    <Input placeholder="Email Address" />
                </Form.Item>
                <Form.Item
                    name={'firstName'}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please input your first name',
                        },
                    ]}
                >
                    <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item
                    name={'lastName'}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please input your last name',
                        },
                    ]}
                >
                    <Input placeholder="Last Name" />
                </Form.Item>
                <Form.Item
                    name={'signUpPassword'}
                    hasFeedback
                    dependencies={['confirmPassword']}
                    rules={[
                        {
                            required: true,
                            message: 'Please input a strong password',
                        },
                        { validator: validatePasswordPromise },
                    ]}
                >
                    <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item
                    name={'confirmPassword'}
                    hasFeedback
                    dependencies={['signUpPassword']}
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password',
                        },
                        { validator: validateConfirmPassword },
                    ]}
                >
                    <Input.Password placeholder="Confirm Password" />
                </Form.Item>
                <div className={'row'}>
                    <div className={'btn-container'}>
                        <GenericButton
                            onClick={null}
                            htmlType={
                                'submit'
                            } /* will automatically call handleSubmit on click or press enter */
                            loading={isLoading}
                            text={'Sign Up'}
                            type={GenericButtonType.greenNoBackground}
                            disabled={isDisabled}
                        />
                    </div>
                </div>
            </Form>
        </div>
    )
}
