import { ContentType } from 'common/src/commentThread/types'
import { WBSearchResponse } from 'common/src/elasticSearch/responses'
import React, { ReactElement, useMemo } from 'react'
import styles from './SearchResults.module.scss'
import { SearchResultHeader } from '@/components/search/SearchModal/SearchResultHeader/SearchResultHeader'
import { AccessType } from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/types'
import { SearchResult } from '@/components/search/SearchModal/SearchResult/SearchResult'

interface SearchResultsProps {
    results: WBSearchResponse
    contentTypeFoci: ContentType[]
    setContentTypeFoci: (contentType: ContentType[]) => void
    setIsSearchModalVisible: (isVisible: boolean) => void
    accessTypes: AccessType[]
}
export const SearchResults: React.FC<SearchResultsProps> = (
    props
): ReactElement => {
    const visibleOutlineSearchResults = useMemo((): ReactElement[] => {
        if (!props.contentTypeFoci.includes(ContentType.OUTLINE)) {
            return []
        }
        return props.results.outlines.map((searchResult, index) => {
            return (
                <SearchResult
                    setIsSearchModalVisible={props.setIsSearchModalVisible}
                    searchResult={searchResult}
                    searchContext={'outlines'}
                    isDisabled={
                        !props.accessTypes.includes(AccessType.OUTLINES)
                    }
                    key={index}
                />
            )
        })
    }, [props])

    const visibleNotecardSearchResults = useMemo((): ReactElement[] => {
        if (!props.contentTypeFoci.includes(ContentType.NOTECARD)) {
            return []
        }
        return props.results.notecards.map((searchResult, index) => {
            return (
                <SearchResult
                    setIsSearchModalVisible={props.setIsSearchModalVisible}
                    searchResult={searchResult}
                    searchContext={'notecards'}
                    isDisabled={
                        !props.accessTypes.includes(AccessType.NOTECARDS)
                    }
                    key={index}
                />
            )
        })
    }, [props])

    const visiblePracticeProblemSearchResults = useMemo((): ReactElement[] => {
        if (!props.contentTypeFoci.includes(ContentType.PRACTICE_PROBLEM)) {
            return []
        }
        return props.results.practiceProblems.map((searchResult, index) => {
            return (
                <SearchResult
                    setIsSearchModalVisible={props.setIsSearchModalVisible}
                    searchResult={searchResult}
                    searchContext={'practiceProblems'}
                    isDisabled={
                        !props.accessTypes.includes(
                            AccessType.PRACTICE_PROBLEMS
                        )
                    }
                    key={index}
                />
            )
        })
    }, [props])

    return (
        <div className={styles.searchResults}>
            <div className={styles.searchResultsColumn}>
                <SearchResultHeader
                    contentType={ContentType.NOTECARD}
                    numResults={props.results.notecards.length}
                    setContentTypeFoci={props.setContentTypeFoci}
                    contentTypeFoci={props.contentTypeFoci}
                    isAccessLocked={
                        !props.accessTypes.includes(AccessType.NOTECARDS)
                    }
                    arrowDirection={
                        props.contentTypeFoci.includes(ContentType.NOTECARD)
                            ? 'down'
                            : 'right'
                    }
                    addOrReplaceOnClick={'add'}
                />
                <div className={styles.searchResultsBodyContainer}>
                    {visibleNotecardSearchResults}
                </div>
            </div>
            <div className={styles.searchResultsColumn}>
                <SearchResultHeader
                    contentType={ContentType.PRACTICE_PROBLEM}
                    numResults={props.results.practiceProblems.length}
                    setContentTypeFoci={props.setContentTypeFoci}
                    contentTypeFoci={props.contentTypeFoci}
                    isAccessLocked={
                        !props.accessTypes.includes(
                            AccessType.PRACTICE_PROBLEMS
                        )
                    }
                    arrowDirection={
                        props.contentTypeFoci.includes(
                            ContentType.PRACTICE_PROBLEM
                        )
                            ? 'down'
                            : 'right'
                    }
                    addOrReplaceOnClick={'add'}
                />
                <div className={styles.searchResultsBodyContainer}>
                    {visiblePracticeProblemSearchResults}
                </div>
            </div>
            <div className={styles.searchResultsColumn}>
                <SearchResultHeader
                    contentType={ContentType.OUTLINE}
                    numResults={props.results.outlines.length}
                    setContentTypeFoci={props.setContentTypeFoci}
                    contentTypeFoci={props.contentTypeFoci}
                    isAccessLocked={
                        !props.accessTypes.includes(AccessType.OUTLINES)
                    }
                    arrowDirection={
                        props.contentTypeFoci.includes(ContentType.OUTLINE)
                            ? 'down'
                            : 'right'
                    }
                    addOrReplaceOnClick={'add'}
                />
                <div className={styles.searchResultsBodyContainer}>
                    {visibleOutlineSearchResults}
                </div>
            </div>
        </div>
    )
}
