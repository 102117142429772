import React, { ReactElement, useCallback, useMemo } from 'react'
import { isContactInstructorModalVisibleAtom } from '@/atoms/isContactInstructorModalVisible'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
    GenericButton,
    GenericButtonType,
} from '@/components/static/ui/Button/GenericButton/GenericButton'
import styles from './ContactInstructorButton.module.scss'

import { frontendDisplayedCourseHasActiveSubscriptionSelector } from '@/atoms/accountMaintenance/userInfo'
import { Tooltip } from 'antd'

interface ContactInstructorButtonProps {
    isSmallDesktopMode: boolean
}
export const ContactInstructorButton: React.FC<ContactInstructorButtonProps> = (
    props
): ReactElement => {
    const setIsContactInstructorModalVisible = useSetRecoilState(
        isContactInstructorModalVisibleAtom
    )
    const handleClick = useCallback((): void => {
        setIsContactInstructorModalVisible(true)
    }, [setIsContactInstructorModalVisible])

    const hasActiveSubscription = useRecoilValue(
        frontendDisplayedCourseHasActiveSubscriptionSelector
    )

    const button = useMemo((): ReactElement => {
        const base = (
            <GenericButton
                type={GenericButtonType.greenNoBackground}
                text={
                    props.isSmallDesktopMode ? (
                        <div>?</div>
                    ) : (
                        'Contact Instructor'
                    )
                }
                onClick={handleClick}
                disabled={!hasActiveSubscription}
            />
        )
        if (props.isSmallDesktopMode) {
            return (
                <Tooltip title={'Contact Instructor'} placement={'right'}>
                    {base}
                </Tooltip>
            )
        }
        return base
    }, [handleClick, hasActiveSubscription, props.isSmallDesktopMode])

    return (
        <div className={styles.contactInstructorButtonContainer}>
            <div
                className={styles.contactInstructorButton}
                style={
                    // matches the study timer button size
                    props.isSmallDesktopMode
                        ? {
                              width: '48px',
                              height: '40px',
                          }
                        : undefined
                }
            >
                {button}
            </div>
        </div>
    )
}
