import { logger } from '@/logging/FrontendLogger'
import { logout } from '@/api/authentication'
import { setIsLoggedIn } from '@/auth/types'

export const frontendLogout = async (): Promise<void> => {
    logger.info(`Frontend logging out`)
    setIsLoggedIn(false)
    await logout()
    // resets recoil by doing a hard refresh
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.location = window.location.origin
}
