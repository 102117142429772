import React, { ReactElement, useMemo } from 'react'
import styles from './HeaderAnnouncement.module.scss'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import Link from 'next/link'
import {
    FIRST_PURCHASE_AT_JEP_DISCOUNT,
    FIRST_PURCHASE_AT_JEP_DISCOUNT_CODE,
} from 'common/src/account/consts'
import { NotificationKeys } from '@/utils/notificationKeys'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import notification from 'antd/lib/notification'
import CopyToClipboard from 'react-copy-to-clipboard'
export const HeaderAnnouncement: React.FC<{ isLoggedIn: boolean }> = (
    props
): ReactElement => {
    const base = useMemo((): ReactElement => {
        return (
            <CopyToClipboard
                text={FIRST_PURCHASE_AT_JEP_DISCOUNT_CODE}
                onCopy={() =>
                    notification.success({
                        message: `Successfully copied invite code`,
                        duration: 3, // seconds
                        onClick: () => {
                            notification.destroy(
                                NotificationKeys.SUCCESSFULLY_COPIED_INVITE_CODE
                            )
                        },
                        className: reusableCssClass.clickMe,
                        key: NotificationKeys.SUCCESSFULLY_COPIED_INVITE_CODE,
                    })
                }
            >
                <div className={styles.headerAnnouncement}>
                    Use code {FIRST_PURCHASE_AT_JEP_DISCOUNT_CODE} for&nbsp;
                    {(FIRST_PURCHASE_AT_JEP_DISCOUNT * 100).toFixed(0) +
                        '%'}{' '}
                    off your first purchase.
                </div>
            </CopyToClipboard>
        )
    }, [])
    if (props.isLoggedIn) {
        return base
    }

    return (
        <Link
            href={UiLayoutConstant.COURSES_PAGE}
            className={styles.headerAnnouncement} // put style here again to a-tag specific styling
        >
            {base}
        </Link>
    )
}
