import { IProblemUserStats } from 'common/src/practiceProblems/types'
import { atom, selector } from 'recoil'
import { contentTreeStateAtom } from '@/atoms/contentTree'
import { getProblemUserStats } from '@/api/practiceProblems'
import { LoggerSelectorFamily } from '@/atoms/logger'
import { createQuizCreator } from '@/frontendLogic/practiceProblems/visualizations/QuizCreator/QuizCreator'
import { defaultInvalidationAtom, InvalidationAtom } from '@/atoms/types'
import { resetOnSetEffect } from '@/atoms/atomEffects/resetOnSetEffect'
import { QuizCreator } from '@/frontendLogic/practiceProblems/visualizations/QuizCreator/QuizCreator.types'
import { logAtomChangesEffect } from '@/atoms/atomEffects/logAtomChangesEffect'
import { PracticeProblemTopicMastery } from '@/frontendLogic/practiceProblems/visualizations/PracticeProblemTopicMastery/PracticeProblemTopicMastery.types'
import { createPracticeProblemTopicMastery } from '@/frontendLogic/practiceProblems/visualizations/PracticeProblemTopicMastery/create'
import { PracticeProblemLearningObjectivesScoreMap } from '@/frontendLogic/practiceProblems/visualizations/PracticeProblemLearningObjectives/PracticeProblemLearningObjectives.types'
import { createPracticeProblemLearningObjectivesMastery } from '@/frontendLogic/practiceProblems/visualizations/PracticeProblemLearningObjectives/create'
import {
    frontendDisplayedCourseSelector,
    userInfoStateAtom,
} from '@/atoms/accountMaintenance/userInfo'

export const problemUserStatsAtom = atom<IProblemUserStats[] | null>({
    key: 'problemUserStatsAtom',
    default: selector<IProblemUserStats[] | null>({
        key: 'problemUserStatsFetcher',
        get: async ({ get }) => {
            const frontendDisplayedCourse = get(frontendDisplayedCourseSelector)
            const logger = get(LoggerSelectorFamily(problemUserStatsAtom.key))
            if (!frontendDisplayedCourse) {
                logger.warn(
                    `No frontend displayed course, so problem user stats will not be retrieved`
                )
                return null
            }

            get(userInfoStateAtom) // forces update upon new login
            get(problemUserStatsInvalidationAtom)
            logger.info(`Retrieving initial problem user stats`)
            const response = await getProblemUserStats(frontendDisplayedCourse)
            return response.data.payload?.problemUserStatsArray ?? null
        },
    }),
})

// key is problem ID
export const problemUserStatsMapSelector = selector<Map<
    string,
    IProblemUserStats
> | null>({
    key: 'problemUserStatsMapSelector',
    get: ({ get }) => {
        const problemUserStats = get(problemUserStatsAtom)
        if (!problemUserStats) {
            get(LoggerSelectorFamily(problemUserStatsMapSelector.key)).warn(
                'No problem user stats, returning null'
            )
            return null
        }

        const map = new Map<string, IProblemUserStats>()
        for (const practiceProblemUserStat of problemUserStats) {
            map.set(practiceProblemUserStat.id, practiceProblemUserStat)
        }
        return map
    },
})

export const problemUserStatsInvalidationAtom = atom<InvalidationAtom>({
    key: 'problemUserStatsInvalidationAtom',
    default: defaultInvalidationAtom,
    effects: [
        logAtomChangesEffect('Problem User Stats Invalidation Atom'),
        resetOnSetEffect(problemUserStatsAtom),
    ],
})

export const quizCreatorSelector = selector<QuizCreator | null>({
    key: 'quizCreatorSelector',
    get: ({ get }) => {
        const logger = get(LoggerSelectorFamily(quizCreatorSelector.key))
        const problemUserStats = get(problemUserStatsAtom)
        if (!problemUserStats) {
            logger.warn('No problem user stats, returning null')
            return null
        }

        const contentTreeState = get(contentTreeStateAtom)
        if (contentTreeState === null) {
            logger.warn('No content tree state, returning null')
            return null
        }

        return createQuizCreator(
            problemUserStats,
            contentTreeState.contentTree,
            false
        )
    },
})

export const practiceProblemTopicMasterySelector =
    selector<PracticeProblemTopicMastery | null>({
        key: 'practiceProblemTopicMasterySelector',
        get: ({ get }) => {
            const logger = get(
                LoggerSelectorFamily(practiceProblemTopicMasterySelector.key)
            )
            const problemUserStats = get(problemUserStatsAtom)
            if (!problemUserStats) {
                logger.warn('No problem user stats, returning null')
                return null
            }

            const contentTreeState = get(contentTreeStateAtom)
            if (contentTreeState === null) {
                logger.warn('No content tree state, returning null')
                return null
            }

            logger.info('Generating practice problem topic mastery')
            return createPracticeProblemTopicMastery(
                problemUserStats,
                contentTreeState.contentTree
            )
        },
    })

export const practiceProblemLearningObjectivesMasterySelector =
    selector<PracticeProblemLearningObjectivesScoreMap | null>({
        key: 'practiceProblemLearningObjectivesMasterySelector',
        get: ({ get }) => {
            const logger = get(
                LoggerSelectorFamily(practiceProblemTopicMasterySelector.key)
            )
            const problemUserStats = get(problemUserStatsAtom)
            if (problemUserStats === null) {
                logger.warn('No problem user stats, returning null')
                return null
            }

            const contentTreeState = get(contentTreeStateAtom)
            if (contentTreeState === null) {
                logger.warn('No content tree state, returning null')
                return null
            }

            logger.info(
                'Generating practice problem learning objectives mastery'
            )
            return createPracticeProblemLearningObjectivesMastery(
                problemUserStats.filter(
                    (problemUserStat) => !problemUserStat.isNotecardProblem
                ),
                contentTreeState.topicToLearningObjectivesMap
            )
        },
    })

export const questionProgressSelector = selector<{
    numAttempted: number
    total: number
}>({
    key: 'questionProgressSelector',
    get: ({ get }) => {
        const problemUserStats = get(problemUserStatsAtom)
        let numAttempted = 0
        for (const problemUserStat of problemUserStats) {
            if (problemUserStat.numAttempts > 0) {
                numAttempted++
            }
        }

        return { numAttempted, total: problemUserStats.length }
    },
})
