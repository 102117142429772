import React, { ReactElement, useState } from 'react'
import Modal from 'antd/lib/modal/Modal'

import { DisplayErrorProps } from '@/components/DisplayError/DisplayError.types'
import { frontendLogout } from '@/auth/frontendLogout'

export const DisplayError: React.FC<DisplayErrorProps> = (
    props
): ReactElement => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(props.popUp)

    const handleClose = async (): Promise<void> => {
        setIsModalVisible(false)
        if (props.logout) {
            await frontendLogout()
        }
    }

    const renderErrorText = (): ReactElement => {
        return <div className={'error-text'}>{props.message}</div>
    }

    const renderErrorModal = (): ReactElement => {
        return (
            <Modal
                title="Error"
                centered
                footer={null}
                open={isModalVisible}
                onCancel={handleClose}
            >
                <div className={'error-text'}>{props.message}</div>
            </Modal>
        )
    }

    if (!props.message) {
        return <div />
    }

    let errorDisplay: ReactElement
    if (props.popUp) {
        errorDisplay = renderErrorModal()
    } else {
        errorDisplay = renderErrorText()
    }

    return <div>{errorDisplay}</div>
}
