import React, { ReactElement, useMemo } from 'react'
import styles from './TwoPartPillButton.module.scss'
import Tooltip from 'antd/lib/tooltip'
import Button from 'antd/lib/button/button'

export interface TwoPartPillButtonProps {
    leftSideContent: ReactElement
    rightSideContent: ReactElement
    leftSideOnClick: (event: React.MouseEvent) => void
    rightSideOnClick: (event: React.MouseEvent) => void
    leftTooltipText?: string
    rightTooltipText?: string
    selectedSide?: 'left' | 'right'
    leftSideDisabled?: boolean
    rightSideDisabled?: boolean
    isHidden?: boolean
}

export const TwoPartPillButton: React.FC<TwoPartPillButtonProps> = (
    props
): ReactElement => {
    const leftSide = useMemo((): ReactElement => {
        const leftSideButton = (
            <Button
                className={`${styles.leftPill} ${styles.pill} ${props.selectedSide === 'left' ? styles.isSelected : ''}`}
                onClick={props.leftSideOnClick}
                disabled={props.leftSideDisabled}
            >
                {props.leftSideContent}
            </Button>
        )
        if (props.leftTooltipText) {
            return (
                <Tooltip title={props.leftTooltipText} placement={'top'}>
                    {leftSideButton}
                </Tooltip>
            )
        }
        return leftSideButton
    }, [
        props.leftSideContent,
        props.leftSideDisabled,
        props.leftSideOnClick,
        props.leftTooltipText,
        props.selectedSide,
    ])

    const rightSide = useMemo((): ReactElement => {
        const rightSideButton = (
            <Button
                className={`${styles.rightPill} ${styles.pill} ${props.selectedSide === 'right' ? styles.isSelected : ''}`}
                onClick={props.rightSideOnClick}
                disabled={props.rightSideDisabled}
            >
                {props.rightSideContent}
            </Button>
        )
        if (props.rightTooltipText) {
            return (
                <Tooltip title={props.rightTooltipText} placement={'top'}>
                    {rightSideButton}
                </Tooltip>
            )
        }
        return rightSideButton
    }, [
        props.rightSideContent,
        props.rightSideDisabled,
        props.rightSideOnClick,
        props.rightTooltipText,
        props.selectedSide,
    ])
    return (
        <div
            className={styles.twoPartPillButtonContainer}
            style={{ visibility: props.isHidden ? 'hidden' : 'initial' }}
        >
            {leftSide}
            {rightSide}
        </div>
    )
}
