import { ApiMethod, EmptyResponse } from 'common/src/api/types'
import {
    DeleteS3FileRequest,
    DeleteUploadedUserExcelFileRequest,
    GetSignedUrlForDownloadRequest,
    GetSignedUrlForMtbUploadRequest,
    GetSignedUrlForUserDownloadRequest,
    GetSignedUrlForUserUploadRequest,
    GetSignedUrlRequestQuery,
    UploadImageRequest,
} from 'common/src/api/s3/requests'
import {
    GetSignedUrlAndS3KeyResponse,
    GetSignedUrlResponse,
    UploadImageResponse,
} from 'common/src/api/s3/responses'
import { APIResponseWrapper } from '@/api/types'
import { callBackend } from '../../server/callBackend'

const PREFIX = '/wb/privateS3'

export const getSignedUrlForS3Key = async (s3Key: string): Promise<string> => {
    const response = await callBackend<
        GetSignedUrlRequestQuery,
        GetSignedUrlResponse
    >(PREFIX + '/getMagicTextBoxSignedUrl', ApiMethod.GET, { s3Key })
    return response.data.payload.signedUrl
}

export const getPreSignedUrlForPut = async (
    fileExtension: string
): Promise<GetSignedUrlAndS3KeyResponse> => {
    const response = await callBackend<
        GetSignedUrlForMtbUploadRequest,
        GetSignedUrlAndS3KeyResponse
    >(
        PREFIX + '/getSignedUrlAndS3KeyForMagicTextBoxImageUpload',
        ApiMethod.POST,
        { fileExtension }
    )
    return response.data.payload
}

export const deleteUploadedImage = async (s3Key: string): Promise<void> => {
    await callBackend<DeleteS3FileRequest, EmptyResponse>(
        PREFIX + '/deleteMagicTextBoxImage',
        ApiMethod.POST,
        { s3Key }
    )
}

export const uploadB64Image = async (b64Img: string): Promise<string> => {
    const response = await callBackend<UploadImageRequest, UploadImageResponse>(
        PREFIX + '/uploadMagicTextBoxImage',
        ApiMethod.POST,
        { b64Img }
    )
    return response.data.payload.s3Key
}

export const getSignedUrlForPracticeProblemExcelFileDownload = async (
    courseName: string,
    id: string,
    downloadedFileName: string
): APIResponseWrapper<GetSignedUrlResponse> => {
    return await callBackend<
        GetSignedUrlForDownloadRequest,
        GetSignedUrlResponse
    >(
        PREFIX + '/getSignedUrlForPracticeProblemExcelFileDownload',
        ApiMethod.GET,
        { courseName, id, downloadedFileName }
    )
}

export const getSignedUrlForPracticeProblemExcelSolutionFileDownload = async (
    courseName: string,
    id: string,
    downloadedFileName: string
): APIResponseWrapper<GetSignedUrlResponse> => {
    return await callBackend<
        GetSignedUrlForDownloadRequest,
        GetSignedUrlResponse
    >(
        PREFIX + '/getSignedUrlForPracticeProblemExcelSolutionFileDownload',
        ApiMethod.GET,
        { courseName, id, downloadedFileName }
    )
}

export const getSignedUrlForPracticeProblemExcelFileUpload = async (
    courseName: string,
    problemSetID: string,
    index: number
): APIResponseWrapper<GetSignedUrlAndS3KeyResponse> => {
    return await callBackend<
        GetSignedUrlForUserUploadRequest,
        GetSignedUrlAndS3KeyResponse
    >(
        PREFIX + '/getSignedUrlForPracticeProblemExcelFileUpload',
        ApiMethod.POST,
        { courseName, problemSetID, index }
    )
}

export const getSignedUrlForPracticeProblemUserExcelFileDownload = async (
    courseName: string,
    problemSetID: string,
    index: number,
    downloadedFileName: string
): APIResponseWrapper<GetSignedUrlResponse | null> => {
    return await callBackend<
        GetSignedUrlForUserDownloadRequest,
        GetSignedUrlResponse
    >(
        PREFIX + '/getSignedUrlForPracticeProblemUserExcelFileDownload',
        ApiMethod.GET,
        { courseName, problemSetID, index, downloadedFileName }
    )
}

export const deleteUploadedExcelFile = async (
    courseName: string,
    problemSetID: string,
    index: number
): APIResponseWrapper<GetSignedUrlResponse> => {
    return await callBackend<DeleteUploadedUserExcelFileRequest, EmptyResponse>(
        PREFIX + '/deleteUploadedExcelFile',
        ApiMethod.POST,
        { courseName, problemSetID, index }
    )
}

export const getSignedUrlForSupportUpload =
    async (): APIResponseWrapper<GetSignedUrlAndS3KeyResponse> => {
        return await callBackend<null, GetSignedUrlAndS3KeyResponse>(
            PREFIX + '/getSignedUrlForSupportUpload',
            ApiMethod.POST,
            null
        )
    }
