import { ApiMethod, EmptyRequest } from 'common/src/api/types'
import { CancelToken } from 'axios'
import { CourseTracksResponse } from 'common/src/api/core/courses/responses'
import { APIResponseWrapper } from '@/api/types'
import {
    CourseTracksRequest,
    GenericContentRequest,
} from 'common/src/api/core/courses/requests'
import { PreviousExamAnalysisSerializedResponse } from 'common/src/previousExamAnalysis/types'
import { IWaitlistMetadata } from 'common/src/api/websiteFrontendVsWebsiteBackend/waitlist/types'
import { CourseConfigType } from 'common/src/courseConfig'
import { callBackend } from '../../server/callBackend'

const PREFIX = '/wb/notLoggedIn/'

export const getCourseTracks = async (
    configType: CourseConfigType,
    cancelToken: CancelToken = null
): APIResponseWrapper<CourseTracksResponse> => {
    return await callBackend<CourseTracksRequest, CourseTracksResponse>(
        PREFIX + 'getCourseTracks',
        ApiMethod.GET,
        { configType },
        cancelToken
    )
}

export const getSessionID = async (
    cancelToken: CancelToken = null
): APIResponseWrapper<{ sessionID: string }> => {
    return await callBackend<EmptyRequest, { sessionID: string }>(
        PREFIX + 'getSessionID',
        ApiMethod.GET,
        null,
        cancelToken
    )
}

export const getPreviousExamAnalysisForCourseName = async (
    courseName: string
): APIResponseWrapper<PreviousExamAnalysisSerializedResponse> => {
    return await callBackend<
        GenericContentRequest,
        PreviousExamAnalysisSerializedResponse
    >(
        PREFIX + 'getPreviousExamAnalysisForCourseName',
        ApiMethod.GET,
        {
            courseName,
        },
        null
    )
}

export const addToWaitlist = async (
    inputData: IWaitlistMetadata
): APIResponseWrapper<null> => {
    return await callBackend<IWaitlistMetadata, null>(
        PREFIX + 'addToWaitlist',
        ApiMethod.POST,
        inputData,
        null
    )
}
