import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import Modal from 'antd/lib/modal/Modal'
import Tooltip from 'antd/lib/tooltip'
import notification from 'antd/lib/notification'
import styles from './SearchModal.module.scss'
import Send from '../../icons/send.svg'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { ContentType } from 'common/src/commentThread/types'
import { useDebouncedSearch } from '@/hooks/useDebouncedSearch'
import { useRecoilValue, useRecoilState } from 'recoil'
import { SearchContext } from 'common/src/elasticSearch/types'
import { nanoid } from 'nanoid'
import { queryContent } from '@/api/search'
import { NotificationKeys } from '@/utils/notificationKeys'
import { QueryContentResponse } from 'common/src/llm/queryContent'
import axios, { CancelTokenSource } from 'axios'
import { encoding_for_model, Tiktoken } from '@dqbd/tiktoken'
import { useDebounce } from '@/hooks/useDebounce'
import { QUESTION_CONTEXT_MODEL, TokenLimit } from 'common/src/llm/limits'
import { useWindowSize } from '@/hooks/useWindowSize'
import { LoadingComponent } from '@/components/static/LoadingComponent/LoadingComponent'
import { LoadingType } from '@/components/static/images/owlBranding/loadingImage'
import { RecentlySearchedTopics } from '@/components/search/SearchModal/RecentlySearchedTopics/RecentlySearchedTopics'
import { AskEupheus } from '@/components/search/SearchModal/AskEupheus/AskEupheus'
import { SearchResults } from '@/components/search/SearchModal/SearchResults/SearchResults'
import { isMobileModeAtom } from '@/atoms/isMobileMode'
import { frontendDisplayedCourseAccessTypesSelector } from '@/atoms/accountMaintenance/userInfo'
import {
    AwaitConditionOptions,
    useAwaitCondition,
} from '@/hooks/useAwaitCondition'
import { registerEvent } from '@/api/analyticsEvent'
import { PaidUserAnalyticsEventType } from 'common/src/api/websiteFrontendVsWebsiteBackend/analyticsEvent/types'
import {
    GenericButton,
    GenericButtonType,
} from '@/components/static/ui/Button/GenericButton/GenericButton'
import message from 'antd/lib/message'
import Input, { InputRef } from 'antd/lib/input'
import { SearchResultsMobile } from '@/components/search/SearchModal/SearchResultsMobile/SearchResultsMobile'
import { searchTextAtom } from '@/atoms/search/searchText'
import { courseConfigTypeAtom } from '@/atoms/courseInfo'
import { CourseConfig, getCourseConfig } from 'common/src/courseConfig'
import { useLogger } from '@/hooks/useLogger'

interface SearchModalProps {
    isVisible: boolean
    setIsVisible: (isVisible: boolean) => void
    courseName: string
}

const ALL_CONTENT_TYPES = [
    ContentType.OUTLINE,
    ContentType.NOTECARD,
    ContentType.PRACTICE_PROBLEM,
]

const TOKEN_LIMIT = TokenLimit.WF_QUESTION
const CANCEL_ERROR = 'User canceled search'
const MIN_HEIGHT_IN_PX_FOR_FREQUENTLY_SEARCHED_TOPICS = 650

export const SearchModal: React.FC<SearchModalProps> = (
    props
): ReactElement => {
    const isVisibleRef = useRef<boolean>(props.isVisible)
    useEffect(() => {
        isVisibleRef.current = props.isVisible
    }, [props.isVisible])
    const accessTypes = useRecoilValue(
        frontendDisplayedCourseAccessTypesSelector
    )
    const isMobileMode = useRecoilValue(isMobileModeAtom)

    const inputRef = useRef<InputRef>(null)
    const [searchText, setSearchText] = useRecoilState(searchTextAtom)
    const [mostRecentSearchText, setMostRecentSearchText] = useState<string>('')
    const [hasQueried, setHasQueried] = useState<boolean>(false)
    const [isAskEupheusLoading, setIsAskEupheusLoading] =
        useState<boolean>(false)
    const [contentTypeFoci, setContentTypeFoci] =
        useState<ContentType[]>(ALL_CONTENT_TYPES)

    const cancelTokenRef = useRef<CancelTokenSource | null>()
    const [queryResults, setQueryResults] =
        useState<QueryContentResponse | null>(null)
    const [contentQueryId, setContentQueryId] = useState<string>('')
    const encoderRef = useRef<Tiktoken | null>(null)
    const [tokenCount, setTokenCount] = useState<number>(0)
    const debouncedUpdateTotalTokens = useDebounce(
        () => {
            if (searchText === null) return
            if (encoderRef.current === null) return
            const tokens = encoderRef.current.encode(searchText)
            setTokenCount(tokens.length)
        },
        250,
        1_000
    )

    const awaitConditionOptions = useMemo(
        (): AwaitConditionOptions => ({
            conditionChecker: async (): Promise<boolean> => {
                return !!queryResultsExist.current
            },
            checkIntervalInMs: 1_000,
            maxDurationToCheckInMs: 30_000,
            onSuccess: (): void => null,
            onFailure: (): void =>
                notification.warning({
                    message: 'Uh-oh',
                    description:
                        'This is taking a bit longer than expected. It may be faster to click "Cancel" and try again.',
                    duration: 0,
                    onClick: () => {
                        notification.destroy(
                            NotificationKeys.CONTENT_QUERY_TAKING_TOO_LONG
                        )
                    },
                    className: reusableCssClass.clickMe,
                    key: NotificationKeys.CONTENT_QUERY_TAKING_TOO_LONG,
                }),
        }),
        []
    )
    const [awaitCondition, cancelAwaitCondition] = useAwaitCondition(
        awaitConditionOptions
    )
    const logger = useLogger(SearchModal.name)
    const cancelQuery = useCallback(
        (searchText: string): void => {
            setHasQueried(false)
            setQueryResults(null)
            setSearchText('')
            setMostRecentSearchText('')
            const executionDurationMs = cancelAwaitCondition()
            if (cancelTokenRef.current) {
                cancelTokenRef.current.cancel(CANCEL_ERROR)
            }

            if (!queryResults) {
                logger.warn(
                    `User canceled query after: ${executionDurationMs} ms`
                )
                registerEvent(
                    PaidUserAnalyticsEventType.CONTENT_QUERY_CANCEL_CLICK,
                    {
                        searchText,
                        contentQueryId,
                        executionDurationMs,
                    }
                )
            }
            setTimeout(() => inputRef.current.focus({ cursor: 'all' }))
        },
        [
            cancelAwaitCondition,
            contentQueryId,
            logger,
            queryResults,
            setSearchText,
        ]
    )
    useEffect(() => {
        encoderRef.current = encoding_for_model(QUESTION_CONTEXT_MODEL)
    }, [])
    useEffect(() => {
        debouncedUpdateTotalTokens()
    }, [debouncedUpdateTotalTokens, searchText])

    useEffect(() => {
        if (props.isVisible && inputRef.current) {
            setTimeout(() => inputRef.current.focus(), 0)
        }
    }, [props.isVisible])
    const searchButtonDisabledText = useMemo((): string | null => {
        if (searchText === '') return 'No search text entered'
        if (tokenCount > TOKEN_LIMIT)
            return 'Question too long. Please shorten your query and try again.'
        if (queryResults && searchText === mostRecentSearchText)
            return 'Search already submitted. Clear to search for something different.'
        return null
    }, [mostRecentSearchText, queryResults, searchText, tokenCount])

    const searchContexts: SearchContext[] = useMemo(() => {
        if (accessTypes.length > 0) {
            return ['outlines', 'notecards', 'practiceProblems', 'other']
        } else {
            return ['other']
        }
    }, [accessTypes.length])
    const [searchResults] = useDebouncedSearch(searchText, searchContexts)

    useEffect(() => {
        if (!searchText) {
            setContentTypeFoci(ALL_CONTENT_TYPES)
            setHasQueried(false)
            setQueryResults(null)
        }
    }, [searchText])

    const queryResultsExist = useRef<boolean>(false)
    const windowSize = useWindowSize()

    const courseConfigType = useRecoilValue(courseConfigTypeAtom)
    const courseConfig = useMemo((): CourseConfig => {
        return getCourseConfig(courseConfigType)
    }, [courseConfigType])

    const middleSection = useMemo((): ReactElement => {
        const shouldShowRecentlySearchedTopics =
            courseConfig.shouldRecentlySearchedTopicsInSearchModal &&
            windowSize.height >= MIN_HEIGHT_IN_PX_FOR_FREQUENTLY_SEARCHED_TOPICS
        if (!searchText && shouldShowRecentlySearchedTopics) {
            return (
                <RecentlySearchedTopics
                    courseName={props.courseName}
                    isVisible={true}
                    setSearchText={setSearchText}
                    setContentTypeFoci={setContentTypeFoci}
                    isMobileMode={isMobileMode}
                    accessTypes={accessTypes}
                />
            )
        }

        if (isAskEupheusLoading) {
            return (
                <div>
                    <LoadingComponent
                        loadingType={LoadingType.owl}
                        maxHeightPx={windowSize.height * 0.375}
                        styleOverrides={{ padding: 0 }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <GenericButton
                            text={'Cancel'}
                            type={GenericButtonType.greenNoBackground}
                            onClick={() => cancelQuery(searchText)}
                        />
                    </div>
                </div>
            )
        }
        if (hasQueried) {
            return (
                <AskEupheus
                    queryResults={queryResults}
                    isLoading={isAskEupheusLoading}
                    searchText={mostRecentSearchText}
                    contentQueryId={contentQueryId}
                />
            )
        }

        if (searchResults) {
            if (windowSize.width < 800) {
                return (
                    <SearchResultsMobile
                        results={searchResults}
                        contentTypeFoci={contentTypeFoci}
                        setContentTypeFoci={setContentTypeFoci}
                        setIsSearchModalVisible={props.setIsVisible}
                        setSearchText={setSearchText}
                        accessTypes={accessTypes}
                    />
                )
            } else {
                return (
                    <SearchResults
                        results={searchResults}
                        contentTypeFoci={contentTypeFoci}
                        setContentTypeFoci={setContentTypeFoci}
                        setIsSearchModalVisible={props.setIsVisible}
                        accessTypes={accessTypes}
                    />
                )
            }
        }

        // default to loading component if we don't know what to show
        if (shouldShowRecentlySearchedTopics) {
            return (
                <div className={styles.middleContainerImgContainer}>
                    <LoadingComponent loadingType={LoadingType.skeleton} />
                </div>
            )
        }

        return
    }, [
        searchText,
        courseConfig.shouldRecentlySearchedTopicsInSearchModal,
        isAskEupheusLoading,
        hasQueried,
        searchResults,
        props.courseName,
        props.setIsVisible,
        setSearchText,
        isMobileMode,
        accessTypes,
        windowSize.height,
        windowSize.width,
        cancelQuery,
        queryResults,
        mostRecentSearchText,
        contentQueryId,
        contentTypeFoci,
    ])

    const queryContentCallback = useCallback(
        async (searchText: string): Promise<void> => {
            if (!searchText) {
                message.info('No search text entered')
                return
            }
            awaitCondition()
            try {
                if (queryResults) {
                    setQueryResults(null)
                }
                setHasQueried(true)
                setIsAskEupheusLoading(true)
                cancelTokenRef.current = axios.CancelToken.source()
                const newContentQueryId = nanoid(5)
                setContentQueryId(newContentQueryId)
                setMostRecentSearchText(searchText)
                const response = await queryContent(
                    {
                        courseName: props.courseName,
                        query: searchText,
                        contentQueryId: newContentQueryId,
                    },
                    cancelTokenRef.current.token
                )

                if (response.data.success) {
                    setQueryResults(response.data.payload)
                    registerEvent(
                        PaidUserAnalyticsEventType.CONTENT_QUERY_RESULT_VIEWED,
                        {
                            isModalOpen: isVisibleRef.current,
                            contentQueryId: newContentQueryId,
                        }
                    )
                } else {
                    setHasQueried(false)
                    setTimeout(() => inputRef.current.focus({ cursor: 'all' }))
                    if (!response.data.error.includes(CANCEL_ERROR)) {
                        notification.info({
                            message: 'Uh-oh',
                            description:
                                'It looks like too many people are using this feature right now. Please try again in a minute. If this keeps happening, feel free to contact us at support@justenoughprep.com.',
                            key: NotificationKeys.ERROR_QUERYING_CONTENT,
                            className: reusableCssClass.clickMe,
                            onClick: () =>
                                notification.destroy(
                                    NotificationKeys.ERROR_QUERYING_CONTENT
                                ),
                            duration: null,
                        })
                    }
                }
            } finally {
                cancelAwaitCondition()
                setIsAskEupheusLoading(false)
            }
        },
        [awaitCondition, cancelAwaitCondition, props.courseName, queryResults]
    )

    const onAskEupheusHandler = useCallback(async (): Promise<void> => {
        if (searchButtonDisabledText) {
            return
        } else {
            await queryContentCallback(searchText)
        }
    }, [queryContentCallback, searchButtonDisabledText, searchText])

    const inputBar = useMemo((): ReactElement => {
        const placeHolder = isMobileMode
            ? 'Ask a question or enter keywords'
            : 'Ask a question or enter keywords to find answers...'
        return (
            <Input
                placeholder={placeHolder}
                value={searchText}
                onChange={(nextState) => setSearchText(nextState.target.value)}
                ref={inputRef}
                onPressEnter={onAskEupheusHandler}
                disabled={isAskEupheusLoading}
                allowClear={true}
            />
        )
    }, [
        isMobileMode,
        searchText,
        onAskEupheusHandler,
        isAskEupheusLoading,
        setSearchText,
    ])

    const sendButton = useMemo((): ReactElement => {
        let className = `${reusableCssClass.centerChildrenVertically} ${styles.sendButtonContainer}`
        if (searchButtonDisabledText || isAskEupheusLoading) {
            className += ` ${styles.disabled}`
        } else {
            className += ` ${reusableCssClass.clickMe}`
        }

        return (
            <div className={className} onClick={onAskEupheusHandler}>
                <Tooltip title={searchButtonDisabledText} placement={'left'}>
                    <Send />
                </Tooltip>
            </div>
        )
    }, [isAskEupheusLoading, onAskEupheusHandler, searchButtonDisabledText])

    const searchModalTitle = useMemo((): string => {
        if (hasQueried && isAskEupheusLoading) {
            return 'Asking Eupheus...'
        }
        if (hasQueried && queryResults.queryAsQuestion) {
            return queryResults.queryAsQuestion
        }

        return 'What do you want to know?'
    }, [hasQueried, isAskEupheusLoading, queryResults])

    const modalHeight = useMemo((): number => {
        return 0.75 * windowSize.height
    }, [windowSize.height])

    const instructions = useMemo((): string => {
        if (isMobileMode) {
            return 'Type to search relevant content. Press Send button to Ask Eupheus.'
        }

        return `Start typing and you will find relevant outlines, notecards,
          and practice problems appear. Still not satisfied? Hit the
        Send button to Ask Eupheus for a complete answer powered by
        ChatGPT based on the source material.`
    }, [isMobileMode])
    return (
        <Modal
            zIndex={1010}
            open={props.isVisible}
            onCancel={() => props.setIsVisible(false)}
            className={styles.searchModalContainer}
            footer={null}
            style={{ height: modalHeight }}
        >
            <div className={styles.titleContainer}>{searchModalTitle}</div>
            <div className={`${styles.middleContainerContents}`}>
                {middleSection}
            </div>

            <div className={styles.userInputContainer}>
                <div className={styles.questionContainer}>
                    {inputBar}
                    {sendButton}
                </div>
                <div className={`${styles.instructionContainer}`}>
                    {instructions}
                </div>
            </div>
        </Modal>
    )
}
