import { useSetRecoilState } from 'recoil'
import { isMobileModeAtom, isSmallDesktopModeAtom } from '@/atoms/isMobileMode'
import { useEffect } from 'react'
import { useIsMobileMode } from '@/hooks/static/useIsMobileMode'
import { useIsSmallDesktopMode } from '@/hooks/static/useIsSmallDesktopMode'

export const useReactToWindowSizeAtoms = (): void => {
    const setIsMobileMode = useSetRecoilState<boolean>(isMobileModeAtom)
    const setIsSmallDesktopMode = useSetRecoilState(isSmallDesktopModeAtom)
    const isMobileMode = useIsMobileMode(true)
    const isSmallDesktopMode = useIsSmallDesktopMode(true)
    useEffect(() => {
        setIsMobileMode(isMobileMode)
    }, [isMobileMode, setIsMobileMode])
    useEffect(() => {
        setIsSmallDesktopMode(isSmallDesktopMode)
    }, [isSmallDesktopMode, setIsSmallDesktopMode])
}
