import { APIResponseWrapper } from '@/api/types'
import {
    GetUserMtbRequestPayload,
    GetUserMtbResponsePayload,
    GetUserMtbsRequestPayload,
    GetUserMtbsResponsePayload,
    SaveUserMtbRequestPayload,
} from 'common/src/userMtb/types'
import { callBackend } from '../../server/callBackend'
import { ApiMethod, EmptyResponse } from 'common/src/api/types'
import { CancelToken } from 'axios'

const PREFIX = '/wb/userMtb/'

export const getUserMtb = async (
    id: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<GetUserMtbResponsePayload> => {
    return await callBackend<
        GetUserMtbRequestPayload,
        GetUserMtbResponsePayload
    >(PREFIX + 'getUserMtb', ApiMethod.GET, { id }, cancelToken)
}

export const getUserMtbs = async (
    ids: string[],
    cancelToken: CancelToken = null
): APIResponseWrapper<GetUserMtbsResponsePayload> => {
    return await callBackend<
        GetUserMtbsRequestPayload,
        GetUserMtbsResponsePayload
    >(PREFIX + 'getUserMtbs', ApiMethod.GET, { ids }, cancelToken)
}

export const saveUserMtb = async (
    payload: SaveUserMtbRequestPayload,
    cancelToken: CancelToken = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<SaveUserMtbRequestPayload, EmptyResponse>(
        PREFIX + 'saveUserMtb',
        ApiMethod.POST,
        payload,
        cancelToken
    )
}
