import React, { ReactElement } from 'react'
import { useRecoilValue } from 'recoil'
import { isMobileModeAtom } from '@/atoms/isMobileMode'
import { LoggedInHeaderMobileMode } from '@/components/LoggedInHeader/LoggedInHeaderMobileMode/LoggedInHeaderMobileMode'
import { LoggedInHeaderDesktopMode } from '@/components/LoggedInHeader/LoggedInHeaderDesktopMode/LoggedInHeaderDesktopMode'

export const LoggedInHeader: React.FC<unknown> = (): ReactElement => {
    const isMobileMode = useRecoilValue<boolean>(isMobileModeAtom)
    return isMobileMode ? (
        <LoggedInHeaderMobileMode />
    ) : (
        <LoggedInHeaderDesktopMode />
    )
}
