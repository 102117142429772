import Cookie from 'js-cookie'
import type { NextRequest } from 'next/server'

const LOGGED_IN_FLAG_COOKIE_NAME = 'isLoggedIntoJEP'

export const setIsLoggedIn = (isLoggedIn: boolean): void => {
    if (isLoggedIn) {
        Cookie.set(LOGGED_IN_FLAG_COOKIE_NAME, 'true')
    } else {
        Cookie.remove(LOGGED_IN_FLAG_COOKIE_NAME)
    }
}
export const getIsLoggedIn = (): boolean =>
    !!Cookie.get(LOGGED_IN_FLAG_COOKIE_NAME)

export const getIsLoggedInServerSide = (req: NextRequest): boolean => {
    return !!req.cookies.get(LOGGED_IN_FLAG_COOKIE_NAME)
}
