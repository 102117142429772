import React, { ReactElement } from 'react'
import styles from './AnimateOnHoverArrow.module.scss'
import RightArrow from '../../icons/rightArrow.svg'
import Icon from '@ant-design/icons'

// enclosing container should have css class of 'animate-on-hover-container'
export const AnimateOnHoverArrow: React.FC<unknown> = (): ReactElement => {
    return (
        <div className={styles.animateOnHover}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore*/}
            <Icon component={RightArrow} />
        </div>
    )
}
