import {
    QuizCreator,
    ProblemTypeProblemAttribute,
} from '@/frontendLogic/practiceProblems/visualizations/QuizCreator/QuizCreator.types'
import { YearToSittingsMap } from '@/components/practiceProblems/CreateQuizPage/CreateQuiz/BuildQuizSelector/ProblemType/previousExamMappings'

/**
 * Logical OR between gold standard, include starred, and previous exam sittings.
 * If previous exam sittings selection is empty, do not apply the filter at all.
 */
export const filterIDsOnProblemType = (
    ids: string[],
    quizCreator: QuizCreator,
    includeGoldStandard: boolean,
    includeStarred: boolean,
    selectedYearToSittingsMap: YearToSittingsMap,
    skipPreviousExamCheck: boolean
): string[] => {
    return ids.filter((id): boolean => {
        // apply gold standard filter
        if (includeGoldStandard) {
            if (
                quizCreator.problemTypeProblemAttributeMap
                    .get(ProblemTypeProblemAttribute.goldStandard)
                    ?.has(id)
            ) {
                return true
            }
        }

        // apply starred filter
        if (includeStarred) {
            if (quizCreator.starredSet.has(id)) {
                return true
            }
        }

        // apply previous exam filter
        if (!skipPreviousExamCheck) {
            for (const [
                year,
                sittingSeasonSet,
            ] of selectedYearToSittingsMap.entries()) {
                for (const sittingSeason of sittingSeasonSet.values()) {
                    const candidateIDs: Set<string> =
                        quizCreator.previousExamSittingsMap
                            .get(year)
                            ?.get(sittingSeason) ?? new Set<string>()
                    if (candidateIDs.has(id)) return true
                }
            }
        }

        return false
    })
}
