import { DateTime, Duration } from 'luxon'

export const secondsToHMS = (durationInSeconds: number): string => {
    if (typeof durationInSeconds !== 'number') return 'N/A'
    return Duration.fromObject({ seconds: durationInSeconds }).toFormat(
        'hh:mm:ss'
    )
}

export const secondsToHM = (durationInSeconds: number): string => {
    if (typeof durationInSeconds !== 'number') return 'N/A'
    return Duration.fromObject({ seconds: durationInSeconds }).toFormat('hh:mm')
}

export const secondsToHoursAndMinutes = (
    durationInSeconds: number,
    includeZeroHours = true
): string => {
    if (typeof durationInSeconds !== 'number') return '0 hours 0 minutes'
    const [hoursStr, minutesStr] = Duration.fromObject({
        seconds: durationInSeconds,
    })
        .toFormat('hh mm')
        .split(' ')
    const { hours, minutes } = {
        hours: parseInt(hoursStr),
        minutes: parseInt(minutesStr),
    }
    const hoursText =
        !includeZeroHours && hours === 0
            ? ''
            : `${hours} ${hours === 1 ? 'hour ' : 'hours '}`
    const minutesText = minutes === 1 ? `1 minute` : `${minutes} minutes`
    return `${hoursText}${minutesText}`
}

export const timeSinceInHours = (time: Date): number => {
    return timeSinceInUnits(time, 'hours')
}

export const timeSinceInUnits = (
    time: Date,
    units: 'hours' | 'minutes' | 'seconds' | 'milliseconds'
): number => {
    if (time === null) return 99999
    return DateTime.fromJSDate(new Date()).diff(
        DateTime.fromJSDate(time),
        units
    )[units]
}

export const getTimeMs = (): number => new Date().getTime()

export const numDaysUntil = (untilDate: Date): number => {
    return DateTime.fromJSDate(new Date(untilDate))
        .startOf('day')
        .diff(DateTime.fromJSDate(new Date()).startOf('day'), 'days').days
}

export const buildHumanReadableTextForDuration = (
    untilDate: Date | string,
    fromDate: Date | string = new Date()
): string => {
    const untilLuxonDate = DateTime.fromJSDate(new Date(untilDate)).startOf(
        'day'
    )
    const fromLuxonDate = DateTime.fromJSDate(new Date(fromDate)).startOf('day')
    const monthsUntil = Math.floor(
        untilLuxonDate.diff(fromLuxonDate, 'months').months
    )

    const monthAdjustedUntilLuxonDate = untilLuxonDate.minus({
        months: monthsUntil,
    })
    const weeksUntil = Math.floor(
        monthAdjustedUntilLuxonDate.diff(fromLuxonDate, 'weeks').weeks
    )

    const monthAndWeekAdjustedUntilLuxonDate =
        monthAdjustedUntilLuxonDate.minus({
            weeks: weeksUntil,
        })
    const daysUntil = Math.floor(
        monthAndWeekAdjustedUntilLuxonDate.diff(fromLuxonDate, 'days').days
    )

    const outArr: string[] = []
    if (monthsUntil) outArr.push(buildDurationString(monthsUntil, 'month'))
    if (weeksUntil) outArr.push(buildDurationString(weeksUntil, 'week'))
    if (daysUntil) outArr.push(buildDurationString(daysUntil, 'day'))
    return outArr.join(', ')
}

const buildDurationString = (
    amt: number,
    singularDurationUnit: string
): string | null => {
    switch (amt) {
        case 0:
            return null
        case 1:
            return `${amt} ${singularDurationUnit}`
        default:
            return `${amt} ${singularDurationUnit}s`
    }
}

export const displayStringForCommentPostTime = (date: Date): string => {
    const currentTime = DateTime.fromJSDate(new Date())
    const postTime = DateTime.fromJSDate(new Date(date))
    const timeDifferenceInHours = Math.floor(
        currentTime.diff(postTime, 'hours').hours
    )
    if (timeDifferenceInHours > 12) {
        return postTime.toFormat("ccc, LLL dd 'at' hh:mm a")
    }

    if (timeDifferenceInHours === 1) {
        return `1 hour ago`
    }

    if (timeDifferenceInHours > 0) {
        return `${timeDifferenceInHours} hours ago`
    }

    const timeDifferenceInMinutes = Math.floor(
        currentTime.diff(postTime, 'minutes').minutes
    )

    if (timeDifferenceInMinutes === 1) {
        return `1 minute ago`
    }

    if (timeDifferenceInMinutes > 0) {
        return `${timeDifferenceInMinutes} minutes ago`
    }

    const timeDifferenceInSeconds = Math.floor(
        currentTime.diff(postTime, 'seconds').seconds
    )

    if (timeDifferenceInSeconds === 1) {
        return `1 second ago`
    }

    return `${timeDifferenceInSeconds} seconds ago`
}
