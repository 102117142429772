import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import Tabs from 'antd/lib/tabs'
import { LoginForm } from './LoginForm/LoginForm'
import { SignUpForm } from './SignUpForm/SignUpForm'
import SwipeableViews from 'react-swipeable-views'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { ForgotPasswordForm } from '@/components/static/Authenticator/ForgotPasswordForm/ForgotPasswordForm'
import {
    AuthenticatorOpenPurpose,
    AuthenticatorRoutingProps,
    AuthenticatorTabName,
} from '@/components/static/Authenticator/Authenticator.types'
import styles from './Authenticator.module.scss'
import { buildReadOnlyTwoWayIndexMapForEnum } from 'common/src/utils/twoWayMap'
import { CourseConfigType } from 'common/src/courseConfig'

const twoWayAuthenticatorTabMap =
    buildReadOnlyTwoWayIndexMapForEnum(AuthenticatorTabName)

interface AuthenticatorProps {
    defaultTab: AuthenticatorTabName
    setIsModalVisible: (isModalVisible: boolean) => void
    routingPurpose: AuthenticatorRoutingProps | null
    courseConfigType: CourseConfigType
}

export const Authenticator: React.FC<AuthenticatorProps> = (
    props
): ReactElement => {
    const [activeTab, setActiveTab] = useState<AuthenticatorTabName>(
        props.defaultTab
    )

    const switchTabs = useCallback((newTab: AuthenticatorTabName): void => {
        setActiveTab(newTab)
    }, [])

    const handleChangeIndex = useCallback(
        (index: number): void => {
            const nextTab = twoWayAuthenticatorTabMap.getBackward(index)
            switchTabs(nextTab)
        },
        [switchTabs]
    )

    const validViews = useMemo((): ReactElement[] => {
        return [
            <SignUpForm
                key={AuthenticatorTabName.signUp}
                setIsModalVisible={props.setIsModalVisible}
                authenticatorPayload={{
                    purpose: props.routingPurpose?.purpose,
                    courseName: props.routingPurpose?.courseName,
                    accessTypes: props.routingPurpose?.accessTypes,
                }}
                courseConfigType={props.courseConfigType}
            />,
            <LoginForm
                key={AuthenticatorTabName.Login}
                changeToForgotPassword={() =>
                    switchTabs(AuthenticatorTabName.forgotPassword)
                }
                setIsModalVisible={props.setIsModalVisible}
                authenticatorPayload={{
                    purpose: props.routingPurpose?.purpose,
                    courseName: props.routingPurpose?.courseName,
                    accessTypes: props.routingPurpose?.accessTypes,
                }}
                courseConfigType={props.courseConfigType}
            />,
            <ForgotPasswordForm
                key={AuthenticatorTabName.forgotPassword}
                changeToLogin={() => switchTabs(AuthenticatorTabName.Login)}
            />,
        ]
    }, [
        props.setIsModalVisible,
        props.routingPurpose?.purpose,
        props.routingPurpose?.courseName,
        props.routingPurpose?.accessTypes,
        props.courseConfigType,
        switchTabs,
    ])

    return (
        <div className={styles.authenticator}>
            <Tabs
                activeKey={activeTab}
                onChange={(key: string) =>
                    switchTabs(key as AuthenticatorTabName)
                }
                animated
                items={[
                    {
                        key: AuthenticatorTabName.signUp,
                        label: AuthenticatorTabName.signUp,
                        disabled:
                            props.routingPurpose?.purpose ===
                                AuthenticatorOpenPurpose.LOGIN &&
                            props.routingPurpose?.courseName === null,
                    },
                    {
                        key: AuthenticatorTabName.Login,
                        label: AuthenticatorTabName.Login,
                    },
                    {
                        key: AuthenticatorTabName.forgotPassword,
                        label: AuthenticatorTabName.forgotPassword,
                    },
                ]}
            />
            <SwipeableViews
                index={twoWayAuthenticatorTabMap.getForward(activeTab)}
                onChangeIndex={handleChangeIndex}
                slideClassName={`${reusableCssClass.sliderContainer} ${styles.moreSliderPadding}`}
            >
                {validViews}
            </SwipeableViews>
        </div>
    )
}
