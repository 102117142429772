import { getEmptyContentTreeMap } from 'common/src/ContentTree'
import { IBaseScore, ScoreMap } from 'common/src/practiceProblems/scores/types'
import {
    ContentIDToNameMap,
    ContentTree,
    EmptyContentFrame,
} from 'common/src/ContentTree/types'
import {
    getEmptyBaseScore,
    getMostRecentScoreFromProblemUserStats,
    mergeBaseScores,
} from 'common/src/practiceProblems/scores'
import { PracticeProblemTopicMastery } from '@/frontendLogic/practiceProblems/visualizations/PracticeProblemTopicMastery/PracticeProblemTopicMastery.types'
import { IProblemUserStats } from 'common/src/practiceProblems/types'
import { ContentPath, hashContentPath } from 'common/src/ContentPath'

export const createPracticeProblemTopicMastery = (
    problemUserStats: IProblemUserStats[],
    contentTree: ContentTree
): PracticeProblemTopicMastery => {
    return new PracticeProblemTopicMasteryFactory(
        problemUserStats,
        contentTree
    ).toObj()
}

class PracticeProblemTopicMasteryFactory {
    private readonly emptyContentFrame: EmptyContentFrame
    private readonly scoreMap: ScoreMap
    private readonly depth: number
    private readonly contentIDToNameMap: ContentIDToNameMap
    private readonly EMPTY_BASE_SCORE = getEmptyBaseScore()

    constructor(
        problemUserStats: IProblemUserStats[],
        contentTree: ContentTree
    ) {
        const { contentTreeMap, depth, contentIDToNameMap } =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            getEmptyContentTreeMap<null>(contentTree, () => null)
        this.emptyContentFrame = contentTreeMap
        this.depth = depth
        this.contentIDToNameMap = contentIDToNameMap

        this.scoreMap = new Map()
        this.fillEmptyScoreMap(problemUserStats)
    }

    public toObj = (): PracticeProblemTopicMastery => ({
        emptyContentFrame: this.emptyContentFrame,
        scoreMap: this.scoreMap,
        depth: this.depth,
        contentIDToNameMap: this.contentIDToNameMap,
    })

    private fillEmptyScoreMap = (
        problemUserStats: IProblemUserStats[]
    ): void => {
        problemUserStats.forEach((problemUserStat): void => {
            const baseScore =
                getMostRecentScoreFromProblemUserStats(problemUserStat)
            for (let i = 0; i <= problemUserStat.contentPath.length; i++) {
                this.updateScoreMap(
                    baseScore,
                    problemUserStat.contentPath.slice(0, i)
                )
            }
        })
    }

    private updateScoreMap = (
        baseScore: IBaseScore,
        contentPath: ContentPath
    ): void => {
        const hashedContentPath = hashContentPath(contentPath)
        this.scoreMap.set(
            hashedContentPath,
            mergeBaseScores(
                this.scoreMap.get(hashedContentPath) || this.EMPTY_BASE_SCORE,
                baseScore
            )
        )
    }
}
