import { ContentPath } from 'common/src/ContentPath'

export type StartLessonStrategy = 'notecard' | 'quiz'

export interface ILesson {
    strategy: StartLessonStrategy
    contentPaths: ContentPath[]
    notecardIDs: string[]
}

export const MAX_NUM_NOTECARDS_PER_LESSON = 5
