import React, { ReactElement, useCallback, useRef, useState } from 'react'
import Input from 'antd/lib/input'
import Form, { FormInstance } from 'antd/lib/form'
import notification from 'antd/lib/notification'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { sendPasswordRecoveryEmail } from '@/api/authentication'
import {
    ForgotPasswordFormProps,
    IForgotPasswordForm,
} from '@/components/static/Authenticator/ForgotPasswordForm/ForgotPasswordForm.types'
import { NotificationKeys } from '@/utils/notificationKeys'
import styles from '../Authenticator.module.scss'
import {
    clearAuthenticationError,
    handleAuthenticationError,
} from '@/components/static/Authenticator/handleAuthenticationError'
import {
    CTAButton,
    CTAButtonType,
} from '@/components/static/ui/Button/CTAButton/CTAButton'
import { logger } from '@/logging/FrontendLogger'

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (
    props
): ReactElement => {
    const formRef = useRef<FormInstance<IForgotPasswordForm>>(null)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const handleSubmit = useCallback(
        async (values: IForgotPasswordForm) => {
            setIsLoading(true)
            clearAuthenticationError()

            const response = await sendPasswordRecoveryEmail(
                values.forgotPasswordEmail
            )
            if (!response.data.success) {
                handleAuthenticationError(response.data.error, {
                    message: 'Issue recovering password',
                    description:
                        'Something went wrong trying to recover your password. Please refresh the page and try again.',
                })
            } else {
                notification.success({
                    message: 'Email successfully sent',
                    description:
                        'Be sure to follow the instructions in the email to recover your password',
                    duration: 10, // seconds
                    onClick: () => {
                        notification.destroy(
                            NotificationKeys.PASSWORD_RECOVERY_EMAIL_SUCCESSFULLY_SENT
                        )
                    },
                    className: reusableCssClass.clickMe,
                    key: NotificationKeys.PASSWORD_RECOVERY_EMAIL_SUCCESSFULLY_SENT,
                })
                props.changeToLogin()
            }
            setIsLoading(false)
        },
        [props]
    )

    const handleChange = useCallback(async (): Promise<void> => {
        clearAuthenticationError()

        try {
            await formRef.current.validateFields()
            setIsForgotPasswordEmailButtonDisabled(false)
        } catch (error) {
            logger.info(`Failed to validate forgotPassword fields`)
            setIsForgotPasswordEmailButtonDisabled(true)
            return
        }
    }, [])

    const [
        isForgotPasswordEmailButtonDisabled,
        setIsForgotPasswordEmailButtonDisabled,
    ] = useState<boolean>(true)

    return (
        <div className={styles.forgotPasswordForm}>
            <div className={styles.forgotPasswordInstructions}>
                Please enter the email associated with your account
            </div>
            <Form onFinish={handleSubmit} onChange={handleChange} ref={formRef}>
                <Form.Item
                    name={'forgotPasswordEmail'}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Email is required' },
                        {
                            type: 'email',
                            message: 'Please enter a valid email address',
                        },
                    ]}
                >
                    <Input placeholder="Email" />
                </Form.Item>
                <div className={'btn-container'}>
                    <CTAButton
                        type={CTAButtonType.primaryDarkBackground}
                        loading={isLoading}
                        text={'Send Recovery Email'}
                        htmlType={
                            'submit'
                        } /* will automatically call handleSubmit on click or press enter */
                        onClick={null}
                        disabled={isForgotPasswordEmailButtonDisabled}
                    />
                </div>
                <div
                    className={`${styles.returnToLoginContainer} ${reusableCssClass.centerText}`}
                >
                    <div
                        className={`${reusableCssClass.clickableText}`}
                        onClick={props.changeToLogin}
                    >
                        Return to Login
                    </div>
                </div>
            </Form>
        </div>
    )
}
