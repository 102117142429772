import { RecoilValue, Snapshot } from 'recoil'

export const loadValueIfNotExists = async <T>(
    snapshot: Snapshot,
    recoilValue: RecoilValue<T>
): Promise<void> => {
    const loadable = snapshot.getLoadable(recoilValue)
    if (loadable.state !== 'hasValue') {
        await snapshot.getPromise(recoilValue)
    }
}
