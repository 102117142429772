import React, { ReactElement, ReactNode, useEffect } from 'react'
import styles from './ErrorComponent.module.scss'
import { QuizOwl } from '@/components/static/images/owlBranding/quizOwl'
import { buildFrontendLogger } from '@/logging/FrontendLogger'

interface ErrorComponentProps {
    title: ReactNode
    text: ReactNode
    sourceTextForErrorLog: string
}

const logger = buildFrontendLogger('ErrorComponent')
export const ErrorComponent: React.FC<ErrorComponentProps> = (
    props
): ReactElement => {
    useEffect(() => {
        logger.error(`BABY HAS BEEN SHOWN FROM: ${props.sourceTextForErrorLog}`)
    }, [props.sourceTextForErrorLog])
    return (
        <div className={styles.errorContainer}>
            <div className={styles.error}>
                <div className={styles.header}>{props.title}</div>
                <div className={styles.text}>{props.text}</div>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <QuizOwl />
                </div>
            </div>
        </div>
    )
}
