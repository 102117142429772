import { useCallback, useEffect } from 'react'
import { getClientIP } from '@/api/external'
import { registerSessionData } from '@/api/analyticsEvent'
import { logger } from '@/logging/FrontendLogger'

/**
 * Runs once per session
 */
export const useRegisterSessionData = (): void => {
    const registerSessionDataAsync = useCallback(async (): Promise<void> => {
        const clientIP = await getClientIP()
        await registerSessionData(clientIP)
    }, [])

    useEffect(() => {
        registerSessionDataAsync().catch((e) =>
            logger.info(
                `Failed to register session data with error: ${
                    (e as Error)?.message || e
                }`
            )
        )
    }, [registerSessionDataAsync])
}
