import assert from 'assert'

export const filterIDs = (
    ids: string[],
    map: Map<string, Set<string>>,
    includedKeys: string[]
): string[] => {
    return ids.filter((id) => {
        let shouldKeep = false
        includedKeys.forEach((key) => {
            assert(
                map.has(key),
                `Key: ${key} not found in map. Available map keys are: ${Array.from(
                    map.keys()
                ).join(', ')}`
            )
            if (map.get(key)?.has(id)) {
                shouldKeep = true
                return
            }
        })
        return shouldKeep
    })
}
