import { AtomEffect, RecoilState } from 'recoil'
import { InvalidationAtom } from '@/atoms/types'
import { resetRecoilExternalState } from '@/components/RecoilExternalStatePortal'

export const resetOnSetEffect =
    <T>(atomToReset: RecoilState<T>): AtomEffect<InvalidationAtom> =>
    ({ onSet }) => {
        onSet(() => {
            resetRecoilExternalState(atomToReset)
        })
    }
