import React, { ReactElement } from 'react'
import styles from './RecentlySearchedTopics.module.scss'
import { RecentlySearchedTopic } from '@/components/search/SearchModal/RecentlySearchedTopics/RecentlySearchedTopic/RecentlySearchedTopic'
import { ContentType } from 'common/src/commentThread/types'
import { AskEupheusImage } from '@/components/static/images/owlBranding/askEupheusImage'
import { AccessType } from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/types'

interface RecentlySearchedTopicsProps {
    courseName: string
    isVisible: boolean
    setSearchText: (text: string) => void
    setContentTypeFoci: (contentType: ContentType[]) => void
    isMobileMode: boolean
    accessTypes: AccessType[]
}
export const RecentlySearchedTopics: React.FC<RecentlySearchedTopicsProps> = (
    props
): ReactElement => {
    return (
        <div className={styles.recentlySearchedTopics}>
            <div className={styles.title}>
                <AskEupheusImage />
                <div>Frequently Searched Topics</div>
            </div>
            <div className={styles.recentlySearchedTopicsContainer}>
                <div>
                    <RecentlySearchedTopic
                        contentType={ContentType.NOTECARD}
                        courseName={props.courseName}
                        setSearchText={props.setSearchText}
                        setContentTypeFoci={props.setContentTypeFoci}
                        isAccessLocked={
                            !props.accessTypes.includes(AccessType.NOTECARDS)
                        }
                    />
                </div>
                <div>
                    <RecentlySearchedTopic
                        contentType={ContentType.PRACTICE_PROBLEM}
                        courseName={props.courseName}
                        setSearchText={props.setSearchText}
                        setContentTypeFoci={props.setContentTypeFoci}
                        isAccessLocked={
                            !props.accessTypes.includes(
                                AccessType.PRACTICE_PROBLEMS
                            )
                        }
                    />
                </div>
                <div>
                    <RecentlySearchedTopic
                        contentType={ContentType.OUTLINE}
                        courseName={props.courseName}
                        setSearchText={props.setSearchText}
                        setContentTypeFoci={props.setContentTypeFoci}
                        isAccessLocked={
                            !props.accessTypes.includes(AccessType.OUTLINES)
                        }
                    />
                </div>
            </div>
        </div>
    )
}
