import { useCallback, useRef } from 'react'
import { Timeout } from '@/types'

export interface AwaitConditionOptions {
    conditionChecker: () => Promise<boolean>
    checkIntervalInMs: number
    maxDurationToCheckInMs: number
    onSuccess: () => void
    onFailure: () => void
}

// returns a tuple of: [begin awaiting, cancel]
export const useAwaitCondition = (
    options: AwaitConditionOptions
): [() => void, () => number] => {
    const isCanceledRef = useRef<boolean>(false)
    const startTimeInMsRef = useRef<number>(new Date().getTime())
    const latestTimeoutRef = useRef<Timeout | null>(null)
    const recursiveTimeoutConditionChecker =
        useCallback(async (): Promise<void> => {
            if (isCanceledRef.current) return
            if (
                new Date().getTime() - startTimeInMsRef.current >
                options.maxDurationToCheckInMs
            ) {
                options.onFailure()
                return
            }

            const success = await options.conditionChecker()
            if (success) {
                options.onSuccess()
                return
            }

            latestTimeoutRef.current = setTimeout(
                () => recursiveTimeoutConditionChecker(),
                options.checkIntervalInMs
            )
        }, [options])

    return [
        useCallback((): void => {
            startTimeInMsRef.current = new Date().getTime()
            isCanceledRef.current = false
            recursiveTimeoutConditionChecker()
        }, [recursiveTimeoutConditionChecker]),
        // returns the duration of the execution thus far, in ms
        useCallback((): number => {
            isCanceledRef.current = true
            clearTimeout(latestTimeoutRef.current)
            return new Date().getTime() - startTimeInMsRef.current
        }, []),
    ]
}
