import { atom, selector } from 'recoil'
import { NotecardSetState } from 'common/src/notecards/types'
import { defaultInvalidationAtom, InvalidationAtom } from '@/atoms/types'
import { resetOnSetEffect } from '@/atoms/atomEffects/resetOnSetEffect'
import { logAtomChangesEffect } from '@/atoms/atomEffects/logAtomChangesEffect'

export interface FrontendNotecardSetState {
    notecardSet: NotecardSetState
    mode: NotecardSetMode
    completedNotecardIDs: string[]
    attemptedNotecardIDs: string[]
    numAttempts: number
}

export const notecardSetStateAtom = atom<FrontendNotecardSetState | null>({
    key: 'notecardSetStateAtom',
    default: null,
})

export enum NotecardSetMode {
    lesson = 'lesson',
    review = 'review',
    extraPractice = 'extraPractice',
    recentMistakes = 'recentMistakes',
}

export const notecardInSetRenderInvalidationAtom = atom<InvalidationAtom>({
    key: 'notecardInSetRenderInvalidationAtom',
    default: defaultInvalidationAtom,
})

export const notecardSetStateInvalidationAtom = atom<InvalidationAtom>({
    key: 'notecardSetStateInvalidationAtom',
    default: defaultInvalidationAtom,
    effects: [
        logAtomChangesEffect('Notecard Set State Invalidation Atom'),
        resetOnSetEffect(notecardSetStateAtom),
    ],
})

export const activeNotecardIDSelector = selector<string | null>({
    key: 'activeNotecardIDSelector',
    get: ({ get }) => {
        return (
            get(notecardSetStateAtom)?.notecardSet.stateData.activeNotecardID ??
            null
        )
    },
})
