import { useRecoilCallback } from 'recoil'
import { outlineProgressMapAtom } from '@/atoms/outlines/outlineProgress'
import { nodeMetadataAtom } from '@/atoms/outlines/nodeMetadata'
import { notecardUserMetadataAtom } from '@/atoms/notecards/notecardUserMetadata/notecardUserMetadata'
import { problemSetMetadataAtom } from '@/atoms/practiceProblems/problemSetMetadata'
import { problemUserStatsAtom } from '@/atoms/practiceProblems/problemUserStats'
import { mostRecentlyVisitedOutlineContentPathAtom } from '@/atoms/outlines/mostRecentlyVisitedOutlineContentPath'
import { mostRecentProblemSetMetadataSelector } from '@/atoms/practiceProblems/mostRecentProblemSetMetadata'
import { mostRecentNotecardSetIDSelector } from '@/atoms/notecards/mostRecentNotecardSetID'
import { loadValueIfNotExists } from '@/atoms/loadValueIfNotExists'
import { useEffect } from 'react'

export const useLoadBigCommonAtoms = (): void => {
    const loadAtomCallback = useRecoilCallback(
        ({ snapshot }) =>
            () => {
                loadValueIfNotExists(snapshot, outlineProgressMapAtom)
                loadValueIfNotExists(snapshot, nodeMetadataAtom)
                loadValueIfNotExists(snapshot, notecardUserMetadataAtom)
                loadValueIfNotExists(snapshot, problemSetMetadataAtom)
                loadValueIfNotExists(snapshot, problemUserStatsAtom)
                loadValueIfNotExists(
                    snapshot,
                    mostRecentlyVisitedOutlineContentPathAtom
                )
                loadValueIfNotExists(
                    snapshot,
                    mostRecentProblemSetMetadataSelector
                )
                loadValueIfNotExists(snapshot, mostRecentNotecardSetIDSelector)
            },
        []
    )
    useEffect(() => loadAtomCallback(), [])
}
