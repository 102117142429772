import { atom, selector } from 'recoil'
import { NotecardSetMetadata } from 'common/src/notecards/types'
import { getNotecardSetMetadata } from '@/api/notecards'
import { defaultInvalidationAtom, InvalidationAtom } from '@/atoms/types'
import { resetOnSetEffect } from '@/atoms/atomEffects/resetOnSetEffect'
import { LoggerSelectorFamily } from '@/atoms/logger'
import { cleanNotecardSetMetadata } from '@/frontendLogic/notecards/clean'
import { getForeverPromise } from 'common/src/utils/getForeverPromise'
import { logAtomChangesEffect } from '@/atoms/atomEffects/logAtomChangesEffect'
import {
    frontendDisplayedCourseSelector,
    userInfoStateAtom,
} from '@/atoms/accountMaintenance/userInfo'

const fetchNotecardSetMetadata = async (
    courseName: string
): Promise<NotecardSetMetadata[] | null> => {
    const response = await getNotecardSetMetadata(courseName)
    if (response.data.payload === null) return null
    return cleanNotecardSetMetadata(response.data.payload.notecardSetMetadata)
}

export const notecardSetMetadataAtom = atom<NotecardSetMetadata[] | null>({
    key: 'notecardSetMetadataAtom',
    default: selector<NotecardSetMetadata[] | null>({
        key: 'notecardSetMetadataFetcher',
        get: async ({ get }) => {
            const frontendDisplayedCourse = get(frontendDisplayedCourseSelector)
            const logger = get(
                LoggerSelectorFamily(notecardSetMetadataAtom.key)
            )
            if (!frontendDisplayedCourse) {
                logger.warn(
                    `No frontend displayed course, so notecard set metadata will not be retrieved (returning forever promise instead)`
                )
                return getForeverPromise()
            }

            get(userInfoStateAtom) // forces update upon new login
            get(notecardSetMetadataInvalidationAtom) // invalidates selector
            logger.info(`Retrieving initial notecard set metadata`)
            return await fetchNotecardSetMetadata(frontendDisplayedCourse)
        },
    }),
})

export const notecardSetMetadataInvalidationAtom = atom<InvalidationAtom>({
    key: 'notecardSetMetadataInvalidationAtom',
    default: defaultInvalidationAtom,
    effects: [
        logAtomChangesEffect('Notecard Set Metadata Invalidation Atom'),
        resetOnSetEffect(notecardSetMetadataAtom),
    ],
})
