import { atom, selector } from 'recoil'
import { getSerializedNotecardSuccessRate } from '@/api/notecards'
import { LoggerSelectorFamily } from '@/atoms/logger'
import { getForeverPromise } from 'common/src/utils/getForeverPromise'
import {
    frontendDisplayedCourseSelector,
    userInfoStateAtom,
} from '@/atoms/accountMaintenance/userInfo'
import {
    NotecardSuccessRate,
    deserializeNotecardSuccessRate,
} from 'common/src/notecards/notecardSuccessRate'

export const fetchNotecardSuccessRate = async (
    courseName: string
): Promise<NotecardSuccessRate | null> => {
    const serializedNotecardSuccessRate = (
        await getSerializedNotecardSuccessRate(courseName)
    ).data.payload
    if (serializedNotecardSuccessRate === null) {
        return null
    }

    return deserializeNotecardSuccessRate(serializedNotecardSuccessRate)
}

export const historicalNotecardSuccessRateAtom =
    atom<NotecardSuccessRate | null>({
        key: 'historicalNotecardSuccessRateAtom',
        default: selector<NotecardSuccessRate | null>({
            key: 'historicalNotecardSuccessRateFetcher',
            get: async ({ get }): Promise<NotecardSuccessRate | null> => {
                const frontendDisplayedCourse = get(
                    frontendDisplayedCourseSelector
                )
                const logger = get(
                    LoggerSelectorFamily(historicalNotecardSuccessRateAtom.key)
                )
                if (frontendDisplayedCourse === null) {
                    logger.warn(
                        `No frontend displayed course, so notecard success rate will not be retrieved (returning forever promise instead)`
                    )
                    return getForeverPromise()
                }

                get(userInfoStateAtom) // forces update upon new login
                logger.info(`Retrieving initial notecard success rate`)
                return fetchNotecardSuccessRate(frontendDisplayedCourse)
            },
        }),
    })
