import React, { ReactElement, useCallback } from 'react'
import Button from 'antd/lib/button/button'
import styles from './SearchLink.module.scss'
import { ColorParams } from '@/components/static/ui/Button/Button.types'
import { JepColorFsaHealth } from '@/components/static/ui/consts'
import { SearchModal } from '@/components/search/SearchModal/SearchModal'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { useRecoilState, useRecoilValue } from 'recoil'
import { isSearchModalVisibleAtom } from '@/atoms/search/isSearchModalVisible'
import { SearchOutlined } from '@ant-design/icons'
import { frontendDisplayedCourseHasActiveSubscriptionSelector } from '@/atoms/accountMaintenance/userInfo'
import ConfigProvider from 'antd/lib/config-provider'

const SearchLinkColorParams: ColorParams = {
    background: JepColorFsaHealth.black,
    border: JepColorFsaHealth.black,
    textColor: JepColorFsaHealth.white,

    hoverBackground: JepColorFsaHealth.black,
    hoverBorder: JepColorFsaHealth.black,
    hoverText: JepColorFsaHealth.white,
}

interface SearchLinkProps {
    courseName: string
    isMobileMode: boolean
}

export const SearchLink: React.FC<SearchLinkProps> = (props): ReactElement => {
    const [isSearchModalVisible, setIsSearchModalVisible] =
        useRecoilState<boolean>(isSearchModalVisibleAtom)

    const hasActiveSubscription = useRecoilValue(
        frontendDisplayedCourseHasActiveSubscriptionSelector
    )

    const onClickHandler = useCallback((): void => {
        if (hasActiveSubscription) {
            setIsSearchModalVisible(true)
        }
    }, [hasActiveSubscription, setIsSearchModalVisible])

    return (
        <div className={styles.searchLink}>
            <SearchModal
                isVisible={isSearchModalVisible}
                setIsVisible={setIsSearchModalVisible}
                courseName={props.courseName}
            />
            <div className={styles.searchLinkBackgroundColor}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: SearchLinkColorParams.background,
                            colorPrimaryBorder: SearchLinkColorParams.border,
                            colorPrimaryText: SearchLinkColorParams.textColor,

                            colorPrimaryBgHover:
                                SearchLinkColorParams.hoverBackground,
                            colorPrimaryBorderHover:
                                SearchLinkColorParams.hoverBorder,
                            colorPrimaryTextHover:
                                SearchLinkColorParams.hoverText,
                            colorTextPlaceholder: 'white',
                        },
                    }}
                >
                    <div
                        className={`${styles.inputStyles} ${
                            reusableCssClass.centerChildrenVertically
                        } ${
                            hasActiveSubscription
                                ? ''
                                : reusableCssClass.dontTouchMe
                        }`}
                        onClick={onClickHandler}
                    >
                        {props.isMobileMode
                            ? 'Search...'
                            : 'Search or Ask Eupheus...'}
                    </div>
                </ConfigProvider>
            </div>

            {!props.isMobileMode && (
                <div className={styles.searchGlassContainer}>
                    <Button
                        onClick={onClickHandler}
                        className={`${styles.searchGlass}`}
                    >
                        <SearchOutlined style={{ fontSize: 18 }} />
                    </Button>
                </div>
            )}
        </div>
    )
}
