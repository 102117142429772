// removes leading subdomain
import { loggedInSubdomain, Subdomain } from '@/utils/paths/types'

export const cleanPath = (path: string): string => {
    let cleanedPath = path
    for (const subdomain of Object.values(Subdomain)) {
        if (path.startsWith('/' + subdomain))
            cleanedPath = cleanedPath.replace('/' + subdomain, '')
    }

    if (cleanedPath.startsWith('/' + loggedInSubdomain)) {
        cleanedPath = cleanedPath.replace('/' + loggedInSubdomain, '')
    }

    return cleanedPath
}
