import { atom, selector } from 'recoil'
import { invalidateAtomsEffect } from '@/atoms/atomEffects/invalidateAtomsEffect'
import { ProblemSetState } from '@/frontendLogic/practiceProblems/problemSet/problemSet.types'
import { problemSetMetadataInvalidationAtom } from '@/atoms/practiceProblems/problemSetMetadata'
import { practiceProblemSuccessRateInvalidationAtom } from '@/atoms/practiceProblems/practiceProblemSuccessRate'
import { defaultInvalidationAtom, InvalidationAtom } from '@/atoms/types'
import { resetOnSetEffect } from '@/atoms/atomEffects/resetOnSetEffect'
import { logAtomChangesEffect } from '@/atoms/atomEffects/logAtomChangesEffect'
import {
    frontendDisplayedCourseSelector,
    userInfoStateAtom,
} from '@/atoms/accountMaintenance/userInfo'
import { LoggerSelectorFamily } from '@/atoms/logger'
import { mostRecentProblemSetMetadataSelector } from '@/atoms/practiceProblems/mostRecentProblemSetMetadata'
import { getProblemSet } from '@/api/practiceProblems'

export interface FrontendProblemSetState {
    problemSetState: ProblemSetState | null
    problemSetMode: ProblemSetMode
}

export enum ProblemSetMode {
    lesson = 'lesson',
    normal = 'normal',
}

export const problemSetStateAtom = atom<FrontendProblemSetState | null>({
    key: 'problemSetStateAtom',
    default: selector<FrontendProblemSetState | null>({
        key: 'problemSetStateFetcher',
        get: async ({ get }) => {
            const frontendDisplayedCourse = get(frontendDisplayedCourseSelector)
            const logger = get(LoggerSelectorFamily(problemSetStateAtom.key))
            if (!frontendDisplayedCourse) {
                logger.warn(
                    `No frontend displayed course, so not retrieving most recent problem set state (returning null instead)`
                )
                return null
            }

            get(userInfoStateAtom) // forces update upon new login
            get(problemSetStateInvalidationAtom)
            const mostRecentProblemSetMetadata = get(
                mostRecentProblemSetMetadataSelector
            )
            if (!mostRecentProblemSetMetadata) {
                logger.warn(
                    `No problem set history, so not retrieving most recent problem set state (returning null instead)`
                )
                return null
            }

            logger.info(`Retrieving initial problem set state`)
            const problemSetResponse = await getProblemSet(
                frontendDisplayedCourse,
                mostRecentProblemSetMetadata.id,
                mostRecentProblemSetMetadata.problemSetType
            )
            return {
                problemSetState: problemSetResponse.data.payload,
                problemSetMode: ProblemSetMode.normal,
            }
        },
    }),
    effects: [
        invalidateAtomsEffect([
            problemSetMetadataInvalidationAtom,
            practiceProblemSuccessRateInvalidationAtom,
            // problemUserStatsInvalidationAtom,
        ]),
    ],
})

export const problemSetStateInvalidationAtom = atom<InvalidationAtom>({
    key: 'problemSetStateInvalidationAtom',
    default: defaultInvalidationAtom,
    effects: [
        logAtomChangesEffect('Problem Set State Invalidation Atom'),
        resetOnSetEffect(problemSetStateAtom),
    ],
})
