import { atom, selector, selectorFamily } from 'recoil'
import { ContentPath } from 'common/src/ContentPath'
import { getOutlineMetadata } from '@/api/outlines'
import { contentTreeStateAtom } from '@/atoms/contentTree'
import { LoggerSelectorFamily } from '@/atoms/logger'
import {
    createOutlineProgressMap,
    getProgressesForContentPath,
    getProgressForContentPath,
} from '@/frontendLogic/outlines/OutlineProgressMap/OutlineProgressMap'
import {
    IBaseProgress,
    OutlineProgressMap,
} from '@/frontendLogic/outlines/OutlineProgressMap/OutlineProgressMap.types'
import { defaultInvalidationAtom, InvalidationAtom } from '@/atoms/types'
import { resetOnSetEffect } from '@/atoms/atomEffects/resetOnSetEffect'
import { logAtomChangesEffect } from '@/atoms/atomEffects/logAtomChangesEffect'
import {
    frontendDisplayedCourseSelector,
    userInfoStateAtom,
} from '@/atoms/accountMaintenance/userInfo'

export const outlineProgressMapAtom = atom<OutlineProgressMap | null>({
    key: 'outlineProgressMapAtom',
    default: selector<OutlineProgressMap | null>({
        key: 'outlineProgressMapFetcher',
        get: async ({ get }) => {
            const frontendDisplayedCourse = get(frontendDisplayedCourseSelector)
            const logger = get(LoggerSelectorFamily(outlineProgressMapAtom.key))
            if (frontendDisplayedCourse === null) {
                logger.warn(
                    `No frontend displayed course, so outline progress map will not be retrieved (returning null instead)`
                )
                return null
            }

            get(userInfoStateAtom) // forces update upon new login
            get(outlineProgressMapInvalidationAtom)
            logger.info(`Retrieving initial outline metadata`)
            const outlineMetadataResponse = await getOutlineMetadata(
                frontendDisplayedCourse
            )
            const data = outlineMetadataResponse.data.payload?.outlineMetadata
            if (!data) {
                logger.warn(
                    `Failed to get outline metadata response, returning null`
                )
                return null
            }

            const contentTreeState = get(contentTreeStateAtom)
            if (contentTreeState === null) {
                logger.warn('No content tree state, returning null')
                return null
            }

            return createOutlineProgressMap(data, contentTreeState.contentTree)
        },
    }),
})

export const outlineProgressMapInvalidationAtom = atom<InvalidationAtom>({
    key: 'outlineProgressMapInvalidationAtom',
    default: defaultInvalidationAtom,
    effects: [
        logAtomChangesEffect('Outline Progress Map Invalidation Atom'),
        resetOnSetEffect(outlineProgressMapAtom),
    ],
})

export const outlineProgressForContentPathSelectorFamily = selectorFamily<
    IBaseProgress | null,
    ContentPath
>({
    key: 'outlineProgressForContentPathSelectorFamily',
    get:
        (contentPath: ContentPath) =>
        ({ get }) => {
            const outlineProgressMap = get(outlineProgressMapAtom)
            if (!outlineProgressMap) {
                get(
                    LoggerSelectorFamily(
                        outlineProgressForContentPathSelectorFamily(contentPath)
                            .key
                    )
                ).warn('Outline progress map is null, returning null')
                return null
            }

            return getProgressForContentPath(outlineProgressMap, contentPath)
        },
})

const sum = (a: number, b: number): number => {
    return a + b
}

// Very similar to outlineProgressForContentPathSelectorFamily, but works for content path []
export const outlineCompletionSelectorFamily = selectorFamily<
    {
        numRead: number
        total: number
        percentRead: number
    },
    ContentPath
>({
    key: 'outlineCompletionSelector',
    get:
        (contentPath: ContentPath) =>
        ({ get }) => {
            const map = get(outlineProgressMapAtom)
            if (map === null) {
                get(
                    LoggerSelectorFamily(
                        outlineCompletionSelectorFamily(contentPath).key
                    )
                ).warn('Outline progress map is null, returning 0')
                return { numRead: 0, total: 0, percentRead: 0 }
            }
            const progresses = getProgressesForContentPath(map, contentPath)
            const numRead = progresses
                .map((namedBaseProgress) => namedBaseProgress.read)
                .reduce(sum, 0)
            const total = progresses
                .map((namedBaseProgress) => namedBaseProgress.total)
                .reduce(sum, 0)
            const percentRead = numRead / total
            return { numRead, total, percentRead }
        },
})
