import notification from 'antd/lib/notification'
import { NotificationKeys } from '@/utils/notificationKeys'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { isEnumMember } from 'common/src/utils/isEnumMember'
import { AuthenticationError } from 'common/src/errors'
import { jepAssertNever } from 'common/src/utils/jepAssertNever'

export interface MessageAndDescription {
    message: string
    description: string
}

const getMessageAndDescriptionForError = (
    error: string,
    fallbackMessageAndDescription: MessageAndDescription
): MessageAndDescription => {
    if (!isEnumMember(AuthenticationError, error)) {
        return fallbackMessageAndDescription
    }
    switch (error) {
        // These technically shouldn't happen (frontend validation should prevent this), but this is here just in case
        case AuthenticationError.INVALID_EMAIL:
        case AuthenticationError.INVALID_PASSWORD:
        case AuthenticationError.INVALID_GOOGLE_TOKEN: {
            return fallbackMessageAndDescription
        }
        case AuthenticationError.INCORRECT_PASSWORD: {
            return {
                message: 'Incorrect password',
                description: 'Please enter the correct password and try again.',
            }
        }
        case AuthenticationError.EMAIL_NOT_FOUND: {
            return {
                message: 'Email not found',
                description:
                    'Please enter the original email address used to sign up.',
            }
        }
        case AuthenticationError.USER_WITH_EMAIL_EXISTS: {
            return {
                message: 'Email already exists',
                description: 'A user with that email address already exists.',
            }
        }
        case AuthenticationError.NO_PASSWORD_GOOGLE_LOGIN: {
            return {
                message: 'No password set',
                description:
                    'It appears you signed up using Google and therefore don\'t actually have a password. Either login by clicking "Login with Google" or click "Forgot your password?" to set up a password.',
            }
        }
        case AuthenticationError.INVALID_TOKEN_DURING_RESET_PASSWORD: {
            return {
                message: 'Invalid URL',
                description:
                    'The url used to access this page is invalid. Please double check to ensure you correctly copied the url sent via the email. If this does not work, please restart the reset password flow from the beginning.',
            }
        }
        default:
            jepAssertNever(error, 'Unhandled AuthenticationError')
    }
}

export const clearAuthenticationError = (): void =>
    notification.destroy(NotificationKeys.AUTHENTICATION_ERROR)

export const handleAuthenticationError = (
    errorMsg: string,
    fallbackMessageAndDescription: MessageAndDescription
): void => {
    notification.error({
        ...getMessageAndDescriptionForError(
            errorMsg,
            fallbackMessageAndDescription
        ),
        duration: 0,
        onClick: () => clearAuthenticationError(),
        className: reusableCssClass.clickMe,
        key: NotificationKeys.AUTHENTICATION_ERROR,
    })
}
