import { ContentType } from 'common/src/commentThread/types'
import { WBSearchResponse } from 'common/src/elasticSearch/responses'
import React, { ReactElement, useMemo } from 'react'
import { AccessType } from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/types'
import { SearchResultHeader } from '@/components/search/SearchModal/SearchResultHeader/SearchResultHeader'
import styles from './SearchResultsMobile.module.scss'
import { SearchResult } from '@/components/search/SearchModal/SearchResult/SearchResult'
import {
    SearchContext,
    WBSearchResultsContentBase,
} from 'common/src/elasticSearch/types'
import { jepAssertNever } from 'common/src/utils/jepAssertNever'

interface SearchResultsProps {
    results: WBSearchResponse
    contentTypeFoci: ContentType[]
    setContentTypeFoci: (contentType: ContentType[]) => void
    setIsSearchModalVisible: (isVisible: boolean) => void
    setSearchText: (searchText: string) => void
    accessTypes: AccessType[]
}

const chooseArray = (
    results: WBSearchResponse,
    contentType: ContentType
): WBSearchResultsContentBase[] => {
    switch (contentType) {
        case ContentType.PRACTICE_PROBLEM:
            return results.practiceProblems
        case ContentType.OUTLINE:
        case ContentType.SUMMARY:
            return results.outlines
        case ContentType.NOTECARD:
            return results.notecards
        default:
            jepAssertNever(contentType, 'Unknown contentType to chooseArray')
    }
}

const chooseContext = (contentType: ContentType): SearchContext => {
    switch (contentType) {
        case ContentType.PRACTICE_PROBLEM:
            return 'practiceProblems'
        case ContentType.OUTLINE:
        case ContentType.SUMMARY:
            return 'outlines'
        case ContentType.NOTECARD:
            return 'notecards'
        default:
            jepAssertNever(contentType, 'Unknown contentTYpe to chooseContext')
    }
}

export const SearchResultsMobile: React.FC<SearchResultsProps> = (
    props
): ReactElement => {
    const activeContentType = useMemo(
        (): ContentType | undefined => props.contentTypeFoci[0],
        [props.contentTypeFoci]
    )
    const activeAccessType = useMemo((): AccessType | undefined => {
        switch (activeContentType) {
            case ContentType.PRACTICE_PROBLEM:
                return AccessType.PRACTICE_PROBLEMS
            case ContentType.SUMMARY:
            case ContentType.OUTLINE:
                return AccessType.OUTLINES
            case ContentType.NOTECARD:
                return AccessType.NOTECARDS
        }
    }, [activeContentType])

    if (!activeContentType) {
        return (
            <div className={styles.menuContainer}>
                <SearchResultHeader
                    contentType={ContentType.NOTECARD}
                    numResults={props.results.notecards.length}
                    setContentTypeFoci={props.setContentTypeFoci}
                    contentTypeFoci={props.contentTypeFoci}
                    isAccessLocked={
                        !props.accessTypes.includes(AccessType.NOTECARDS)
                    }
                    arrowDirection={'right'}
                    addOrReplaceOnClick={'replace'}
                />
                <SearchResultHeader
                    contentType={ContentType.PRACTICE_PROBLEM}
                    numResults={props.results.practiceProblems.length}
                    setContentTypeFoci={props.setContentTypeFoci}
                    contentTypeFoci={props.contentTypeFoci}
                    isAccessLocked={
                        !props.accessTypes.includes(
                            AccessType.PRACTICE_PROBLEMS
                        )
                    }
                    arrowDirection={'right'}
                    addOrReplaceOnClick={'replace'}
                />
                <SearchResultHeader
                    contentType={ContentType.OUTLINE}
                    numResults={props.results.outlines.length}
                    setContentTypeFoci={props.setContentTypeFoci}
                    contentTypeFoci={props.contentTypeFoci}
                    isAccessLocked={
                        !props.accessTypes.includes(AccessType.OUTLINES)
                    }
                    arrowDirection={'right'}
                    addOrReplaceOnClick={'replace'}
                />
            </div>
        )
    }

    const array = chooseArray(props.results, activeContentType)
    return (
        <div className={styles.activeContentTypeContainer}>
            <SearchResultHeader
                contentType={activeContentType}
                numResults={props.results.practiceProblems.length}
                setContentTypeFoci={props.setContentTypeFoci}
                contentTypeFoci={props.contentTypeFoci}
                isAccessLocked={false}
                arrowDirection={'left'}
                addOrReplaceOnClick={'replace'}
            />
            <div className={styles.searchResultsBodyContainer}>
                {array.map((searchResult, index) => {
                    return (
                        <SearchResult
                            setIsSearchModalVisible={
                                props.setIsSearchModalVisible
                            }
                            searchContext={chooseContext(activeContentType)}
                            searchResult={searchResult}
                            isDisabled={
                                !props.accessTypes.includes(activeAccessType)
                            }
                            key={index}
                        />
                    )
                })}
            </div>
        </div>
    )
}
