import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import styles from './AskEupheus.module.scss'
import { LoadingComponent } from '@/components/static/LoadingComponent/LoadingComponent'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { QueryContentResponse } from 'common/src/llm/queryContent'
import { hashContentPath } from 'common/src/ContentPath'
import { OutlineLink } from '@/components/OutlineLink/OutlineLink'
import { isSearchModalVisibleAtom } from '@/atoms/search/isSearchModalVisible'
import { AnimatedTyping } from '@/components/utils/AnimatedTyping/AnimatedTyping'
import { registerEvent } from '@/api/analyticsEvent'
import { PaidUserAnalyticsEventType } from 'common/src/api/websiteFrontendVsWebsiteBackend/analyticsEvent/types'
import { Feedback } from '@/components/utils/Feedback/Feedback'
import { frontendDisplayedCourseAccessTypesSelector } from '@/atoms/accountMaintenance/userInfo'
import { FadeInOnMount } from '@/components/static/FadeInOnMount/FadeInOnMount'

interface EupheusResultsProps {
    searchText: string
    queryResults: QueryContentResponse | null
    isLoading: boolean
    contentQueryId: string
}
export const AskEupheus: React.FC<EupheusResultsProps> = (
    props
): ReactElement => {
    const setIsSearchModalVisible = useSetRecoilState(isSearchModalVisibleAtom)
    const accessTypes = useRecoilValue(
        frontendDisplayedCourseAccessTypesSelector
    )

    const [isTextFinishedWriting, setIsTextFinishedWriting] =
        useState<boolean>(false)
    const relevantContentSections = useMemo((): ReactElement | null => {
        if (
            !isTextFinishedWriting ||
            !props.queryResults.relevantContentPaths?.length
        )
            return null
        return (
            <FadeInOnMount fadeInDurationInMs={500}>
                <div className={styles.relevantContentSectionHeader}>
                    Relevant Resources
                </div>
                <div className={styles.relevantContentSectionsContainer}>
                    <div className={styles.relevantContentSectionsBody}>
                        {props.queryResults.relevantContentPaths.map(
                            (contentPath, index) => (
                                <OutlineLink
                                    key={index}
                                    contentPath={contentPath}
                                    isAllOneLink={true}
                                    onClickSideEffect={(e) => {
                                        // only close if not holding ctrl (will open in new tab)
                                        if (!e.ctrlKey) {
                                            setIsSearchModalVisible(false)
                                        }
                                        registerEvent(
                                            PaidUserAnalyticsEventType.SEARCH_RESULT_CLICKED,
                                            {
                                                id: hashContentPath(
                                                    contentPath
                                                ),
                                                searchText: props.searchText,
                                                contentQueryId:
                                                    props.contentQueryId,
                                                type: 'contentQuery',
                                            }
                                        )
                                    }}
                                    accessTypes={accessTypes}
                                />
                            )
                        )}
                    </div>
                </div>
            </FadeInOnMount>
        )
    }, [
        accessTypes,
        isTextFinishedWriting,
        props.contentQueryId,
        props.queryResults.relevantContentPaths,
        props.searchText,
        setIsSearchModalVisible,
    ])

    const onFeedbackClick = useCallback(
        (isPositive: boolean) => {
            registerEvent(
                PaidUserAnalyticsEventType.CONTENT_QUERY_USER_FEEDBACK,
                {
                    isPositive,
                    searchText: props.searchText,
                    contentQueryId: props.contentQueryId,
                }
            )
        },
        [props.contentQueryId, props.searchText]
    )

    return (
        <>
            <div className={styles.askEupheusSearchMenu}>
                {
                    <div className={styles.queryResults}>
                        {props.isLoading ? (
                            <LoadingComponent />
                        ) : (
                            <div className={styles.queryResultsBody}>
                                <div className={styles.responseText}>
                                    <AnimatedTyping
                                        text={props.queryResults.response}
                                        onComplete={() =>
                                            setIsTextFinishedWriting(true)
                                        }
                                    />
                                </div>
                                {relevantContentSections}
                                <div className={styles.feedbackContainer}>
                                    <Feedback
                                        onFeedbackClick={onFeedbackClick}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                }
            </div>
        </>
    )
}
