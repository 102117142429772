import React, { ReactElement, useCallback, useMemo } from 'react'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import styles from './StudyTimer.module.scss'
import Button from 'antd/lib/button/button'
import notification from 'antd/lib/notification'
import { NotificationKeys } from '@/utils/notificationKeys'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { isStudyTimeModalVisibleAtom } from '@/atoms/accountMaintenance/studyTimer'
import { updateStudyTimeRecordForDate2 } from '@/api/studyTime'
import { secondsToHM, secondsToHoursAndMinutes } from '@/utils/dateTime'
import { studyTimerAtom } from '@/atoms/accountMaintenance/studyTimeRecords'
import { StudyTimeAction } from 'common/src/api/websiteFrontendVsWebsiteBackend/studyTime/types'
import { ProcessStudyTimeActionRequest } from 'common/src/api/websiteFrontendVsWebsiteBackend/studyTime/requests'
import { getTimeZone } from '@/components/utils/getTimeZone'
import { isPausedFromInactivityModalOpenAtom } from '@/atoms/isTimerPaused'
import { PausedFromInactivityModal } from '@/components/layout/LeftSidebarLoggedIn/StudyTimer/PausedFromInactivityModal/PausedFromInactivityModal'
import PauseCircle from '@/components/icons/pauseCircle.svg'
import Play from '@/components/icons/play.svg'
import { useStudyTimer } from '@/hooks/useStudyTimer'
import {
    frontendDisplayedCourseHasActiveSubscriptionSelector,
    frontendDisplayedCourseSelector,
} from '@/atoms/accountMaintenance/userInfo'
import { Tooltip } from 'antd'

interface StudyTimerProps {
    isSmallDesktopMode: boolean
}
export const StudyTimer: React.FC<StudyTimerProps> = (props): ReactElement => {
    useStudyTimer()

    useSetRecoilState(isStudyTimeModalVisibleAtom)
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const hasActiveSubscription = useRecoilValue(
        frontendDisplayedCourseHasActiveSubscriptionSelector
    )
    const [studyTimer, setStudyTimer] = useRecoilState(studyTimerAtom)
    const isPausedFromInactivityModalOpen = useRecoilValue<boolean>(
        isPausedFromInactivityModalOpenAtom
    )
    const handleSetIsTimerPausedByUser = useCallback(
        (isPaused: boolean): void => {
            setStudyTimer((current) => {
                if (isPaused) {
                    return { ...current, isPaused: true, reason: 'MANUAL' }
                }
                return { ...current, isPaused: false }
            })

            let payload: ProcessStudyTimeActionRequest

            if (isPaused) {
                payload = {
                    courseName,
                    action: StudyTimeAction.PAUSE_TIMER,
                    timeZone: getTimeZone(),
                    isFirstRequest: false,
                }
            } else {
                payload = {
                    courseName,
                    action: StudyTimeAction.PLAY_TIMER,
                    isFirstRequest: false,
                    timeZone: getTimeZone(),
                }
            }
            updateStudyTimeRecordForDate2(payload)
        },
        [courseName, setStudyTimer]
    )

    const isPaused = useMemo(
        (): boolean =>
            !hasActiveSubscription ||
            (studyTimer.isPaused && studyTimer.reason === 'MANUAL') ||
            isPausedFromInactivityModalOpen,
        [hasActiveSubscription, isPausedFromInactivityModalOpen, studyTimer]
    )
    const studyTimeStr = useMemo(
        (): string =>
            secondsToHoursAndMinutes(
                studyTimer.studyTimeForMostRecentDate,
                false
            ),
        [studyTimer.studyTimeForMostRecentDate]
    )

    const isDisabled = useMemo(
        (): boolean => !hasActiveSubscription,
        [hasActiveSubscription]
    )

    const handleOnClick = useCallback(
        (event: React.MouseEvent): void => {
            event.stopPropagation()
            if (isDisabled) {
                return
            }

            handleSetIsTimerPausedByUser(!isPaused)

            if (isPaused) {
                notification.destroy(NotificationKeys.STUDY_TIMER_UNPAUSED)
                notification.destroy(NotificationKeys.STUDY_TIMER_PAUSED)
                notification.success({
                    message: 'Study timer active',
                    description: `Time spent studying today: ${studyTimeStr}`,
                    duration: 3, // seconds
                    onClick: () => {
                        notification.destroy(
                            NotificationKeys.STUDY_TIMER_PAUSED
                        )
                    },
                    key: NotificationKeys.STUDY_TIMER_PAUSED,
                })
            } else {
                notification.destroy(NotificationKeys.STUDY_TIMER_UNPAUSED)
                notification.destroy(NotificationKeys.STUDY_TIMER_PAUSED)
                notification.info({
                    message: 'Study timer paused',
                    description: `Time spent studying today: ${studyTimeStr}`,
                    duration: 3, // seconds
                    onClick: () => {
                        notification.destroy(
                            NotificationKeys.STUDY_TIMER_UNPAUSED
                        )
                    },
                    className: reusableCssClass.clickMe,
                    key: NotificationKeys.STUDY_TIMER_UNPAUSED,
                })
            }
        },
        [handleSetIsTimerPausedByUser, isDisabled, isPaused, studyTimeStr]
    )
    const pausePlay = useMemo((): ReactElement => {
        let symbol: ReactElement
        if (isPaused) {
            symbol = <Play />
        } else {
            symbol = <PauseCircle />
        }
        return <div className={styles.pausePlayContainer}>{symbol}</div>
    }, [isPaused])

    const text = useMemo((): string => {
        if (isPaused) {
            return 'Paused'
        }
        return 'Tracking...'
    }, [isPaused])

    const className = useMemo((): string => {
        if (isDisabled) {
            return styles.disabled
        }
        if (isPaused) {
            return styles.paused
        }
        return styles.play
    }, [isDisabled, isPaused])

    const studyButtonBody = useMemo((): ReactElement => {
        if (props.isSmallDesktopMode) {
            return pausePlay
        }
        return (
            <div className={styles.buttonContentsContainer}>
                {' '}
                <div className={styles.timeText}>
                    {secondsToHM(studyTimer.studyTimeForMostRecentDate)}
                </div>
                <div className={`${styles.pausePlayContainer}`}>
                    {pausePlay}
                </div>
                <div className={styles.textContainer}>{text}</div>
            </div>
        )
    }, [
        pausePlay,
        props.isSmallDesktopMode,
        studyTimer.studyTimeForMostRecentDate,
        text,
    ])

    const button = useMemo((): ReactElement => {
        const base = (
            <Button
                className={`${styles.clockMenuItem} ${className}`}
                onClick={(event) => {
                    if (isDisabled) return
                    handleOnClick(event)
                }}
                disabled={isDisabled}
            >
                {studyButtonBody}
            </Button>
        )

        if (props.isSmallDesktopMode) {
            const tooltipText = isPaused
                ? 'Study Timer Paused - Click to Start.'
                : 'Study Timer Running - Click to Pause.'
            return (
                <Tooltip title={tooltipText} placement={'right'}>
                    {base}
                </Tooltip>
            )
        }
        return base
    }, [
        className,
        handleOnClick,
        isDisabled,
        isPaused,
        props.isSmallDesktopMode,
        studyButtonBody,
    ])

    return (
        <>
            <PausedFromInactivityModal />
            <div className={`${styles.clockMenuItemContainer}`}>{button}</div>
        </>
    )
}
