import { StringifiedMagicTextBox } from 'magic-text-box-lexical/src/components/types'
import {
    atomFamily,
    DefaultValue,
    selectorFamily,
    useRecoilCallback,
} from 'recoil'
import { getUserMtb } from '@/api/userMtb'
import { problemUserStatsMapSelector } from '@/atoms/practiceProblems/problemUserStats'
import { PracticeProblemType } from 'common/src/practiceProblems'
import { problemSetStateAtom } from '@/atoms/practiceProblems/problemSet'
import { isProblemMultiPartUser } from 'common/src/practiceProblems/typeguards'
import { LoggerSelectorFamily } from '@/atoms/logger'

export const USER_MTB_KEY_SENTINEL_VALUE = 'USER_MTB_KEY_SENTINEL_VALUE'

// key is ID, value is stringified magic text box
export const userMtbAtomFamily = atomFamily<StringifiedMagicTextBox, string>({
    key: 'userMtbAtomFamily',
    default: selectorFamily<StringifiedMagicTextBox, string>({
        key: 'userMtbDefaultSelectorFamily',
        get: (id) => async () => {
            if (id?.startsWith(USER_MTB_KEY_SENTINEL_VALUE)) return undefined
            const response = await getUserMtb(id)
            return response.data.payload?.userMtb
        },
    }),
})

export const userMtbAtomFamilyNoDefaultSelector = atomFamily<
    StringifiedMagicTextBox,
    string
>({
    key: 'userMtbAtomFamilyNoDefaultSelector',
})

export const useGetUserMtbForKey = (): ((
    key: string
) => StringifiedMagicTextBox | null) => {
    return useRecoilCallback(
        ({ snapshot }) =>
            (key: string): StringifiedMagicTextBox | null => {
                const result = snapshot.getLoadable(userMtbAtomFamily(key))
                if (result.state !== 'hasValue') return null
                return result.contents
            },
        []
    )
}

export const subProblemNotesUserMtbSelectorFamily = selectorFamily<
    StringifiedMagicTextBox[] | null, // null if not multi-part problem
    string // super problem ID
>({
    key: 'subProblemNotesUserMtbSelectorFamily',
    get:
        (id) =>
        ({ get }) => {
            if (id?.startsWith(USER_MTB_KEY_SENTINEL_VALUE)) {
                return null
            }

            const logger = get(
                LoggerSelectorFamily(
                    subProblemNotesUserMtbSelectorFamily(id).key
                )
            )
            const problemUserStatsMap = get(problemUserStatsMapSelector)
            if (problemUserStatsMap === null) {
                logger.warn('No problem user stats map, returning null')
                return null
            }

            const specificProblem = problemUserStatsMap.get(id)
            if (!specificProblem) {
                logger.warn(
                    `Problem ID: ${id} not found in problem user stats map. Returning null.`
                )
                return null
            }

            if (specificProblem.type !== PracticeProblemType.MULTI_PART) {
                return null
            }

            return (
                specificProblem.subProblemData?.map((data) =>
                    get(userMtbAtomFamily(data.notesMtbKey))
                ) ?? null
            )
        },
    set:
        (id) =>
        ({ get, set }, newNotesOrDefaultValue) => {
            if (
                newNotesOrDefaultValue instanceof DefaultValue ||
                newNotesOrDefaultValue === null
            ) {
                return
            }

            const logger = get(
                LoggerSelectorFamily(
                    subProblemNotesUserMtbSelectorFamily(id).key
                )
            )
            const problemUserStatsMap = get(problemUserStatsMapSelector)
            if (problemUserStatsMap === null) {
                logger.warn('No problem user stats map, doing no-op')
                return
            }

            const specificProblem = problemUserStatsMap.get(id)
            if (!specificProblem) {
                logger.warn(
                    `Unable to find problem ID: ${id} in problem user stats map. No op.`
                )
                return
            }

            if (specificProblem.type !== PracticeProblemType.MULTI_PART) {
                return
            }

            for (const [
                index,
                data,
            ] of specificProblem.subProblemData?.entries() ?? []) {
                const existingData = get(userMtbAtomFamily(data.notesMtbKey))
                const newData = newNotesOrDefaultValue[index]
                if (existingData !== newData) {
                    set(userMtbAtomFamily(data.notesMtbKey), newData)
                }
            }
        },
})

export const subProblemUserAnswersMtbSelectorFamily = selectorFamily<
    StringifiedMagicTextBox[] | null, // null if not multi-part problem
    string // super problem ID
>({
    key: 'subProblemUserAnswersMtbSelectorFamily',
    get:
        (id) =>
        ({ get }) => {
            if (id?.startsWith(USER_MTB_KEY_SENTINEL_VALUE)) {
                return null
            }

            const logger = get(
                LoggerSelectorFamily(
                    subProblemUserAnswersMtbSelectorFamily(id).key
                )
            )
            const problemSet = get(problemSetStateAtom)
            if (problemSet === null) {
                logger.warn('No problem set, returning null')
                return null
            }

            const specificProblem = problemSet.problemSetState.problems.find(
                (problem) => problem.id === id
            )
            if (!specificProblem) {
                logger.warn(
                    `Unable to find problem ID: ${id} in problem set id: ${problemSet.problemSetState.id} problems. Returning null.`
                )
                return null
            }

            if (!isProblemMultiPartUser(specificProblem)) {
                return null
            }

            return specificProblem.subProblems.map((subProblem) =>
                get(userMtbAtomFamily(subProblem.userAnswerMtbKey))
            )
        },
    set:
        (id) =>
        ({ get, set }, newUserAnswerOrDefaultValue) => {
            if (
                newUserAnswerOrDefaultValue instanceof DefaultValue ||
                newUserAnswerOrDefaultValue === null
            ) {
                return
            }

            const logger = get(
                LoggerSelectorFamily(
                    subProblemUserAnswersMtbSelectorFamily(id).key
                )
            )
            const problemSet = get(problemSetStateAtom)
            if (!problemSet) {
                logger.warn(`No problem set, effectively a no-op.`)
                return
            }

            const specificProblem = problemSet.problemSetState.problems.find(
                (problem) => problem.id === id
            )
            if (!specificProblem) {
                logger.warn(
                    `Unable to find problem ID: ${id} in problem set ID: ${problemSet.problemSetState.id}. Effectively a no-op.`
                )
                return
            }

            if (!isProblemMultiPartUser(specificProblem)) {
                return
            }

            for (const [index, data] of specificProblem.subProblems.entries()) {
                const existingData = get(
                    userMtbAtomFamily(data.userAnswerMtbKey)
                )
                const newData = newUserAnswerOrDefaultValue[index]
                if (existingData !== newData) {
                    set(userMtbAtomFamily(data.userAnswerMtbKey), newData)
                }
            }
        },
})
