import { TopicToLearningObjectivesMap } from 'common/src/api/core/contentTree/responses'
import { ContentPath } from 'common/src/ContentPath'

export const getLearningObjectiveDescription = (
    topicToLearningObjectivesMap: TopicToLearningObjectivesMap,
    contentPath: ContentPath,
    learningObjectiveID: string
): string => {
    const learningObjectivesForTopic =
        topicToLearningObjectivesMap.get(contentPath[0]) || []
    const learningObjective = learningObjectivesForTopic.filter(
        (learningObjective) => learningObjective.id === learningObjectiveID
    )
    return learningObjective.length === 1
        ? learningObjective[0].description
        : null
}
