import { AtomEffect } from 'recoil'
import { logger } from '@/logging/FrontendLogger'

export const logAtomChangesEffect =
    <T>(descr: string, canSendToBackend = true): AtomEffect<T> =>
    ({ onSet }) => {
        onSet((newVal, oldVal) => {
            logger.info(
                `[${logAtomChangesEffect.name}: ${descr}] Atom changed value: ${JSON.stringify(
                    oldVal
                )} -> ${JSON.stringify(newVal)}`,
                { canSendToBackend }
            )
        })
    }
