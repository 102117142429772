import { atom, selector, selectorFamily } from 'recoil'
import { CourseInfo, ReleaseStatus } from 'common/src/api/core/courses/types'
import { getCourseTracks } from '@/api/notLoggedIn'
import { FeatureFlag } from 'common/src/featureFlags'
import { CourseConfigType } from 'common/src/courseConfig'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { LoggerSelectorFamily } from '@/atoms/logger'

const AVAILABLE_COURSE_STATUSES: ReleaseStatus[] = [
    ReleaseStatus.fullyAvailable,
    ReleaseStatus.hiddenButAvailable,
]

const isAvailableCourseReleaseStatus = (
    releaseStatus: ReleaseStatus
): boolean => {
    return AVAILABLE_COURSE_STATUSES.includes(releaseStatus)
}

const getHttpClient = (): AxiosInstance => {
    const config: AxiosRequestConfig = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        withCredentials: true,
    }

    return axios.create(config)
}
const httpClient = getHttpClient()

export const courseConfigTypeAtom = atom<CourseConfigType>({
    key: 'courseConfigTypeAtom',
    default: selector<CourseConfigType>({
        key: 'courseConfigTypeFetcher',
        get: async ({ get }) => {
            const courseConfigTypeResponse =
                await httpClient.get('/courseConfigType')
            if (!courseConfigTypeResponse?.data) {
                get(LoggerSelectorFamily(courseConfigTypeAtom.key)).warn(
                    'No course config type response, returning fsa health by default'
                )
                return CourseConfigType.fsaHealth
            }

            return courseConfigTypeResponse.data.courseConfigType
        },
    }),
})

export const coursesInfoAtom = atom<CourseInfo[] | null>({
    key: 'coursesInfoAtom',
    default: selector<CourseInfo[] | null>({
        key: 'coursesInfoFetcher',
        get: async ({ get }) => {
            const courseConfigType = get(courseConfigTypeAtom)
            const courseTracksResponse = await getCourseTracks(courseConfigType)
            if (courseTracksResponse.data.payload === null) {
                get(LoggerSelectorFamily(coursesInfoAtom.key)).warn(
                    'No course tracks response, returning null'
                )
                return null
            }

            return courseTracksResponse.data.payload.courseTracks
                .filter((trackGroup) => {
                    // filter out not available courses
                    return !!trackGroup.filter(
                        (courseInfo) =>
                            courseInfo.releaseInfo.releaseStatus !==
                            ReleaseStatus.notAvailable
                    ).length
                })
                .flat()
        },
    }),
})

export const courseInfoSelectorFamily = selectorFamily<
    CourseInfo | null,
    string
>({
    key: 'courseInfoSelectorFamily',
    get:
        (courseName: string) =>
        ({ get }) => {
            const courseTracks = get(coursesInfoAtom)
            if (!courseTracks) {
                get(
                    LoggerSelectorFamily(
                        courseInfoSelectorFamily(courseName).key
                    )
                ).warn('No course tracks, returning null')
                return null
            }

            return (
                courseTracks.find(
                    (courseTrack) => courseTrack.name === courseName
                ) ?? null
            )
        },
})

export const isCourseAvailableSelectorFamily = selectorFamily<boolean, string>({
    key: 'isCourseAvailableSelectorFamily',
    get:
        (courseName: string) =>
        ({ get }) => {
            const logger = get(
                LoggerSelectorFamily(
                    isCourseAvailableSelectorFamily(courseName).key
                )
            )

            if (!courseName) {
                logger.warn('No course name, returning false')
                return false
            }

            const courseInfo = get(courseInfoSelectorFamily(courseName))
            if (!courseInfo) {
                logger.warn(
                    `Unable to find course info for name: ${courseName}`
                )
                return false
            }

            return isAvailableCourseReleaseStatus(
                courseInfo.releaseInfo.releaseStatus
            )
        },
})

export const isFeatureFlagActiveSelectorFamily = selectorFamily<
    boolean | null,
    { courseName: string; featureFlag: FeatureFlag }
>({
    key: 'isFeatureFlagActiveSelectorFamily',
    get:
        (data) =>
        ({ get }) => {
            const logger = get(
                LoggerSelectorFamily(
                    isFeatureFlagActiveSelectorFamily(data).key
                )
            )
            if (!data.courseName) {
                logger.warn('No course name, returning null')
                return null
            }

            const courseInfo = get(courseInfoSelectorFamily(data.courseName))
            return courseInfo?.featureFlags.includes(data.featureFlag) ?? null
        },
})
