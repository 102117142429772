import { UiLayoutConstant } from '@/globals/uiLayoutConstant'

export type UiLayoutCoursePages =
    | UiLayoutConstant.COURSES_GHVR_PAGE
    | UiLayoutConstant.COURSES_GHRM_PAGE
    | UiLayoutConstant.COURSES_GHDP_PAGE
    | UiLayoutConstant.COURSES_PHARMACY_TECHNICIAN_PAGE
export const COURSE_NAME_TO_UI_LAYOUT_CONSTANT: Record<
    string,
    UiLayoutCoursePages
> = {
    design_and_pricing: UiLayoutConstant.COURSES_GHDP_PAGE,
    risk_mitigation: UiLayoutConstant.COURSES_GHRM_PAGE,
    valuation_and_regulation: UiLayoutConstant.COURSES_GHVR_PAGE,
    pharmacy_technician: UiLayoutConstant.COURSES_PHARMACY_TECHNICIAN_PAGE,
} as const
