import { CourseConfigType } from 'common/src/courseConfig'

export type ColorMap = Record<
    keyof typeof JepColorFsaHealth & keyof typeof JepColorPharmacy,
    string
>

export const getJepColor = (
    courseConfigType: CourseConfigType,
    isLoggedIn: boolean = false
): ColorMap => {
    switch (courseConfigType) {
        case CourseConfigType.fsaHealth:
            return JepColorFsaHealth
        case CourseConfigType.pharmacyTechnician:
            if (isLoggedIn) {
                return JepColorFsaHealth
            }
            return JepColorPharmacy
        default:
            return JepColorFsaHealth
    }
}

export const JepColorFsaHealth = {
    colorOne: '#3749B6',
    colorTwo: '#E9604F',
    colorThree: '#63DC93',
    colorFour: '#F5D257',
    colorFive: '#6e85c4',
    lightenColorFive: '#B3C7FE',
    colorSix: '#77B991',
    colorSeven: '#BDC9E7',
    colorEight: '#8283bc',
    colorNine: '#524169',
    colorTen: '#422d4d80',
    colorEleven: '#7d859b',
    white: '#EEEEEE',
    gray: '#C4C5C7',
    anotherGray: '#363A45',
    black: '#1B202C',
    black5PctLighter: '#252B3C',
    black10PctLighter: '#2E374C',
    green20PctLighter: '#98D097',
    white25PctDarker: '#AEAEAE',
    highlight: '#39435A',
    background: '#333A4B',
    lightGray: '#454d5d',
    headerTextColor: '#1B202C',
    splashPageTopContainer: '#1B202C',
    splashPageTeamContainer: '#2d3146',
    splashPageBackgroundGradient:
        'linear-gradient(90deg, #4b507d 0%, #333a4b 100%);',
    splashPageMiddleGradient:
        'linear-gradient(180deg, #485060 0%, #333a4b 100%);',
    courseCardColor: '#1B202C', // black
    notecardMasteryContainerColor: '#49365a',
    notecardMasteryTextColor: '#a88fcb',
    notecardLearning: '#bcc2e7',
    notecardFiller: '#524169',
    filterDisplayContainer: '#433557',
    setSelectedOptionBackground: '#7b6994',
    setCreatorTopicTitleColor: '#b09ccb',
    setCreatorCountColor: '#292233',
    red: '#d66962',
    yellow: '#d6bc62',
    green: '#53b051',
    reactVisTitleText: '#6b6b76',
    reactVisGridLines: '#e6e6e9',
    reactVisCrosshairLine: '#47d3d9',
    reactVisInnerBackground: '#3a3a48',
    reactVisInnerColor: '#fff',
    reactVisLegendItemDisabledColor: '#b8b8b8',
    reactVisLegendItemClickableHover: '#f9f9f9',
    textColor: '#EEEEEE',
    menuTextColor: '#EEEEEE',
    menuTextSelectedColor: '#F5D257', // color four
    formInputOutlineColor: '#39435A', // highlight
}

export const JepColorPharmacy = {
    colorOne: '#002c5c',
    colorTwo: '#E9604F',
    colorThree: '#007d89',
    colorFour: '#002c5c',
    colorFive: '#007d89',
    lightenColorFive: '#B3C7FE',
    colorSix: '#007d89',
    colorSeven: '#BDC9E7',
    colorEight: '#8283bc',
    colorNine: '#524169',
    colorTen: '#422d4d80',
    colorEleven: '#7d859b',
    white: '#EEEEEE',
    gray: '#363A45',
    anotherGray: '#EEEEEE',
    black: '#002c5c',
    black5PctLighter: '#252B3C',
    black10PctLighter: '#2E374C',
    green20PctLighter: '#98D097',
    white25PctDarker: '#AEAEAE',
    highlight: '#ffffff',
    background: '#EEEEEE',
    lightGray: '#f4f4f4',
    headerTextColor: '#FFFFFF',
    splashPageTopContainer: '#ffffff',
    splashPageTeamContainer: '#ffffff',
    splashPageBackgroundGradient: '#EEEEEE',
    splashPageMiddleGradient: '#EEEEEE',
    courseCardColor: '#ffffff',
    notecardMasteryContainerColor: '#49365a',
    notecardMasteryTextColor: '#a88fcb',
    notecardLearning: '#bcc2e7',
    notecardFiller: '#524169',
    filterDisplayContainer: '#433557',
    setSelectedOptionBackground: '#7b6994',
    setCreatorTopicTitleColor: '#b09ccb',
    setCreatorCountColor: '#292233',
    red: '#d66962',
    yellow: '#d6bc62',
    green: '#53b051',
    reactVisTitleText: '#6b6b76',
    reactVisGridLines: '#e6e6e9',
    reactVisCrosshairLine: '#47d3d9',
    reactVisInnerBackground: '#3a3a48',
    reactVisInnerColor: '#fff',
    reactVisLegendItemDisabledColor: '#b8b8b8',
    reactVisLegendItemClickableHover: '#f9f9f9',
    textColor: '#1B202C',
    menuTextColor: '#FFFFFF',
    menuTextSelectedColor: '#8094CB',
    formInputOutlineColor: '#7d859b', // color eleven
}

// keep in sync with src/styles/limitMaxWidth.module.scss width
export const LIMIT_MAX_WIDTH_IN_PX = 1200

// keep in sync with $logged-in-wide-desktop-mode-nav-bar-width in src/styles/variables.scss
export const LOGGED_IN_WIDE_DESKTOP_MODE_NAV_BAR_WIDTH_IN_PX = 305

// keep in sync with $logged-in-small-desktop-mode-nav-bar-width in src/styles/variables.scss
export const LOGGED_IN_SMALL_DESKTOP_MODE_NAV_BAR_WIDTH_IN_PX = 150
