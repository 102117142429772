import { selector } from 'recoil'
import { frontendDisplayedCourseSelector } from '@/atoms/accountMaintenance/userInfo'
import { courseInfoSelectorFamily } from '@/atoms/courseInfo'
import { userSetNextSittingDateAtom } from '@/atoms/accountMaintenance/studyTimer'

export const nextSittingDateSelector = selector<Date | null>({
    key: 'nextSittingDateSelector',
    get: ({ get }) => {
        const activeCourse = get(frontendDisplayedCourseSelector)
        if (activeCourse === null) return null
        const courseInfo = get(courseInfoSelectorFamily(activeCourse))

        if (courseInfo === null) return null
        if (courseInfo.nextSittingDate) {
            return new Date(courseInfo.nextSittingDate)
        }

        const userSetNextSittingDate = get(userSetNextSittingDateAtom)
        return userSetNextSittingDate ?? null
    },
})
