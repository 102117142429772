import { APIResponseWrapper } from '@/api/types'
import {
    GetAllStudyTimeRecordsResponse,
    GetCurrentStudyTimeStatusResponse,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/studyTime/responses'
import {
    GetCurrentStudyTimeStatusRequest,
    GetStudyTimeGoalInHoursForCourseRequest,
    GetStudyTimeGoalInHoursForCourseResponse,
    ProcessStudyTimeActionRequest,
    SetStudyTimeGoalInHoursForCourseRequest,
    UpdateStudyTimeRecordForDateStr,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/studyTime/requests'
import { ApiMethod } from 'common/src/api/types'
import { GenericContentRequest } from 'common/src/api/core/courses/requests'
import { callBackend } from '../../server/callBackend'
import { CourseConfigType } from 'common/src/courseConfig'
import { SetNextSittingDateRequest } from 'common/src/api/websiteFrontendVsWebsiteBackend/studyTime/requests'

const PREFIX = '/wb/studyTime/'

// TODO: Remove
export const updateStudyTimeRecordForDate = async (
    courseName: string,
    date: Date,
    timeInSeconds: number,
    allowDecreasingTime: boolean
): APIResponseWrapper<void> => {
    const dateStr = date.toString()
    return await callBackend<UpdateStudyTimeRecordForDateStr, void>(
        PREFIX + 'updateStudyTimeRecordForDate',
        ApiMethod.POST,
        {
            courseName,
            dateStr,
            timeInSeconds,
            allowDecreasingTime,
        }
    )
}

export const updateStudyTimeRecordForDate2 = async (
    payload: ProcessStudyTimeActionRequest
): APIResponseWrapper<void> => {
    return await callBackend<ProcessStudyTimeActionRequest, void>(
        PREFIX + 'updateStudyTimeRecordForDate2',
        ApiMethod.POST,
        payload
    )
}

export const getStudyTimeGoalInHoursForCourse = async (
    courseName: string
): APIResponseWrapper<GetStudyTimeGoalInHoursForCourseResponse> => {
    return await callBackend<
        GetStudyTimeGoalInHoursForCourseRequest,
        GetStudyTimeGoalInHoursForCourseResponse
    >(PREFIX + 'getStudyTimeGoalInHoursForCourse', ApiMethod.GET, {
        courseName,
    })
}

export const setStudyTimeGoalInHoursForCourse = async (
    courseName: string,
    studyTimeGoalInHours: number
): APIResponseWrapper<void> => {
    return await callBackend<SetStudyTimeGoalInHoursForCourseRequest, void>(
        PREFIX + 'setStudyTimeGoalInHoursForCourse',
        ApiMethod.POST,
        {
            courseName,
            studyTimeGoalInHours,
        }
    )
}

export const getAllStudyTimeRecords = async (
    courseName: string
): APIResponseWrapper<GetAllStudyTimeRecordsResponse> => {
    return await callBackend<
        GenericContentRequest,
        GetAllStudyTimeRecordsResponse
    >(PREFIX + 'getAllStudyTimeRecords', ApiMethod.GET, { courseName })
}

export const getCurrentStudyTimeStatus = async (
    courseName: string,
    timeZone: string,
    isFirstRequest: boolean
): APIResponseWrapper<GetCurrentStudyTimeStatusResponse> => {
    return await callBackend<
        GetCurrentStudyTimeStatusRequest,
        GetCurrentStudyTimeStatusResponse
    >(PREFIX + 'getCurrentStudyTimeStatus', ApiMethod.GET, {
        courseName,
        timeZone,
        isFirstRequest: isFirstRequest.toString(),
    })
}

export const setNextSittingDateBackend = async (
    courseName: string,
    timeZone: string,
    courseConfigType: CourseConfigType,
    nextSittingDate: Date
): APIResponseWrapper<void> => {
    return await callBackend<SetNextSittingDateRequest, void>(
        PREFIX + 'setNextSittingDate',
        ApiMethod.POST,
        {
            courseName,
            courseConfigType,
            nextSittingDateStr: nextSittingDate.toString(),
            timeZone,
        }
    )
}

export const getNextSittingDate = async (
    courseName: string
): APIResponseWrapper<{ dateStr: string } | null> => {
    return await callBackend<GenericContentRequest, { dateStr: string } | null>(
        PREFIX + 'getNextSittingDate',
        ApiMethod.GET,
        {
            courseName,
        }
    )
}
