import { ContentType } from 'common/src/commentThread/types'
import React, { ReactElement, useCallback, useMemo } from 'react'
import styles from './SearchResultHeader.module.scss'
import { startCase } from 'lodash'
import ChevronDown from '../../../icons/ChevronDown.svg'
import ChevronLeft from '../../../icons/ChevronLeft.svg'
import ChevronRight2 from '../../../icons/ChevronRight2.svg'
import { Padlock } from '@/components/static/images/owlBranding/padlock'
import { reusableCssClass } from '@/utils/reusableCssClasses'

export type ArrowDirection = 'right' | 'left' | 'down'

interface SearchResultHeaderProps {
    contentType: ContentType
    numResults: number
    setContentTypeFoci: (contentTypes: ContentType[]) => void
    contentTypeFoci: ContentType[]
    isAccessLocked: boolean
    arrowDirection: ArrowDirection
    addOrReplaceOnClick: 'add' | 'replace'
}
export const SearchResultHeader: React.FC<SearchResultHeaderProps> = (
    props
): ReactElement => {
    const setContentTypeFoci = useCallback(
        (contentType: ContentType): void => {
            if (props.addOrReplaceOnClick === 'replace') {
                if (props.contentTypeFoci.includes(props.contentType)) {
                    props.setContentTypeFoci([])
                } else {
                    props.setContentTypeFoci([props.contentType])
                }
            } else {
                if (props.contentTypeFoci.includes(contentType)) {
                    props.setContentTypeFoci(
                        props.contentTypeFoci.filter(
                            (_contentType) => _contentType != contentType
                        )
                    )
                } else {
                    props.setContentTypeFoci([
                        ...props.contentTypeFoci,
                        contentType,
                    ])
                }
            }
        },
        [props]
    )
    const chevronIcon = useMemo((): ReactElement => {
        switch (props.arrowDirection) {
            case 'right':
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return <ChevronRight2 style={{ scale: '50%' }} />
            case 'down':
                return <ChevronDown />
            case 'left':
                return <ChevronLeft />
            default:
                return <ChevronDown />
        }
    }, [props.arrowDirection])

    return (
        <div
            className={`${styles.contentTypeTitle} ${
                props.isAccessLocked ? styles.disabled : ''
            }`}
            onClick={() => setContentTypeFoci(props.contentType)}
        >
            {props.isAccessLocked && (
                <div className={reusableCssClass.centerChildrenVertically}>
                    <Padlock />
                </div>
            )}
            {props.arrowDirection === 'left' && (
                <div className={styles.iconContainer}>
                    <div className={styles.iconContainerInner}>
                        {chevronIcon}
                    </div>
                </div>
            )}
            <div>{`${startCase(props.contentType.toLowerCase())}s (${
                props.numResults
            })`}</div>
            {props.arrowDirection !== 'left' && (
                <div className={styles.iconContainer}>
                    <div className={styles.iconContainerInner}>
                        {chevronIcon}
                    </div>
                </div>
            )}
        </div>
    )
}
