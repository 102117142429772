import { CancelToken } from 'axios'
import { ApiMethod, EmptyResponse } from 'common/src/api/types'
import {
    RegisterAnalyticsEventRequest,
    RegisterSessionDataRequest,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/analyticsEvent/requests'
import { APIResponseWrapper } from '@/api/types'
import {
    AnalyticsEventType,
    ClientIPInfo,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/analyticsEvent/types'
import { getResolution } from '@/utils/getResolution'
import { callBackend } from '../../server/callBackend'

const PREFIX = '/wb/analytics/'

export const registerEvent = async (
    analyticsEventType: AnalyticsEventType,
    additionalData?: Record<string, unknown>,
    cancelToken: CancelToken = null
): APIResponseWrapper<EmptyResponse> => {
    // console.log(
    //     `XXX REGISTER EVENT: ${analyticsEventType} | Data: ${
    //         additionalData ? JSON.stringify(additionalData, null, 2) : 'NO DATA'
    //     } XXX`
    // )
    return await callBackend<RegisterAnalyticsEventRequest, EmptyResponse>(
        PREFIX + 'registerEvent',
        ApiMethod.POST,
        {
            analyticsEventType,
            additionalData,
            resolution: getResolution(),
        },
        cancelToken
    )
}

export const registerSessionData = async (
    clientIPInfo: ClientIPInfo,
    cancelToken: CancelToken = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<RegisterSessionDataRequest, EmptyResponse>(
        PREFIX + 'registerSessionData',
        ApiMethod.POST,
        { clientIPInfo, resolution: getResolution() },
        cancelToken
    )
}
