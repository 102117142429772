import { useEffect, useRef } from 'react'
import { PAUSE_AFTER_INACTIVITY_IN_MINUTES } from 'common/src/api/websiteFrontendVsWebsiteBackend/studyTime/types'

const getNow = (): number => new Date().getTime()
export const useActivityMonitor = (): (() => boolean) => {
    const lastActiveTimestamp = useRef<number>(getNow())
    const touchLastActiveTimestamp = (): void => {
        lastActiveTimestamp.current = getNow()
    }
    const getIsInactive = (): boolean =>
        getNow() - lastActiveTimestamp.current >
        PAUSE_AFTER_INACTIVITY_IN_MINUTES * 60 * 1_000
    useEffect(() => {
        document.addEventListener('mousemove', touchLastActiveTimestamp)
        document.addEventListener('mousedown', touchLastActiveTimestamp)
        document.addEventListener('keydown', touchLastActiveTimestamp)
        return () => {
            document.removeEventListener('mousemove', touchLastActiveTimestamp)
            document.removeEventListener('mousedown', touchLastActiveTimestamp)
            document.removeEventListener('keydown', touchLastActiveTimestamp)
        }
    }, [])

    return getIsInactive
}
