import { atom, selector } from 'recoil'
import { DateTime } from 'luxon'
import dayjs, { Dayjs } from 'dayjs'
import { StudyTimeRecordDateFormat } from 'common/src/api/websiteFrontendVsWebsiteBackend/studyTime/types'

export const todayAtom = atom<DateTime>({
    key: 'todayAtom',
    default: DateTime.now(),
})

export const todayDayJsSelector = selector<Dayjs>({
    key: 'todayDayJsSelector',
    get: ({ get }) => {
        const todayDateTime = get(todayAtom)
        return dayjs(todayDateTime.toJSDate())
    },
})

export const todayDateStrSelector = selector<string>({
    key: 'todayDateStrSelector',
    get: ({ get }) => {
        const todayDateTime = get(todayAtom)
        return todayDateTime.toFormat(StudyTimeRecordDateFormat)
    },
})
