import { NodeMetadataTree } from './NotecardMetadataTree.types'
import { AnnotatedNodeMetadata, NodeMetadata } from 'common/src/outlines/types'
import { getEmptyContentTreeMap } from 'common/src/ContentTree'
import { ContentPath, hashContentPath } from 'common/src/ContentPath'
import { ContentTree, EmptyContentFrame } from 'common/src/ContentTree/types'

export const getMetadataForContentPath = (
    nodeMetadataTree: NodeMetadataTree,
    contentPath: ContentPath
): NodeMetadata | undefined => {
    return nodeMetadataTree.metadataMap.get(hashContentPath(contentPath))
}

export const createNodeMetadataTree = (
    contentMetadataArray: AnnotatedNodeMetadata[],
    contentTree: ContentTree
): NodeMetadataTree => {
    return new NodeMetadataTreeFactory(
        contentMetadataArray,
        contentTree
    ).toObj()
}

class NodeMetadataTreeFactory {
    private readonly emptyContentFrame: EmptyContentFrame
    private readonly metadataMap: Map<string, NodeMetadata>
    private readonly depth: number

    constructor(
        contentMetadataArray: AnnotatedNodeMetadata[],
        contentTree: ContentTree
    ) {
        const { contentTreeMap, depth } = getEmptyContentTreeMap<null>(
            contentTree,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            () => null // covered by second tsconfig
        )
        this.emptyContentFrame = contentTreeMap
        this.depth = depth

        this.metadataMap = new Map()
        this.fillEmptyMetadataMap(contentMetadataArray)
    }

    public toObj = (): NodeMetadataTree => ({
        emptyContentFrame: this.emptyContentFrame,
        metadataMap: this.metadataMap,
        depth: this.depth,
    })

    private fillEmptyMetadataMap = (
        nodeMetadataArray: AnnotatedNodeMetadata[]
    ): void => {
        nodeMetadataArray.forEach((nodeMetadata): void => {
            const data: NodeMetadata = {
                title: nodeMetadata.title,
                description: nodeMetadata.description,
            }
            if (nodeMetadata.nodeReleaseStatus) {
                data.nodeReleaseStatus = nodeMetadata.nodeReleaseStatus
            }
            if (nodeMetadata.videoLink) {
                data.videoLink = nodeMetadata.videoLink
            }
            this.metadataMap.set(
                hashContentPath(nodeMetadata.contentPath),
                data
            )
        })
    }
}
