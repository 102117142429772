import React, { ReactElement, useMemo } from 'react'
import {
    SearchContext,
    WBSearchResultsContentBase,
    WBSearchResultsNotecard,
    WBSearchResultsOutline,
    WBSearchResultsPracticeProblem,
} from 'common/src/elasticSearch/types'
import styles from './SearchResult.module.scss'
import Link from 'next/link'
import { getHrefForSearchContextAndId } from '@/components/search/SearchModal/SearchResults/utils'
import { hashContentPath } from 'common/src/ContentPath'
import { registerEvent } from '@/api/analyticsEvent'
import { PaidUserAnalyticsEventType } from 'common/src/api/websiteFrontendVsWebsiteBackend/analyticsEvent/types'
import { useRecoilValue } from 'recoil'
import { searchTextAtom } from '@/atoms/search/searchText'

interface SearchResultProps {
    setIsSearchModalVisible: (isVisible: boolean) => void
    searchResult: WBSearchResultsContentBase
    searchContext: SearchContext
    isDisabled: boolean
}

const getHref = (
    searchContext: SearchContext,
    searchResult: WBSearchResultsContentBase
): string | undefined => {
    switch (searchContext) {
        case 'outlines':
            return getHrefForSearchContextAndId(
                'outlines',
                hashContentPath(
                    (searchResult as WBSearchResultsOutline).contentPath
                )
            )
        case 'notecards':
            return getHrefForSearchContextAndId(
                'notecards',
                (searchResult as WBSearchResultsNotecard).id
            )
        case 'practiceProblems':
            return getHrefForSearchContextAndId(
                'practiceProblems',
                (searchResult as WBSearchResultsPracticeProblem).id
            )
    }
    throw new Error(`Unexpected search context: ${searchContext}`)
}

export const SearchResult: React.FC<SearchResultProps> = (
    props
): ReactElement => {
    const searchText = useRecoilValue(searchTextAtom)
    const href = useMemo(
        () => getHref(props.searchContext, props.searchResult),
        [props.searchContext, props.searchResult]
    )

    if (props.isDisabled) {
        return (
            <div className={`${styles.contentDisplay} ${styles.disabled}`}>
                {props.searchResult.displayText}
            </div>
        )
    }
    return (
        <Link
            href={href}
            onClick={() => {
                props.setIsSearchModalVisible(false)
                registerEvent(
                    PaidUserAnalyticsEventType.SEARCH_RESULT_CLICKED,
                    {
                        id: hashContentPath(props.searchResult.contentPath),
                        searchContext: props.searchContext,
                        searchText,
                        type: 'freeText',
                    }
                )
            }}
        >
            <div className={styles.contentDisplay}>
                {props.searchResult.displayText}
            </div>
        </Link>
    )
}
