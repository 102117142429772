import { IProblemUserStats } from 'common/src/practiceProblems/types'
import { SittingSeason } from 'common/src/previousExamAnalysis/types'
import produce from 'immer'
import { some } from 'lodash'

export type YearToSittingsMap = Map<number, Set<SittingSeason>>

// add a sitting to a year
export const addSittingToYear = produce(
    (state: YearToSittingsMap, year: number, sitting: SittingSeason): void => {
        state.get(year).add(sitting)
    }
)

export const removeSittingFromYear = produce(
    (state: YearToSittingsMap, year: number, sitting: SittingSeason): void => {
        state.get(year).delete(sitting)
    }
)

export const addAllSittingsToYear = produce(
    (
        state: YearToSittingsMap,
        year: number,
        fullYearToSittingMap: YearToSittingsMap
    ): void => {
        state.set(year, fullYearToSittingMap.get(year))
    }
)

export const removeAllSittingsFromYear = produce(
    (state: YearToSittingsMap, year: number): void => {
        state.set(year, new Set())
    }
)

export const addAllSittingToAllYears = produce(
    (
        state: YearToSittingsMap,
        fullYearToSittingMap: YearToSittingsMap
    ): void => {
        Array.from(state.keys()).forEach((year) => {
            state.set(year, fullYearToSittingMap.get(year))
        })
    }
)
export const removeAllSittingsFromAllYears = produce(
    (state: YearToSittingsMap): void => {
        Array.from(state.keys()).forEach((year) => {
            state.set(year, new Set())
        })
    }
)

export const isYearToSittingsMapEmpty = (
    yearToSittingsMap: YearToSittingsMap
): boolean => {
    return !some(
        Array.from(yearToSittingsMap.values()),
        (value) => Array.from(value).length > 0
    )
}

export interface Sitting {
    year: number
    sitting: SittingSeason
}
export const getSortedSittings = (
    yearToSittingsMap: YearToSittingsMap
): Sitting[] => {
    return Array.from(yearToSittingsMap.keys())
        .flatMap((year) => {
            const sittings = yearToSittingsMap.get(year)
            return Array.from(sittings.values()).map((sitting) => ({
                year,
                sitting,
            }))
        })
        .sort((a, b) => {
            if (a.year !== b.year) return a.year - b.year
            if (a.sitting === b.sitting) return 0
            if (a.sitting === SittingSeason.spring) return -1
            return 1
        })
}

const addYearAndSittingToMap = (
    yearToSittingsMap: YearToSittingsMap,
    year: number,
    sittingSeason: SittingSeason
): void => {
    const existingEntry = yearToSittingsMap.get(year)
    if (existingEntry) {
        existingEntry.add(sittingSeason)
    } else {
        yearToSittingsMap.set(year, new Set<SittingSeason>([sittingSeason]))
    }
}

export const buildYearToSittingsMap = (
    problemUserStats: IProblemUserStats[]
): YearToSittingsMap => {
    const map: YearToSittingsMap = new Map()
    problemUserStats.forEach((problemUserStat) => {
        if (problemUserStat.previousExamID) {
            addYearAndSittingToMap(
                map,
                problemUserStat.previousExamID.year,
                problemUserStat.previousExamID.sittingSeason
            )
        }
    })
    return map
}

export const getSizeFromYearToSittingsMap = (
    yearToSittingsMap: YearToSittingsMap
): number =>
    Array.from(yearToSittingsMap.values()).flatMap((set) =>
        Array.from(set.values())
    ).length
