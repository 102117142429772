import https from 'https'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

export const buildHttpClient = (httpsAgent?: https.Agent): AxiosInstance => {
    const config: AxiosRequestConfig = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        withCredentials: true,
        httpsAgent,
    }

    return axios.create(config)
}
