import { notification } from 'antd'
import { NotificationKeys } from '@/utils/notificationKeys'
import { reusableCssClass } from '@/utils/reusableCssClasses'

export const handleSyncIssue = (): void => {
    notification.error({
        message: 'Your tab is out of date',
        description:
            'We detected that the current window may have data that is out of date. Please refresh the page to use the most up to date data available.',
        key: NotificationKeys.SYNC_ISSUE,
        duration: null,
        onClick: () => {
            window.location.reload()
        },
        className: reusableCssClass.clickMe,
    })
}
