import { useEffect } from 'react'
import { DateTime } from 'luxon'
import { round } from 'lodash'
import { useLogger } from '@/hooks/useLogger'

const calcMsUntil3AMLocal = (): number => {
    const now = DateTime.now()
    const targetHour = 3
    const targetTimeToday = DateTime.local(
        now.year,
        now.month,
        now.day,
        targetHour
    )
    const targetTimeTomorrow = targetTimeToday.plus({ days: 1 })
    const targetTime =
        now < targetTimeToday ? targetTimeToday : targetTimeTomorrow
    const diff = targetTime.diff(now)
    return diff.milliseconds
}

// schedules a reload at 3am local time
export const useForceReloadAround3AMLocal = (): void => {
    const logger = useLogger(useForceReloadAround3AMLocal.name)

    useEffect(() => {
        const msUntil3AMLocal = calcMsUntil3AMLocal()
        // add a random amount of time, plus or minus 30 minutes (uniform)
        const randomPlusOrMinus30MinInMs = Math.round(
            (Math.random() - 0.5) * 1_000 * 60 * 60
        )

        logger.info(
            `Scheduling page reload for 3am local time, which is: ${msUntil3AMLocal} ms from now (${round(
                msUntil3AMLocal / 1_000 / 60 / 60,
                2
            )} hours from now). Also added randomness of: ${round(
                randomPlusOrMinus30MinInMs / 1_000 / 60,
                2
            )} minutes.`
        )
        setTimeout(
            () => window.location.reload(),
            msUntil3AMLocal + randomPlusOrMinus30MinInMs
        )
    }, [logger])
}
