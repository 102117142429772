import React, { PropsWithChildren, ReactElement, Suspense } from 'react'
import { SomethingWentWrong } from '@/components/static/SomethingWentWrong/SomethingWentWrong'
import { ErrorBoundary } from '../static/ErrorBoundary/ErrorBoundary'
import { FullScreenLoadingComponent } from '@/components/static/LoadingComponent/LoadingComponent'
import { LoadingType } from '@/components/static/images/owlBranding/loadingImage'
import styles from '@/components/layout/Frame/Frame.module.scss'
import { LoggedOutHeader } from '@/components/static/LoggedOutHeader/LoggedOutHeader'
import { Footer } from '@/components/layout/Footer/Footer'
import { BasePageProps } from '@/globals/pages.types'

// generally used for pages that can be viewed, regardless if logged in or not
export const LayoutStatic: React.FC<PropsWithChildren<BasePageProps>> = (
    props
): ReactElement => {
    // corresponds to name of component in /index.tsx for SplashPage in pages directory
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isSplashPage = props.children?.props?.isSplashPage
    return (
        <ErrorBoundary fallback={<SomethingWentWrong />}>
            <Suspense
                fallback={
                    <FullScreenLoadingComponent loadingType={LoadingType.owl} />
                }
            >
                <div className={styles.frameContainer}>
                    <LoggedOutHeader
                        courseConfigType={props.courseConfigType}
                    />
                    <div className={`${styles.frameBodyLoggedOut}`}>
                        <div
                            className={`${styles.frameBodyLoggedOutInner} ${
                                isSplashPage
                                    ? styles.isSplashPage
                                    : styles.notSplashPage
                            }`}
                        >
                            {props.children}
                        </div>
                    </div>
                </div>
                <Footer />
            </Suspense>
        </ErrorBoundary>
    )
}
