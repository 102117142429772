import React, { ReactElement, Suspense } from 'react'
import type { AppProps } from 'next/app'
import { enableMapSet } from 'immer'
import Head from 'next/head'
import { primaryHeaderFont, primaryTextFont } from '@/styles/font'
import 'magic-text-box-lexical/src/styles/index.scss'
import { LayoutStatic } from '@/components/layout/LayoutStatic'
import LayoutLoggedIn from '@/components/layout/LayoutDynamic'
import { FullScreenLoadingComponent } from '@/components/static/LoadingComponent/LoadingComponent'
import { LoadingType } from '@/components/static/images/owlBranding/loadingImage'
import { TrackerProvider } from '@/openreplay/TrackerProvider'
import { getJepColor } from '@/components/static/ui/consts'
import { NextPage } from 'next/types'
import { CourseConfigType } from 'common/src/courseConfig'
import { useRouteLogger } from '@/hooks/useRouteLogger'

enableMapSet()

//Global css
require('antd/dist/reset.css')
require('../styles/index.scss')
require('../styles/reactVis.scss')

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: ReactElement) => ReactElement
}

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout
}
export default function WebsiteFrontend({
    Component,
    pageProps,
}: AppPropsWithLayout): ReactElement {
    const innerComponent = <Component {...pageProps} />
    const getLayout = Component.getLayout ?? ((page) => page)
    const courseConfigType =
        pageProps?.courseConfigType ?? CourseConfigType.fsaHealth
    const isLoggedIn = pageProps?.isLoggedIn ?? false
    const color = getJepColor(courseConfigType, isLoggedIn)
    useRouteLogger()
    return (
        <>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx global>{`
                :root {
                    --font-text: ${primaryTextFont.style.fontFamily};
                    --font-header: ${primaryHeaderFont.style.fontFamily};
                    --color-one: ${color.colorOne};
                    --color-two: ${color.colorTwo};
                    --color-three: ${color.colorThree};
                    --color-four: ${color.colorFour};
                    --color-five: ${color.colorFive};
                    --lighten-color-five: ${color.lightenColorFive};
                    --color-six: ${color.colorSix};
                    --color-seven: ${color.colorSeven};
                    --color-eight: ${color.colorEight};
                    --color-nine: ${color.colorNine};
                    --color-ten: ${color.colorTen};
                    --color-eleven: ${color.colorEleven};
                    --text-color: ${color.textColor};
                    --gray: ${color.gray};
                    --background: ${color.background};
                    --black: ${color.black};
                    --black-five-pct-lighter: ${color.black5PctLighter};
                    --black-ten-pct-lighter: ${color.black10PctLighter};
                    --green-twenty-pct-lighter: ${color.green20PctLighter};
                    --white-twenty-five-pct-darker: ${color.white25PctDarker};
                    --white: ${color.white};
                    --highlight: ${color.highlight};
                    --another-gray: ${color.anotherGray};
                    --light-gray: ${color.lightGray};
                    --header-text-color: ${color.headerTextColor};
                    --splash-page-top-container: ${color.splashPageTopContainer};
                    --splash-page-team-container: ${color.splashPageTeamContainer};
                    --splash-page-background-gradient: ${color.splashPageBackgroundGradient};
                    --splash-page-middle-gradient: ${color.splashPageMiddleGradient};
                    --course-card-color: ${color.courseCardColor};
                    --set-creator-count-color: ${color.setCreatorCountColor};
                    --notecard-mastery-container-color: ${color.notecardMasteryContainerColor};
                    --notecard-mastery-text-color: ${color.notecardMasteryTextColor};
                    --notecard-learning: ${color.notecardLearning};
                    --notecard-filler: ${color.notecardFiller};
                    --filter-display-container: ${color.filterDisplayContainer};
                    --set-selected-option-background: ${color.setSelectedOptionBackground};
                    --set-creator-topic-title-color: ${color.setCreatorTopicTitleColor};
                    --set-creator-count-color: ${color.setCreatorCountColor};
                    --red: ${color.red};
                    --yellow: ${color.yellow};
                    --green: ${color.green};
                    --react-vis-title-text: ${color.reactVisTitleText};
                    --react-vis-grid-lines: ${color.reactVisGridLines};
                    --react-vis-crosshair-line: ${color.reactVisCrosshairLine};
                    --react-vis-inner-background: ${color.reactVisInnerBackground};
                    --react-vis-inner-color: ${color.reactVisInnerColor};
                    --react-vis-legend-item-disabled-color: ${color.reactVisLegendItemDisabledColor};
                    --react-vis-legend-item-clickable-hover: ${color.reactVisLegendItemClickableHover};
                    --menu-text-color: ${color.menuTextColor};
                    --menu-text-selected-color: ${color.menuTextSelectedColor};
                    --form-input-outline-color: ${color.formInputOutlineColor};
                }
            `}</style>
            <Head>
                <meta charSet={'UTF-8'} />
                <title>
                    Just Enough Prep | FSA Group & Health Actuarial Exam
                    Preparation
                </title>
                <meta
                    name={'description'}
                    content={'FSA Group & Health Exam Prep Study Materials'}
                />
                <meta
                    name={'viewport'}
                    content={'width=device-width, initial-scale=1'}
                />
                <meta name={'theme-color'} content={'#302ecd'} />
                <meta name={'msapplication-TileColor'} content={'#ffffff'} />
                <meta
                    name={'msapplication-TileImage'}
                    content={'/favicon/ms-icon-144x144.png'}
                />
                <meta name={'theme-color'} content={'#ffffff'} />
            </Head>
            <Suspense
                fallback={
                    <FullScreenLoadingComponent loadingType={LoadingType.owl} />
                }
            >
                {getLayout(
                    <TrackerProvider courseConfigType={courseConfigType}>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore*/}
                        {Component.isStatic ? (
                            <LayoutStatic courseConfigType={courseConfigType}>
                                {innerComponent}
                            </LayoutStatic>
                        ) : (
                            <LayoutLoggedIn>{innerComponent}</LayoutLoggedIn>
                        )}
                    </TrackerProvider>
                )}
            </Suspense>
        </>
    )
}
