import { atom, selector } from 'recoil'
import { UserPaymentHistoryResponse } from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/responses'
import { getUserPaymentHistory } from '@/api/accountMaintenance'
import { LoggerSelectorFamily } from '@/atoms/logger'

export const userPaymentHistoryAtom = atom<UserPaymentHistoryResponse | null>({
    key: 'userPaymentHistoryAtom',
    default: selector<UserPaymentHistoryResponse | null>({
        key: 'userPaymentHistorySelector',
        get: async ({ get }) => {
            get(LoggerSelectorFamily(userPaymentHistoryAtom.key)).info(
                `Retrieving user payment history`
            )
            return (await getUserPaymentHistory()).data.payload
        },
    }),
})

export const purchasedCoursesSelector = selector<string[]>({
    key: 'purchasedCoursesSelector',
    get: ({ get }) => {
        const userPaymentHistory = get(userPaymentHistoryAtom)
        if (!userPaymentHistory) {
            get(LoggerSelectorFamily(purchasedCoursesSelector.key)).warn(
                'No user payment history, returning empty array'
            )
            return []
        }

        return (
            userPaymentHistory.paymentHistory
                .filter((payment) => !payment.isDemo)
                .map((payment) => payment.courseName) || []
        )
    },
})

export const purchasedCoursesExpiredSelector = selector<string[]>({
    key: 'purchasedCoursesExpiredSelector',
    get: ({ get }) => {
        const userPaymentHistory = get(userPaymentHistoryAtom)
        if (!userPaymentHistory) {
            get(LoggerSelectorFamily(purchasedCoursesExpiredSelector.key)).warn(
                'No user payment history, returning empty array'
            )
            return []
        }

        return (
            userPaymentHistory.paymentHistory
                .filter((payment) => !payment.isDemo && !payment.isActive)
                .map((payment) => payment.courseName) || []
        )
    },
})
export const purchasedWithActiveSubscriptionCoursesSelector = selector<
    string[]
>({
    key: 'purchasedWithActiveSubscriptionCoursesSelector',
    get: ({ get }) => {
        const userPaymentHistory = get(userPaymentHistoryAtom)
        if (!userPaymentHistory) {
            get(
                LoggerSelectorFamily(
                    purchasedWithActiveSubscriptionCoursesSelector.key
                )
            ).warn('No user payment history, returning empty array')
            return []
        }

        return userPaymentHistory.paymentHistory
            .filter((payment) => !payment.isDemo && payment.isActive)
            .map((pmt) => pmt.courseName)
    },
})

export const demoCoursesSelector = selector<
    { courseName: string; isActive: boolean }[]
>({
    key: 'demoCoursesSelector',
    get: ({ get }) => {
        const userPaymentHistory = get(userPaymentHistoryAtom)
        if (!userPaymentHistory) {
            get(LoggerSelectorFamily(demoCoursesSelector.key)).warn(
                'No user payment history, returning empty array'
            )
            return []
        }

        return (
            userPaymentHistory.paymentHistory
                .filter((pmt) => pmt.isDemo)
                .map((pmt) => ({
                    courseName: pmt.courseName,
                    isActive: pmt.isActive,
                })) || []
        )
    },
})

export const isUserBeforeNotecardOverhaulSelector = selector<boolean>({
    key: 'isUserBeforeNotecardOverhaulSelector',
    get: ({ get }) => {
        const userPaymentHistory = get(userPaymentHistoryAtom)
        if (!userPaymentHistory) {
            get(
                LoggerSelectorFamily(isUserBeforeNotecardOverhaulSelector.key)
            ).warn('No user payment history, returning false')
            return false
        }

        return userPaymentHistory.paymentHistory.some(
            (pmt) => new Date(pmt.paymentDate) < new Date('2024-07-04')
        )
    },
})
