import React, { ReactElement } from 'react'

import styles from './Footer.module.scss'
import Link from 'next/link'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { MailOutlined, PhoneOutlined } from '@ant-design/icons'

export const Footer: React.FC<unknown> = (): ReactElement => {
    const year = new Date().getFullYear()
    return (
        <footer className={`${styles.footer} container`}>
            <div className={styles.footerContent}>
                <div className={`${styles.footerContainer}`}>
                    <div className={styles.copyright}>
                        © {year} Just Enough Prep. All rights reserved.
                    </div>
                    <div className={styles.linkContainer}>
                        <Link
                            href={UiLayoutConstant.UPDATES_PAGE}
                            className={reusableCssClass.clickableText}
                        >
                            Website Updates
                        </Link>
                        &nbsp;
                        <Link
                            href={UiLayoutConstant.PRIVACY_PAGE}
                            className={reusableCssClass.clickableText}
                        >
                            Privacy Policy
                        </Link>
                        &nbsp;
                        <Link
                            href={UiLayoutConstant.TERMS_OF_USE_PAGE}
                            className={reusableCssClass.clickableText}
                        >
                            Terms Of Use
                        </Link>
                    </div>
                    <div className={styles.contactContainer}>
                        <div>
                            <a
                                href={'mailto:support@justenoughprep.com'}
                                target={'_blank'}
                                className={
                                    reusableCssClass.clickableTextUnderlined
                                }
                                rel={'noreferrer'}
                            >
                                <MailOutlined />
                            </a>
                            &nbsp; support@justenoughprep.com
                        </div>
                        <div>
                            <PhoneOutlined /> (628) 261-3128
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
