import {
    GoogleLoginResponse,
    GoogleLoginResponseOffline,
} from 'react-google-login'

export const isGoogleResponseOffline = (
    googleResponse: GoogleLoginResponse | GoogleLoginResponseOffline
): googleResponse is GoogleLoginResponseOffline => {
    return 'code' in googleResponse
}
