import React, { ReactElement } from 'react'

export const interleaveArray = <T>(array: T[], item: T): T[] => {
    const result: T[] = []

    array.forEach((element, index) => {
        result.push(element)
        if (index < array.length - 1) {
            result.push(item)
        }
    })

    return result
}
export const interleaveArrayComponents = (
    array: ReactElement[],
    separator: ReactElement
): ReactElement[] => {
    const result: ReactElement[] = []

    array.forEach((item, i) => {
        result.push(React.cloneElement(item, { key: `item-${i}` })) // clones item and adds a unique key prop

        if (i < array.length - 1) {
            // don't add a separator after the last item
            result.push(
                React.cloneElement(separator, { key: `separator-${i}` })
            ) // clones separator and adds a unique key prop
        }
    })

    return result
}
