import {
    JEPNotification,
    JEPNotificationType,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/notifications/types'
import React, { ReactElement } from 'react'
import { UrlObject } from 'url'
import Avatar from 'antd/lib/avatar/avatar'
import {
    CommentOutlined,
    ReadOutlined,
    RocketOutlined,
    SoundOutlined,
} from '@ant-design/icons'
import { buildHrefForCommentContentTypeAndId } from '@/components/notification/notificationListItem/NotificationListItem.types'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { buildHrefAndTextForNewCourseContent } from '@/components/notification/notificationListItem/buildNotificationHelperFuncs'
import { UserType } from 'magic-text-box-lexical/src/context/AnnotatedContentContext/AnnotatedContent.types'
import { jepAssertNever } from 'common/src/utils/jepAssertNever'
import styles from './NotificationListItem.module.scss'

export interface NotificationRenderData {
    avatar: ReactElement
    href: UrlObject | string
    textToShow: ReactElement
}
export const useNotificationData = (
    notification: JEPNotification
): NotificationRenderData => {
    switch (notification.type) {
        case JEPNotificationType.COMMENT:
            return {
                avatar: (
                    <Avatar
                        className={`${styles.avatar} ${
                            notification.metadata.commentUserType ===
                            UserType.instructor
                                ? styles.avatarInstructor
                                : styles.avatarStudent
                        }`}
                        size={'large'}
                        icon={<CommentOutlined />}
                    />
                ),
                href: buildHrefForCommentContentTypeAndId(
                    notification.metadata.contentType,
                    notification.metadata.contentId
                ),
                textToShow: (
                    <div>
                        <b>
                            {notification.metadata.commentUserType ===
                            UserType.instructor
                                ? 'Instructor Comment:'
                                : 'Student Comment:'}{' '}
                        </b>
                        {notification.text}
                    </div>
                ),
            }
        case JEPNotificationType.FEATURE_RELEASE:
            return {
                avatar: (
                    <Avatar
                        size={'large'}
                        className={`${styles.avatar} ${styles.avatarFeature}`}
                        icon={<RocketOutlined />}
                    />
                ),
                href: `${UiLayoutConstant.UPDATES_PAGE}#${notification.metadata.featureReleaseDateAsYYYYMMDD}`,
                textToShow: (
                    <div>
                        <b>New feature! </b>
                        {notification.text}
                    </div>
                ),
            }
        case JEPNotificationType.ANNOUNCEMENT:
            return {
                avatar: (
                    <Avatar
                        size={'large'}
                        className={`${styles.avatar} ${styles.avatarAnnouncement}`}
                        icon={<SoundOutlined />}
                    />
                ),
                href: notification.metadata.externalHref,
                textToShow: (
                    <div>
                        <b>Announcement: </b>
                        {notification.text}
                    </div>
                ),
            }
        case JEPNotificationType.NEW_COURSE_CONTENT: {
            const { href, text } = buildHrefAndTextForNewCourseContent(
                notification.metadata
            )
            return {
                avatar: (
                    <Avatar
                        size={'large'}
                        className={`${styles.avatar} ${styles.avatarNewCourseContent}`}
                        icon={<ReadOutlined />}
                    />
                ),
                href,
                textToShow: (
                    <div>
                        <b>New content: </b>
                        {text}
                    </div>
                ),
            }
        }
        default:
            jepAssertNever(
                notification,
                'Unhandled notificationType for NotificationListItem'
            )
    }
}
