import centerChildrenVerticallyStyles from '../styles/centerChildrenVertically.module.scss'
import clickableTextStyles from '../styles/clickableText.module.scss'
import centerText from '../styles/centerText.module.scss'
import swipeableViews from '../styles/swipeableViews.module.scss'
import blur from '../styles/blur.module.scss'

export const reusableCssClass = {
    sliderContainer: swipeableViews.sliderContainer,
    clickableText: clickableTextStyles.clickableText,
    clickableTextUnderlined: clickableTextStyles.underline,
    clickMe: clickableTextStyles.clickMe,
    hoverMe: 'hover-me',
    noHighlight: 'no-highlight',
    invisible: 'invisible',
    blurMe: blur.blurMe,
    dontTouchMe: clickableTextStyles.dontTouchMe,
    cursorNotAllowed: clickableTextStyles.cursorNotAllowed,
    secondaryText: 'secondary-text',

    // responsive text, see responsiveText.less
    responsiveTextLarge: 'responsive-text-large',
    responsiveTextMedium: 'responsive-text-medium',
    responsiveTextMediumSmall: 'responsive-text-medium-small',
    responsiveTextSmall: 'responsive-text-small',

    singleColumn: 'single-column',
    multiColumn: 'multi-column',

    bold: clickableTextStyles.boldMe,
    // don't use this anymore - can cause problems when going from logged out -> logged in
    centerChildrenVertically:
        centerChildrenVerticallyStyles.centerChildrenVertically,
    centerText: centerText.centerText,

    ellipsis: 'ellipsis-me',
}

export const reusableCssVar = {
    primaryColor: '#357BAE',
    successColor: '#52c41a',
    warningColor: '#faad14',
    errorColor: '#f5222d',
    lightGrayColor: '#D3D8DA',
    masteredColor: '#357BAE',
    learningColor: 'rgb(158, 82, 86)',
    unseenColor: '#6e7a82',
    nearlyMasteredColor: '#afaefe',
}

export const DEFAULT_ANIMATION_DURATION_MS = 200
