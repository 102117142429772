import { ProblemSetType } from 'common/src/practiceProblems'

export interface IDataPoint {
    x: string | number | Date
    y: string | number | Date
}

export interface ISuccessRatePlotDataPoint {
    x: Date
    y: number
    id: string
    type: ProblemSetType
}

export enum SuccessRatePlotOption {
    quizOnly = 'Quiz',
    examOnly = 'Exam',
    quizAndExam = 'Both',
}

export interface PracticeProblemSuccessRate {
    quizData: ISuccessRatePlotDataPoint[]
    examData: ISuccessRatePlotDataPoint[]
}
