import { NavigationLoggedInOption } from '@/components/Navigation/LoggedInNavigationOption'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import React, { ReactElement, Suspense, useMemo } from 'react'
import { ContactInstructorButton } from '@/components/layout/ContactInstructorButton/ContactInstructorButton'
import { StudyTimer } from '@/components/layout/LeftSidebarLoggedIn/StudyTimer/StudyTimer'
import styles from './LeftSidebarLoggedInShared.module.scss'
import { ContactInstructorModal } from '@/components/layout/ContactInstructorButton/ContactInstructorModal/ContactInstructorModal'
import { useRecoilValue } from 'recoil'

import {
    frontendDisplayedCourseSelector,
    isActiveCourseDemoSelector,
} from '@/atoms/accountMaintenance/userInfo'
import {
    NavBarSelectedOption,
    getSelectedOptionFromCurrentPage,
} from '@/components/Navigation/LoggedInNavigationOption.types'
import { useRouter } from 'next/router'
import {
    COURSE_NAME_TO_UI_LAYOUT_CONSTANT,
    UiLayoutCoursePages,
} from '@/components/Courses/courseNameToUiLayoutConstant'

interface LeftSidebarLoggedInSharedProps {
    isSmallDesktopMode: boolean
    setIsDrawerVisible: (isVisible: boolean) => void | null
}

export const LeftSidebarLoggedInShared: React.FC<
    LeftSidebarLoggedInSharedProps
> = (props): ReactElement => {
    const frontendDisplayedCourse = useRecoilValue(
        frontendDisplayedCourseSelector
    )
    const isActiveCourseDemo = useRecoilValue(isActiveCourseDemoSelector)
    const buyNowOrCourseButton = useMemo(():
        | {
              text: 'Courses'
              destination: UiLayoutConstant.COURSES_PAGE | UiLayoutCoursePages
          }
        | {
              text: 'Buy Now'
              destination: UiLayoutConstant.COURSE_PAYMENT_PAGE
          } => {
        if (isActiveCourseDemo) {
            return {
                text: 'Buy Now',
                destination: UiLayoutConstant.COURSE_PAYMENT_PAGE,
                // course name is added as query param on LoggedInNavigationOption
            }
        }

        if (frontendDisplayedCourse) {
            return {
                text: 'Courses',
                destination:
                    COURSE_NAME_TO_UI_LAYOUT_CONSTANT[frontendDisplayedCourse],
            }
        }

        return {
            text: 'Courses',
            destination: UiLayoutConstant.COURSES_PAGE,
        }
    }, [frontendDisplayedCourse, isActiveCourseDemo])

    const router = useRouter()
    const selectedOption = useMemo((): NavBarSelectedOption | null => {
        return getSelectedOptionFromCurrentPage(
            router.asPath as UiLayoutConstant
        )
    }, [router])

    return (
        <>
            <Suspense fallback={null}>
                <ContactInstructorModal />
            </Suspense>
            <div className={styles.leftSidebarLoggedInSharedContainer}>
                <div className={styles.genericRoutingButtonsContainer}>
                    <NavigationLoggedInOption
                        text={'Dashboard'}
                        destination={UiLayoutConstant.DASHBOARD_PAGE}
                        isSmallDesktopMode={props.isSmallDesktopMode}
                        setIsDrawerVisible={props.setIsDrawerVisible}
                        isAccessLocked={false}
                        isSelected={
                            selectedOption === UiLayoutConstant.DASHBOARD_PAGE
                        }
                    />
                    <NavigationLoggedInOption
                        text={'Notecards'}
                        destination={UiLayoutConstant.NOTECARDS_DASHBOARD}
                        isSmallDesktopMode={props.isSmallDesktopMode}
                        setIsDrawerVisible={props.setIsDrawerVisible}
                        isAccessLocked={true}
                        isSelected={
                            selectedOption ===
                            UiLayoutConstant.NOTECARDS_DASHBOARD
                        }
                    />
                    <NavigationLoggedInOption
                        text={'Questions'}
                        destination={
                            UiLayoutConstant.PRACTICE_PROBLEMS_DASHBOARD
                        }
                        isSmallDesktopMode={props.isSmallDesktopMode}
                        setIsDrawerVisible={props.setIsDrawerVisible}
                        isAccessLocked={true}
                        isSelected={
                            selectedOption ===
                            UiLayoutConstant.PRACTICE_PROBLEMS_DASHBOARD
                        }
                    />
                    <NavigationLoggedInOption
                        text={'Outlines'}
                        destination={UiLayoutConstant.OUTLINES_DASHBOARD}
                        isSmallDesktopMode={props.isSmallDesktopMode}
                        setIsDrawerVisible={props.setIsDrawerVisible}
                        isAccessLocked={true}
                        isSelected={
                            selectedOption ===
                            UiLayoutConstant.OUTLINES_DASHBOARD
                        }
                    />
                    <NavigationLoggedInOption
                        text={'Study Time'}
                        destination={UiLayoutConstant.STUDY_TIME_DASHBOARD}
                        isSmallDesktopMode={props.isSmallDesktopMode}
                        setIsDrawerVisible={props.setIsDrawerVisible}
                        isAccessLocked={true}
                        isSelected={
                            selectedOption ===
                            UiLayoutConstant.STUDY_TIME_DASHBOARD
                        }
                    />
                    <NavigationLoggedInOption
                        text={'How to Use'}
                        destination={
                            UiLayoutConstant.STUDY_MATERIALS_GUIDE_PAGE
                        }
                        isSmallDesktopMode={props.isSmallDesktopMode}
                        setIsDrawerVisible={props.setIsDrawerVisible}
                        isAccessLocked={false}
                        isSelected={
                            selectedOption ===
                            UiLayoutConstant.STUDY_MATERIALS_GUIDE_PAGE
                        }
                    />

                    <NavigationLoggedInOption
                        text={buyNowOrCourseButton.text}
                        destination={buyNowOrCourseButton.destination}
                        isSmallDesktopMode={props.isSmallDesktopMode}
                        setIsDrawerVisible={props.setIsDrawerVisible}
                        isAccessLocked={false}
                        isSelected={[
                            UiLayoutConstant.COURSES_PAGE,
                            UiLayoutConstant.COURSE_PAYMENT_PAGE,
                        ].includes(selectedOption)}
                    />
                </div>
                <hr />
                <div className={styles.timerContainer}>
                    <StudyTimer isSmallDesktopMode={props.isSmallDesktopMode} />
                </div>
                <div className={styles.contactInstructorContainer}>
                    <ContactInstructorButton
                        isSmallDesktopMode={props.isSmallDesktopMode}
                    />
                </div>
            </div>
        </>
    )
}
