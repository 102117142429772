import React, { ReactElement } from 'react'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import styles from './LeftSidebarLoggedInDesktopMode.module.scss'
import { LeftSidebarLoggedInShared } from '@/components/layout/LeftSidebarLoggedIn/LeftSidebarLoggedInShared'

interface LeftSidebarLoggedInDesktopModeProps {
    currentPage: UiLayoutConstant
    isSmallDesktopMode: boolean
}
export const LeftSidebarLoggedInDesktopMode: React.FC<
    LeftSidebarLoggedInDesktopModeProps
> = (props): ReactElement => {
    return (
        <div className={styles.navigationLoggedIn}>
            <LeftSidebarLoggedInShared
                isSmallDesktopMode={props.isSmallDesktopMode}
                setIsDrawerVisible={null}
            />
        </div>
    )
}
