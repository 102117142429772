import styles from './NotificationModal.module.scss'
import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { NotificationModalProps } from '@/components/notification/NotificationModal.types'
import {
    DEFAULT_NOTIFICATION_FETCH_MAX_COUNT,
    fetchNotifications,
    notificationsAtom,
} from '@/atoms/notifications'
import { useWindowSize } from '@/hooks/useWindowSize'
import { NotificationListItem } from '@/components/notification/notificationListItem/NotificationListItem'
import { JEPNotification } from 'common/src/api/websiteFrontendVsWebsiteBackend/notifications/types'
import { useRecoilValue } from 'recoil'
import {
    GenericButton,
    GenericButtonType,
} from '@/components/static/ui/Button/GenericButton/GenericButton'
import { frontendDisplayedCourseSelector } from '@/atoms/accountMaintenance/userInfo'
import Modal from 'antd/lib/modal/Modal'
import Empty from 'antd/lib/empty/empty'

export const NotificationModal: React.FC<NotificationModalProps> = (
    props
): ReactElement => {
    const notifications = useRecoilValue<JEPNotification[]>(notificationsAtom)

    const [isOnlyShowingTopN, setIsShowingOnlyTopN] = useState<boolean>(true)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const [allNotifications, setAllNotifications] = useState<
        JEPNotification[] | null
    >(null)
    const fetchAndSetAllNotifications = useCallback(async (): Promise<void> => {
        setIsLoading(true)
        const newAllNotifications =
            (await fetchNotifications(courseName, null)) ?? notifications
        setAllNotifications(newAllNotifications)
        setIsShowingOnlyTopN(false)
        setIsLoading(false)
    }, [courseName, notifications])
    const showMoreButton = useMemo(
        (): ReactElement => (
            <div className={styles.notificationModalShowMoreButtonContainer}>
                <GenericButton
                    type={GenericButtonType.primaryDarkBackground}
                    text={'Show More'}
                    onClick={fetchAndSetAllNotifications}
                    loading={isLoading}
                />
            </div>
        ),
        [fetchAndSetAllNotifications, isLoading]
    )

    const notificationsSource = useMemo(
        () => (isOnlyShowingTopN ? notifications : allNotifications),
        [isOnlyShowingTopN, notifications, allNotifications]
    )

    // so when the modal is closed, it goes back to loading the updated information vs. showing all (which could potentially be stale)
    useEffect(() => {
        if (!props.isVisible) setIsShowingOnlyTopN(true)
    }, [props.isVisible])

    const buildListItemForNotification = useCallback(
        (item: JEPNotification, index: number): ReactElement => {
            return (
                <NotificationListItem
                    key={index}
                    notification={item}
                    setIsModalVisible={(isVisible) =>
                        props.setIsVisible(isVisible)
                    }
                />
            )
        },
        [props]
    )

    const windowSize = useWindowSize()
    const maxHeight = useMemo((): number => {
        return windowSize.height * 0.75
    }, [windowSize.height])
    return (
        <Modal
            style={{ maxHeight: `${maxHeight}px` }}
            className={styles.notificationModal}
            open={props.isVisible}
            onCancel={() => {
                props.setIsVisible(false)
            }}
            footer={null}
            destroyOnClose={true}
        >
            <div>
                <div className={styles.notificationModalTitle}>
                    Notifications
                </div>
                {notificationsSource.length ? (
                    notificationsSource.map((notification, index) =>
                        buildListItemForNotification(notification, index)
                    )
                ) : (
                    <Empty key={'no-data'} />
                )}
                {notificationsSource.length ===
                    DEFAULT_NOTIFICATION_FETCH_MAX_COUNT &&
                    isOnlyShowingTopN &&
                    showMoreButton}
            </div>
        </Modal>
    )
}
