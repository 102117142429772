import { atomFamily, selectorFamily } from 'recoil'
import {
    defaultInvalidationAtom,
    invalidate,
    InvalidationAtom,
} from '@/atoms/types'
import {
    resetRecoilExternalState,
    setRecoilExternalState,
} from '@/components/RecoilExternalStatePortal'
import { ContentType } from 'common/src/commentThread/types'
import { fetchComments } from '@/atoms/contentCommentThreads/utils'
import { CommentMetadata } from 'magic-text-box-lexical/src/context/AnnotatedContentContext/AnnotatedContent.types'
import { frontendDisplayedCourseSelector } from '@/atoms/accountMaintenance/userInfo'
import { LoggerSelectorFamily } from '@/atoms/logger'

export const practiceProblemCommentThreadAtomFamily = atomFamily<
    CommentMetadata[],
    string
>({
    key: 'practiceProblemCommentThreadAtomFamily',
    default: selectorFamily<CommentMetadata[], string>({
        key: 'practiceProblemCommentThreadDefaultSelectorFamily',
        get:
            (practiceProblemID) =>
            async ({ get }) => {
                get(
                    practiceProblemCommentThreadInvalidationAtomFamily(
                        practiceProblemID
                    )
                )

                const courseName = get(frontendDisplayedCourseSelector)
                if (!courseName) {
                    get(
                        LoggerSelectorFamily(
                            practiceProblemCommentThreadAtomFamily(
                                practiceProblemID
                            ).key
                        )
                    ).warn('No course name, returning empty comment metadata')
                    return []
                }

                return await fetchComments({
                    contentId: practiceProblemID,
                    contentType: ContentType.PRACTICE_PROBLEM,
                    courseName,
                })
            },
    }),
})
export const practiceProblemCommentThreadInvalidationAtomFamily = atomFamily<
    InvalidationAtom,
    string
>({
    key: 'practiceProblemCommentThreadInvalidationAtomFamily',
    default: defaultInvalidationAtom,
    // WARNING: need to reset manually
})
export const invalidatePracticeProblemCommentThread = (
    practiceProblemID: string
): void => {
    setRecoilExternalState(
        practiceProblemCommentThreadInvalidationAtomFamily(practiceProblemID),
        (val) => invalidate(val)
    )
    resetRecoilExternalState(
        practiceProblemCommentThreadAtomFamily(practiceProblemID)
    )
}
