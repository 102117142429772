import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { NextRouter } from 'next/router'
import { logger } from '@/logging/FrontendLogger'
import { addDemoCourse } from '@/api/accountMaintenance'
import { fireRedditPixelEvent, RedditEvent } from '@/utils/redditPixel'
import {
    AuthenticatorOpenPurpose,
    AuthenticatorRoutingProps,
} from '@/components/static/Authenticator/Authenticator.types'
import { UserInfoResponse } from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/responses'
import assert from 'assert'
import { jepAssertNever } from 'common/src/utils/jepAssertNever'
import notification from 'antd/lib/notification'
import { NotificationKeys } from '@/utils/notificationKeys'
import {
    validateConfirmPasswordUtil,
    validatePasswordUtil,
} from 'common/src/utils/passwords'

export type HandleAuthenticatorClickPayload = AuthenticatorRoutingProps

export const handleAuthenticatorClick = async (
    payload: AuthenticatorRoutingProps,
    router: NextRouter,
    setUserInfo: (userInfo: UserInfoResponse) => void | null
): Promise<boolean> => {
    const { purpose } = payload
    switch (purpose) {
        case AuthenticatorOpenPurpose.DEMO: {
            const courseName = payload.courseName
            logger.info(`Adding demo for ${courseName}`)
            const response = await addDemoCourse(courseName)
            if (!response.data.success) {
                return
            }

            if (setUserInfo) {
                setUserInfo(response.data.payload)
            }
            fireRedditPixelEvent(RedditEvent.SIGNUP)
            notification.destroy(NotificationKeys.MUST_LOGIN_TO_DEMO)
            return await router.push({
                pathname: UiLayoutConstant.COURSE_DEMO_PAGE,
                query: { courseName },
            })
        }
        case AuthenticatorOpenPurpose.PURCHASE: {
            const courseName = payload.courseName
            const accessTypes = payload.accessTypes
            assert(accessTypes, 'Access Types should be set for type purchase')
            notification.destroy(NotificationKeys.MUST_LOGIN_TO_PURCHASE)
            return await router.push({
                pathname: UiLayoutConstant.COURSE_PAYMENT_PAGE,
                query: { courseName, accessTypes },
            })
        }
        case AuthenticatorOpenPurpose.EMAIL_VERIFICATION:
            return await router.push({
                pathname:
                    UiLayoutConstant.COMPLETE_EMAIL_VERIFICATION_PAGE_ALTERNATE,
            })
        case AuthenticatorOpenPurpose.LOGIN:
        case AuthenticatorOpenPurpose.RESET_PASSWORD:
            return await router.push({
                pathname: UiLayoutConstant.DASHBOARD_PAGE,
            })
        case AuthenticatorOpenPurpose.UNSUBSCRIBE:
            return await router.push({
                pathname: UiLayoutConstant.UNSUBSCRIBE,
            })
        default:
            return jepAssertNever(
                purpose,
                'Unhandled AuthenticatorOpenPurpose in handleAuthenticatorClick'
            )
    }
}

export const validatePasswordPromise = async (
    _: unknown,
    password: string
): Promise<void> => {
    const errorOrNull = validatePasswordUtil(password)
    if (errorOrNull === null) return Promise.resolve()
    return Promise.reject(errorOrNull)
}

export const validateConfirmPasswordPromise = async (
    confirmPassword: string,
    otherPassword: string
): Promise<void> => {
    const errorOrNull = validateConfirmPasswordUtil(
        confirmPassword,
        otherPassword
    )
    if (errorOrNull === null) return Promise.resolve()
    return Promise.reject(errorOrNull)
}
