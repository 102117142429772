import { ApiMethod } from 'common/src/api/types'
import { CancelToken } from 'axios'
import {
    WBSearchRequest,
    WBShallowSearchRequest,
} from 'common/src/elasticSearch/requests'
import { WBSearchResponse } from 'common/src/elasticSearch/responses'
import { APIResponseWrapper } from '@/api/types'
import { SearchContext } from 'common/src/elasticSearch/types'
import {
    QueryContentResponse,
    SourceMaterialSearchRequest,
} from 'common/src/llm/queryContent'
import {
    CBGradePracticeProblemResponse,
    GradePracticeProblemRequest,
} from 'common/src/llm/gradePracticeProblem'
import { callBackend } from '../../server/callBackend'

const PREFIX = '/wb/search/'

export const getPrimarySearchResults = async (
    searchText: string,
    searchContexts: SearchContext[],
    courseName?: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<WBSearchResponse> => {
    return await callBackend<WBSearchRequest, WBSearchResponse>(
        PREFIX + 'getPrimarySearchResults',
        ApiMethod.GET,
        {
            searchText,
            searchContexts,
            courseName,
        },
        cancelToken
    )
}

export const getSearchMetadataForIDsAndContext = async (
    ids: string[],
    searchContext: SearchContext,
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<WBSearchResponse> => {
    return await callBackend<WBShallowSearchRequest, WBSearchResponse>(
        PREFIX + 'getSearchMetadataForIDsAndContext',
        ApiMethod.GET,
        {
            ids,
            searchContext,
            courseName,
        },
        cancelToken
    )
}

export const queryContent = async (
    payload: SourceMaterialSearchRequest,
    cancelToken: CancelToken = null
): APIResponseWrapper<QueryContentResponse> => {
    return await callBackend<SourceMaterialSearchRequest, QueryContentResponse>(
        PREFIX + 'queryContent',
        ApiMethod.GET,
        payload,
        cancelToken,
        1 // do not retry after a timeout in order to avoid spamming LLM service
    )
}

export const gradePracticeProblem = async (
    payload: GradePracticeProblemRequest,
    cancelToken: CancelToken = null
): APIResponseWrapper<CBGradePracticeProblemResponse> => {
    return await callBackend<
        GradePracticeProblemRequest,
        CBGradePracticeProblemResponse
    >(PREFIX + 'gradePracticeProblem', ApiMethod.POST, payload, cancelToken, 1)
}
