import React, { ReactElement, useCallback, useRef, useState } from 'react'
import Input from 'antd/lib/input'
import Form, { FormInstance } from 'antd/lib/form'
import { login } from '@/api/authentication'
import { GoogleLoginComponent } from '@/components/static/Authenticator/GoogleLoginComponent/GoogleLoginComponent'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { frontendLogin } from '@/auth/frontendLogin'
import styles from '../Authenticator.module.scss'
import {
    clearAuthenticationError,
    handleAuthenticationError,
} from '@/components/static/Authenticator/handleAuthenticationError'
import {
    GenericButton,
    GenericButtonType,
} from '@/components/static/ui/Button/GenericButton/GenericButton'
import { useRouter } from 'next/router'
import { handleAuthenticatorClick } from '@/components/static/Authenticator/utils'
import { ILoginForm } from '@/components/static/Authenticator/LoginForm/LoginForm.types'
import { AuthenticatorRoutingProps } from '@/components/static/Authenticator/Authenticator.types'
import { CourseConfigType } from 'common/src/courseConfig'

export interface LoginFormProps {
    changeToForgotPassword: () => void
    setIsModalVisible: (isModalVisible: boolean) => void
    authenticatorPayload: AuthenticatorRoutingProps
    courseConfigType: CourseConfigType
}

export const LoginForm: React.FC<LoginFormProps> = (props): ReactElement => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const router = useRouter()
    const handleSubmit = useCallback(
        async (values: ILoginForm): Promise<void> => {
            setIsLoading(true)
            clearAuthenticationError()
            const response = await login(
                values.loginEmail,
                values.loginPassword
            )
            if (!response.data.success) {
                handleAuthenticationError(response.data.error, {
                    message: 'Issue logging in',
                    description:
                        'Something went wrong trying to login. Please refresh the page and try again.',
                })
            } else {
                await frontendLogin(response.data.payload)
                await handleAuthenticatorClick(
                    props.authenticatorPayload,
                    router,
                    null
                )
                props.setIsModalVisible(false)
            }
            setIsLoading(false)
        },
        [props, router]
    )

    const forgotPasswordContainerRef = useRef<HTMLDivElement>(null)

    const formRef = useRef<FormInstance<ILoginForm>>(null)

    // TODO: When we update antd, we MAY be able to take advantage of `await formRef.current.validateFields({ validateOnly: true })`
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const handleChange = useCallback(async (): Promise<void> => {
        clearAuthenticationError()
        // try {
        //     await formRef.current.validateFields()
        //     setIsDisabled(false)
        // } catch (e) {
        //     setIsDisabled(true)
        // }
    }, [])

    return (
        <div className={styles.loginForm}>
            <GoogleLoginComponent
                buttonText={'Login with Google'}
                setIsModalVisible={props.setIsModalVisible}
                payload={props.authenticatorPayload}
                courseConfigType={props.courseConfigType}
            />
            <div className={styles.googleSignupDivider}>OR</div>

            <Form onFinish={handleSubmit} onChange={handleChange} ref={formRef}>
                <Form.Item
                    name={'loginEmail'}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Email is required' },
                        {
                            type: 'email',
                            message: 'Please enter a valid email address',
                        },
                    ]}
                >
                    <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                    name={'loginPassword'}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Password is required' },
                    ]}
                >
                    <Input.Password type="password" placeholder="Password" />
                </Form.Item>
                <div className={styles.forgotPasswordAndLoginButtonContainer}>
                    <div className={`btn-container`}>
                        <GenericButton
                            onClick={null}
                            loading={isLoading}
                            text={'Login'}
                            htmlType={
                                'submit'
                            } /* will automatically call handleSubmit on click or press enter */
                            type={GenericButtonType.greenNoBackground}
                            disabled={isDisabled}
                        />
                    </div>
                    <div
                        className={`${reusableCssClass.centerText}`}
                        id={'cypress-forgot-your-password-link'}
                        ref={forgotPasswordContainerRef}
                    >
                        <div
                            onClick={props.changeToForgotPassword}
                            className={reusableCssClass.clickableText}
                        >
                            Forgot your password?
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
