import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { UiLayoutCoursePages } from '@/components/Courses/courseNameToUiLayoutConstant'

export type NavBarSelectedOption =
    | UiLayoutConstant.DASHBOARD_PAGE
    | UiLayoutConstant.OUTLINES_DASHBOARD
    | UiLayoutConstant.NOTECARDS_DASHBOARD
    | UiLayoutConstant.PRACTICE_PROBLEMS_DASHBOARD
    | UiLayoutConstant.STUDY_TIME_DASHBOARD
    | UiLayoutConstant.STUDY_MATERIALS_GUIDE_PAGE
    | UiLayoutConstant.COURSES_PAGE
    | UiLayoutConstant.COURSE_PAYMENT_PAGE
    | UiLayoutCoursePages

// assumes path is router.asPath, not router.pathname (no /actuary or /pharmacy)
export const getSelectedOptionFromCurrentPage = (
    page: string
): NavBarSelectedOption | null => {
    if (['/', ''].includes(page)) {
        return UiLayoutConstant.DASHBOARD_PAGE
    }

    if (page.includes('/outlines/')) {
        return UiLayoutConstant.OUTLINES_DASHBOARD
    }

    if (page.includes('/notecards/')) {
        return UiLayoutConstant.NOTECARDS_DASHBOARD
    }

    if (page.includes('/problems/')) {
        return UiLayoutConstant.PRACTICE_PROBLEMS_DASHBOARD
    }

    if (page.includes('/studyTime/')) {
        return UiLayoutConstant.STUDY_TIME_DASHBOARD
    }

    if (page.includes('/guide')) {
        return UiLayoutConstant.STUDY_MATERIALS_GUIDE_PAGE
    }

    if (page.includes('/courses')) {
        return UiLayoutConstant.COURSES_PAGE
    }

    if (page.includes('/coursePayment')) {
        return UiLayoutConstant.COURSE_PAYMENT_PAGE
    }

    // must be after all the other dashboards
    if (page.includes('/dashboard')) {
        return UiLayoutConstant.DASHBOARD_PAGE
    }

    return null
}
