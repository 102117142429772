import { atom, selector } from 'recoil'
import { getNotifications } from '@/api/notifications'
import { JEPNotification } from 'common/src/api/websiteFrontendVsWebsiteBackend/notifications/types'
import { userInfoStateAtom } from '@/atoms/accountMaintenance/userInfo'

export const DEFAULT_NOTIFICATION_FETCH_MAX_COUNT = 10
export const notificationsAtom = atom<JEPNotification[]>({
    key: 'notificationsAtom',
    default: selector<JEPNotification[]>({
        key: 'notificationsAtomFetcher',
        get: async ({ get }) => {
            const userInfoState = get(userInfoStateAtom)
            if (!userInfoState?.frontendDisplayedCourseInfo) return []
            return (
                (await fetchNotifications(
                    userInfoState.frontendDisplayedCourseInfo.name,
                    DEFAULT_NOTIFICATION_FETCH_MAX_COUNT
                )) ?? []
            )
        },
    }),
})

export const numUnseenNotificationsSelector = selector<number>({
    key: 'numUnseenNotificationsSelector',
    get: ({ get }) => {
        return get(notificationsAtom).filter(
            (notification) => !notification.hasBeenSeen
        ).length
    },
})

export const fetchNotifications = async (
    courseName: string,
    maxNotificationCount: number | null
): Promise<JEPNotification[] | null> => {
    if (!courseName) return []
    const response = await getNotifications(courseName, maxNotificationCount)
    return response.data.payload
}
