import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { WBSearchResultsPracticeProblem } from 'common/src/elasticSearch/types'
import { AddPracticeProblemMetadata } from '@/components/practiceProblems/CreateQuizPage/CreateQuiz/AddSpecificProblem/AddSpecificProblem.types'

export const getIndividualProblemPagePathForPracticeProblemID = (
    problemID: string
): string => {
    return UiLayoutConstant.SINGLE_PRACTICE_PROBLEM_PAGE + problemID
}

export const convertPracticeProblemSearchResultsToAddPracticeProblemMetadata = (
    practiceProblemSearchResults: WBSearchResultsPracticeProblem[]
): AddPracticeProblemMetadata[] => {
    return practiceProblemSearchResults.map((practiceProblemSearchResult) => ({
        key: practiceProblemSearchResult.id,
        name: practiceProblemSearchResult.displayText,
        isStarred: practiceProblemSearchResult.isStarred,
    }))
}
