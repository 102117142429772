import React, { ReactElement, useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { useRouter } from 'next/router'
import { LeftSidebarLoggedIn } from '@/components/layout/LeftSidebarLoggedIn/LeftSidebarLoggedIn'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { useRunOnceOnClient } from '@/hooks/useRunOnceOnClient'
import { useReactToWindowSizeAtoms } from '@/hooks/useReactToWindowSizeAtoms'
import { isMobileModeAtom, isSmallDesktopModeAtom } from '@/atoms/isMobileMode'

export const NavigationLoggedIn: React.FC<unknown> = (): ReactElement => {
    const router = useRouter()
    const page = useMemo((): string => router.asPath, [router])
    const isMobileMode = useRecoilValue(isMobileModeAtom)
    const isSmallDesktopMode = useRecoilValue(isSmallDesktopModeAtom)
    useRunOnceOnClient()
    useReactToWindowSizeAtoms()

    return (
        <LeftSidebarLoggedIn
            currentPage={page as UiLayoutConstant}
            isMobileMode={isMobileMode}
            isSmallDesktopMode={isSmallDesktopMode}
        />
    )
}
