import { getCommentsForContentTypeAndContentId } from '@/api/commentThread'
import { MinCommentThreadPayload } from 'common/src/api/websiteFrontendVsWebsiteBackend/commentThread/requests'
import { CommentMetadata } from 'magic-text-box-lexical/src/context/AnnotatedContentContext/AnnotatedContent.types'

export const fetchComments = async (
    payload: MinCommentThreadPayload
): Promise<CommentMetadata[]> => {
    const response = await getCommentsForContentTypeAndContentId(payload)
    return (
        response.data.payload?.sort(
            (commentA, commentB) =>
                new Date(commentA.createdAt).getTime() -
                new Date(commentB.createdAt).getTime()
        ) ?? []
    )
}
