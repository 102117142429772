import React, { ReactElement, useCallback, useMemo } from 'react'
import Button from 'antd/lib/button/button'
import { BellFilled, GiftFilled } from '@ant-design/icons'
import styles from './PersonalSection.module.scss'
import { JepColorFsaHealth } from '@/components/static/ui/consts'
import { NotificationModal } from '@/components/notification/NotificationModal'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { useRouter } from 'next/router'
import Dropdown from 'antd/lib/dropdown'
import { frontendLogout } from '@/auth/frontendLogout'
import { PersonalSectionDropdownMenuItem } from '@/components/LoggedInHeader/PersonalSection/PersonalSectionDropdownMenuItem/PersonalSectionDropdownMenuItem'
import { MenuProps } from 'antd/lib/menu'
import Badge from 'antd/lib/badge'
import Link from 'next/link'
import { courseConfigTypeAtom } from '@/atoms/courseInfo'
import { useRecoilValue } from 'recoil'
import { CourseConfig, getCourseConfig } from 'common/src/courseConfig'
import { Tooltip } from 'antd'

interface PersonalSectionProps {
    displayName: string
    initials: string
    numUnseenNotifications: number
    isNotificationModalVisible: boolean
    setIsNotificationModalVisible: (isVisible: boolean) => void
    courseName: string
    isMobileMode: boolean
}

enum PersonalDropdownMenuKey {
    ACCOUNT_DETAILS = 'ACCOUNT_DETAILS',
    LOGOUT = 'LOG0UT',
    NOTIFICATIONS = 'NOTIFICATIONS',
    INVITE = 'INVITE',
    COURSES = 'COURSES',
}

export const PersonalSection: React.FC<PersonalSectionProps> = (
    props
): ReactElement => {
    const router = useRouter()
    const courseConfigType = useRecoilValue(courseConfigTypeAtom)
    const courseConfig = useMemo((): CourseConfig => {
        return getCourseConfig(courseConfigType)
    }, [courseConfigType])
    const handleMenuClick: MenuProps['onClick'] = useCallback(
        async (event): Promise<void> => {
            switch (event.key) {
                case PersonalDropdownMenuKey.LOGOUT:
                    await frontendLogout()
                    await router.replace({
                        pathname: UiLayoutConstant.SPLASH_PAGE,
                    })
                    return
                case PersonalDropdownMenuKey.NOTIFICATIONS:
                    props.setIsNotificationModalVisible(
                        !props.isNotificationModalVisible
                    )
                    return
                default:
                    return
            }
        },
        [props, router]
    )

    const items: MenuProps['items'] = useMemo(() => {
        const items: MenuProps['items'] = [
            {
                key: PersonalDropdownMenuKey.NOTIFICATIONS,
                label: (
                    <PersonalSectionDropdownMenuItem text={'Notifications'} />
                ),
            },
            {
                key: PersonalDropdownMenuKey.INVITE,
                label: (
                    <PersonalSectionDropdownMenuItem
                        text={`Get $${courseConfig.referralPayout}`}
                        href={UiLayoutConstant.INVITE}
                    />
                ),
            },
            {
                key: PersonalDropdownMenuKey.ACCOUNT_DETAILS,
                label: (
                    <PersonalSectionDropdownMenuItem
                        text={'Account Details'}
                        href={UiLayoutConstant.ACCOUNT_MAINTENANCE_PAGE}
                    />
                ),
            },
            {
                key: PersonalDropdownMenuKey.COURSES,
                label: (
                    <PersonalSectionDropdownMenuItem
                        text={'Courses'}
                        href={UiLayoutConstant.COURSES_PAGE}
                    />
                ),
            },
            {
                key: PersonalDropdownMenuKey.LOGOUT,
                label: <PersonalSectionDropdownMenuItem text={'Logout'} />,
            },
        ]
        return items
    }, [courseConfig.referralPayout])

    return (
        <div
            className={`${styles.personalSection} ${
                props.isMobileMode ? styles.mobileMode : ''
            }`}
        >
            <NotificationModal
                isVisible={props.isNotificationModalVisible}
                setIsVisible={(isVisible) =>
                    props.setIsNotificationModalVisible(isVisible)
                }
                courseName={props.courseName}
            />
            <Dropdown
                menu={{ items, onClick: handleMenuClick }}
                placement={'bottomRight'}
                overlayClassName={styles.personalSectionDropdownMenu}
            >
                <Button className={styles.initialIcon}>{props.initials}</Button>
            </Dropdown>
            {!props.isMobileMode && (
                <>
                    <div className={styles.welcomeBack}>
                        <div className={styles.welcomeBackText}>
                            Welcome Back,
                        </div>
                        <div className={styles.displayNameText}>
                            {props.displayName}
                        </div>
                    </div>
                    <div
                        className={styles.notificationsBadge}
                        onClick={() =>
                            props.setIsNotificationModalVisible(true)
                        }
                    >
                        <Badge
                            count={props.numUnseenNotifications}
                            size={'default'}
                        >
                            <Tooltip
                                title={'Notifications'}
                                placement={'bottom'}
                            >
                                <BellFilled
                                    style={{
                                        fontSize: '28px',
                                        color: JepColorFsaHealth.white,
                                    }}
                                />
                            </Tooltip>
                        </Badge>
                    </div>
                    <div className={styles.giftBadgeContainer}>
                        <Tooltip title={'Referral Bonus'} placement={'bottom'}>
                            <Link href={UiLayoutConstant.INVITE}>
                                <GiftFilled
                                    style={{
                                        fontSize: '28px',
                                        color: JepColorFsaHealth.white,
                                    }}
                                    className={styles.giftBadge}
                                />
                            </Link>
                        </Tooltip>
                    </div>
                </>
            )}
        </div>
    )
}
