import { ContentType } from 'common/src/commentThread/types'
import {
    FsaHealthExam,
    ExamName,
    PharmacyTechnicianExam,
} from 'common/src/courseConfig'

/**
 * used admin-dashboard's get_analytics_event_of_type.py method to
 * list all queries people clicked results for by exam and search context.
 * Next, I fed this to chat gpt-4 with the prompt:
 * Can you identify the four or five most commonly asked terms from the following list of search queries?
 * Manually selected from there
 */
export const CONTENT_TYPE_TO_SEARCH_STRINGS: Record<
    ExamName,
    Record<Partial<ContentType>, string[]>
> = {
    [FsaHealthExam.design_and_pricing]: {
        [ContentType.OUTLINE]: ['Capitation', 'Level funding', 'EGWP'],
        [ContentType.NOTECARD]: [
            'LTC assumptions',
            'Risk adjustment',
            'Essential health benefits',
        ],
        [ContentType.PRACTICE_PROBLEM]: [
            'Level funding',
            'Deficit recovery arrangements',
            'Credibility',
        ],
        [ContentType.SUMMARY]: [],
    },
    [FsaHealthExam.risk_mitigation]: {
        [ContentType.OUTLINE]: [
            'Claims-free period',
            'Risk adjustment',
            'Reconciled risk',
        ],
        [ContentType.NOTECARD]: ['Functional approach', 'DSME', 'TNHP'],
        [ContentType.PRACTICE_PROBLEM]: [
            'Risk adjustment',
            'TNHP',
            'ACO calculation',
        ],
        [ContentType.SUMMARY]: [],
    },
    [FsaHealthExam.valuation_and_regulation]: {
        [ContentType.OUTLINE]: [
            'DAC',
            'Age-to-ultimate development method',
            'Loss adjustment expense',
        ],
        [ContentType.NOTECARD]: [
            'Claim reserves',
            'Premium equivalents model for ASO contracts',
            'Quota share reinsurance',
        ],
        [ContentType.PRACTICE_PROBLEM]: [
            'Age-to-ultimate',
            'Accounting entries',
            'Medicare coordination of benefits',
        ],
        [ContentType.SUMMARY]: [],
    },
    [PharmacyTechnicianExam.pharmacy_technician]: {
        [ContentType.OUTLINE]: [
            'Controlled Substance Act',
            'Combat Methamphetamine Epidemic Act',
            'Prescription Processing Math',
        ],
        [ContentType.NOTECARD]: ['TODO'],
        [ContentType.PRACTICE_PROBLEM]: ['TODO'],
        [ContentType.SUMMARY]: [],
    },
}
