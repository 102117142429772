import React, { ReactElement, useState } from 'react'
import { useDrawerWidth } from '@/hooks/useDrawerWidth'
import { MenuOutlined } from '@ant-design/icons'
import mobileStyles from './LeftSidebarLoggedInMobileMode.module.scss'
import { LeftSidebarLoggedInShared } from '@/components/layout/LeftSidebarLoggedIn/LeftSidebarLoggedInShared'
import { JepColorFsaHealth } from '@/components/static/ui/consts'
import Drawer from 'antd/lib/drawer'

export const LeftSidebarLoggedInMobileMode: React.FC<
    unknown
> = (): ReactElement => {
    const drawerWidth = useDrawerWidth()
    const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)

    return (
        <div>
            <div className={mobileStyles.leftSidebarLoggedInMobileMode}>
                <Drawer
                    placement={'left'}
                    closable={false}
                    open={isDrawerVisible}
                    width={drawerWidth}
                    onClose={() => setIsDrawerVisible(false)}
                    className={mobileStyles.drawerMenu}
                    bodyStyle={{
                        background: JepColorFsaHealth.anotherGray,
                    }}
                >
                    <LeftSidebarLoggedInShared
                        isSmallDesktopMode={false}
                        setIsDrawerVisible={setIsDrawerVisible}
                    />
                </Drawer>
            </div>
            <div
                onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                className={mobileStyles.mobileModeMenuDrawerContainer}
            >
                <div className={mobileStyles.mobileModeMenuDrawer}>
                    <MenuOutlined />
                </div>
            </div>
        </div>
    )
}
