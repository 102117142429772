import React, { ReactElement } from 'react'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import styles from './LogoLoggedOut.module.scss'
import Link from 'next/link'
import { ReadOutlined } from '@ant-design/icons'

export const LogoLoggedOut: React.FC<unknown> = (): ReactElement => {
    return (
        <div className={styles.logoLoggedOut}>
            <Link
                href={{
                    pathname: UiLayoutConstant.SPLASH_PAGE,
                }}
                className={`${styles.siteTitleContainer}`}
            >
                <span className={`${styles.siteTitle}`}>
                    <div className={styles.logoContainer}>
                        <ReadOutlined />
                    </div>
                    <div className={styles.lettersContainer}>&nbsp;JEP</div>
                </span>
            </Link>
        </div>
    )
}
