import { AccessType } from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/types'

export enum AuthenticatorTabName {
    signUp = 'Sign Up',
    Login = 'Login', // cannot be camel case because of antd tabs
    forgotPassword = 'Forgot Password',
}

export enum AuthenticatorOpenPurpose {
    DEMO = 'DEMO',
    PURCHASE = 'PURCHASE',
    LOGIN = 'LOGIN',
    UNSUBSCRIBE = 'UNSUBSCRIBE',
    EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
    RESET_PASSWORD = 'RESET_PASSWORD',
}

export interface AuthenticatorRoutingPropsNonPurchaseOrDemo {
    purpose: Exclude<
        AuthenticatorOpenPurpose,
        AuthenticatorOpenPurpose.PURCHASE | AuthenticatorOpenPurpose.DEMO
    >
    courseName: null
    accessTypes: null
}

export interface AuthenticatorRoutingProps {
    purpose: AuthenticatorOpenPurpose
    courseName: string | null
    accessTypes: AccessType[] | null
}
