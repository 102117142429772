import {
    SearchContext,
    WBSearchResultsNotecard,
    WBSearchResultsOther,
    WBSearchResultsOutline,
    WBSearchResultsPracticeProblem,
} from 'common/src/elasticSearch/types'
import { hashContentPath, unHashContentPath } from 'common/src/ContentPath'
import { getOutlinePagePathnameForContentPath } from '@/components/outlines/utils'
import { getIndividualNotecardPagePathForNotecardID } from '@/components/notecards/utils'
import { getIndividualProblemPagePathForPracticeProblemID } from '@/components/practiceProblems/utils'

export const getKeyFromSearchResult = (
    searchResult:
        | WBSearchResultsOutline
        | WBSearchResultsNotecard
        | WBSearchResultsPracticeProblem
        | WBSearchResultsOther
): string => {
    if ('id' in searchResult) return searchResult.id
    if ('contentPath' in searchResult)
        return hashContentPath(searchResult.contentPath)
    return searchResult.displayText
}

export const getHrefForSearchContextAndId = (
    searchContext: SearchContext,
    id: string
): string => {
    switch (searchContext) {
        case 'outlines': {
            return getOutlinePagePathnameForContentPath(unHashContentPath(id))
        }
        case 'notecards': {
            return getIndividualNotecardPagePathForNotecardID(id)
        }
        case 'practiceProblems': {
            return getIndividualProblemPagePathForPracticeProblemID(id)
        }
        case 'other':
        default:
            throw new Error(`Unexpected search context: ${searchContext}`)
    }
}
