import axios from 'axios'
import { ClientIPInfo } from 'common/src/api/websiteFrontendVsWebsiteBackend/analyticsEvent/types'
import axiosRetry from 'axios-retry'

const httpClient = axios.create()
axiosRetry(httpClient, { retries: 3, retryDelay: axiosRetry.exponentialDelay })
export const getClientIP = async (): Promise<ClientIPInfo> => {
    // url has api key from: https://geolocation-db.com/dashboard (used aj.gray619@gmail.com to get one, it's free & unlimited)
    const response = await httpClient.get(
        'https://geolocation-db.com/json/a9e48c70-8b22-11ed-8d13-bd165d1291e3'
    )
    return response.data as ClientIPInfo
}
