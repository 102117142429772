import {
    resetRecoilExternalState,
    setRecoilExternalState,
} from '@/components/RecoilExternalStatePortal'
import {
    defaultInvalidationAtom,
    invalidate,
    InvalidationAtom,
} from '@/atoms/types'
import { atomFamily, selectorFamily } from 'recoil'
import { fetchComments } from '@/atoms/contentCommentThreads/utils'
import { ContentType } from 'common/src/commentThread/types'
import { CommentMetadata } from 'magic-text-box-lexical/src/context/AnnotatedContentContext/AnnotatedContent.types'
import { frontendDisplayedCourseSelector } from '@/atoms/accountMaintenance/userInfo'
import { LoggerSelectorFamily } from '@/atoms/logger'

export const notecardCommentThreadAtomFamily = atomFamily<
    CommentMetadata[],
    string
>({
    key: 'notecardCommentThreadAtomFamily',
    default: selectorFamily<CommentMetadata[], string>({
        key: 'notecardCommentThreadDefaultSelectorFamily',
        get:
            (notecardID) =>
            async ({ get }) => {
                get(notecardCommentThreadInvalidationAtomFamily(notecardID))

                const courseName = get(frontendDisplayedCourseSelector)
                if (!courseName) {
                    get(
                        LoggerSelectorFamily(
                            notecardCommentThreadAtomFamily(notecardID).key
                        )
                    ).warn('No course name, returning empty comment metadata')
                    return []
                }

                return await fetchComments({
                    contentId: notecardID,
                    contentType: ContentType.NOTECARD,
                    courseName,
                })
            },
    }),
})

export const notecardCommentThreadInvalidationAtomFamily = atomFamily<
    InvalidationAtom,
    string
>({
    key: 'notecardCommentThreadInvalidationAtomFamily',
    default: defaultInvalidationAtom,
    // WARNING: need to reset manually
})

export const invalidateNotecardCommentThread = (notecardID: string): void => {
    setRecoilExternalState(
        notecardCommentThreadInvalidationAtomFamily(notecardID),
        (val) => invalidate(val)
    )
    resetRecoilExternalState(notecardCommentThreadAtomFamily(notecardID))
}
