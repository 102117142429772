import { AccessType } from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/types'

export enum UiLayoutConstant {
    SPLASH_PAGE = '/',
    UPDATES_PAGE = '/updates',
    SPLASH_PAGE_LOGGED_IN = '/home',
    CONTACT_PAGE = '/contact',
    PRIVACY_PAGE = '/privacy',
    TERMS_OF_USE_PAGE = '/termsOfUse',
    DASHBOARD_PAGE = '/dashboard',
    OUTLINES_DASHBOARD = '/outlines/dashboard',
    OUTLINE_PAGE = '/outlines/content/',
    SINGLE_NOTECARD_PAGE = '/notecards/content/',
    SINGLE_PRACTICE_PROBLEM_PAGE = '/problems/content/',
    GENERATE_QUIZ_WITH_SPECIFIC_PROBLEMS_PAGE = '/problems/generateQuizWithSpecificProblems/',
    COMPLETE_EMAIL_VERIFICATION_PAGE_ALTERNATE = '/accountMaintenance/completeEmailVerification',
    COURSE_PAYMENT_PAGE = '/coursePayment',
    COURSE_DEMO_PAGE = '/courseDemo',
    COURSES_PAGE = '/courses',
    COURSES_GHDP_PAGE = '/courses/ghdp',
    COURSES_GHRM_PAGE = '/courses/ghrm',
    COURSES_GHVR_PAGE = '/courses/ghvr',
    COURSES_PHARMACY_TECHNICIAN_PAGE = '/courses/ptce',
    ACCOUNT_MAINTENANCE_PAGE = '/accountMaintenance',
    RESET_PASSWORD_PAGE = '/resetPassword',
    SUPPORT_PAGE = '/support',
    PRACTICE_PROBLEMS_DASHBOARD = '/problems/dashboard',
    PROBLEM_SET_REVIEW = '/problems/review',
    CREATE_QUIZ = '/problems/createQuiz',
    PROBLEM_SET = '/problems/problemSet',
    NOTECARDS_DASHBOARD = '/notecards/dashboard',
    CREATE_NOTECARD_SET = '/notecards/createNewDeck',
    NOTECARD_SET_EXTRA_PRACTICE = '/notecards/extraPractice',
    NOTECARD_SET_RECENT_MISTAKES = '/notecards/recentMistakes',
    NOTECARD_SET_REVIEW = '/notecards/review',
    LESSON = '/lesson',
    GENERATE_DECK_WITH_SPECIFIC_NOTECARDS_PAGE = '/notecards/generateDeckWithSpecificNotecards/',
    STUDY_MATERIALS_GUIDE_PAGE = '/guide',
    STUDY_MATERIALS_GUIDE_PHILOSOPHY = '/guide/philosophy',
    STUDY_MATERIALS_GUIDE_NOTECARDS = '/guide/notecards',
    STUDY_MATERIALS_GUIDE_OUTLINES = '/guide/outlines',
    STUDY_MATERIALS_GUIDE_PROBLEMS = '/guide/problems',
    STUDY_MATERIALS_GUIDE_OTHER = '/guide/other',
    STUDY_MATERIALS_GUIDE_TIPS = '/guide/tips',
    PREVIOUS_EXAM_ANALYSIS_PAGE = '/previousExamAnalysis',
    EXAM_CHANGE_GUIDE_PAGE = '/examUpdateGuide',
    STUDY_TIME_DASHBOARD = '/studyTime/dashboard',
    ERROR_404_PAGE = '/somethingWentWrong',
    CONTENT_CREATION = '/contentCreation',
    INVITE = '/invite',
    UNSUBSCRIBE = '/unsubscribe',
    EDUCATOR = '/educator', // pharmacy only
}

const wildcardRouterPathnameRegex = new RegExp(/\[.*\]/g)
export const stripWildcardsFromRouterPathname = (
    routerPathname: string
): string => {
    return routerPathname.replaceAll(wildcardRouterPathnameRegex, '')
}

// null means the page does not need any access types to be visited
// i.e. can be viewed without a subscription
export const PAGE_TO_REQUIRED_ACCESS_TYPE: Record<
    UiLayoutConstant,
    AccessType | null
> = {
    [UiLayoutConstant.SPLASH_PAGE]: null,
    [UiLayoutConstant.UPDATES_PAGE]: null,
    [UiLayoutConstant.SPLASH_PAGE_LOGGED_IN]: null,
    [UiLayoutConstant.CONTACT_PAGE]: null,
    [UiLayoutConstant.PRIVACY_PAGE]: null,
    [UiLayoutConstant.TERMS_OF_USE_PAGE]: null,
    [UiLayoutConstant.DASHBOARD_PAGE]: null, // needs active course, not specific access type
    [UiLayoutConstant.OUTLINES_DASHBOARD]: AccessType.OUTLINES,
    [UiLayoutConstant.OUTLINE_PAGE]: AccessType.OUTLINES,
    [UiLayoutConstant.SINGLE_NOTECARD_PAGE]: AccessType.NOTECARDS,
    [UiLayoutConstant.SINGLE_PRACTICE_PROBLEM_PAGE]:
        AccessType.PRACTICE_PROBLEMS,
    [UiLayoutConstant.GENERATE_QUIZ_WITH_SPECIFIC_PROBLEMS_PAGE]:
        AccessType.PRACTICE_PROBLEMS,
    [UiLayoutConstant.COMPLETE_EMAIL_VERIFICATION_PAGE_ALTERNATE]: null,
    [UiLayoutConstant.COURSE_PAYMENT_PAGE]: null,
    [UiLayoutConstant.COURSE_DEMO_PAGE]: null,
    [UiLayoutConstant.COURSES_PAGE]: null,
    [UiLayoutConstant.COURSES_GHDP_PAGE]: null,
    [UiLayoutConstant.COURSES_GHRM_PAGE]: null,
    [UiLayoutConstant.COURSES_GHVR_PAGE]: null,
    [UiLayoutConstant.COURSES_PHARMACY_TECHNICIAN_PAGE]: null,
    [UiLayoutConstant.ACCOUNT_MAINTENANCE_PAGE]: null,
    [UiLayoutConstant.RESET_PASSWORD_PAGE]: null,
    [UiLayoutConstant.SUPPORT_PAGE]: null,
    [UiLayoutConstant.PRACTICE_PROBLEMS_DASHBOARD]:
        AccessType.PRACTICE_PROBLEMS,
    [UiLayoutConstant.PROBLEM_SET_REVIEW]: AccessType.PRACTICE_PROBLEMS,
    [UiLayoutConstant.CREATE_QUIZ]: AccessType.PRACTICE_PROBLEMS,
    [UiLayoutConstant.PROBLEM_SET]: AccessType.PRACTICE_PROBLEMS,
    [UiLayoutConstant.NOTECARDS_DASHBOARD]: AccessType.NOTECARDS,
    [UiLayoutConstant.CREATE_NOTECARD_SET]: AccessType.NOTECARDS,
    [UiLayoutConstant.NOTECARD_SET_EXTRA_PRACTICE]: AccessType.NOTECARDS,
    [UiLayoutConstant.NOTECARD_SET_RECENT_MISTAKES]: AccessType.NOTECARDS,
    [UiLayoutConstant.NOTECARD_SET_REVIEW]: AccessType.NOTECARDS,
    [UiLayoutConstant.LESSON]: AccessType.NOTECARDS,
    [UiLayoutConstant.GENERATE_DECK_WITH_SPECIFIC_NOTECARDS_PAGE]:
        AccessType.NOTECARDS,
    [UiLayoutConstant.STUDY_MATERIALS_GUIDE_PAGE]: null,
    [UiLayoutConstant.STUDY_MATERIALS_GUIDE_PHILOSOPHY]: null,
    [UiLayoutConstant.STUDY_MATERIALS_GUIDE_NOTECARDS]: null,
    [UiLayoutConstant.STUDY_MATERIALS_GUIDE_OUTLINES]: null,
    [UiLayoutConstant.STUDY_MATERIALS_GUIDE_PROBLEMS]: null,
    [UiLayoutConstant.STUDY_MATERIALS_GUIDE_OTHER]: null,
    [UiLayoutConstant.STUDY_MATERIALS_GUIDE_TIPS]: null,
    [UiLayoutConstant.PREVIOUS_EXAM_ANALYSIS_PAGE]: null,
    [UiLayoutConstant.EXAM_CHANGE_GUIDE_PAGE]: null,
    [UiLayoutConstant.STUDY_TIME_DASHBOARD]: null, // needs active course, not specific access type
    [UiLayoutConstant.ERROR_404_PAGE]: null,
    [UiLayoutConstant.CONTENT_CREATION]: null,
    [UiLayoutConstant.INVITE]: null,
    [UiLayoutConstant.UNSUBSCRIBE]: null,
    [UiLayoutConstant.EDUCATOR]: null,
} as const
