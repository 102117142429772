import { ApiMethod, EmptyRequest, EmptyResponse } from 'common/src/api/types'
import {
    IsEmailVerifiedResponse,
    UserInfoResponse,
    UserPaymentHistoryResponse,
    InviteCodeResponse,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/responses'
import {
    SetNextNotecardModeRequest,
    SetNotecardSetModeRequest,
    SetShouldShowOutlineCommentsRequest,
    SetUserSettingsRequest,
    UpdateAccountEmailRequest,
    UpdateAccountPasswordRequest,
    UpdateDisplayNameRequest,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/requests'
import { CancelToken } from 'axios'
import { GenericContentRequest } from 'common/src/api/core/courses/requests'
import { APIResponseWrapper } from '@/api/types'
import {
    NextNotecardMode,
    NotecardSetMode,
    ReferralData,
    UserSettings,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/types'
import { callBackend } from '../../server/callBackend'

const PREFIX = '/wb/accountMaintenance/'

export const sendVerificationEmail = async (
    cancelToken: CancelToken = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<EmptyRequest, EmptyResponse>(
        PREFIX + 'sendVerificationEmail',
        ApiMethod.POST,
        null,
        cancelToken
    )
}

export const getIsEmailVerified = async (
    cancelToken: CancelToken = null
): APIResponseWrapper<IsEmailVerifiedResponse> => {
    return await callBackend<EmptyRequest, IsEmailVerifiedResponse>(
        PREFIX + 'getIsEmailVerified',
        ApiMethod.GET,
        null,
        cancelToken
    )
}

export const getUserInfo = async (
    cancelToken: CancelToken = null
): APIResponseWrapper<UserInfoResponse> => {
    return await callBackend<EmptyRequest, UserInfoResponse>(
        PREFIX + 'getUserInfo',
        ApiMethod.GET,
        null,
        cancelToken
    )
}

export const getUserPaymentHistory =
    async (): APIResponseWrapper<UserPaymentHistoryResponse> => {
        return await callBackend<EmptyRequest, UserPaymentHistoryResponse>(
            PREFIX + 'getUserPaymentHistory',
            ApiMethod.GET,
            null
        )
    }

export const updateAccountEmail = async (
    email: string
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<UpdateAccountEmailRequest, EmptyResponse>(
        PREFIX + 'updateAccountEmail',
        ApiMethod.POST,
        {
            email,
        }
    )
}

export const updateAccountPassword = async (
    currentPassword: string,
    newPassword: string
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<UpdateAccountPasswordRequest, EmptyResponse>(
        PREFIX + 'updateAccountPassword',
        ApiMethod.POST,
        {
            currentPassword,
            newPassword,
        }
    )
}

export const updateDisplayName = async (
    displayName: string
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<UpdateDisplayNameRequest, EmptyResponse>(
        PREFIX + 'updateDisplayName',
        ApiMethod.POST,
        {
            displayName,
        }
    )
}

export const updateActiveCourse = async (
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<UserInfoResponse> => {
    return await callBackend<GenericContentRequest, UserInfoResponse>(
        PREFIX + 'updateActiveCourse',
        ApiMethod.POST,
        {
            courseName,
        },
        cancelToken
    )
}

export const addDemoCourse = async (
    courseName: string
): APIResponseWrapper<UserInfoResponse> => {
    return await callBackend<GenericContentRequest, UserInfoResponse>(
        PREFIX + 'addDemoCourse',
        ApiMethod.POST,
        {
            courseName,
        }
    )
}

export const addReferralData = async (
    referralData: ReferralData
): APIResponseWrapper<void> => {
    return await callBackend<ReferralData, void>(
        PREFIX + 'addReferralData',
        ApiMethod.POST,
        referralData
    )
}

export const getShouldShowReferralDataModal =
    async (): APIResponseWrapper<boolean> => {
        return await callBackend<void, boolean>(
            PREFIX + 'getShouldShowReferralDataModal',
            ApiMethod.GET
        )
    }

export const getUserSettings = async (): APIResponseWrapper<UserSettings> => {
    return await callBackend<EmptyRequest, UserSettings>(
        PREFIX + 'getUserSettings',
        ApiMethod.GET
    )
}

export const setUserSettings = async (
    newUserSettings: UserSettings
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<SetUserSettingsRequest, EmptyResponse>(
        PREFIX + 'setUserSettings',
        ApiMethod.POST,
        { newUserSettings }
    )
}

export const completeEmailVerification =
    async (): APIResponseWrapper<UserInfoResponse> => {
        return await callBackend<EmptyRequest, UserInfoResponse>(
            PREFIX + 'completeEmailVerification',
            ApiMethod.POST,
            null
        )
    }
export const unsubscribe = async (): APIResponseWrapper<null> => {
    return await callBackend<null, null>(
        PREFIX + 'unsubscribe',
        ApiMethod.POST,
        null
    )
}
export const subscribe = async (): APIResponseWrapper<null> => {
    return await callBackend<null, null>(
        PREFIX + 'subscribe',
        ApiMethod.POST,
        null
    )
}

export const getInviteCode =
    async (): APIResponseWrapper<InviteCodeResponse> => {
        return await callBackend<null, InviteCodeResponse>(
            PREFIX + 'inviteCode',
            ApiMethod.GET,
            null
        )
    }
export const generateReceiptHtml = async (): APIResponseWrapper<{
    html: string
}> => {
    return await callBackend<null, { html: string }>(
        PREFIX + 'generateReceiptHtml',
        ApiMethod.GET,
        null
    )
}
