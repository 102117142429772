import { APIResponseWrapper } from '@/api/types'
import { ApiMethod } from 'common/src/api/types'
import {
    AddCommentToThreadRequest,
    GetOutlineAndSummaryCommentThreadsForContentPathRequest,
    MinCommentThreadPayload,
    UpvoteRequest,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/commentThread/requests'
import {
    AddCommentToThreadResponse,
    GetCommentsResponse,
    GetOutlineAndSummaryCommentThreadsForContentPathResponse,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/commentThread/responses'
import { CommentMetadata } from 'magic-text-box-lexical/src/context/AnnotatedContentContext/AnnotatedContent.types'
import { callBackend } from '../../server/callBackend'

const PREFIX = '/wb/commentThread/'

export const addCommentToThread = async (
    payload: AddCommentToThreadRequest
): APIResponseWrapper<AddCommentToThreadResponse> => {
    return await callBackend<
        AddCommentToThreadRequest,
        AddCommentToThreadResponse
    >(PREFIX + 'addCommentToThread', ApiMethod.POST, payload)
}

export const getCommentsForContentTypeAndContentId = async (
    payload: MinCommentThreadPayload
): APIResponseWrapper<CommentMetadata[]> => {
    return await callBackend<MinCommentThreadPayload, GetCommentsResponse>(
        PREFIX + 'getCommentsForContentTypeAndContentId',
        ApiMethod.GET,
        payload
    )
}

export const enactUpvoteActionOnComment = async (
    payload: UpvoteRequest
): APIResponseWrapper<void> => {
    return await callBackend<UpvoteRequest, void>(
        PREFIX + 'enactUpvoteActionOnComment',
        ApiMethod.POST,
        payload
    )
}

export const getOutlineAndSummaryCommentThreadsForContentPath = async (
    payload: GetOutlineAndSummaryCommentThreadsForContentPathRequest
): APIResponseWrapper<GetOutlineAndSummaryCommentThreadsForContentPathResponse> => {
    return await callBackend<
        GetOutlineAndSummaryCommentThreadsForContentPathRequest,
        GetOutlineAndSummaryCommentThreadsForContentPathResponse
    >(
        PREFIX + 'getOutlineAndSummaryCommentThreadsForContentPath',
        ApiMethod.GET,
        payload
    )
}
