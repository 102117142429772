import assert from 'assert'
import { random, shuffle } from 'lodash'
import {
    MathVsConceptualProblemAttribute,
    MathVsConceptualProblemAttributeMap,
    PracticeProblemUserStatsMap,
    PreviousExamSittingsMap,
    ProblemTypeProblemAttribute,
    ProblemTypeProblemAttributeMap,
    ProblemTypeSelections,
    QuizCreator,
    UserHistoryProblemAttribute,
    UserHistoryProblemAttributeMap,
} from './QuizCreator.types'
import {
    isReturnFromTraversalBaseType,
    traverseMapForPath,
} from 'common/src/ContentTree'
import { ContentPath } from 'common/src/ContentPath'
import { IProblemUserStats } from 'common/src/practiceProblems/types'
import {
    createBaseSetCreator,
    getSelectedIDsForTopicsOnly,
} from '@/frontendLogic/SetCreator/SetCreator'
import { SetTopicMap } from '@/frontendLogic/SetCreator/SetCreator.types'
import { filterIDs } from '@/utils/filterIDs'
import { ContentTree } from 'common/src/ContentTree/types'
import { PreviousExamID } from 'common/src/previousExamAnalysis/types'
import { buildFrontendLogger } from '@/logging/FrontendLogger'
import {
    isYearToSittingsMapEmpty,
    YearToSittingsMap,
} from '@/components/practiceProblems/CreateQuizPage/CreateQuiz/BuildQuizSelector/ProblemType/previousExamMappings'
import { filterIDsOnProblemType } from '@/frontendLogic/practiceProblems/visualizations/QuizCreator/filterIDsOnProblemType'

/**
 * Ensure specifically added problems are included in the quiz
 * Do everything as "shuffled" as possible
 * Don't include more problems than "numProblems"
 */
export const selectProblemIDsUsingQuestionLimit = (
    specificallyAddedProblemIDs: string[],
    allPossibleProblemIDs: string[],
    numProblems: number
): string[] => {
    const shuffledSpecificallyAddedProblemIDs = shuffle(
        specificallyAddedProblemIDs
    )
    const shuffledRemainingProblemIDs = shuffle(
        allPossibleProblemIDs.filter(
            (id) => !specificallyAddedProblemIDs.includes(id)
        )
    )
    const shuffledProblemIDs = shuffledSpecificallyAddedProblemIDs.concat(
        shuffledRemainingProblemIDs
    )
    return shuffle(shuffledProblemIDs.slice(0, numProblems))
}

const logger = buildFrontendLogger('Quiz Creator')

/**
 * Ensure specifically added problems are included in the quiz (as much as possible)
 * Do everything as "shuffled" as possible
 * Don't include more minutes than "numMinutes"
 */
export const selectProblemIDsUsingMinuteLimit = (
    specificallyAddedProblemIDs: string[],
    allPossibleProblemIDs: string[],
    quizCreator: QuizCreator,
    numMinutes: number
): string[] => {
    const shuffledSpecificallyAddedProblemIDs = shuffle(
        specificallyAddedProblemIDs
    )
    const shuffledRemainingProblemIDs = shuffle(
        allPossibleProblemIDs.filter(
            (id) => !specificallyAddedProblemIDs.includes(id)
        )
    )
    const shuffledProblemIDs = shuffledSpecificallyAddedProblemIDs.concat(
        shuffledRemainingProblemIDs
    )
    const targetPoints = numMinutes / 3
    let currentPoints = 0
    const finalProblemIDs = []
    let unusedCandidateProblemIDs: string[] = []
    for (const [
        currentIndex,
        candidateProblemID,
    ] of shuffledProblemIDs.entries()) {
        const currentProblemUserStats =
            quizCreator.practiceProblemUserStatsMap.get(candidateProblemID)
        assert(currentProblemUserStats)
        const newPoints = currentPoints + currentProblemUserStats.points
        if (newPoints > targetPoints) {
            unusedCandidateProblemIDs = shuffledProblemIDs.slice(
                currentIndex + 1
            )
            break
        }
        currentPoints = newPoints
        finalProblemIDs.push(candidateProblemID)
    }

    // at this point we are below the target point threshold, but may have some remaining candidates that we can add
    let didAddProblemsToFillInRemainingPoints = false
    if (unusedCandidateProblemIDs.length) {
        let remainingPoints = targetPoints - currentPoints
        logger.info(
            `Attempting to fill in the point gap of: ${remainingPoints} points with smaller problems.`
        )
        while (currentPoints < targetPoints) {
            // identify which of the remaining candidate problem ids will not put us over the threshold
            unusedCandidateProblemIDs = unusedCandidateProblemIDs.filter(
                (problemID) => {
                    const points =
                        quizCreator.practiceProblemUserStatsMap.get(
                            problemID
                        )?.points
                    return points != null && points < remainingPoints
                }
            )

            // pick a random one
            if (unusedCandidateProblemIDs.length === 0) break
            const randomIndex = random(
                0,
                unusedCandidateProblemIDs.length - 1,
                false
            )
            const newProblemID =
                unusedCandidateProblemIDs.splice(randomIndex)[0]
            finalProblemIDs.push(newProblemID)
            const newProblemIDPoints =
                quizCreator.practiceProblemUserStatsMap.get(
                    newProblemID
                )?.points
            assert(newProblemIDPoints != null)
            remainingPoints -= newProblemIDPoints
            didAddProblemsToFillInRemainingPoints = true
        }

        if (didAddProblemsToFillInRemainingPoints) {
            logger.info(
                `Was able to fill in point gap with additional questions`
            )
        } else {
            logger.info(
                `Was NOT able to fill in point gap with additional questions`
            )
        }
    }

    logger.info(
        `Created quiz with points: ${currentPoints} vs. target points: ${targetPoints}`
    )
    return shuffle(finalProblemIDs)
}

export const generateQuizTags = (
    quizCreator: QuizCreator,
    contentPaths: ContentPath[],
    userHistoryAttributes: UserHistoryProblemAttribute[],
    problemTypeSelections: ProblemTypeSelections,
    problemTypeAttributes: MathVsConceptualProblemAttribute[]
): string[] => {
    const quizTagsSet = new Set<string>()
    for (const type of [...userHistoryAttributes, ...problemTypeAttributes])
        quizTagsSet.add(type)
    contentPaths.forEach((contentPath) => {
        // grab all highest level content
        assert(
            quizCreator.contentIDToNameMap.has(contentPath[0]),
            `Quiz creator content ID to name map is missing content ID: ${contentPath[0]}`
        )
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        quizTagsSet.add(quizCreator.contentIDToNameMap.get(contentPath[0])!)
    })

    if (problemTypeSelections.goldStandardOnly) {
        quizTagsSet.add(ProblemTypeProblemAttribute.goldStandard)
    }

    if (problemTypeSelections.selectedYearToSittingsMap.size > 0) {
        quizTagsSet.add(ProblemTypeProblemAttribute.previousExam)
    }

    return [...quizTagsSet.values()]
}

export const getSelectedProblemIDs = (
    quizCreator: QuizCreator,
    userHistoryAttributes: UserHistoryProblemAttribute[],
    includeGoldStandardOnly: boolean,
    previousExamSelections: YearToSittingsMap,
    problemTypeAttributes: MathVsConceptualProblemAttribute[],
    includeStarredOnly: boolean
): string[] => {
    let candidateSelectedProblemIDs = getSelectedIDsForTopicsOnly(quizCreator)
    const skipUserHistoryAttributeCheck =
        userHistoryAttributes.length ===
        Object.keys(UserHistoryProblemAttribute).length
    if (!skipUserHistoryAttributeCheck) {
        candidateSelectedProblemIDs = filterIDs(
            candidateSelectedProblemIDs,
            quizCreator.userHistoryProblemAttributeMap,
            userHistoryAttributes
        )
    }

    const skipPreviousExamCheck = isYearToSittingsMapEmpty(
        previousExamSelections
    )
    if (
        includeGoldStandardOnly ||
        includeStarredOnly ||
        !skipPreviousExamCheck
    ) {
        candidateSelectedProblemIDs = filterIDsOnProblemType(
            candidateSelectedProblemIDs,
            quizCreator,
            includeGoldStandardOnly,
            includeStarredOnly,
            previousExamSelections,
            skipPreviousExamCheck
        )
    }

    const skipProblemTypeAttributeCheck =
        problemTypeAttributes.length ===
        Object.keys(MathVsConceptualProblemAttribute).length
    if (!skipProblemTypeAttributeCheck) {
        candidateSelectedProblemIDs = filterIDs(
            candidateSelectedProblemIDs,
            quizCreator.mathVsConceptualProblemAttributeMap,
            problemTypeAttributes
        )
    }

    return candidateSelectedProblemIDs
}

export const createQuizCreator = (
    quizProblemUserStatsArray: IProblemUserStats[],
    contentTree: ContentTree,
    selectAllTopics: boolean
): QuizCreator => {
    const baseSetCreator = createBaseSetCreator(
        contentTree,
        quizProblemUserStatsArray
    )

    fillEmptyTopicMapInPlace(baseSetCreator.topicMap, quizProblemUserStatsArray)

    if (selectAllTopics) {
        fillEmptyTopicMapInPlace(
            baseSetCreator.selectedTopicMap,
            quizProblemUserStatsArray
        )
    }

    return {
        ...baseSetCreator,
        userHistoryProblemAttributeMap: createUserHistoryProblemAttributeMap(
            quizProblemUserStatsArray
        ),
        problemTypeProblemAttributeMap: createProblemTypeProblemAttributeMap(
            quizProblemUserStatsArray
        ),
        mathVsConceptualProblemAttributeMap:
            createMathVsConceptualProblemAttributeMap(
                quizProblemUserStatsArray
            ),
        previousExamSittingsMap: buildPreviousExamSittingsMap(
            quizProblemUserStatsArray
        ),
        practiceProblemUserStatsMap: buildPracticeProblemUserStatsMap(
            quizProblemUserStatsArray
        ),
    }
}

const fillEmptyTopicMapInPlace = (
    topicMap: SetTopicMap,
    quizProblemUserStatsArray: IProblemUserStats[]
): void => {
    quizProblemUserStatsArray.forEach((problemUserStats): void => {
        const baseSet = traverseMapForPath(
            topicMap,
            problemUserStats.contentPath
        )
        assert(
            isReturnFromTraversalBaseType(baseSet),
            `Unexpected return from traversal (expected: set for content path: ${problemUserStats.contentPath})`
        )
        baseSet.add(problemUserStats.id)
    })
}

const createUserHistoryProblemAttributeMap = (
    quizProblemUserStatsArray: IProblemUserStats[]
): UserHistoryProblemAttributeMap => {
    const map: UserHistoryProblemAttributeMap = new Map()
    for (const userHistoryProblemAttribute of Object.values(
        UserHistoryProblemAttribute
    )) {
        map.set(userHistoryProblemAttribute, new Set<string>())
    }

    quizProblemUserStatsArray.forEach((problemMetadata) => {
        const userHistoryProblemAttributes =
            getUserHistoryProblemAttributesForProblemMetadata(problemMetadata)
        userHistoryProblemAttributes.forEach((userHistoryProblemAttribute) => {
            map.get(userHistoryProblemAttribute)?.add(problemMetadata.id)
        })
    })
    return map
}

const getUserHistoryProblemAttributesForProblemMetadata = (
    problemMetadata: IProblemUserStats
): Set<UserHistoryProblemAttribute> => {
    const set = new Set<UserHistoryProblemAttribute>()
    if (problemMetadata.numAttempts === 0) {
        set.add(UserHistoryProblemAttribute.new)
    } else {
        if (problemMetadata.latestAttemptScore < 1.0) {
            set.add(UserHistoryProblemAttribute.incorrect)
        } else {
            set.add(UserHistoryProblemAttribute.correct)
        }
    }
    return set
}

const createProblemTypeProblemAttributeMap = (
    quizProblemUserStatsArray: IProblemUserStats[]
): ProblemTypeProblemAttributeMap => {
    const map: ProblemTypeProblemAttributeMap = new Map()
    for (const problemTypeProblemAttribute of Object.values(
        ProblemTypeProblemAttribute
    )) {
        map.set(problemTypeProblemAttribute, new Set<string>())
    }

    quizProblemUserStatsArray.forEach((problemMetadata) => {
        const problemTypeProblemAttributes =
            getProblemTypeProblemAttributesForProblemMetadata(problemMetadata)
        problemTypeProblemAttributes.forEach((problemTypeProblemAttribute) => {
            map.get(problemTypeProblemAttribute)?.add(problemMetadata.id)
        })
    })
    return map
}

const buildPreviousExamSittingsMap = (
    problemMetadata: IProblemUserStats[]
): PreviousExamSittingsMap => {
    const map: PreviousExamSittingsMap = new Map()

    problemMetadata.forEach((problem) => {
        if (problem.previousExamID) {
            addProblemToPreviousExamSittingMap(
                map,
                problem.id,
                problem.previousExamID
            )
        }
    })

    return map
}

const buildPracticeProblemUserStatsMap = (
    problemMetadata: IProblemUserStats[]
): PracticeProblemUserStatsMap => {
    const map: PracticeProblemUserStatsMap = new Map()

    problemMetadata.forEach((problem) => {
        map.set(problem.id, problem)
    })

    return map
}

const addProblemToPreviousExamSittingMap = (
    map: PreviousExamSittingsMap,
    problemID: string,
    previousExamID: PreviousExamID
): void => {
    let innerMap = map.get(previousExamID.year)
    if (!innerMap) {
        innerMap = new Map()
        map.set(previousExamID.year, innerMap)
    }

    let innerSet = innerMap.get(previousExamID.sittingSeason)
    if (!innerSet) {
        innerSet = new Set()
        innerMap.set(previousExamID.sittingSeason, innerSet)
    }

    innerSet.add(problemID)
}

const getProblemTypeProblemAttributesForProblemMetadata = (
    problemMetadata: IProblemUserStats
): Set<ProblemTypeProblemAttribute> => {
    const set = new Set<ProblemTypeProblemAttribute>()
    if (problemMetadata.isPreviousExamQuestion) {
        set.add(ProblemTypeProblemAttribute.previousExam)
    }

    if (problemMetadata.isGoldStandard) {
        set.add(ProblemTypeProblemAttribute.goldStandard)
    }
    return set
}

const createMathVsConceptualProblemAttributeMap = (
    quizProblemUserStatsArray: IProblemUserStats[]
): MathVsConceptualProblemAttributeMap => {
    const map: MathVsConceptualProblemAttributeMap = new Map()
    for (const mathVsConceptualProblemAttribute of Object.values(
        MathVsConceptualProblemAttribute
    )) {
        map.set(mathVsConceptualProblemAttribute, new Set<string>())
    }

    quizProblemUserStatsArray.forEach((problemMetadata) => {
        const mathVsConceptualProblemAttributes =
            getMathVsConceptualAttributes(problemMetadata)
        mathVsConceptualProblemAttributes.forEach(
            (mathVsConceptualProblemAttribute) => {
                map
                    .get(mathVsConceptualProblemAttribute)
                    ?.add(problemMetadata.id)
            }
        )
    })
    return map
}

const getMathVsConceptualAttributes = (
    problemMetadata: IProblemUserStats
): Set<MathVsConceptualProblemAttribute> => {
    const set = new Set<MathVsConceptualProblemAttribute>()

    if (problemMetadata.isMathematical) {
        set.add(MathVsConceptualProblemAttribute.math)
    }

    if (problemMetadata.isConceptual) {
        set.add(MathVsConceptualProblemAttribute.conceptual)
    }

    return set
}
