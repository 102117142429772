import { IGenericPracticeProblemUser } from 'common/src/practiceProblems/types'
import { atom, selector } from 'recoil'
import { nextLessonSelector } from '@/atoms/notecards/notecardUserMetadata/notecardUserMetadata'
import { getPracticeProblems } from '@/api/practiceProblems'
import { USER_MTB_KEY_SENTINEL_VALUE } from '@/atoms/userMtb'
import { PracticeProblemType } from 'common/src/practiceProblems'
import { frontendDisplayedCourseSelector } from '@/atoms/accountMaintenance/userInfo'

export interface FrontendLessonQuizState {
    problems: IGenericPracticeProblemUser[]
    completedProblemIDs: string[]
}

export const frontendLessonQuizStateAtom = atom<FrontendLessonQuizState | null>(
    {
        key: 'frontendLessonQuizStateAtom',
        default: selector<FrontendLessonQuizState | null>({
            key: 'frontendLessonQuizStateFetcher',
            get: async ({ get }) => {
                const nextLesson = get(nextLessonSelector)
                const frontendDisplayedCourse = get(
                    frontendDisplayedCourseSelector
                )
                if (!frontendDisplayedCourse || !nextLesson) {
                    return null
                }
                const questionsResponse = await getPracticeProblems(
                    frontendDisplayedCourse,
                    nextLesson.notecardIDs,
                    false
                )
                if (!questionsResponse.data.success) {
                    throw new Error('Failed to get practiceProblems')
                }

                const questions = questionsResponse.data.payload
                if (!questions || questions?.length === 0) {
                    return null
                }
                const frontendQuestions: IGenericPracticeProblemUser[] =
                    questions.map((question) => ({
                        ...question,
                        userAnswerMtbKey: `${USER_MTB_KEY_SENTINEL_VALUE}_${question.id}`,
                        type: PracticeProblemType.SHORT_ANSWER,
                    }))
                return {
                    problems: frontendQuestions,
                    completedProblemIDs: [],
                }
            },
        }),
    }
)
