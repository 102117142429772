import React, { CSSProperties, ReactElement, useMemo } from 'react'
import Button from 'antd/lib/button/button'
import { JEPBaseButtonProps } from '@/components/static/ui/Button/Button.types'
import { AnimateOnHoverArrow } from '@/components/utils/AnimateOnHoverArrow/AnimateOnHoverArrow'
import { UrlObject } from 'url'
import Link from 'next/link'
import styles from './CTAButton.module.scss'
import animateOnHoverStyles from '@/components/utils/AnimateOnHoverArrow/AnimateOnHoverArrow.module.scss'

export enum CTAButtonType {
    primaryDarkBackground = 'primaryDarkBackground',
    greenDarkBackground = 'greenDarkBackground',
    defaultNoBorderDarkBackground = 'defaultNoBorderDarkBackground',
    defaultBorderDarkBackground = 'defaultBorderDarkBackground',
    primaryLightBackground = 'primaryLightBackground',
    defaultLightBackground = 'defaultLightBackground',
}

const CTAButtonTypeToStyleClass: Record<CTAButtonType, string> = {
    [CTAButtonType.primaryDarkBackground]: styles.primaryDarkBackground,
    [CTAButtonType.greenDarkBackground]: styles.greenDarkBackground,
    [CTAButtonType.defaultNoBorderDarkBackground]:
        styles.defaultNoBorderDarkBackground,
    [CTAButtonType.defaultBorderDarkBackground]:
        styles.defaultBorderDarkBackground,
    [CTAButtonType.primaryLightBackground]: styles.primaryLightBackground,
    [CTAButtonType.defaultLightBackground]: styles.defaultLightBackground,
}

interface CTAButtonProps extends JEPBaseButtonProps {
    href?: UrlObject | string
    type: CTAButtonType
    style?: CSSProperties
}

export const CTAButton: React.FC<CTAButtonProps> = (props): ReactElement => {
    const { text, href, ...buttonProps } = props
    const coreButton = useMemo((): ReactElement => {
        return (
            <Button
                {...buttonProps}
                type={'primary'}
                size={'large'}
                className={`${CTAButtonTypeToStyleClass[props.type]} ${
                    animateOnHoverStyles.animateOnHoverContainer
                } ${styles.sharedCTAButtonStyles} ${
                    props.disabled
                        ? styles.sharedCTAButtonDisabledIconStyle
                        : ''
                }`}
                style={props.style}
            >
                {text}&nbsp;&nbsp; <AnimateOnHoverArrow />
            </Button>
        )
    }, [buttonProps, props.disabled, props.style, props.type, text])
    return (
        <>
            {props.disabled || !props.href ? (
                coreButton
            ) : (
                <Link href={href}>{coreButton}</Link>
            )}
        </>
    )
}
