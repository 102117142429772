import { useContext, useEffect } from 'react'
import { OpenreplayContext } from '@/openreplay/TrackerProvider'
import { useRecoilValue } from 'recoil'
import { userInfoStateAtom } from '@/atoms/accountMaintenance/userInfo'
import { isAccessInfoDemo } from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/responses'

export const useSyncUserInfoWithOpenreplay = (): void => {
    const { setMetadata } = useContext(OpenreplayContext)
    const userInfoState = useRecoilValue(userInfoStateAtom)

    useEffect(() => {
        setMetadata({
            userEmail: userInfoState?.email,
            displayName: userInfoState?.displayName,
            userID: userInfoState?.userID,
            isDemo: userInfoState?.frontendDisplayedCourseInfo?.accessInfo
                ? isAccessInfoDemo(
                      userInfoState.frontendDisplayedCourseInfo.accessInfo
                  )
                : false,
            courseID: userInfoState?.frontendDisplayedCourseInfo?.id,
            courseName: userInfoState?.frontendDisplayedCourseInfo?.name,
        })
    }, [setMetadata, userInfoState])
}
