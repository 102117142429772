import { ContentPath } from 'common/src/ContentPath'
import { LeafNodeAnnotationSelection } from 'common/src/outlines/types'

export interface IBaseProgress {
    read: number
    starredSections: LeafNodeAnnotationSelection[]
    total: number
    index: number
}

export interface INamedBaseProgress extends IBaseProgress {
    contentPath: ContentPath
}

export const getEmptyBaseProgress = (): IBaseProgress => {
    return { read: 0, starredSections: [], total: 0, index: 0 }
}
export const mergeBaseProgresses = (
    baseProgressA: IBaseProgress,
    baseProgressB: IBaseProgress,
    index: number
): IBaseProgress => {
    return {
        read: baseProgressA.read + baseProgressB.read,
        starredSections: [
            ...baseProgressA.starredSections,
            ...baseProgressB.starredSections,
        ],
        total: baseProgressA.total + baseProgressB.total,
        index,
    }
}

export type IProgress = INamedBaseProgress | INamedNonBaseProgress

export interface INamedNonBaseProgress extends INamedBaseProgress {
    progresses: IProgress[]
}

// keys are hashed content paths
export interface OutlineProgressMap {
    [key: string]: IBaseProgress
}
