import { ContentTree } from 'common/src/ContentTree/types'
import { ContentPath } from 'common/src/ContentPath'
import { atom, selector, selectorFamily } from 'recoil'
import { getContentTree } from '@/api/contentTree'
import { flattenContentTree } from 'common/src/ContentTree/contentTree'
import { LoggerSelectorFamily } from '@/atoms/logger'
import { deserializeMap } from 'common/src/utils/mapSerialization'
import { TopicToLearningObjectivesMap } from 'common/src/api/core/contentTree/responses'
import { defaultInvalidationAtom, InvalidationAtom } from '@/atoms/types'
import { resetOnSetEffect } from '@/atoms/atomEffects/resetOnSetEffect'
import { logAtomChangesEffect } from '@/atoms/atomEffects/logAtomChangesEffect'
import { getLearningObjectiveDescription } from '@/utils/getLearningObjectiveDescription'
import { frontendDisplayedCourseSelector } from '@/atoms/accountMaintenance/userInfo'

export interface ContentTreeState {
    contentTree: ContentTree
    contentTierTitles: string[]
    flattenedContentTree: ContentPath[]
    topicToLearningObjectivesMap: TopicToLearningObjectivesMap
}

export const fetchContentTreeState = async (
    courseName: string
): Promise<ContentTreeState | null> => {
    const contentTreeResponse = await getContentTree(courseName)
    if (contentTreeResponse.data.payload === null) {
        return null
    }

    const flattenedContentTree = flattenContentTree(
        contentTreeResponse.data.payload.contentTree
    )
    const topicToLearningObjectivesMap = deserializeMap(
        contentTreeResponse.data.payload.topicToLearningObjectivesMap
    )
    return {
        contentTree: contentTreeResponse.data.payload.contentTree,
        contentTierTitles: contentTreeResponse.data.payload.contentTierTitles,
        flattenedContentTree,
        topicToLearningObjectivesMap,
    }
}

export const contentTreeStateAtom = atom<ContentTreeState | null>({
    key: 'contentTreeStateAtom',
    default: selector<ContentTreeState | null>({
        key: 'contentTreeStateFetcher',
        get: async ({ get }) => {
            const frontendDisplayedCourse = get(frontendDisplayedCourseSelector)
            const logger = get(LoggerSelectorFamily(contentTreeStateAtom.key))
            if (!frontendDisplayedCourse) {
                logger.warn(
                    `No frontend displayed course, so content tree state will not be retrieved (returning null instead)`
                )
                return null
            }

            get(contentTreeStateInvalidationAtom)
            logger.info(`Retrieving initial content tree state`)
            return await fetchContentTreeState(frontendDisplayedCourse)
        },
    }),
})

export const contentTreeStateInvalidationAtom = atom<InvalidationAtom>({
    key: 'contentTreeStateInvalidationAtom',
    default: defaultInvalidationAtom,
    effects: [
        logAtomChangesEffect('Content Tree State Invalidation Atom'),
        resetOnSetEffect(contentTreeStateAtom),
    ],
})

export const contentTreeDepthSelector = selector<number>({
    key: 'contentTreeDepthSelector',
    get: ({ get }) => {
        if (!get(frontendDisplayedCourseSelector)) {
            get(LoggerSelectorFamily(contentTreeDepthSelector.key)).warn(
                `Attempted to access content tree depth selector, but no frontend displayed course exists (returning 3 instead)`
            )
            return 3
        }

        return get(contentTreeStateAtom)?.contentTierTitles?.length ?? 3
    },
})

export const contentTreeTierTitlesSelector = selector<string[]>({
    key: 'contentTreeTierTitlesSelector',
    get: ({ get }) => {
        if (!get(frontendDisplayedCourseSelector)) {
            get(LoggerSelectorFamily(contentTreeDepthSelector.key)).warn(
                `Attempted to access content tree tier titles selector, but no frontend displayed course exists (returning fake data instead)`
            )
            return ['Topic', 'Subtopic', 'Chapter']
        }

        return (
            get(contentTreeStateAtom)?.contentTierTitles ?? [
                'Topic',
                'Subtopic',
                'Chapter',
            ]
        )
    },
})

export const getLearningObjectiveDescriptionSelectorFamily = selectorFamily<
    string | null,
    { learningObjectiveID: string | undefined; contentPath: ContentPath }
>({
    key: 'getLearningObjectiveDescriptionSelectorFamily',
    get:
        (data) =>
        ({ get }) => {
            const logger = get(
                LoggerSelectorFamily(
                    getLearningObjectiveDescriptionSelectorFamily(data).key
                )
            )

            if (data.learningObjectiveID === undefined) {
                logger.warn('No learning objective ID, returning null')
                return null
            }

            const topicToLearningObjectivesMap =
                get(contentTreeStateAtom)?.topicToLearningObjectivesMap
            if (!topicToLearningObjectivesMap) {
                logger.warn(
                    'No topic to learning objectives map, returning null'
                )
                return null
            }

            return getLearningObjectiveDescription(
                topicToLearningObjectivesMap,
                data.contentPath,
                data.learningObjectiveID
            )
        },
})
