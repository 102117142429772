import React, { ReactElement } from 'react'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { LeftSidebarLoggedInDesktopMode } from '@/components/layout/LeftSidebarLoggedIn/LeftSidebarLoggedInDesktopMode/LeftSidebarLoggedInDesktopMode'

interface NavigationLoggedInProps {
    currentPage: UiLayoutConstant
    isMobileMode: boolean
    isSmallDesktopMode: boolean
}
export const LeftSidebarLoggedIn: React.FC<NavigationLoggedInProps> = (
    props
): ReactElement => {
    if (props.isMobileMode) {
        // Navigation is in Header in mobile mode
        return <></>
    }

    return (
        <LeftSidebarLoggedInDesktopMode
            currentPage={props.currentPage}
            isSmallDesktopMode={props.isSmallDesktopMode}
        />
    )
}
