import { atom } from 'recoil'
import { registerEvent } from '@/api/analyticsEvent'
import { PaidUserAnalyticsEventType } from 'common/src/api/websiteFrontendVsWebsiteBackend/analyticsEvent/types'
import { searchTextAtom } from '@/atoms/search/searchText'

export const isSearchModalVisibleAtom = atom<boolean>({
    key: 'isSearchModalVisibleAtom',
    default: false,
    effects: [
        ({ onSet, getLoadable }) => {
            onSet((isNowOpen, wasPreviouslyOpen) => {
                if (!wasPreviouslyOpen && isNowOpen) {
                    registerEvent(PaidUserAnalyticsEventType.SEARCH_BOX_OPENED)
                    return
                }

                if (wasPreviouslyOpen && !isNowOpen) {
                    const searchTextLoadable = getLoadable(searchTextAtom)

                    registerEvent(
                        PaidUserAnalyticsEventType.SEARCH_BOX_CLOSED,
                        {
                            searchText: searchTextLoadable.getValue(),
                        }
                    )
                }
            })
        },
    ],
})
