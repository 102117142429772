import { ContentPath } from 'common/src/ContentPath'
import { atom, selector } from 'recoil'
import { getMostRecentlyVisitedOutlineContentPath } from '@/api/outlines'
import { nodeMetadataTreeSelector } from '@/atoms/outlines/nodeMetadata'
import { LoggerSelectorFamily } from '@/atoms/logger'
import { getMetadataForContentPath } from '@/frontendLogic/outlines/NodeMetadataTree'
import { defaultInvalidationAtom, InvalidationAtom } from '@/atoms/types'
import { resetOnSetEffect } from '@/atoms/atomEffects/resetOnSetEffect'
import { logAtomChangesEffect } from '@/atoms/atomEffects/logAtomChangesEffect'
import {
    frontendDisplayedCourseSelector,
    userInfoStateAtom,
} from '@/atoms/accountMaintenance/userInfo'

export const mostRecentlyVisitedOutlineContentPathAtom =
    atom<ContentPath | null>({
        key: 'mostRecentlyVisitedOutlineContentPathAtom',
        default: selector<ContentPath | null>({
            key: 'mostRecentlyVisitedOutlineContentPathFetcher',
            get: async ({ get }) => {
                const frontendDisplayedCourse = get(
                    frontendDisplayedCourseSelector
                )
                const logger = get(
                    LoggerSelectorFamily(
                        mostRecentlyVisitedOutlineContentPathAtom.key
                    )
                )

                if (frontendDisplayedCourse === null) {
                    logger.warn(
                        `No frontend displayed course, so most recently visited outline content path will not be retrieved (returning null instead)`
                    )
                    return null
                }

                get(userInfoStateAtom) // forces update upon new login
                get(mostRecentlyVisitedOutlineContentPathInvalidationAtom)
                logger.info(
                    `Retrieving initial most recently visited outline content path`
                )
                const mostRecentlyVisitedOutlineContentPathResponse =
                    await getMostRecentlyVisitedOutlineContentPath(
                        frontendDisplayedCourse
                    )
                if (
                    !mostRecentlyVisitedOutlineContentPathResponse?.data
                        ?.payload?.contentPath
                ) {
                    logger.warn(
                        `Unable to fetch most recently visited outline content path, returning null`
                    )
                    return null
                }

                return mostRecentlyVisitedOutlineContentPathResponse.data
                    .payload.contentPath
            },
        }),
    })

export const mostRecentlyVisitedOutlineContentPathInvalidationAtom =
    atom<InvalidationAtom>({
        key: 'mostRecentlyVisitedOutlineContentPathInvalidationAtom',
        default: defaultInvalidationAtom,
        effects: [
            logAtomChangesEffect(
                'Most Recently Visited Outline Content Path Invalidation Atom'
            ),
            resetOnSetEffect(mostRecentlyVisitedOutlineContentPathAtom),
        ],
    })

export const mostRecentlyAccessedOutlineNodeSelector = selector<string | null>({
    key: 'mostRecentlyAccessedOutlineNode',
    get: ({ get }) => {
        const nodeMetadataTree = get(nodeMetadataTreeSelector)
        const logger = get(
            LoggerSelectorFamily(mostRecentlyAccessedOutlineNodeSelector.key)
        )
        if (!nodeMetadataTree) {
            logger.warn(
                `Node metadata tree not available, so not retrieving metadata for content path`
            )
            return null
        }

        const mostRecentlyAccessedOutlineContentPath = get(
            mostRecentlyVisitedOutlineContentPathAtom
        )
        if (!mostRecentlyAccessedOutlineContentPath) {
            logger.warn(
                `Most recently accessed outline content path not available, so not retrieving metadata for content path`
            )
            return null
        }

        return (
            getMetadataForContentPath(
                nodeMetadataTree,
                mostRecentlyAccessedOutlineContentPath
            )?.title ?? null
        )
    },
})
