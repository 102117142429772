import {
    ISuccessRatePlotDataPoint,
    PracticeProblemSuccessRate,
    SuccessRatePlotOption,
} from './PracticeProblemSuccessRate.types'
import { IProblemSetMetadataResponse } from 'common/src/practiceProblems/responses'
import { IProblemSetMetadata } from 'common/src/practiceProblems/types'
import { LineSeriesPoint } from 'react-vis'

// Built with react-vis LineMarkSeries in mind

export const buildPlotDataPoints = (
    successRate: PracticeProblemSuccessRate,
    successRatePlotOption: SuccessRatePlotOption
): LineSeriesPoint[] => {
    let unsortedData: ISuccessRatePlotDataPoint[]
    switch (successRatePlotOption) {
        case SuccessRatePlotOption.quizOnly:
            unsortedData = successRate.quizData
            break
        case SuccessRatePlotOption.examOnly:
            unsortedData = successRate.examData
            break
        case SuccessRatePlotOption.quizAndExam:
            unsortedData = [...successRate.quizData, ...successRate.examData]
            break
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return unsortedData.slice().sort((a, b) => a.x.getTime() - b.x.getTime())
}

export const createPracticeProblemSuccessRate = (
    problemSetMetadataResponse: IProblemSetMetadataResponse
): PracticeProblemSuccessRate => {
    return {
        quizData: problemSetMetadataResponse.quizMetadata
            .filter((quiz) => quiz.isComplete && quiz.isFullyGraded)
            .map((quiz) => getSuccessRateDataFromProblemSetMetadata(quiz)),
        examData: problemSetMetadataResponse.examMetadata
            .filter((exam) => exam.isComplete && exam.isFullyGraded)
            .map((exam) => getSuccessRateDataFromProblemSetMetadata(exam)),
    }
}

const getSuccessRateDataFromProblemSetMetadata = (
    problemSetMetadata: IProblemSetMetadata
): ISuccessRatePlotDataPoint => {
    return {
        x: problemSetMetadata.date,
        y: problemSetMetadata.score / problemSetMetadata.points,
        id: problemSetMetadata.id,
        type: problemSetMetadata.problemSetType,
    }
}
