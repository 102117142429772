import styles from '@/components/LoggedInHeader/LoggedInHeaderDesktopMode/LoggedInHeaderDesktopMode.module.scss'
import { SearchLink } from '@/components/search/SearchLink/SearchLink'
import { PersonalSection } from '@/components/LoggedInHeader/PersonalSection/PersonalSection'
import React, { ReactElement, useEffect, useState } from 'react'
import { markAsSeen } from '@/frontendLogic/notifications/markAsSeen'
import { markNotificationsAsSeen } from '@/api/notifications'
import { logger } from '@/logging/FrontendLogger'
import { registerEvent } from '@/api/analyticsEvent'
import { useRecoilValue, useRecoilState } from 'recoil'
import {
    frontendDisplayedCourseSelector,
    userInfoStateAtom,
    userInitialsSelector,
} from '@/atoms/accountMaintenance/userInfo'
import {
    notificationsAtom,
    numUnseenNotificationsSelector,
} from '@/atoms/notifications'
import { JEPNotification } from 'common/src/api/websiteFrontendVsWebsiteBackend/notifications/types'
import { PaidUserAnalyticsEventType } from 'common/src/api/websiteFrontendVsWebsiteBackend/analyticsEvent/types'
import { GlobalBreadcrumbs } from './GlobalBreadcrumbs/GlobalBreadcrumbs'

export const LoggedInHeaderDesktopMode: React.FC<unknown> = (
    _props
): ReactElement => {
    const userInfo = useRecoilValue(userInfoStateAtom)
    const initials = useRecoilValue(userInitialsSelector)

    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const [isNotificationModalVisible, setIsNotificationModalVisible] =
        useState<boolean>(false)
    const [notifications, setNotifications] =
        useRecoilState<JEPNotification[]>(notificationsAtom)
    const numUnseenNotifications = useRecoilValue(
        numUnseenNotificationsSelector
    )
    useEffect(() => {
        if (isNotificationModalVisible) {
            if (numUnseenNotifications > 0) {
                setNotifications(markAsSeen(notifications))
                markNotificationsAsSeen(courseName)
                    .then(() =>
                        logger.info(
                            'Notifications modal opened, all notifs marked as seen'
                        )
                    )
                    .catch((e) =>
                        logger.info(`Error marking notifs as seen`, e)
                    )
            }
            registerEvent(PaidUserAnalyticsEventType.OPEN_NOTIFICATIONS_MODAL)
        }
    }, [
        courseName,
        isNotificationModalVisible,
        notifications,
        numUnseenNotifications,
        setNotifications,
    ])

    return (
        <div className={styles.loggedInHeader}>
            <div className={styles.topRow}>
                <div className={styles.searchContainer}>
                    <SearchLink courseName={courseName} isMobileMode={false} />
                </div>
                <div className={styles.personalSectionContainer}>
                    <PersonalSection
                        displayName={userInfo?.displayName}
                        numUnseenNotifications={numUnseenNotifications}
                        setIsNotificationModalVisible={
                            setIsNotificationModalVisible
                        }
                        isNotificationModalVisible={isNotificationModalVisible}
                        courseName={courseName}
                        initials={initials}
                        isMobileMode={false}
                    />
                </div>
            </div>
            <div className={styles.bottomRow}>
                <GlobalBreadcrumbs />
            </div>
        </div>
    )
}
