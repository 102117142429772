import { PracticeProblemSuccessRate } from '@/frontendLogic/practiceProblems/visualizations/PracticeProblemSuccessRate/PracticeProblemSuccessRate.types'
import { atom, selector } from 'recoil'
import { LoggerSelectorFamily } from '@/atoms/logger'
import { createPracticeProblemSuccessRate } from '@/frontendLogic/practiceProblems/visualizations/PracticeProblemSuccessRate/PracticeProblemSuccessRate'
import { problemSetMetadataAtom } from '@/atoms/practiceProblems/problemSetMetadata'
import { defaultInvalidationAtom, InvalidationAtom } from '@/atoms/types'
import { resetOnSetEffect } from '@/atoms/atomEffects/resetOnSetEffect'
import { getForeverPromise } from 'common/src/utils/getForeverPromise'
import { logAtomChangesEffect } from '@/atoms/atomEffects/logAtomChangesEffect'
import {
    frontendDisplayedCourseSelector,
    userInfoStateAtom,
} from '@/atoms/accountMaintenance/userInfo'

export const practiceProblemSuccessRateAtom = atom<PracticeProblemSuccessRate>({
    key: 'practiceProblemSuccessRateAtom',
    default: selector<PracticeProblemSuccessRate>({
        key: 'practiceProblemSuccessRateFetcher',
        get: async ({ get }) => {
            const frontendDisplayedCourse = get(frontendDisplayedCourseSelector)
            const logger = get(
                LoggerSelectorFamily(practiceProblemSuccessRateAtom.key)
            )
            if (!frontendDisplayedCourse) {
                logger.warn(
                    `No frontend displayed course, so practice problem success rate will not be retrieved (returning forever promise instead)`
                )
                return getForeverPromise()
            }

            get(userInfoStateAtom)
            get(practiceProblemSuccessRateInvalidationAtom)
            logger.info(`Retrieving initial practice problem success rate`)
            const problemSetHistoryData = get(problemSetMetadataAtom)
            if (problemSetHistoryData === null) {
                logger.warn('No problem set history data, returning null')
                return null
            }

            return createPracticeProblemSuccessRate(problemSetHistoryData)
        },
    }),
})

export const practiceProblemSuccessRateInvalidationAtom =
    atom<InvalidationAtom>({
        key: 'practiceProblemSuccessRateInvalidationAtom',
        default: defaultInvalidationAtom,
        effects: [
            logAtomChangesEffect(
                'Practice problem Success Rate Invalidation Atom'
            ),
            resetOnSetEffect(practiceProblemSuccessRateAtom),
        ],
    })
