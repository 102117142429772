import { atom } from 'recoil'
import { logAtomChangesEffect } from '@/atoms/atomEffects/logAtomChangesEffect'

export const isMobileModeAtom = atom<boolean>({
    key: 'isMobileModeAtom',
    default: true,
    effects: [logAtomChangesEffect('isMobileModeAtom')],
})

export const isSmallDesktopModeAtom = atom<boolean>({
    key: 'isSmallDesktopModeAtom',
    default: false,
})
