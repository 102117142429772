import React, { ReactElement, useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { isEligibleForWelcomePromoCodeAtom } from '@/atoms/isEligibleForWelcomePromoCode'
import { HeaderAnnouncement } from '@/components/Header/HeaderAnnouncement/HeaderAnnouncement'
import { useRouter } from 'next/router'
import { cleanPath } from '@/utils/paths/cleanPath'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'

export const LoggedInHeaderAnnouncementWrapper: React.FC<
    unknown
> = (): ReactElement => {
    const isEligibleForWelcomePromoCode = useRecoilValue(
        isEligibleForWelcomePromoCodeAtom
    )
    const router = useRouter()
    return useMemo((): ReactElement => {
        const path = cleanPath(router.pathname)
        if (
            path === UiLayoutConstant.COURSE_PAYMENT_PAGE &&
            isEligibleForWelcomePromoCode
        ) {
            return <HeaderAnnouncement isLoggedIn={true} />
        }
        return <></>
    }, [isEligibleForWelcomePromoCode, router.pathname])
}
