import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { isOnlineAtom } from '@/atoms/isOnline'
import message from 'antd/lib/message'

const onlineStatusMessageKey = 'online-status'
export const useNetworkStatus = (): void => {
    const setIsOnline = useSetRecoilState(isOnlineAtom)

    // Initial state using the navigator.onLine property
    useEffect(() => {
        setIsOnline(navigator.onLine)
    }, [setIsOnline])

    useEffect(() => {
        // Handler to set online status
        const goOnline = (): void => {
            message.success({
                key: onlineStatusMessageKey,
                content:
                    'Back online! Refresh the page for full functionality.',
            })
            setIsOnline(true)
        }

        // Handler to set offline status
        const goOffline = (): void => {
            message.warning({
                key: onlineStatusMessageKey,
                content:
                    'Network issues detected. Some website functionality will be limited until you are back online.',
            })
            setIsOnline(false)
        }

        // Adding event listeners for online and offline events
        window.addEventListener('online', goOnline)
        window.addEventListener('offline', goOffline)

        return () => {
            // Cleanup by removing event listeners
            window.removeEventListener('online', goOnline)
            window.removeEventListener('offline', goOffline)
        }
    }, [setIsOnline])
}
