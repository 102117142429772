import { ApiMethod } from 'common/src/api/types'
import {
    StripeClientSecretResponse,
    ValidatePromoCodeResponse,
    ConfirmPaymentResponse,
    IsPromoCodeValidForUserResponse,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/payment/responses'
import {
    ConfirmPaymentRequest,
    PaymentRequest,
    ValidatePromoCodeRequest,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/payment/requests'
import { CancelToken } from 'axios'
import { AccessType } from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/types'
import { callBackend } from '../../server/callBackend'
import { IsPromoCodeValidForUserRequest } from 'common/src/api/websiteFrontendVsWebsiteBackend/payment/requests'

const PREFIX = '/wb/payment/'

export const validatePromoCode = async (
    courseName: string,
    accessTypes: AccessType[],
    promoCode: string
) => {
    return await callBackend<
        ValidatePromoCodeRequest,
        ValidatePromoCodeResponse
    >(PREFIX + 'validatePromoCode', ApiMethod.GET, {
        courseName,
        accessTypes,
        promoCode,
    })
}

export const createNewPayment = async (
    courseName: string,
    promoCode: string,
    accessTypes: AccessType[]
) => {
    return await callBackend<PaymentRequest, StripeClientSecretResponse>(
        PREFIX + 'createNewPayment',
        ApiMethod.POST,
        {
            courseName,
            promoCode,
            accessTypes,
        }
    )
}

export const confirmPayment = async (
    stripeID: string,
    cancelToken: CancelToken = null
) => {
    return await callBackend<ConfirmPaymentRequest, ConfirmPaymentResponse>(
        PREFIX + 'confirmPayment',
        ApiMethod.POST,
        {
            stripeID,
        },
        cancelToken
    )
}

export const isPromoCodeValidForUser = async (
    promoCode: string,
    cancelToken: CancelToken = null
) => {
    return await callBackend<
        IsPromoCodeValidForUserRequest,
        IsPromoCodeValidForUserResponse
    >(
        PREFIX + 'isPromoCodeValidForUser',
        ApiMethod.GET,
        {
            promoCode,
        },
        cancelToken
    )
}
