export const shouldUseTracking = (): boolean => {
    switch (process.env.NEXT_PUBLIC_JEP_MODE) {
        case 'dev':
            return false
        case 'test':
            return true
        case 'prod':
            return true
    }
}
