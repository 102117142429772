import { CancelToken } from 'axios'
import {
    IPracticeProblemUser,
    IProblemSet,
    IProblemSetMetadata,
    PracticeProblemSyncData,
} from 'common/src/practiceProblems/types'
import {
    IProblemSetMetadataResponse,
    IProblemUserStatsResponse,
} from 'common/src/practiceProblems/responses'
import {
    GenericContentRequest,
    SpecificContentRequest,
} from 'common/src/api/core/courses/requests'
import { ApiMethod, EmptyResponse } from 'common/src/api/types'
import { APIResponseWrapper } from '@/api/types'
import {
    CreateProblemSetForContentPathRequest,
    CreateQuizRequest,
    GetPracticeProblemsRequest,
    GetProblemSetRequest,
    SetIsProblemStarredRequest,
    SetProblemSetNotesRequest,
    SyncIndividualPracticeProblemRequest,
    SyncProblemSetRequest,
} from 'common/src/practiceProblems/requests'
import { ProblemSetType } from 'common/src/practiceProblems'
import { callBackend } from '../../server/callBackend'
import { ContentPath } from 'common/src/ContentPath'

const PREFIX = '/wb/practiceProblems/'

export async function getProblemSetMetadata(
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<IProblemSetMetadataResponse> {
    return await callBackend<
        GenericContentRequest,
        IProblemSetMetadataResponse
    >(
        PREFIX + 'getProblemSetMetadata',
        ApiMethod.GET,
        { courseName },
        cancelToken
    )
}

export async function getProblemUserStats(
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<IProblemUserStatsResponse> {
    return await callBackend<GenericContentRequest, IProblemUserStatsResponse>(
        PREFIX + 'getProblemUserStats',
        ApiMethod.GET,
        {
            courseName,
        },
        cancelToken
    )
}

export async function createQuiz(
    courseName: string,
    problemIDs: string[],
    tags: string[],
    isTimed: boolean,
    cancelToken: CancelToken = null
): APIResponseWrapper<IProblemSet> {
    return await callBackend<CreateQuizRequest, IProblemSet>(
        PREFIX + 'createQuiz',
        ApiMethod.POST,
        {
            courseName,
            problemIDs,
            tags,
            isTimed,
        },
        cancelToken
    )
}

export async function createExam(
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<IProblemSet> {
    return await callBackend<GenericContentRequest, IProblemSet>(
        PREFIX + 'createExam',
        ApiMethod.POST,
        { courseName },
        cancelToken
    )
}

export async function getProblemSet(
    courseName: string,
    id: string,
    type?: ProblemSetType,
    cancelToken: CancelToken = null
): APIResponseWrapper<IProblemSet> {
    return await callBackend<GetProblemSetRequest, IProblemSet>(
        PREFIX + 'getProblemSet',
        ApiMethod.GET,
        { courseName, id, type },
        cancelToken
    )
}

export async function getPracticeProblem(
    courseName: string,
    id: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<IPracticeProblemUser> {
    return await callBackend<SpecificContentRequest, IPracticeProblemUser>(
        PREFIX + 'getPracticeProblem',
        ApiMethod.GET,
        {
            courseName,
            id,
        },
        cancelToken
    )
}

export async function getPracticeProblems(
    courseName: string,
    ids: string[],
    throwIfMissing: boolean,
    cancelToken: CancelToken = null
): APIResponseWrapper<IPracticeProblemUser[]> {
    return await callBackend<
        GetPracticeProblemsRequest,
        IPracticeProblemUser[]
    >(
        PREFIX + 'getPracticeProblems',
        ApiMethod.GET,
        {
            courseName,
            practiceProblemIDs: ids,
            throwIfMissingStr: throwIfMissing.toString(),
        },
        cancelToken
    )
}

export async function syncProblemSet(
    courseName: string,
    id: string,
    updatedProblemsUserOnlyMetadata: PracticeProblemSyncData[],
    timeElapsedSinceLastSyncInSeconds: number,
    currentActiveProblemIndex: number,
    isComplete: boolean,
    cancelToken: CancelToken = null
): APIResponseWrapper<EmptyResponse> {
    return await callBackend<SyncProblemSetRequest, EmptyResponse>(
        PREFIX + 'syncProblemSet',
        ApiMethod.POST,
        {
            courseName,
            id,
            updatedProblemsUserOnlyMetadata,
            timeElapsedSinceLastSyncInSeconds,
            currentActiveProblemIndex,
            isComplete,
        },
        cancelToken
    )
}

export async function setProblemSetNotes(
    courseName: string,
    id: string,
    notes: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<EmptyResponse> {
    return await callBackend<SetProblemSetNotesRequest, EmptyResponse>(
        PREFIX + 'setProblemSetNotes',
        ApiMethod.POST,
        {
            courseName,
            id,
            notes,
        },
        cancelToken
    )
}

export async function getMostRecentProblemSetMetadata(
    courseName: string
): APIResponseWrapper<IProblemSetMetadata> {
    return await callBackend<GenericContentRequest, IProblemSetMetadata>(
        PREFIX + 'getMostRecentProblemSetMetadata',
        ApiMethod.GET,
        {
            courseName,
        }
    )
}

export async function deleteProblemSet(
    courseName: string,
    problemSetID: string,
    cancelToken?: CancelToken
): APIResponseWrapper<EmptyResponse> {
    return await callBackend<SpecificContentRequest, EmptyResponse>(
        PREFIX + 'deleteProblemSet',
        ApiMethod.POST,
        {
            courseName,
            id: problemSetID,
        },
        cancelToken
    )
}

export async function syncIndividualPracticeProblem(
    courseName: string,
    practiceProblemID: string,
    updatedPracticeProblemUserOnlyMetadata: PracticeProblemSyncData,
    cancelToken?: CancelToken
): APIResponseWrapper<EmptyResponse> {
    return await callBackend<
        SyncIndividualPracticeProblemRequest,
        EmptyResponse
    >(
        PREFIX + 'syncIndividualPracticeProblem',
        ApiMethod.POST,
        {
            courseName,
            id: practiceProblemID,
            updatedPracticeProblemUserOnlyMetadata,
        },
        cancelToken
    )
}

export async function createRecommendedQuiz(
    cancelToken: CancelToken = null
): APIResponseWrapper<IProblemSet> {
    return await callBackend<EmptyResponse, IProblemSet>(
        PREFIX + 'createRecommendedQuiz',
        ApiMethod.POST,
        null,
        cancelToken
    )
}

export async function createProblemSetForContentPath(
    courseName: string,
    contentPath: ContentPath,
    cancelToken: CancelToken = null
): APIResponseWrapper<IProblemSet> {
    return await callBackend<
        CreateProblemSetForContentPathRequest,
        IProblemSet
    >(
        PREFIX + 'createProblemSetForContentPath',
        ApiMethod.POST,
        {
            courseName,
            contentPath,
        },
        cancelToken
    )
}

export async function setIsProblemStarred(
    payload: SetIsProblemStarredRequest,
    cancelToken: CancelToken = null
): APIResponseWrapper<EmptyResponse> {
    return await callBackend<SetIsProblemStarredRequest, EmptyResponse>(
        PREFIX + 'setIsProblemStarred',
        ApiMethod.POST,
        payload,
        cancelToken
    )
}
