import React, { ReactElement } from 'react'
import Image from 'next/image'
import src from '../../../../../public/owlBranding/excitedOwl.webp'

export const ExcitedOwl: React.FC<{ priority: boolean; className?: string }> = (
    props
): ReactElement => (
    <Image
        src={src}
        alt={'excited owl'}
        priority={props.priority}
        className={props.className}
    />
)
