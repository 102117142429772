import { atom, selector } from 'recoil'
import { LoggerSelectorFamily } from '@/atoms/logger'
import { getUserInfo } from '@/api/accountMaintenance'
import produce from 'immer'
import {
    isAccessInfoDemo,
    UserInfoResponse,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/responses'
import {
    AccessType,
    ALL_ACCESS_TYPES,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/types'
import { logAtomChangesEffect } from '@/atoms/atomEffects/logAtomChangesEffect'

export type UserInfoState = UserInfoResponse

export const fetchUserInfo = async (): Promise<UserInfoState | null> => {
    return (await getUserInfo()).data.payload
}
export const userInfoStateAtom = atom<UserInfoState | null>({
    key: 'userInfoStateAtom',
    default: selector<UserInfoState | null>({
        key: 'userInfoStateSelector',
        get: async ({ get }) => {
            get(LoggerSelectorFamily(userInfoStateAtom.key)).info(
                `Retrieving initial user info state atom`
            )
            return fetchUserInfo()
        },
    }),
    effects: [logAtomChangesEffect('User info state changing')],
})

export const userInitialsSelector = selector<string>({
    key: 'userInitialsSelector',
    get: ({ get }) => {
        const userInfo = get(userInfoStateAtom)
        let initialsOutput = ''
        if (userInfo?.firstName && userInfo?.firstName?.length >= 1) {
            initialsOutput += userInfo.firstName[0].toUpperCase()
        }

        if (userInfo?.lastName && userInfo?.lastName?.length >= 1) {
            initialsOutput += userInfo.lastName[0].toUpperCase()
        }

        return initialsOutput
    },
})

export const mergeUserInfo = produce(
    (userInfoState: UserInfoState, newData: Partial<UserInfoState>) => {
        Object.assign(userInfoState, newData)
    }
)

export const setEmailVerified = produce(
    (userInfo: UserInfoState, isEmailVerified: boolean) => {
        userInfo.isEmailVerified = isEmailVerified
    }
)

export const frontendDisplayedCourseSelector = selector<string | null>({
    key: 'frontendDisplayedCourseSelector',
    get: ({ get }) => {
        return get(userInfoStateAtom)?.frontendDisplayedCourseInfo?.name ?? null
    },
})

export const isActiveCourseDemoSelector = selector<boolean>({
    key: 'isActiveCourseDemoSelector',
    get: ({ get }) => {
        const accessInfo =
            get(userInfoStateAtom)?.frontendDisplayedCourseInfo?.accessInfo
        if (!accessInfo) {
            get(LoggerSelectorFamily(isActiveCourseDemoSelector.key)).warn(
                'No access info, returning false'
            )
            return false
        }

        return isAccessInfoDemo(accessInfo)
    },
})

export const activeCourseExpirationDateSelector = selector<Date | null>({
    key: 'activeCourseExpirationDateSelector',
    get: ({ get }) => {
        const accessInfo =
            get(userInfoStateAtom)?.frontendDisplayedCourseInfo?.accessInfo
        if (!accessInfo) {
            get(
                LoggerSelectorFamily(activeCourseExpirationDateSelector.key)
            ).warn('No access info, returning null')
            return null
        }

        if (isAccessInfoDemo(accessInfo)) {
            return accessInfo.expirationDate
        }

        return accessInfo
            .map((info) => info.expirationDate)
            .reduce(
                (maxDate, currentDate) =>
                    currentDate > maxDate ? currentDate : maxDate,
                accessInfo[0].expirationDate
            )
    },
})

export const frontendDisplayedCourseHasActiveSubscriptionSelector =
    selector<boolean>({
        key: 'frontendDisplayedCourseHasActiveSubscriptionSelector',
        get: ({ get }) => {
            const accessInfo =
                get(userInfoStateAtom)?.frontendDisplayedCourseInfo?.accessInfo
            if (!accessInfo) {
                get(
                    LoggerSelectorFamily(
                        frontendDisplayedCourseHasActiveSubscriptionSelector.key
                    )
                ).warn('No access info, returning false')
                return false
            }

            if (isAccessInfoDemo(accessInfo)) {
                return accessInfo.isSubscriptionActive
            }

            return accessInfo.some((info) => info.isSubscriptionActive)
        },
    })

export const frontendDisplayedCourseAccessTypesSelector = selector<
    AccessType[]
>({
    key: 'frontendDisplayedCourseAccessTypesSelector',
    get: ({ get }) => {
        const accessInfo =
            get(userInfoStateAtom)?.frontendDisplayedCourseInfo?.accessInfo
        if (!accessInfo) {
            get(
                LoggerSelectorFamily(
                    frontendDisplayedCourseAccessTypesSelector.key
                )
            ).warn('No access info, returning empty array')
            return []
        }

        if (isAccessInfoDemo(accessInfo)) {
            return accessInfo.isSubscriptionActive ? ALL_ACCESS_TYPES : []
        }

        return accessInfo
            .filter((info) => info.isSubscriptionActive)
            .map((activeInfo) => activeInfo.accessType)
    },
})

export const frontendDisplayedCoursePurchasedAccessTypesSelector = selector<
    AccessType[]
>({
    key: 'frontendDisplayedCoursePurchasedAccessTypesSelector',
    get: ({ get }) => {
        const accessInfo =
            get(userInfoStateAtom)?.frontendDisplayedCourseInfo?.accessInfo
        if (!accessInfo) {
            get(
                LoggerSelectorFamily(
                    frontendDisplayedCoursePurchasedAccessTypesSelector.key
                )
            ).warn('No access info, returning empty array')
            return []
        }

        if (isAccessInfoDemo(accessInfo)) {
            return []
        }

        return accessInfo
            .filter((info) => info.isSubscriptionActive)
            .map((activeInfo) => activeInfo.accessType)
    },
})
