import React, { ReactElement, useMemo } from 'react'
import styles from './GlobalBreadcrumbs.module.scss'
import { useRouter } from 'next/router'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import Link from 'next/link'
import { interleaveArrayComponents } from '@/utils/interleaveArray'
import { useRecoilValueLoadable } from 'recoil'
import { problemSetStateAtom } from '@/atoms/practiceProblems/problemSet'
import { ProblemSetType } from 'common/src/practiceProblems/consts'

export const GlobalBreadcrumbs: React.FC<unknown> = (_props): ReactElement => {
    const router = useRouter()
    const problemSetLoadable = useRecoilValueLoadable(problemSetStateAtom)
    const quizOrExam = useMemo((): ProblemSetType | null => {
        if (problemSetLoadable.state !== 'hasValue') return null
        return (
            problemSetLoadable.contents?.problemSetState.problemSetType || null
        )
    }, [problemSetLoadable])
    const prettyNamesAndUrls = useMemo(
        (): PrettyNameAndUrl[] =>
            getPrettyNameAndUrlsForPath(router.pathname, quizOrExam).filter(
                (x) => !!x // filter in case of any misses
            ),
        [quizOrExam, router.pathname]
    )
    const components = useMemo(
        (): ReactElement[] =>
            prettyNamesAndUrls.map(({ prettyName, url }, index) => (
                <GlobalBreadcrumbComponent
                    prettyName={prettyName}
                    url={url}
                    isLast={index === prettyNamesAndUrls.length - 1}
                    key={index}
                />
            )),
        [prettyNamesAndUrls]
    )

    return (
        <div className={styles.globalBreadcrumbs}>
            {interleaveArrayComponents(components, <BreadcrumbDivider />)}
        </div>
    )
}

const BreadcrumbDivider: React.FC<unknown> = (_props): ReactElement => (
    <div className={styles.breadcrumbDivider}>&gt;</div>
)

const GlobalBreadcrumbComponent: React.FC<{
    prettyName: string
    url: string
    isLast: boolean
}> = (props): ReactElement => {
    return (
        <div className={styles.globalBreadcrumbComponent}>
            {props.isLast ? (
                <div className={styles.lastComponent}>{props.prettyName}</div>
            ) : (
                <Link href={props.url}>{props.prettyName}</Link>
            )}
        </div>
    )
}

interface PrettyNameAndUrl {
    prettyName: string
    url: string
}

const PageToPrettyNameAndUrlMapping: Partial<
    Record<UiLayoutConstant, PrettyNameAndUrl>
> = {
    [UiLayoutConstant.DASHBOARD_PAGE]: {
        prettyName: 'Home',
        url: UiLayoutConstant.DASHBOARD_PAGE,
    },
    [UiLayoutConstant.OUTLINES_DASHBOARD]: {
        prettyName: 'Outlines',
        url: UiLayoutConstant.OUTLINES_DASHBOARD,
    },
    [UiLayoutConstant.OUTLINE_PAGE]: {
        prettyName: 'Content',
        url: UiLayoutConstant.OUTLINE_PAGE,
    },
    [UiLayoutConstant.NOTECARDS_DASHBOARD]: {
        prettyName: 'Notecards',
        url: UiLayoutConstant.NOTECARDS_DASHBOARD,
    },
    [UiLayoutConstant.CREATE_NOTECARD_SET]: {
        prettyName: 'Create Deck',
        url: UiLayoutConstant.CREATE_NOTECARD_SET,
    },
    [UiLayoutConstant.LESSON]: {
        prettyName: 'Lesson',
        url: UiLayoutConstant.LESSON,
    },
    [UiLayoutConstant.NOTECARD_SET_EXTRA_PRACTICE]: {
        prettyName: 'Extra Practice',
        url: UiLayoutConstant.NOTECARD_SET_EXTRA_PRACTICE,
    },
    [UiLayoutConstant.NOTECARD_SET_RECENT_MISTAKES]: {
        prettyName: 'Recent Mistakes',
        url: UiLayoutConstant.NOTECARD_SET_RECENT_MISTAKES,
    },
    [UiLayoutConstant.NOTECARD_SET_REVIEW]: {
        prettyName: 'Review',
        url: UiLayoutConstant.NOTECARD_SET_REVIEW,
    },
    [UiLayoutConstant.PRACTICE_PROBLEMS_DASHBOARD]: {
        prettyName: 'Practice Questions',
        url: UiLayoutConstant.PRACTICE_PROBLEMS_DASHBOARD,
    },
    [UiLayoutConstant.CREATE_QUIZ]: {
        prettyName: 'Create Quiz',
        url: UiLayoutConstant.CREATE_QUIZ,
    },
    [UiLayoutConstant.STUDY_MATERIALS_GUIDE_PAGE]: {
        prettyName: 'Guide',
        url: UiLayoutConstant.STUDY_MATERIALS_GUIDE_PAGE,
    },
    [UiLayoutConstant.STUDY_MATERIALS_GUIDE_PHILOSOPHY]: {
        prettyName: 'Philosophy',
        url: UiLayoutConstant.STUDY_MATERIALS_GUIDE_PHILOSOPHY,
    },
    [UiLayoutConstant.STUDY_MATERIALS_GUIDE_NOTECARDS]: {
        prettyName: 'Notecards',
        url: UiLayoutConstant.STUDY_MATERIALS_GUIDE_NOTECARDS,
    },
    [UiLayoutConstant.STUDY_MATERIALS_GUIDE_OUTLINES]: {
        prettyName: 'Outlines',
        url: UiLayoutConstant.STUDY_MATERIALS_GUIDE_OUTLINES,
    },
    [UiLayoutConstant.STUDY_MATERIALS_GUIDE_PROBLEMS]: {
        prettyName: 'Problems',
        url: UiLayoutConstant.STUDY_MATERIALS_GUIDE_PROBLEMS,
    },
    [UiLayoutConstant.STUDY_MATERIALS_GUIDE_OTHER]: {
        prettyName: 'Other',
        url: UiLayoutConstant.STUDY_MATERIALS_GUIDE_OTHER,
    },
    [UiLayoutConstant.STUDY_MATERIALS_GUIDE_TIPS]: {
        prettyName: 'Tips',
        url: UiLayoutConstant.STUDY_MATERIALS_GUIDE_TIPS,
    },
}

const getPrettyNameAndUrlsForPath = (
    path: string,
    quizOrExam: ProblemSetType | null
): PrettyNameAndUrl[] => {
    // Outlines
    if (path.includes(UiLayoutConstant.OUTLINES_DASHBOARD)) {
        return [
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.DASHBOARD_PAGE],
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.OUTLINES_DASHBOARD],
        ]
    }

    if (path.includes(UiLayoutConstant.OUTLINE_PAGE)) {
        return [
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.DASHBOARD_PAGE],
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.OUTLINES_DASHBOARD],
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.OUTLINE_PAGE],
        ]
    }

    // Notecards
    if (path.includes(UiLayoutConstant.NOTECARDS_DASHBOARD)) {
        return [
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.DASHBOARD_PAGE],
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.NOTECARDS_DASHBOARD],
        ]
    }

    if (path.includes(UiLayoutConstant.CREATE_NOTECARD_SET)) {
        return [
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.DASHBOARD_PAGE],
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.NOTECARDS_DASHBOARD],
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.CREATE_NOTECARD_SET],
        ]
    }

    if (path.includes(UiLayoutConstant.NOTECARD_SET_EXTRA_PRACTICE)) {
        return [
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.DASHBOARD_PAGE],
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.NOTECARDS_DASHBOARD],
            PageToPrettyNameAndUrlMapping[
                UiLayoutConstant.NOTECARD_SET_EXTRA_PRACTICE
            ],
        ]
    }

    if (path.includes(UiLayoutConstant.NOTECARD_SET_RECENT_MISTAKES)) {
        return [
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.DASHBOARD_PAGE],
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.NOTECARDS_DASHBOARD],
            PageToPrettyNameAndUrlMapping[
                UiLayoutConstant.NOTECARD_SET_RECENT_MISTAKES
            ],
        ]
    }

    if (path.includes(UiLayoutConstant.LESSON)) {
        return [
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.DASHBOARD_PAGE],
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.NOTECARDS_DASHBOARD],
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.LESSON],
        ]
    }

    if (path.includes(UiLayoutConstant.NOTECARD_SET_REVIEW)) {
        return [
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.DASHBOARD_PAGE],
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.NOTECARDS_DASHBOARD],
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.NOTECARD_SET_REVIEW],
        ]
    }

    // Practice Problems
    if (path.includes(UiLayoutConstant.PRACTICE_PROBLEMS_DASHBOARD)) {
        return [
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.DASHBOARD_PAGE],
            PageToPrettyNameAndUrlMapping[
                UiLayoutConstant.PRACTICE_PROBLEMS_DASHBOARD
            ],
        ]
    }

    if (path.includes(UiLayoutConstant.CREATE_QUIZ)) {
        return [
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.DASHBOARD_PAGE],
            PageToPrettyNameAndUrlMapping[
                UiLayoutConstant.PRACTICE_PROBLEMS_DASHBOARD
            ],
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.CREATE_QUIZ],
        ]
    }

    if (path.includes(UiLayoutConstant.PROBLEM_SET)) {
        return [
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.DASHBOARD_PAGE],
            PageToPrettyNameAndUrlMapping[
                UiLayoutConstant.PRACTICE_PROBLEMS_DASHBOARD
            ],
            {
                prettyName: quizOrExam ?? 'Quiz',
                url: UiLayoutConstant.DASHBOARD_PAGE, // unused because last item
            },
        ]
    }

    if (path.includes(UiLayoutConstant.PROBLEM_SET_REVIEW)) {
        return [
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.DASHBOARD_PAGE],
            PageToPrettyNameAndUrlMapping[
                UiLayoutConstant.PRACTICE_PROBLEMS_DASHBOARD
            ],
            {
                prettyName: `${quizOrExam ?? 'Quiz'} Review`,
                url: UiLayoutConstant.DASHBOARD_PAGE, // unused because last item
            },
        ]
    }

    // Guide
    if (path.includes(UiLayoutConstant.STUDY_MATERIALS_GUIDE_TIPS)) {
        return [
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.DASHBOARD_PAGE],
            PageToPrettyNameAndUrlMapping[
                UiLayoutConstant.STUDY_MATERIALS_GUIDE_PAGE
            ],
            PageToPrettyNameAndUrlMapping[
                UiLayoutConstant.STUDY_MATERIALS_GUIDE_TIPS
            ],
        ]
    }

    if (path.includes(UiLayoutConstant.STUDY_MATERIALS_GUIDE_OTHER)) {
        return [
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.DASHBOARD_PAGE],
            PageToPrettyNameAndUrlMapping[
                UiLayoutConstant.STUDY_MATERIALS_GUIDE_PAGE
            ],
            PageToPrettyNameAndUrlMapping[
                UiLayoutConstant.STUDY_MATERIALS_GUIDE_OTHER
            ],
        ]
    }

    if (path.includes(UiLayoutConstant.STUDY_MATERIALS_GUIDE_PROBLEMS)) {
        return [
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.DASHBOARD_PAGE],
            PageToPrettyNameAndUrlMapping[
                UiLayoutConstant.STUDY_MATERIALS_GUIDE_PAGE
            ],
            PageToPrettyNameAndUrlMapping[
                UiLayoutConstant.STUDY_MATERIALS_GUIDE_PROBLEMS
            ],
        ]
    }

    if (path.includes(UiLayoutConstant.STUDY_MATERIALS_GUIDE_OUTLINES)) {
        return [
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.DASHBOARD_PAGE],
            PageToPrettyNameAndUrlMapping[
                UiLayoutConstant.STUDY_MATERIALS_GUIDE_PAGE
            ],
            PageToPrettyNameAndUrlMapping[
                UiLayoutConstant.STUDY_MATERIALS_GUIDE_OUTLINES
            ],
        ]
    }

    if (path.includes(UiLayoutConstant.STUDY_MATERIALS_GUIDE_NOTECARDS)) {
        return [
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.DASHBOARD_PAGE],
            PageToPrettyNameAndUrlMapping[
                UiLayoutConstant.STUDY_MATERIALS_GUIDE_PAGE
            ],
            PageToPrettyNameAndUrlMapping[
                UiLayoutConstant.STUDY_MATERIALS_GUIDE_NOTECARDS
            ],
        ]
    }

    if (path.includes(UiLayoutConstant.STUDY_MATERIALS_GUIDE_PHILOSOPHY)) {
        return [
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.DASHBOARD_PAGE],
            PageToPrettyNameAndUrlMapping[
                UiLayoutConstant.STUDY_MATERIALS_GUIDE_PAGE
            ],
            PageToPrettyNameAndUrlMapping[
                UiLayoutConstant.STUDY_MATERIALS_GUIDE_PHILOSOPHY
            ],
        ]
    }

    if (path.includes(UiLayoutConstant.STUDY_MATERIALS_GUIDE_PAGE)) {
        return [
            PageToPrettyNameAndUrlMapping[UiLayoutConstant.DASHBOARD_PAGE],
            PageToPrettyNameAndUrlMapping[
                UiLayoutConstant.STUDY_MATERIALS_GUIDE_PAGE
            ],
        ]
    }

    return []
}
