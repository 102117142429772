import assert from 'assert'
import { INotecardUser, NotecardSetState } from 'common/src/notecards/types'

export const getIndexForID = (
    objectsWithID: { id: string }[],
    id: string
): number => {
    for (const [index, objectWithID] of objectsWithID.entries()) {
        if (objectWithID.id === id) return index
    }
    throw new Error(
        `Unable to find id: ${id} in array: ${JSON.stringify(
            objectsWithID,
            null,
            2
        )}`
    )
}

const getNotecardForID = (
    notecards: INotecardUser[],
    id: string
): INotecardUser => {
    return notecards[getIndexForID(notecards, id)]
}

export const getActiveNotecard = (state: NotecardSetState): INotecardUser => {
    return getNotecardForID(state.notecards, state.stateData.activeNotecardID)
}

export const getNextNotecard = (state: NotecardSetState): INotecardUser => {
    const nextNotecardIndex = getIndexForID(
        state.notecards,
        state.stateData.nextNotecardID
    )
    return state.notecards[nextNotecardIndex]
}

export const getPreviousNotecard = (state: NotecardSetState): INotecardUser => {
    assert(
        state.stateData.previousNotecardIDStack.length,
        `Previous notecard stack is of length 0, cannot get previous notecard.`
    )
    const previousNotecardID =
        state.stateData.previousNotecardIDStack[
            state.stateData.previousNotecardIDStack.length - 1
        ]
    return getNotecardForID(state.notecards, previousNotecardID)
}
