import { useEffect } from 'react'
import notification from 'antd/lib/notification'
import { useHotkeys } from 'react-hotkeys-hook'

export const useNotificationConfig = (): void => {
    useEffect(() => {
        notification.config({
            top: 112,
            placement: 'topRight',
        })
    }, [])

    // Pressing esc on any page will destroy any open notification
    useHotkeys('esc', () => {
        setTimeout(notification.destroy, 200)
    })
}
