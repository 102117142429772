import produce from 'immer'
import { IProblemSetMetadata } from 'common/src/practiceProblems/types'

export const cleanProblemSetMetadata = produce(
    (problemSetMetadata: IProblemSetMetadata[]): void => {
        problemSetMetadata.forEach((problemSetMetadatum) => {
            problemSetMetadatum.date = new Date(problemSetMetadatum.date)
        })
    }
)
