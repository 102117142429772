import { RecoilState, AtomEffect } from 'recoil'
import { InvalidationAtom, invalidate } from '@/atoms/types'
import { setRecoilExternalState } from '@/components/RecoilExternalStatePortal'

export const invalidateAtomsEffect =
    <T>(invalidationAtoms: RecoilState<InvalidationAtom>[]): AtomEffect<T> =>
    ({ onSet }): void => {
        onSet((): void => {
            for (const invalidationAtom of invalidationAtoms) {
                setRecoilExternalState(invalidationAtom, (val) =>
                    invalidate(val)
                )
            }
        })
    }
