import { AnnotatedNodeMetadata } from 'common/src/outlines/types'
import { atom, selector } from 'recoil'
import { contentTreeStateAtom } from '@/atoms/contentTree'
import { getNodeMetadata } from '@/api/outlines'
import { LoggerSelectorFamily } from '@/atoms/logger'
import { NodeMetadataTree } from '@/frontendLogic/outlines/NodeMetadataTree/NotecardMetadataTree.types'
import { createNodeMetadataTree } from '@/frontendLogic/outlines/NodeMetadataTree'
import { defaultInvalidationAtom, InvalidationAtom } from '@/atoms/types'
import { resetOnSetEffect } from '@/atoms/atomEffects/resetOnSetEffect'
import { logAtomChangesEffect } from '@/atoms/atomEffects/logAtomChangesEffect'
import {
    frontendDisplayedCourseSelector,
    userInfoStateAtom,
} from '@/atoms/accountMaintenance/userInfo'

export const nodeMetadataAtom = atom<AnnotatedNodeMetadata[] | null>({
    key: 'nodeMetadataAtom',
    default: selector<AnnotatedNodeMetadata[] | null>({
        key: 'nodeMetadataFetcher',
        get: async ({ get }) => {
            const frontendDisplayedCourse = get(frontendDisplayedCourseSelector)
            const logger = get(LoggerSelectorFamily(nodeMetadataAtom.key))
            if (frontendDisplayedCourse === null) {
                logger.warn(
                    `No frontend displayed course, so node metadata will not be retrieved`
                )
                return null
            }

            get(userInfoStateAtom) // forces update upon new login
            get(nodeMetadataInvalidationAtom) // forces update upon new login
            logger.info(`Retrieving initial node metadata`)
            const response = await getNodeMetadata(frontendDisplayedCourse)
            return response.data.payload?.nodeMetadata ?? null
        },
    }),
})

export const nodeMetadataInvalidationAtom = atom<InvalidationAtom>({
    key: 'nodeMetadataInvalidationAtom',
    default: defaultInvalidationAtom,
    effects: [
        logAtomChangesEffect('Node Metadata Invalidation Atom'),
        resetOnSetEffect(nodeMetadataAtom),
    ],
})

export const nodeMetadataTreeSelector = selector<NodeMetadataTree | null>({
    key: 'nodeMetadataTreeSelector',
    get: ({ get }) => {
        const contentTreeState = get(contentTreeStateAtom)
        const logger = get(LoggerSelectorFamily(nodeMetadataTreeSelector.key))
        if (!contentTreeState) {
            logger.warn(
                `No content tree, so node metadata tree will not be retrieved`
            )
            return null
        }

        const nodeMetadata = get(nodeMetadataAtom)
        if (!nodeMetadata) {
            logger.warn(
                `No node metadata, so node metadata tree will not be retrieved`
            )
            return null
        }

        return createNodeMetadataTree(
            nodeMetadata,
            contentTreeState.contentTree
        )
    },
})
