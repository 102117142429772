import { selector } from 'recoil'
import { getMostRecentNotecardSetID } from '@/api/notecards'
import { notecardSetMetadataAtom } from '@/atoms/notecards/notecardSetMetadata'
import { LoggerSelectorFamily } from '@/atoms/logger'
import {
    frontendDisplayedCourseSelector,
    userInfoStateAtom,
} from '@/atoms/accountMaintenance/userInfo'

export const mostRecentNotecardSetIDSelector = selector<string | null>({
    key: 'mostRecentNotecardSetIDSelector',
    get: async ({ get }) => {
        const frontendDisplayedCourse = get(frontendDisplayedCourseSelector)
        const logger = get(
            LoggerSelectorFamily(mostRecentNotecardSetIDSelector.key)
        )
        if (!frontendDisplayedCourse) {
            logger.warn(
                `No frontend displayed course, so most recent notecard set ID will not be retrieved (returning null instead)`
            )
            return null
        }
        get(userInfoStateAtom) // forces update upon new login
        get(notecardSetMetadataAtom) // invalidate if notecard set metadata is changed
        logger.info(`Retrieving most recent notecard set ID`)
        return (
            (await getMostRecentNotecardSetID(frontendDisplayedCourse)).data
                ?.payload?.id ?? null
        )
    },
})
