import React, { ReactElement, useMemo } from 'react'
import styles from './BuyCoursePrompt.module.scss'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { useRecoilValue } from 'recoil'
import {
    GenericButton,
    GenericButtonType,
} from '@/components/static/ui/Button/GenericButton/GenericButton'
import Link from 'next/link'
import {
    frontendDisplayedCourseAccessTypesSelector,
    frontendDisplayedCourseSelector,
    isActiveCourseDemoSelector,
    userInfoStateAtom,
} from '@/atoms/accountMaintenance/userInfo'
import {
    purchasedCoursesExpiredSelector,
    purchasedCoursesSelector,
} from '@/atoms/accountMaintenance/userPaymentHistory'
import { courseInfoSelectorFamily } from '@/atoms/courseInfo'
import {
    ACCESS_TYPE_TO_PRETTY_NAME,
    AccessType,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/types'
import { COURSE_NAME_TO_UI_LAYOUT_CONSTANT } from '@/components/Courses/courseNameToUiLayoutConstant'
import { useLogger } from '@/hooks/useLogger'
import {
    ActionCard,
    ActionCardsContainer,
} from '@/components/ActionCard/ActionCard'
import { ExcitedOwl } from '@/components/static/images/owlBranding/excitedOwl'
import { NotecardOwl } from '@/components/static/images/owlBranding/notecardOwl'
import { QuizOwl } from '@/components/static/images/owlBranding/quizOwl'
import { startCase } from 'lodash'

export const BuyCoursePrompt: React.FC<{ accessRequired?: AccessType }> = (
    props
): ReactElement => {
    const logger = useLogger(BuyCoursePrompt.name)
    const isActiveCourseDemo = useRecoilValue(isActiveCourseDemoSelector)
    const purchasedCourses = useRecoilValue(purchasedCoursesSelector)
    const frontendDisplayedCourse = useRecoilValue(
        frontendDisplayedCourseSelector
    )
    const courseInfo = useRecoilValue(
        courseInfoSelectorFamily(frontendDisplayedCourse)
    )
    const accessTypes = useRecoilValue(
        frontendDisplayedCourseAccessTypesSelector
    )

    const hasPurchasedCourseAlready = useMemo((): boolean => {
        return purchasedCourses.includes(frontendDisplayedCourse)
    }, [purchasedCourses, frontendDisplayedCourse])
    const purchasedExpiredCourses = useRecoilValue(
        purchasedCoursesExpiredSelector
    )

    const hasPurchasedCourseExpired = useMemo((): boolean => {
        return purchasedExpiredCourses.includes(frontendDisplayedCourse)
    }, [purchasedExpiredCourses, frontendDisplayedCourse])

    const hasAccess = props.accessRequired
        ? accessTypes.includes(props.accessRequired)
        : true

    const userInfo = useRecoilValue(userInfoStateAtom)
    const [headerText, subText] = useMemo((): [string, string | undefined] => {
        if (!courseInfo) {
            logger.error(
                `User somehow got to the "buy course prompt" without any course info! User info object: ${JSON.stringify(userInfo)}`
            )
            return [
                'Hi there! You currently do not have an active subscription.',
                "Let's fix that.",
            ]
        }

        // has already purchased course but it's either expired or tombstoned
        if (hasPurchasedCourseExpired) {
            return [
                `Hi there! Your purchased course of ${courseInfo.prettyName} has expired.`,
                undefined,
            ]
        }

        // has demo that has expired
        // action buttons already have text, no need to rub it in
        if (isActiveCourseDemo) {
            return ['', '']
        }

        // action buttons already have text, no need to rub it in
        if (props.accessRequired && !hasAccess) {
            return ['', '']
        }

        // this can be hit if the user's course is tombstoned
        return [
            'Hi there! You currently do not have an active subscription.',
            undefined,
        ]
    }, [
        courseInfo,
        hasAccess,
        hasPurchasedCourseExpired,
        isActiveCourseDemo,
        logger,
        props.accessRequired,
        userInfo,
    ])

    return (
        <div className={styles.buyCoursePromptContainer}>
            <div className={styles.buyCoursePromptHeaderText}>{headerText}</div>
            {subText && (
                <div className={styles.buyCoursePromptSubText}>{subText}</div>
            )}
            <ActionCardsContainer>
                {isActiveCourseDemo || hasPurchasedCourseAlready ? (
                    <ActionCard
                        title={
                            hasPurchasedCourseAlready
                                ? 'Access Locked'
                                : 'Demo Expired'
                        }
                        text={
                            <p>
                                {hasPurchasedCourseAlready
                                    ? props.accessRequired
                                        ? `Please purchase the ${ACCESS_TYPE_TO_PRETTY_NAME[
                                              props.accessRequired
                                          ].toLowerCase()} part of the course to continue enjoying our content.`
                                        : `Restore your access with forever pass guarantee.`
                                    : 'Thank you for using our demo!'}
                            </p>
                        }
                        icon={<ExcitedOwl priority={true} />}
                        actionButtons={[]}
                        type={'noAction'}
                    />
                ) : (
                    <ActionCard
                        title={'Try free'}
                        text={
                            <>
                                <p>
                                    Near full access to all our content &
                                    features for 7 days.
                                </p>
                                <p>No payment information required.</p>
                            </>
                        }
                        icon={<ExcitedOwl priority={true} />}
                        actionButtons={[
                            <Link
                                key={'demo'}
                                href={{
                                    pathname: frontendDisplayedCourse
                                        ? COURSE_NAME_TO_UI_LAYOUT_CONSTANT[
                                              frontendDisplayedCourse
                                          ]
                                        : UiLayoutConstant.COURSES_PAGE,
                                }}
                            >
                                <GenericButton
                                    type={GenericButtonType.whiteGhost}
                                    text={"Let's go"}
                                    onClick={() => null}
                                />
                            </Link>,
                        ]}
                        type={'secondary'}
                    />
                )}
                {hasPurchasedCourseExpired ? (
                    <ActionCard
                        title={'Contact us'}
                        text={
                            <p>
                                Please contact us in order to reactivate your
                                subscription with our included forever pass
                                guarantee.
                            </p>
                        }
                        icon={<QuizOwl />}
                        actionButtons={[
                            <Link
                                key={'support'}
                                href={{
                                    pathname: UiLayoutConstant.SUPPORT_PAGE,
                                }}
                            >
                                <GenericButton
                                    type={GenericButtonType.whiteGhost}
                                    text={`Let's Go`}
                                    onClick={null}
                                />
                            </Link>,
                        ]}
                        type={'noAction'}
                    />
                ) : (
                    <ActionCard
                        title={'Buy now'}
                        text={
                            <p>
                                Our pass guarantee means you will never need to
                                pay for the same content twice.
                            </p>
                        }
                        icon={<NotecardOwl />}
                        actionButtons={[
                            <Link
                                key={'buy'}
                                href={
                                    frontendDisplayedCourse
                                        ? {
                                              pathname:
                                                  UiLayoutConstant.COURSE_PAYMENT_PAGE,
                                              query: {
                                                  courseName:
                                                      frontendDisplayedCourse,
                                              },
                                          }
                                        : {
                                              pathname:
                                                  UiLayoutConstant.COURSES_PAGE,
                                          }
                                }
                            >
                                <GenericButton
                                    type={GenericButtonType.whiteGhost}
                                    text={
                                        hasPurchasedCourseAlready
                                            ? `Purchase ${ACCESS_TYPE_TO_PRETTY_NAME[
                                                  props.accessRequired
                                              ].toLowerCase()}`
                                            : 'Buy Now'
                                    }
                                    onClick={null}
                                />
                            </Link>,
                        ]}
                        type={'primary'}
                    />
                )}
            </ActionCardsContainer>
        </div>
    )
}
