import React, { ReactElement, useMemo } from 'react'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import Button from 'antd/lib/button/button'
import Link from 'next/link'
import styles from './PersonalSectionDropdownMenuItem.module.scss'
import { Cogs } from '@/components/static/images/owlBranding/cogs'
import { World } from '@/components/static/images/owlBranding/randoWorld'
import { Present } from '@/components/static/images/owlBranding/present'
import { Bell } from '@/components/static/images/owlBranding/bell'
import { Books } from '@/components/static/images/owlBranding/books'

const getIconForImage = (page: string): ReactElement => {
    switch (page) {
        case 'Account Details':
            return <Cogs />
        case 'Logout':
            return <World />
        case 'Get $250':
        case 'Get $25':
            return <Present />
        case 'Notifications':
            return <Bell />
        case 'Courses':
            return <Books />
    }
}

interface PersonalSectionDropdownMenuItemProps {
    text: string
    href?: UiLayoutConstant
}

export const PersonalSectionDropdownMenuItem: React.FC<
    PersonalSectionDropdownMenuItemProps
> = (props): ReactElement => {
    return useMemo((): ReactElement => {
        const base = (
            <Button className={styles.personalSectionDropdownMenuItem}>
                {props.text}
                <div
                    className={styles.iconContainer}
                    style={{ height: '100%' }}
                >
                    {getIconForImage(props.text)}
                </div>
            </Button>
        )
        if (props.href) {
            return (
                <Link
                    className={styles.personalSectionDropdownMenuItemContainer}
                    href={props.href}
                >
                    {base}
                </Link>
            )
        }

        return (
            <div className={styles.personalSectionDropdownMenuItemContainer}>
                {base}
            </div>
        )
    }, [props.href, props.text])
}
