import {
    getEmptyBaseProgress,
    IBaseProgress,
    INamedBaseProgress,
    mergeBaseProgresses,
    OutlineProgressMap,
} from './OutlineProgressMap.types'
import { getIndexForContentPathAmongSiblings } from 'common/src/ContentTree'
import { OutlineMetadata } from 'common/src/outlines/types'
import {
    hashContentPath,
    ContentPath,
    unHashContentPath,
} from 'common/src/ContentPath'
import { ContentTree } from 'common/src/ContentTree/types'

export const getProgressForContentPath = (
    outlineProgressMap: OutlineProgressMap,
    contentPath: ContentPath
): IBaseProgress => {
    if (!contentPath) {
        return getEmptyBaseProgress()
    }
    return (
        outlineProgressMap[hashContentPath(contentPath)] ||
        getEmptyBaseProgress()
    )
}

export const getProgressesForContentPath = (
    outlineProgressMap: OutlineProgressMap,
    contentPath: ContentPath,
    sorted = false
): INamedBaseProgress[] => {
    const outputArray: INamedBaseProgress[] = []
    const hashedContentPath = hashContentPath(contentPath)
    if (!outlineProgressMap) {
        return []
    }
    for (const [keyHashedContentPath, baseProgress] of Object.entries(
        outlineProgressMap
    )) {
        const keyContentPath = unHashContentPath(keyHashedContentPath)
        if (
            keyHashedContentPath.startsWith(hashedContentPath) &&
            keyContentPath.length == contentPath.length + 1
        ) {
            outputArray.push({
                ...baseProgress,
                contentPath: keyContentPath,
            })
        }
    }

    if (sorted) {
        return outputArray.sort((a, b) => a.index - b.index)
    }

    return outputArray
}

// TODO: Adding a sorted method would require passing in content tree in some fashion...probably not worthwhile
// Returns everything at the base depth for a given content path
export const getBaseProgressesForContentPath = (
    outlineProgressMap: OutlineProgressMap,
    contentPath: ContentPath,
    maxDepth: number
): INamedBaseProgress[] => {
    const outputArray: INamedBaseProgress[] = []
    const hashedContentPath = hashContentPath(contentPath)
    for (const [keyHashedContentPath, baseProgress] of Object.entries(
        outlineProgressMap
    )) {
        const keyContentPath = unHashContentPath(keyHashedContentPath)
        if (
            keyHashedContentPath.startsWith(hashedContentPath) &&
            keyContentPath.length === maxDepth
        )
            outputArray.push({
                ...baseProgress,
                contentPath: keyContentPath,
            })
    }

    return outputArray
}

export const createOutlineProgressMap = (
    outlineMetadataArray: OutlineMetadata[],
    contentTree: ContentTree
): OutlineProgressMap => {
    return new OutlineProgressTreeFactory(
        outlineMetadataArray,
        contentTree
    ).toObj()
}

// This one is a bit different from the others since it needs to be stored in redux.
// As such, the object is instead a plain object, and operations may use a separate content tree (also stored in redux).
class OutlineProgressTreeFactory {
    private readonly progressMap: OutlineProgressMap
    private readonly contentTree: ContentTree

    constructor(
        outlineMetadataArray: OutlineMetadata[],
        contentTree: ContentTree
    ) {
        this.progressMap = {}
        this.contentTree = contentTree

        this.fillEmptyProgressMap(outlineMetadataArray)
    }

    public toObj = (): OutlineProgressMap => this.progressMap

    private fillEmptyProgressMap = (
        outlineMetadataArray: OutlineMetadata[]
    ): void => {
        outlineMetadataArray.forEach((outlineMetadata): void => {
            const progress: IBaseProgress = {
                read: outlineMetadata.isRead ? 1 : 0,
                starredSections: outlineMetadata.starredSections,
                total: 1,
                index: getIndexForContentPathAmongSiblings(
                    outlineMetadata.contentPath,
                    this.contentTree
                ),
            }
            for (let i = 1; i <= outlineMetadata.contentPath.length; i++) {
                this.tryUpdateProgressMap(
                    progress,
                    outlineMetadata.contentPath.slice(0, i)
                )
            }
        })
    }

    private tryUpdateProgressMap = (
        progress: IBaseProgress,
        contentPath: ContentPath
    ): void => {
        const contentPathHash = hashContentPath(contentPath)
        const index = getIndexForContentPathAmongSiblings(
            contentPath,
            this.contentTree
        )
        this.progressMap[contentPathHash] = mergeBaseProgresses(
            this.progressMap[contentPathHash] || getEmptyBaseProgress(),
            progress,
            index
        )
    }
}
