import React, { ReactElement, useCallback } from 'react'
import Modal from 'antd/lib/modal/Modal'
import { secondsToHMS } from '@/utils/dateTime'
import { useRecoilState, useRecoilValue } from 'recoil'
import { studyTimerAtom } from '@/atoms/accountMaintenance/studyTimeRecords'
import { isPausedFromInactivityModalOpenAtom } from '@/atoms/isTimerPaused'
import { updateStudyTimeRecordForDate2 } from '@/api/studyTime'
import { StudyTimeAction } from 'common/src/api/websiteFrontendVsWebsiteBackend/studyTime/types'
import styles from './PausedFromInactivityModal.module.scss'
import { getTimeZone } from '@/components/utils/getTimeZone'
import {
    GenericButton,
    GenericButtonType,
} from '@/components/static/ui/Button/GenericButton/GenericButton'
import { frontendDisplayedCourseSelector } from '@/atoms/accountMaintenance/userInfo'

export const PausedFromInactivityModal: React.FC<
    unknown
> = (): ReactElement => {
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const [
        isPausedFromInactivityModalOpen,
        setIsPausedFromInactivityModalOpen,
    ] = useRecoilState<boolean>(isPausedFromInactivityModalOpenAtom)
    const [studyTimer, setStudyTimer] = useRecoilState(studyTimerAtom)

    const closeModal = useCallback(
        async (unpauseTimer: boolean) => {
            if (unpauseTimer) {
                // effectively acts as a heartbeat because pause due to inactivity does not pause in the database
                await updateStudyTimeRecordForDate2({
                    courseName,
                    action: StudyTimeAction.PLAY_TIMER,
                    timeZone: getTimeZone(),
                    isFirstRequest: false, // does not matter
                })
                setStudyTimer((current) => ({ ...current, isPaused: false }))
            } else {
                await updateStudyTimeRecordForDate2({
                    courseName,
                    action: StudyTimeAction.PAUSE_TIMER,
                    timeZone: getTimeZone(),
                    isFirstRequest: false, // does not matter
                })
                setStudyTimer((current) => ({
                    ...current,
                    isPaused: true,
                    reason: 'MANUAL',
                }))
            }
            setIsPausedFromInactivityModalOpen(false)
        },
        [courseName, setIsPausedFromInactivityModalOpen, setStudyTimer]
    )
    return (
        <Modal
            centered
            open={isPausedFromInactivityModalOpen}
            footer={false}
            onOk={() => closeModal(true)}
            onCancel={() => closeModal(false)}
            className={styles.pausedFromInactivityModal}
        >
            <div className={styles.pausedFromInactivityTitle}>
                Your study timer has been paused due to inactivity. Click{' '}
                <b>Continue Timer</b> to start the timer again.
            </div>
            <div className={styles.pausedFromInactivityText}>
                <div>
                    <b>Time studied today:</b>{' '}
                    {secondsToHMS(studyTimer.studyTimeForMostRecentDate)}
                </div>
                <div>
                    <b>Total time studied:</b>{' '}
                    {secondsToHMS(studyTimer.cumulativeStudyTime)}
                </div>
            </div>
            <div className={styles.pausedFromInactivityModalButton}>
                <GenericButton
                    type={GenericButtonType.primaryDarkBackground}
                    text={'Continue Timer'}
                    onClick={() => closeModal(true)}
                />
            </div>
        </Modal>
    )
}
