import { LoggerSelectorFamily } from '@/atoms/logger'
import { atom, selector } from 'recoil'
import { isPromoCodeValidForUser } from '@/api/payment'
import { FIRST_PURCHASE_AT_JEP_DISCOUNT_CODE } from 'common/src/account/consts'

export const isEligibleForWelcomePromoCodeAtom = atom<boolean>({
    key: 'isEligibleForWelcomePromoCodeAtom',
    default: selector<boolean>({
        key: 'isEligibleForWelcomePromoCode',
        get: async ({ get }) => {
            get(
                LoggerSelectorFamily(isEligibleForWelcomePromoCodeAtom.key)
            ).info(`Retrieving is eligible for welcome promo code`)
            const response = await isPromoCodeValidForUser(
                FIRST_PURCHASE_AT_JEP_DISCOUNT_CODE
            )
            return response?.data?.payload?.isValid || false
        },
    }),
})
