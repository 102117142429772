import { ApiMethod } from 'common/src/api/types'
import { ContentTreeResponse } from 'common/src/api/core/contentTree/responses'
import { CancelToken } from 'axios'
import { GenericContentRequest } from 'common/src/api/core/courses/requests'
import { APIResponseWrapper } from '@/api/types'
import {
    GetContentHTMLDataResponse,
    GetNumContentTypeInContentPathResponse,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/contentTree/responses'
import { ContentVersionPayload } from 'common/src/api/backendToBackend/content/types'
import { GetFormulaSheetEntriesResponse } from 'common/src/formulaSheet/types'
import { callBackend } from '../../server/callBackend'
import { ContentPath } from 'common/src/ContentPath'
import { ContentType } from 'common/src/commentThread/types'
import { GetNumContentTypeInContentPathRequest } from 'common/src/api/websiteFrontendVsWebsiteBackend/contentTree/requests'

const PREFIX = '/wb/contentTree/'

export const getContentTree = async (
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<ContentTreeResponse> => {
    return await callBackend<GenericContentRequest, ContentTreeResponse>(
        PREFIX + 'getContentTree',
        ApiMethod.GET,
        { courseName },
        cancelToken
    )
}

export const getContentHTMLData = async (
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<GetContentHTMLDataResponse> => {
    return await callBackend<GenericContentRequest, GetContentHTMLDataResponse>(
        PREFIX + 'getContentHTMLData',
        ApiMethod.GET,
        { courseName },
        cancelToken
    )
}

export const getContentVersion = async (
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<ContentVersionPayload> => {
    return await callBackend<GenericContentRequest, ContentVersionPayload>(
        PREFIX + 'getContentVersion',
        ApiMethod.GET,
        { courseName },
        cancelToken
    )
}

export const getFormulaSheetEntries = async (
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<GetFormulaSheetEntriesResponse> => {
    return await callBackend<
        GenericContentRequest,
        GetFormulaSheetEntriesResponse
    >(
        PREFIX + 'getFormulaSheetEntries',
        ApiMethod.GET,
        { courseName },
        cancelToken
    )
}

export const getNumContentTypeInContentPath = async (
    courseName: string,
    contentPath: ContentPath,
    contentType: ContentType.NOTECARD | ContentType.PRACTICE_PROBLEM,
    cancelToken: CancelToken = null
): APIResponseWrapper<GetNumContentTypeInContentPathResponse> => {
    return await callBackend<
        GetNumContentTypeInContentPathRequest,
        GetNumContentTypeInContentPathResponse
    >(
        PREFIX + 'getNumContentTypeInContentPath',
        ApiMethod.GET,
        { courseName, contentPath, contentType },
        cancelToken
    )
}
