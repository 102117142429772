import { useWindowSize } from '@/hooks/useWindowSize'
import { LOGGED_IN_MOBILE_MODE_BREAKPOINT_IN_PX } from '@/hooks/static/useIsMobileMode'

const LOGGED_IN_SMALL_DESKTOP_MODE_BREAKPOINT_IN_PX = 1400 // show condensed sidebar options in desktop mode
export const useIsSmallDesktopMode = (isLoggedIn: boolean): boolean => {
    const { width } = useWindowSize()
    if (isLoggedIn) {
        return (
            width <= LOGGED_IN_SMALL_DESKTOP_MODE_BREAKPOINT_IN_PX &&
            width > LOGGED_IN_MOBILE_MODE_BREAKPOINT_IN_PX
        )
    } else {
        return false
    }
}
