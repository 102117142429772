export enum NotificationKeys {
    EMAIL_CONFIRMATION_RESEND = 'EMAIL_CONFIRMATION_RESEND',
    EMAIL_CONFIRMATION_RESEND_SUCCESS = 'EMAIL_CONFIRMATION_RESEND_SUCCESS',
    CHANGE_PASSWORD_WARNING = 'CHANGE_PASSWORD_WARNING',
    CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
    RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
    INSUFFICIENT_NOTECARDS = 'INSUFFICIENT_NOTECARDS',
    NO_PRACTICE_PROBLEMS_SELECTED = 'NO_PRACTICE_PROBLEMS_SELECTED',
    NO_ACTIVE_COURSE = 'NO_ACTIVE_COURSE',
    NO_NOTECARD_HISTORY = 'NO_NOTECARD_HISTORY',
    NO_PRACTICE_PROBLEM_HISTORY = 'NO_PRACTICE_PROBLEM_HISTORY',
    EMAIL_SUCCESSFULLY_UPDATED = 'EMAIL_SUCCESSFULLY_UPDATED',
    PASSWORD_SUCCESSFULLY_UPDATED = 'PASSWORD_SUCCESSFULLY_UPDATED',
    DISPLAY_NAME_SUCCESSFULLY_UPDATED = 'DISPLAY_NAME_SUCCESSFULLY_UPDATED',
    PASSWORD_RECOVERY_EMAIL_SUCCESSFULLY_SENT = 'PASSWORD_RECOVERY_EMAIL_SUCCESSFULLY_SENT',
    SUPPORT_MESSAGE_SUCCESSFULLY_SENT = 'SUPPORT_MESSAGE_SUCCESSFULLY_SENT',
    ADD_NOTECARD_TO_NOTECARD_SET_SUCCESS = 'ADD_NOTECARD_TO_NOTECARD_SET_SUCCESS',
    FEEDBACK_EMAIL_SUCCESSFULLY_SENT = 'FEEDBACK_EMAIL_SUCCESSFULLY_SENT',
    MUST_LOGIN_TO_CONTINUE = 'MUST_LOGIN_TO_CONTINUE',
    MUST_LOGIN_TO_DEMO = 'MUST_LOGIN_TO_DEMO',
    MUST_LOGIN_TO_PURCHASE = 'MUST_LOGIN_TO_PURCHASE',
    JOINED_WAITLIST = 'JOINED_WAITLIST',
    ERROR_DOWNLOADING_HTML = 'ERROR_DOWNLOADING_HTML',
    ERROR_DOWNLOADING_FORMULA_SHEET = 'ERROR_DOWNLOADING_FORMULA_SHEET',
    ERROR_BUILDING_EXAM = 'ERROR_BUILDING_EXAM',
    ERROR_BUILDING_QUIZ = 'ERROR_BUILDING_QUIZ',
    ALREADY_ON_WAITLIST = 'ALREADY_ON_WAITLIST',
    ERROR_JOINING_WAITLIST = 'ERROR_JOINING_WAITLIST',
    ERROR_QUERYING_CONTENT = 'ERROR_QUERYING_CONTENT',
    ERROR_AUTOGRADING_PRACTICE_PROBLEM = 'ERROR_AUTOGRADING_PRACTICE_PROBLEM',
    STUDY_TIMER_PAUSED = 'STUDY_TIMER_PAUSED',
    STUDY_TIMER_UNPAUSED = 'STUDY_TIMER_UNPAUSED',
    TWO_NEW_NOTECARDS = 'TWO_NEW_NOTECARDS',
    ERROR_BUILDING_SPECIFIC_PROBLEMS_PAGE = 'ERROR_BUILDING_SPECIFIC_PROBLEMS_PAGE',
    ERROR_BUILDING_SPECIFIC_NOTECARDS_PAGE = 'ERROR_BUILDING_SPECIFIC_NOTECARDS_PAGE',
    ERROR_BUILDING_NOTECARD_DECK = 'ERROR_BUILDING_NOTECARD_DECK',
    STUDY_TIME_GOAL_UPDATED = 'STUDY_TIME_GOAL_UPDATED',
    STUDY_TIME_UPDATED = 'STUDY_TIME_UPDATED',
    CANT_EDIT_CURRENT_DAY_STUDY_TIME = 'CANT_EDIT_CURRENT_DAY_STUDY_TIME',
    EMAIL_NOTIFICATION_SUBSCRIPTION_STATUS = 'EMAIL_NOTIFICATION_SUBSCRIPTION_STATUS',
    AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
    PROMO_CODE_ERROR = 'PROMO_CODE_ERROR',
    PAYMENT_ERROR = 'PAYMENT_ERROR',
    ERROR_BUILDING_NOTECARD_SET = 'ERROR_BUILDING_NOTECARD_SET',
    ERROR_RETRIEVING_NOTECARD_SET = 'ERROR_RETRIEVING_NOTECARD_SET',
    CONTENT_QUERY_TAKING_TOO_LONG = 'CONTENT_QUERY_TAKING_TOO_LONG',
    AUTOGRADING_TAKING_TOO_LONG = 'AUTOGRADING_TAKING_TOO_LONG',
    LOADING_TAKING_TOO_LONG = 'LOADING_TAKING_TOO_LONG',
    NOTECARD_LEARNING_OBJECTIVE = 'NOTECARD_LEARNING_OBJECTIVE',
    NOTECARD_HINT = 'NOTECARD_HINT',
    UNSUBSCRIBE_LOGIN_REQUIRED = 'UNSUBSCRIBE_LOGIN_REQUIRED',
    UNSUBSCRIBE_ERROR = 'UNSUBSCRIBE_ERROR',
    UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS',
    PURCHASE_PRICE_IS_ZERO = 'PURCHASE_PRICE_IS_ZERO',
    SUCCESSFULLY_COPIED_INVITE_CODE = 'SUCCESSFULLY_COPIED_INVITE_CODE',
    ERROR_CREATING_DEMO = 'ERROR_CREATING_DEMO',
    ERROR_SETTING_NEXT_SITTING_DATE = 'ERROR_SETTING_NEXT_SITTING_DATE',
    NEXT_SITTING_DATE_UPDATED = 'NEXT_SITTING_DATE_UPDATED',
    INVALID_MAGIC_TEXT_BOX = 'INVALID_MAGIC_TEXT_BOX',
    SUCCESFULLY_COMPLETED_NOTECARD_REVIEWS = 'SUCCESFULLY_COMPLETED_NOTECARD_REVIEWS',
    SUCCESSFULLY_COMPLETED_LESSON = 'SUCCESSFULLY_COMPLETED_LESSON',
    SYNC_ISSUE = 'SYNC_ISSUE',
}
