import React, {
    PropsWithChildren,
    ReactElement,
    useState,
    useEffect,
} from 'react'
import styles from './FadeInOnMount.module.scss'

export const FadeInOnMount: React.FC<
    PropsWithChildren<{ fadeInDurationInMs: number; className?: string }>
> = (props): ReactElement => {
    const [isVisible, setIsVisible] = useState(false) // Start as hidden

    // When component mounts, make it visible (trigger entrance animation)
    useEffect(() => {
        setIsVisible(true)
    }, [])

    return (
        <span
            className={`${styles.fadeInOnMount} ${
                isVisible ? styles.visible : styles.notYetVisible
            } ${props.className}`}
            style={{
                transition: `opacity ${props.fadeInDurationInMs}ms ease`,
            }}
        >
            {props.children}
        </span>
    )
}
