import { useRecoilValue } from 'recoil'
import React, { PropsWithChildren, ReactElement, useMemo } from 'react'
import { BuyCoursePrompt } from '@/components/utils/BuyCoursePrompt/BuyCoursePrompt'
import { frontendDisplayedCourseAccessTypesSelector } from '@/atoms/accountMaintenance/userInfo'
import {
    PAGE_TO_REQUIRED_ACCESS_TYPE,
    UiLayoutConstant,
} from '@/globals/uiLayoutConstant'
import { useRouter } from 'next/router'

import { cleanPath } from '@/utils/paths/cleanPath'

export const AccessWrapper: React.FC<PropsWithChildren> = (
    props
): ReactElement => {
    const router = useRouter()

    const cleanedPage = useMemo((): UiLayoutConstant => {
        const page = router.pathname as UiLayoutConstant
        return cleanPath(page) as UiLayoutConstant
    }, [router.pathname])
    const accessRequired = PAGE_TO_REQUIRED_ACCESS_TYPE[cleanedPage]

    const accessTypes = useRecoilValue(
        frontendDisplayedCourseAccessTypesSelector
    )
    const hasAccess = accessRequired
        ? accessTypes.includes(accessRequired)
        : true
    if (!hasAccess) {
        return <BuyCoursePrompt accessRequired={accessRequired} />
    }

    return <>{props.children}</>
}
