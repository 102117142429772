import { googleLogin } from '@/api/authentication'
import {
    GoogleLogin,
    GoogleLoginResponse,
    GoogleLoginResponseOffline,
} from 'react-google-login'
import React, { ReactElement, useCallback, useState } from 'react'
import styles from './GoogleLoginComponent.module.scss'
import notification from 'antd/lib/notification'
import { isGoogleResponseOffline } from '@/components/static/Authenticator/GoogleLoginComponent/GoogleLoginComponent.types'
import { frontendLogin } from '@/auth/frontendLogin'
import {
    clearAuthenticationError,
    handleAuthenticationError,
} from '@/components/static/Authenticator/handleAuthenticationError'
import { NotificationKeys } from '@/utils/notificationKeys'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import {
    CTAButton,
    CTAButtonType,
} from '@/components/static/ui/Button/CTAButton/CTAButton'
import {
    handleAuthenticatorClick,
    HandleAuthenticatorClickPayload,
} from '@/components/static/Authenticator/utils'
import { useRouter } from 'next/router'
import { buildFrontendLogger } from '@/logging/FrontendLogger'
import { CourseConfigType } from 'common/src/courseConfig'

const handleGoogleFailure = async (response: {
    error: string
}): Promise<void> => {
    notification.error({
        message: 'Google sign-in failure',
        description: `Something went wrong trying to sign in with Google: ${response.error}. Please try again.`,
        duration: 0,
        onClick: () => clearAuthenticationError(),
        className: reusableCssClass.clickMe,
        key: NotificationKeys.AUTHENTICATION_ERROR,
    })
}

interface GoogleLoginComponentProps {
    buttonText: string
    setIsModalVisible: (isModalVisible: boolean) => void
    payload: HandleAuthenticatorClickPayload
    courseConfigType: CourseConfigType
}

const logger = buildFrontendLogger('GoogleLoginComponent')
export const GoogleLoginComponent: React.FC<GoogleLoginComponentProps> = (
    props
): ReactElement => {
    const router = useRouter()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const handleGoogleSuccess = useCallback(
        async (
            googleResponse: GoogleLoginResponse | GoogleLoginResponseOffline
        ): Promise<void> => {
            setIsLoading(true)
            clearAuthenticationError()

            if (isGoogleResponseOffline(googleResponse)) {
                handleAuthenticationError('Google Response Offline', {
                    message: 'Issue logging in',
                    description:
                        'Something went wrong trying to login. Please refresh the page and try again.',
                })
                logger.error(
                    `Expected a google login response, but got google login response offline: ${JSON.stringify(
                        googleResponse,
                        null,
                        2
                    )}`
                )
                setIsLoading(false)
                return
            }
            const tokenID = googleResponse.getAuthResponse().id_token
            const response = await googleLogin(tokenID, props.courseConfigType)
            if (!response.data.success) {
                handleAuthenticationError(response.data.error, {
                    message: 'Issue logging in',
                    description:
                        'Something went wrong trying to login. Please refresh the page and try again.',
                })
            } else {
                await frontendLogin(response.data.payload)
                await handleAuthenticatorClick(props.payload, router, null)
                props.setIsModalVisible(false)
            }
            setIsLoading(false)
        },
        [props, router]
    )

    return (
        <div className={`${styles.googleLoginContainer} container`}>
            <div className={'row'}>
                <div className={'column btn-container'}>
                    <div className={styles.googleLoginButtonContainer}>
                        <GoogleLogin
                            clientId={
                                process.env.NEXT_PUBLIC_GOOGLE_AUTH_CLIENT_ID
                            }
                            onSuccess={handleGoogleSuccess}
                            onFailure={handleGoogleFailure}
                            cookiePolicy={'single_host_origin'}
                            render={(renderProps) => (
                                <CTAButton
                                    onClick={renderProps.onClick}
                                    type={CTAButtonType.primaryDarkBackground}
                                    disabled={false}
                                    // disabled={renderProps.disabled}
                                    text={props.buttonText}
                                    loading={isLoading}
                                />
                            )}
                            responseType={'permission id_token'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
