import { ContentType } from 'common/src/commentThread/types'
import React, { ReactElement, useCallback, useMemo } from 'react'
import styles from './RecentlySearchedTopic.module.scss'
import { CONTENT_TYPE_TO_SEARCH_STRINGS } from '@/components/search/SearchModal/RecentlySearchedTopics/RecentlySearchedTopic/RecentlySearchedTopicInfo'
import { Padlock } from '@/components/static/images/owlBranding/padlock'
import { ExamName } from 'common/src/courseConfig'

const CONTENT_TYPE_TO_RENDER_STRING: Record<Partial<ContentType>, string> = {
    [ContentType.NOTECARD]: 'Notecards',
    [ContentType.OUTLINE]: 'Outlines',
    [ContentType.PRACTICE_PROBLEM]: 'Practice Questions',
    [ContentType.SUMMARY]: 'Outlines', // not used
}

interface RecentlySearchedTopicProps {
    courseName: string
    contentType: ContentType
    setSearchText: (text: string) => void
    setContentTypeFoci: (contentType: ContentType[]) => void
    isAccessLocked: boolean
}

export const RecentlySearchedTopic: React.FC<RecentlySearchedTopicProps> = (
    props
): ReactElement => {
    const onClickHandler = useCallback(
        (searchText: string, contentType: ContentType) => {
            props.setSearchText(searchText)
            props.setContentTypeFoci([contentType])
        },
        [props]
    )
    const searchOptions = useMemo((): ReactElement[] => {
        return CONTENT_TYPE_TO_SEARCH_STRINGS[props.courseName as ExamName][
            props.contentType
        ].map((searchText, idx) => {
            return (
                <div
                    className={`${styles.contentTypeSearchOption} ${
                        props.isAccessLocked ? styles.isLocked : ''
                    }`}
                    key={`${props.courseName}_${props.contentType}_${idx}`}
                    onClick={() =>
                        onClickHandler(searchText, props.contentType)
                    }
                >
                    {searchText}
                </div>
            )
        })
    }, [
        onClickHandler,
        props.contentType,
        props.courseName,
        props.isAccessLocked,
    ])

    return (
        <div className={styles.recentlySearchedTopicContainer}>
            <div
                className={`${styles.contentTypeTitleContainer} ${
                    props.isAccessLocked ? styles.isLocked : ''
                }`}
            >
                {props.isAccessLocked && <Padlock />}
                {CONTENT_TYPE_TO_RENDER_STRING[props.contentType]}
            </div>
            <div className={styles.searchOptions}>{searchOptions}</div>
        </div>
    )
}
