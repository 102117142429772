import { logger } from '@/logging/FrontendLogger'
import { setRecoilExternalState } from '@/components/RecoilExternalStatePortal'
import Router from 'next/router'
import { UserInfoResponse } from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/responses'
import { userInfoStateAtom } from '@/atoms/accountMaintenance/userInfo'
import { setIsLoggedIn } from '@/auth/types'

export const frontendLogin = async (
    userInfoResponse: UserInfoResponse,
    shouldAutoRoute = false
): Promise<void> => {
    logger.info(`Frontend logging in ${JSON.stringify(userInfoResponse)}`)
    setRecoilExternalState(userInfoStateAtom, userInfoResponse)
    setIsLoggedIn(true)

    if (shouldAutoRoute) {
        await Router.push({
            pathname: Router.pathname,
            query: Router.query,
        })
    }
}
