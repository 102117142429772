import React, { PropsWithChildren, ReactElement, Suspense } from 'react'
import { RecoilRoot } from 'recoil'
import { RecoilExternalStatePortal } from '@/components/RecoilExternalStatePortal'
import { SomethingWentWrong } from '@/components/static/SomethingWentWrong/SomethingWentWrong'
import {
    FullScreenLoadingComponent,
    LoadingComponent,
} from '@/components/static/LoadingComponent/LoadingComponent'
import { LoadingType } from '@/components/static/images/owlBranding/loadingImage'
import { ErrorBoundary } from '@/components/static/ErrorBoundary/ErrorBoundary'
import styles from '@/components/layout/Frame/Frame.module.scss'
import { NavigationLoggedIn } from '@/components/layout/Navigation/NavigationLoggedIn'
import { Footer } from '@/components/layout/Footer/Footer'
import dynamic from 'next/dynamic'
import { LoggedInHeader } from '@/components/LoggedInHeader/LoggedInHeader'
import { AccessWrapper } from '@/components/DashboardPage/AccessWrapper'
import { LoggedInHeaderAnnouncementWrapper } from '@/components/Header/HeaderAnnouncement/LoggedInHeaderAnnouncementWrapper/LoggedInHeaderAnnouncementWrapper'

// for logged in users
const LayoutDynamic: React.FC<PropsWithChildren<unknown>> = (
    props
): ReactElement => {
    return (
        <RecoilRoot>
            <ErrorBoundary
                fallback={
                    <SomethingWentWrong additionalDescription={'top-most'} />
                }
            >
                <Suspense
                    fallback={
                        <FullScreenLoadingComponent
                            loadingType={LoadingType.owl}
                        />
                    }
                >
                    <ErrorBoundary
                        fallback={
                            <SomethingWentWrong
                                additionalDescription={'just inside recoil'}
                            />
                        }
                    >
                        <div className={`${styles.frameContainer}`}>
                            <LoggedInHeaderAnnouncementWrapper />
                            <div className={`${styles.frameContainerLoggedIn}`}>
                                <ErrorBoundary
                                    fallback={
                                        <SomethingWentWrong
                                            additionalDescription={
                                                'just inside frame'
                                            }
                                        />
                                    }
                                >
                                    <ErrorBoundary fallback={<div />}>
                                        <NavigationLoggedIn />
                                    </ErrorBoundary>

                                    <div
                                        className={`${styles.frameBodyLoggedIn}`}
                                    >
                                        <ErrorBoundary fallback={<div />}>
                                            <LoggedInHeader />
                                        </ErrorBoundary>

                                        <ErrorBoundary
                                            fallback={
                                                <SomethingWentWrong
                                                    additionalDescription={
                                                        'inner-most'
                                                    }
                                                />
                                            }
                                        >
                                            <React.Suspense
                                                fallback={
                                                    <LoadingComponent
                                                        loadingType={
                                                            LoadingType.skeleton
                                                        }
                                                    />
                                                }
                                            >
                                                <AccessWrapper>
                                                    {props.children}
                                                </AccessWrapper>
                                            </React.Suspense>
                                        </ErrorBoundary>
                                    </div>
                                </ErrorBoundary>
                            </div>
                        </div>
                        <ErrorBoundary fallback={<div />}>
                            <Footer />
                        </ErrorBoundary>
                    </ErrorBoundary>
                </Suspense>
            </ErrorBoundary>
            <RecoilExternalStatePortal />
        </RecoilRoot>
    )
}

// prevents SSR
export default dynamic(() => Promise.resolve(LayoutDynamic), {
    ssr: false,
})
