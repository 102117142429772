import React, { ReactElement, useCallback, useEffect } from 'react'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import Link from 'next/link'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { ErrorComponent } from '@/components/static/ErrorBoundary/ErrorComponent/ErrorComponent'

export const SomethingWentWrong: React.FC<{
    additionalDescription?: string
}> = (props): ReactElement => {
    // note: doesn't work 100%
    const reload = useCallback(() => {
        window.location.reload()
    }, [])
    useEffect(() => {
        window.addEventListener('online', reload)
        return () => window.removeEventListener('online', reload)
    }, [reload])

    return (
        <ErrorComponent
            title={<>Oops. Something went wrong.</>}
            text={
                <>
                    Fret not - we will soon be looking into this issue. Feel
                    free to send some feedback if this keeps happening, but
                    otherwise let&apos;s go&nbsp;
                    <Link
                        href={{ pathname: UiLayoutConstant.SPLASH_PAGE }}
                        className={reusableCssClass.clickableText}
                        onClick={() => {
                            setTimeout(() => {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                window.location = window.location.origin
                            }, 0)
                        }}
                    >
                        home
                    </Link>{' '}
                    and try again from there.
                </>
            }
            sourceTextForErrorLog={`Something Went Wrong${
                props.additionalDescription
                    ? ': ' + props.additionalDescription
                    : ''
            }`}
        />
    )
}
