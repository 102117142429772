import { useCallback, useEffect, useMemo, useState } from 'react'
import { getPrimarySearchResults } from '@/api/search'
import { SearchContext } from 'common/src/elasticSearch/types'
import { WBSearchResponse } from 'common/src/elasticSearch/responses'
import { useRecoilValue } from 'recoil'

import { frontendDisplayedCourseSelector } from '@/atoms/accountMaintenance/userInfo'
import { debounce } from 'lodash'

export const useDebouncedSearch = (
    searchText: string,
    searchContexts: SearchContext[]
): [WBSearchResponse | null, boolean] => {
    const [searchResults, setSearchResults] = useState<WBSearchResponse | null>(
        null
    )
    const activeCourse = useRecoilValue(frontendDisplayedCourseSelector)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const debouncedSearch = useMemo(
        () =>
            debounce(
                async (
                    searchText: string,
                    searchContexts: SearchContext[],
                    activeCourse: string
                ) => {
                    setIsLoading(true)
                    const response = await getPrimarySearchResults(
                        searchText,
                        searchContexts,
                        activeCourse
                    )
                    setSearchResults(response.data.payload)
                    setIsLoading(false)
                },
                1_000,
                {
                    maxWait: 1_000,
                }
            ),
        []
    )
    const doSearchDebounced = useCallback(debouncedSearch, [debouncedSearch])

    useEffect(() => {
        if (!searchText) {
            setSearchResults(null)
            return
        }
        doSearchDebounced(searchText, searchContexts, activeCourse)
    }, [searchText, doSearchDebounced, searchContexts, activeCourse])

    return [searchResults, isLoading]
}
