import {
    getEmptyContentTreeMap,
    isReturnFromTraversalBaseMap,
    isReturnFromTraversalBaseType,
    traverseMapForPath,
} from 'common/src/ContentTree'
import {
    ContentIDToNameMap,
    ContentTree,
    ContentTreeMapTraversalReturn,
} from 'common/src/ContentTree/types'
import { ContentPath } from 'common/src/ContentPath'
import {
    BaseSetCreator,
    SelectedTopicMapOperation,
    SetTopicMap,
} from '@/frontendLogic/SetCreator/SetCreator.types'
import produce from 'immer'
import { walkContentTreeMap } from 'common/src/ContentTree/contentTreeMap'
import assert from 'assert'
import { cloneDeep } from 'lodash'

export function createBaseSetCreator(
    contentTree: ContentTree,
    contentArray: StarrableContentWithID[]
): BaseSetCreator {
    return new SetCreatorFactory(contentTree, contentArray).toObj()
}

export const addContentPath = produce(
    <T extends BaseSetCreator>(
        setCreator: T,
        contentPath: ContentPath
    ): void => {
        alterSelectedTopicMapInPlace(
            setCreator,
            contentPath,
            SelectedTopicMapOperation.add
        )
    }
)

export const deleteContentPath = produce(
    <T extends BaseSetCreator>(
        setCreator: T,
        contentPath: ContentPath
    ): void => {
        alterSelectedTopicMapInPlace(
            setCreator,
            contentPath,
            SelectedTopicMapOperation.delete
        )
    }
)

export const selectAllTopicMap = produce(
    <T extends BaseSetCreator>(setCreator: T): void => {
        alterSelectedTopicMapInPlace(
            setCreator,
            [],
            SelectedTopicMapOperation.add
        )
    }
)

export const isContentPathSelected = (
    selectedTopicMap: SetTopicMap,
    contentPath: ContentPath
): boolean => {
    const selectedSubtopicNode = traverseMapForPath(
        selectedTopicMap,
        contentPath
    )
    if (isReturnFromTraversalBaseType(selectedSubtopicNode)) {
        return selectedSubtopicNode.size > 0
    } else {
        return [...selectedSubtopicNode.keys()].some((key) =>
            isContentPathSelected(selectedTopicMap, [...contentPath, key])
        )
    }
}

export const getSelectedChildrenForContentPath = (
    selectedTopicMap: SetTopicMap,
    contentPath: ContentPath
): string[] => {
    const children = getChildrenForContentPath(selectedTopicMap, contentPath)
    return children.filter((key: string): boolean => {
        return isContentPathSelected(selectedTopicMap, [...contentPath, key])
    })
}

export const getChildrenForContentPath = (
    topicMap: SetTopicMap,
    contentPath: ContentPath
): string[] => {
    const node = traverseMapForPath(topicMap, contentPath)
    if (isReturnFromTraversalBaseType(node)) {
        return [...node.values()]
    } else {
        return [...node.keys()]
    }
}

export const getSelectedIDsForTopicsOnly = (
    setCreator: BaseSetCreator,
    contentPath: ContentPath = []
): string[] => {
    return getIDsForTopicMap(setCreator.selectedTopicMap, contentPath)
}

export const getAllIDsForTopicsOnly = (
    setCreator: BaseSetCreator,
    contentPath: ContentPath = []
): string[] => {
    return getIDsForTopicMap(setCreator.topicMap, contentPath)
}

export const getIDsForTopicMap = (
    topicMap: SetTopicMap,
    contentPath: ContentPath = []
): string[] => {
    const node = traverseMapForPath(topicMap, contentPath)
    if (isReturnFromTraversalBaseType(node)) {
        return Array.from(node.values())
    } else {
        return [...node.keys()]
            .map((key) => getIDsForTopicMap(topicMap, [...contentPath, key]))
            .flat()
    }
}

export const getDescendantsForContentPathOfSpecificDepth = (
    topicMap: SetTopicMap,
    contentPath: ContentPath,
    depth: number
): string[] => {
    const descendants: string[] = []
    walkContentTreeMap(
        topicMap,
        contentPath,
        (node: Set<string>, path: ContentPath) => {
            if (path.length === depth) descendants.push(path[path.length - 1])
        },
        (node: SetTopicMap, path: ContentPath) => {
            if (path.length === depth) descendants.push(path[path.length - 1])
        }
    )
    return descendants
}

export const getSelectedDescendantsForContentPathOfSpecificDepth = (
    selectedTopicMap: SetTopicMap,
    contentPath: ContentPath,
    depth: number
): string[] => {
    const descendants: string[] = []
    walkContentTreeMap(
        selectedTopicMap,
        contentPath,
        (node: Set<string>, path: ContentPath) => {
            if (
                path.length === depth &&
                isContentPathSelected(selectedTopicMap, path)
            )
                descendants.push(path[path.length - 1])
        },
        (node: SetTopicMap, path: ContentPath) => {
            if (
                path.length === depth &&
                isContentPathSelected(selectedTopicMap, path)
            )
                descendants.push(path[path.length - 1])
        }
    )
    return descendants
}

const alterSelectedTopicMapInPlace = (
    baseSetCreator: BaseSetCreator,
    contentPath: ContentPath,
    operation: SelectedTopicMapOperation
): void => {
    const subtopicNode = traverseMapForPath(
        baseSetCreator.topicMap,
        contentPath
    )
    const selectedSubtopicParentNode = traverseMapForPath(
        baseSetCreator.selectedTopicMap,
        contentPath.slice(0, contentPath.length - 1)
    )
    if (isReturnFromTraversalBaseType(subtopicNode)) {
        if (isReturnFromTraversalBaseMap(selectedSubtopicParentNode)) {
            switch (operation) {
                case SelectedTopicMapOperation.add:
                    selectedSubtopicParentNode.set(
                        contentPath[contentPath.length - 1],
                        subtopicNode
                    )
                    return
                case SelectedTopicMapOperation.delete:
                    selectedSubtopicParentNode.set(
                        contentPath[contentPath.length - 1],
                        getDefaultFillObj()
                    )
            }
        } else {
            throw new Error(
                `Mismatch between topic and selected topic nodes, subtopic node: ${JSON.stringify(
                    subtopicNode,
                    null,
                    2
                )}, selected subtopic node: ${JSON.stringify(
                    selectedSubtopicParentNode,
                    null,
                    2
                )}`
            )
        }
    } else {
        for (const key of subtopicNode.keys()) {
            alterSelectedTopicMapInPlace(
                baseSetCreator,
                [...contentPath, key],
                operation
            )
        }
    }
}

const getDefaultFillObj = (): Set<string> => new Set<string>()
type StarrableContentWithID = {
    id: string
    isStarred: boolean
    contentPath: ContentPath
}

class SetCreatorFactory {
    public readonly topicMap: SetTopicMap
    public readonly selectedTopicMap: SetTopicMap
    public readonly contentIDToNameMap: ContentIDToNameMap
    public readonly contentArray: StarrableContentWithID[]

    public readonly depth: number

    constructor(
        contentTree: ContentTree,
        contentArray: StarrableContentWithID[]
    ) {
        const { contentTreeMap, depth, contentIDToNameMap } =
            getEmptyContentTreeMap<Set<string>>(contentTree, getDefaultFillObj)
        this.topicMap = contentTreeMap
        this.depth = depth
        this.contentIDToNameMap = contentIDToNameMap

        this.selectedTopicMap = cloneDeep(this.topicMap)
        this.contentArray = contentArray
        this.fillSelectedTopicMap()
    }

    public toObj = (): BaseSetCreator => ({
        topicMap: this.topicMap,
        selectedTopicMap: this.selectedTopicMap,
        depth: this.depth,
        contentIDToNameMap: this.contentIDToNameMap,
        starredSet: SetCreatorFactory.createStarredSet(this.contentArray),
    })

    private static createStarredSet = (
        contentArray: StarrableContentWithID[]
    ): Set<string> => {
        const starredSet = new Set<string>()
        contentArray.forEach((content): void => {
            if (content.isStarred) starredSet.add(content.id)
        })
        return starredSet
    }

    private fillSelectedTopicMap = (): void => {
        this.contentArray.forEach((content) => {
            let traversal:
                | ContentTreeMapTraversalReturn<Set<string>>
                | undefined = this.selectedTopicMap
            let currentIndex = 0
            while (traversal && !isReturnFromTraversalBaseType(traversal)) {
                traversal = traversal.get(content.contentPath[currentIndex])
                currentIndex++
            }
            assert(traversal)
            ;(traversal as Set<string>).add(content.id)
        })
    }
}
