import { useRecoilValue, useSetRecoilState } from 'recoil'
import { sessionDataAtom } from '@/atoms/sessionData'
import { userInfoStateAtom } from '@/atoms/accountMaintenance/userInfo'
import { useEffect } from 'react'
import { getSessionID } from '@/api/notLoggedIn'

export const useSessionData = (): void => {
    const setSessionData = useSetRecoilState(sessionDataAtom)

    // set user info state items
    const userInfoState = useRecoilValue(userInfoStateAtom)
    useEffect(() => {
        if (!userInfoState) {
            return
        }
        setSessionData((sessionData) => ({
            ...sessionData,
            userID: userInfoState.userID,
            displayName: userInfoState.displayName,
            email: userInfoState.email,
            courseID: userInfoState.frontendDisplayedCourseInfo?.id,
            courseName: userInfoState.frontendDisplayedCourseInfo?.name,
        }))
    }, [setSessionData, userInfoState])

    // set session id
    useEffect(() => {
        getSessionID().then((response) => {
            if (!response.data.payload) {
                return
            }
            setSessionData((sessionData) => ({
                ...sessionData,
                sessionID: response.data.payload.sessionID,
            }))
        })
    }, [setSessionData])
}
