import React, { ReactElement, useState, useCallback } from 'react'
import styles from './Feedback.module.scss'
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { reusableCssVar } from '@/utils/reusableCssClasses'

export const Feedback: React.FC<{
    onFeedbackClick: (isPositive: boolean) => void
}> = (props): ReactElement => {
    const [hasFeedbackBeenCollected, setHasFeedbackBeenCollected] =
        useState<boolean>(false)

    const handleClick = useCallback(
        (isPositive: boolean): void => {
            setHasFeedbackBeenCollected(true)
            props.onFeedbackClick(isPositive)
        },
        [props]
    )

    if (hasFeedbackBeenCollected) return null
    return (
        <div className={styles.feedbackContainer}>
            <div className={styles.text}>Was this helpful?</div>
            <div className={styles.options}>
                <CheckCircleTwoTone
                    twoToneColor={reusableCssVar.successColor}
                    onClick={() => handleClick(true)}
                />
                <CloseCircleTwoTone
                    twoToneColor={reusableCssVar.errorColor}
                    onClick={() => handleClick(false)}
                />
            </div>
        </div>
    )
}
