import { Component, ErrorInfo, ReactNode } from 'react'
import {
    ErrorBoundaryProps,
    ErrorBoundaryState,
} from '@/components/static/ErrorBoundary/ErrorBoundary.types'
import { logger } from '@/logging/FrontendLogger'

export class ErrorBoundary extends Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = { error: null, errorInfo: null }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        })

        // You can also log error messages to an error reporting service here
        logger.reactError(error, errorInfo)
    }

    render(): ReactNode {
        if (this.state.errorInfo) {
            return this.props.fallback
        }

        // Normally, just render children
        return this.props.children
    }
}
