import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { registerEvent } from '@/api/analyticsEvent'
import {
    GeneralAnalyticsEventType,
    PaidUserAnalyticsEventType,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/analyticsEvent/types'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { buildFrontendLogger } from '@/logging/FrontendLogger'

const INITIAL_ROUTE_VALUE = 'INITIAL_ROUTE_VALUE'

const logger = buildFrontendLogger('useRouteLogger')
let latestRoute: string = INITIAL_ROUTE_VALUE
export const useRouteLogger = (): void => {
    const router = useRouter()
    // don't use state here as it causes SSR hydration problems. This is a singleton so it should do fine as is
    // const [latestRoute, setLatestRoute] = useState<string>(INITIAL_ROUTE_VALUE)

    useEffect(() => {
        if (latestRoute === router.asPath) return

        if (latestRoute === INITIAL_ROUTE_VALUE) {
            logger.info(`Starting at: ${router.asPath}`)
        } else {
            logger.info(`Navigating ${latestRoute} -> ${router.asPath}`)
        }

        registerEvent(GeneralAnalyticsEventType.ROUTING, {
            fromPage: latestRoute,
            toPage: router.asPath,
        })

        if (
            router.asPath.startsWith(UiLayoutConstant.OUTLINE_PAGE) &&
            !latestRoute.startsWith(UiLayoutConstant.OUTLINE_PAGE)
        ) {
            registerEvent(PaidUserAnalyticsEventType.BEGIN_OUTLINES)
        }

        if (
            !router.asPath.startsWith(UiLayoutConstant.OUTLINE_PAGE) &&
            latestRoute.startsWith(UiLayoutConstant.OUTLINE_PAGE)
        ) {
            registerEvent(PaidUserAnalyticsEventType.STOP_OUTLINES)
        }

        if (
            router.asPath.startsWith(
                UiLayoutConstant.NOTECARD_SET_EXTRA_PRACTICE
            ) &&
            !latestRoute.startsWith(
                UiLayoutConstant.NOTECARD_SET_EXTRA_PRACTICE
            )
        ) {
            registerEvent(PaidUserAnalyticsEventType.BEGIN_NOTECARDS)
        }

        if (
            !router.asPath.startsWith(
                UiLayoutConstant.NOTECARD_SET_EXTRA_PRACTICE
            ) &&
            latestRoute.startsWith(UiLayoutConstant.NOTECARD_SET_EXTRA_PRACTICE)
        ) {
            registerEvent(PaidUserAnalyticsEventType.STOP_NOTECARDS)
        }

        if (
            router.asPath.startsWith(UiLayoutConstant.NOTECARD_SET_REVIEW) &&
            !latestRoute.startsWith(UiLayoutConstant.NOTECARD_SET_REVIEW)
        ) {
            registerEvent(PaidUserAnalyticsEventType.BEGIN_NOTECARDS_REVIEW)
        }

        if (
            !router.asPath.startsWith(UiLayoutConstant.NOTECARD_SET_REVIEW) &&
            latestRoute.startsWith(UiLayoutConstant.NOTECARD_SET_REVIEW)
        ) {
            registerEvent(PaidUserAnalyticsEventType.STOP_NOTECARDS_REVIEW)
        }

        if (
            router.asPath.startsWith(
                UiLayoutConstant.NOTECARD_SET_RECENT_MISTAKES
            ) &&
            !latestRoute.startsWith(
                UiLayoutConstant.NOTECARD_SET_RECENT_MISTAKES
            )
        ) {
            registerEvent(
                PaidUserAnalyticsEventType.BEGIN_NOTECARD_RECENT_MISTAKES
            )
        }

        if (
            !router.asPath.startsWith(
                UiLayoutConstant.NOTECARD_SET_RECENT_MISTAKES
            ) &&
            latestRoute.startsWith(
                UiLayoutConstant.NOTECARD_SET_RECENT_MISTAKES
            )
        ) {
            registerEvent(
                PaidUserAnalyticsEventType.STOP_NOTECARD_RECENT_MISTAKES
            )
        }

        if (
            router.asPath.startsWith(UiLayoutConstant.LESSON) &&
            !latestRoute.startsWith(UiLayoutConstant.LESSON)
        ) {
            registerEvent(PaidUserAnalyticsEventType.BEGIN_NOTECARDS_LESSON)
        }

        if (
            !router.asPath.startsWith(UiLayoutConstant.LESSON) &&
            latestRoute.startsWith(UiLayoutConstant.LESSON)
        ) {
            registerEvent(PaidUserAnalyticsEventType.STOP_NOTECARDS_LESSON)
        }

        if (
            router.asPath.startsWith(UiLayoutConstant.PROBLEM_SET) &&
            !latestRoute.startsWith(UiLayoutConstant.PROBLEM_SET)
        ) {
            registerEvent(PaidUserAnalyticsEventType.BEGIN_PRACTICE_PROBLEMS)
        }

        if (
            !router.asPath.startsWith(UiLayoutConstant.PROBLEM_SET) &&
            latestRoute.startsWith(UiLayoutConstant.PROBLEM_SET)
        ) {
            registerEvent(PaidUserAnalyticsEventType.STOP_PRACTICE_PROBLEMS)
        }

        // setLatestRoute(router.asPath)
        latestRoute = router.asPath
    }, [router.asPath])
}
