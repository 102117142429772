import { AxiosInstance, AxiosResponse, CanceledError, CancelToken } from 'axios'
import { AuthenticationError, SecurityError } from 'common/src/errors'
import { ValidAPIResponse } from 'common/src/api/websiteFrontendVsWebsiteBackend'
import { ApiMethod } from 'common/src/api/types'
import { isEnumMember } from 'common/src/utils/isEnumMember'
import { frontendLogout } from '@/auth/frontendLogout'
import {
    REQUEST_HEADER,
    requestIDFactory,
} from 'common/src/AsyncStorageMiddleware/util'
import { buildFrontendLogger } from '@/logging/FrontendLogger'
import { getPrettyTimeElapsedSince } from 'common/src/decorators/trace/getPrettyTimeElapsedSince'
import { inspect } from 'util'
import { buildHttpClient } from '@/api/buildHttpClient'
import { errorToString } from 'common/src/utils/errorToString'

const clientSideHttpClient = buildHttpClient()

export const TIMEOUT_IN_MS = 120_000

const callBackendLogger = buildFrontendLogger('callBackend')
export const callBackend = async <Req, Res>(
    url: string,
    method: ApiMethod,
    data: Req = null,
    cancelToken: CancelToken = null,
    numRetries = 3,
    httpClient: AxiosInstance = clientSideHttpClient
): Promise<AxiosResponse<ValidAPIResponse<Res>>> => {
    const requestID = requestIDFactory()
    const headers = {
        [REQUEST_HEADER]: requestID,
    }
    const startTime = new Date()

    callBackendLogger.info(
        `=> ${url} / method: ${method} / request ID: ${requestID} / data: ${JSON.stringify(
            data
        )}`,
        { requestID }
    )

    let error: unknown = undefined
    let shouldFireDiscordError = false

    for (let retryCt = 1; retryCt <= numRetries; retryCt++) {
        let response: AxiosResponse<ValidAPIResponse<Res>>
        try {
            switch (method) {
                case ApiMethod.POST:
                    response = await httpClient.post(url, data ?? {}, {
                        cancelToken,
                        headers,
                        timeout: TIMEOUT_IN_MS,
                    })
                    break
                case ApiMethod.GET:
                    response = await httpClient.get(url, {
                        params: data,
                        cancelToken,
                        headers,
                        timeout: TIMEOUT_IN_MS,
                    })
                    break
                default:
                    callBackendLogger.error(
                        `Unexpected request method: ${method}`,
                        { requestID }
                    )
            }
            if (response.data?.success) {
                callBackendLogger.info(
                    `<= ${url} / request ID: ${requestID} / response payload: ${JSON.stringify(
                        response.data.payload
                    )} ${getPrettyTimeElapsedSince(startTime)}`,
                    { requestID }
                )
                return response
            }
            error = response.data.error
        } catch (exception: unknown) {
            error = exception
        }

        shouldFireDiscordError = getShouldReportError({
            errorAsString: errorToString(error),
            url,
            requestID,
            error,
        })

        if (retryCt < numRetries) {
            callBackendLogger.info(
                `${url} / request ID: ${requestID} failed with error code ${
                    typeof error === 'object' && 'code' in error
                        ? error.code
                        : 'NO CODE'
                } / error: ${error}. Retry num ${retryCt + 1}...`,
                { requestID }
            )
        } else {
            if (shouldFireDiscordError) {
                callBackendLogger.error(
                    `${url} / request ID ${requestID} failed with error ${inspect(
                        error
                    )}. Not retrying, just returning...`,
                    { requestID }
                )
            }
            break
        }
    }

    if (shouldFireDiscordError) {
        await handleRequestFailure(error, url, requestID, startTime)
    }
    return {
        data: {
            success: false,
            isError: true,
            payload: null,
            error: error ? error.toString() : null,
        },
        status: null,
        headers: null,
        statusText: null,
        config: null,
    }
}

const getShouldReportError = (input: {
    errorAsString: string
    url: string
    requestID: string
    error: unknown
}): boolean => {
    const { errorAsString, url, requestID, error } = input
    if (isEnumMember(AuthenticationError, errorAsString)) {
        return false
    }

    if (error instanceof CanceledError) {
        return false
    }

    // this fires every minute, we think people just leave JEP open on their phones / laptops
    // and the network requests stop working
    const isGetCurrentStudyTimeStatusNetworkError =
        errorAsString?.includes('AxiosError: Network Error') &&
        url === '/wb/studyTime/getCurrentStudyTimeStatus'

    // logs also fire semi regularly, we don't care if they fail and we think it's just due to browser being inactive
    const isSendLogsTimeoutError =
        errorAsString?.includes('AxiosError: timeout of 120000ms exceeded') &&
        url === '/wb/frontendLogs/sendLogs'

    if (isGetCurrentStudyTimeStatusNetworkError || isSendLogsTimeoutError) {
        callBackendLogger.info(
            `Not firing discord msg for ${url} / error ${errorAsString} / requestID: ${requestID}`,
            { requestID }
        )
        return false
    }

    return true
}

const handleRequestFailure = async (
    error: unknown,
    url: string,
    requestID: string,
    startTime: Date
): Promise<void> => {
    const errorAsString =
        error instanceof Error ? error.message : error?.toString()
    callBackendLogger.error(
        `<!= ${url} / request ID ${requestID} failed with error code ${errorAsString} ${getPrettyTimeElapsedSince(
            startTime
        )}`,
        { requestID }
    )
    if (isEnumMember(SecurityError, errorAsString)) {
        callBackendLogger.error(
            `Detected a security error: ${error}. Immediately logging user out.`,
            { requestID }
        )
        await frontendLogout()
    }
}
