import styles from './LoggedInNavigationOption.module.scss'
import React, { ReactElement, useMemo } from 'react'
import Link from 'next/link'
import { Outline } from '@/components/static/images/owlBranding/outline'
import { Calculator } from '@/components/static/images/owlBranding/calculator'
import { PenHowToUse } from '@/components/static/images/owlBranding/penHowToUse'
import { Cards } from '@/components/static/images/owlBranding/cards'
import { Books } from '@/components/static/images/owlBranding/books'
import { Pencil } from '@/components/static/images/owlBranding/pencil'
import { Cogs } from '@/components/static/images/owlBranding/cogs'
import Tooltip from 'antd/lib/tooltip'
import Button from 'antd/lib/button/button'
import { Clock } from '@/components/static/images/owlBranding/clock'
import { useRecoilValue } from 'recoil'

import {
    frontendDisplayedCourseAccessTypesSelector,
    frontendDisplayedCourseSelector,
} from '@/atoms/accountMaintenance/userInfo'
import { AccessType } from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/types'
import { Padlock } from '@/components/static/images/owlBranding/padlock'
import { ShoppingCart } from '@/components/static/images/owlBranding/shoppingCart'
import { NavBarSelectedOption } from '@/components/Navigation/LoggedInNavigationOption.types'
import { CuteOwl } from '@/components/static/images/owlBranding/cuteOwl'
import { jepAssertNever } from 'common/src/utils/jepAssertNever'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'

export type LoggedInOptionText =
    | 'Dashboard'
    | 'Outlines'
    | 'Notecards'
    | 'Questions'
    | 'How to Use'
    | 'Account'
    | 'Courses'
    | 'Buy Now'
    | 'Logout'
    | 'Study Time'

interface NavigationLoggedInOptionProps {
    text: LoggedInOptionText
    destination: NavBarSelectedOption
    isSmallDesktopMode: boolean // when width is below a set amount, show just the icons instead of the full word to save screen space
    setIsDrawerVisible: (isVisible: boolean) => void
    isAccessLocked: boolean
    isSelected: boolean
}

const getIsButtonDisabled = (
    accessTypes: AccessType[],
    page: LoggedInOptionText
): boolean => {
    switch (page) {
        case 'Outlines':
            return !accessTypes.includes(AccessType.OUTLINES)
        case 'Notecards':
            return !accessTypes.includes(AccessType.NOTECARDS)
        case 'Questions':
            return !accessTypes.includes(AccessType.PRACTICE_PROBLEMS)
        case 'Study Time':
            return accessTypes.length === 0
        case 'Dashboard':
        case 'How to Use':
        case 'Account':
        case 'Courses':
        case 'Buy Now':
        case 'Logout':
            return false
        default:
            return jepAssertNever(
                page,
                'Unhandled page for getIsButtonDisabled'
            )
    }
}

const getIconForPage = (
    accessTypes: AccessType[],
    text: LoggedInOptionText
): ReactElement => {
    const isDisabled = getIsButtonDisabled(accessTypes, text)
    if (isDisabled) {
        return <Padlock />
    }
    switch (text) {
        case 'Dashboard':
            return <CuteOwl />
        case 'Outlines':
            return <Outline />
        case 'Notecards':
            return <Cards />
        case 'Questions':
            return <Calculator />
        case 'How to Use':
            return <PenHowToUse />
        case 'Account':
            return <Cogs />
        case 'Courses':
            return <Books />
        case 'Logout':
        case 'Buy Now':
            return <ShoppingCart />
        case 'Study Time':
            return <Clock />
        default:
            return <Pencil />
    }
}
export const NavigationLoggedInOption: React.FC<
    NavigationLoggedInOptionProps
> = (props): ReactElement => {
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    const accessTypes = useRecoilValue(
        frontendDisplayedCourseAccessTypesSelector
    )
    const isAccessLocked = useMemo((): boolean => {
        return getIsButtonDisabled(accessTypes, props.text)
    }, [accessTypes, props.text])

    const tooltipText = useMemo((): string | undefined => {
        if (isAccessLocked) {
            return 'Click to unlock'
        }
        if (props.isSmallDesktopMode) {
            return props.text
        }

        return
    }, [isAccessLocked, props.isSmallDesktopMode, props.text])
    return useMemo((): ReactElement => {
        let base = (
            <Button
                className={`${styles.navigationLoggedInOption} ${
                    props.isSelected ? styles.isSelected : ''
                }`}
            >
                <div
                    className={`${styles.navigationLoggedInIcon} ${props.isSelected ? styles.isSelected : ''}`}
                >
                    {getIconForPage(accessTypes, props.text)}
                </div>
                <div className={styles.navigationLoggedInText}>
                    {props.text}
                </div>
            </Button>
        )

        const href = {
            pathname: props.destination,
            query: [
                UiLayoutConstant.COURSES_PAGE,
                UiLayoutConstant.COURSE_PAYMENT_PAGE,
            ].includes(props.destination)
                ? { courseName }
                : undefined,
            // this query param is optional - many of the paths do not actually use it
            // HOWEVER, if it is moved, be sure to update LeftSidebarLoggedInShared.tsx to make sure
            // coursePayment and courses page links pass the correct query params
        }

        base = (
            <Link
                href={href}
                onClick={() => {
                    if (props.setIsDrawerVisible) {
                        props.setIsDrawerVisible(false)
                    }
                }}
                className={styles.navigationLoggedInOptionContainer}
            >
                {base}
            </Link>
        )

        if (tooltipText) {
            return (
                <Tooltip title={tooltipText} placement={'right'}>
                    {base}
                </Tooltip>
            )
        }

        return base
    }, [accessTypes, courseName, props, tooltipText])
}
