import { logger } from '@/logging/FrontendLogger'

const ADVERTISING_ID = 't2_q5jmvp1u'

export enum RedditEvent {
    PURCHASE = 'Purchase',
    SIGNUP = 'SignUp',
    LEAD = 'Lead',
}

export const initiateRedditPixel = (): void => {
    if (process.env.NEXT_PUBLIC_JEP_MODE !== 'prod') {
        logger.info(
            `Not initiating Reddit Pixel for env ${process.env.NEXT_PUBLIC_JEP_MODE}`
        )
        return
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import('react-reddit-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
            logger.info('Initiating Reddit Pixel')
            ReactPixel.init(ADVERTISING_ID)
        })
}

export const fireRedditPixelEvent = (event: RedditEvent): void => {
    if (process.env.NEXT_PUBLIC_JEP_MODE !== 'prod') {
        logger.info(
            `Not firing Reddit Pixel Event ${event} for env ${process.env.NEXT_PUBLIC_JEP_MODE}`
        )
        return
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import('react-reddit-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
            ReactPixel.disableFirstPartyCookies()
            logger.info(`Firing Reddit Pixel with event ${event}`)
            ReactPixel.track(event)
        })
}
