import { logger } from '@/logging/FrontendLogger'
import { inspect } from 'util'

export const getTimeZone = (): string => {
    try {
        return (
            Intl.DateTimeFormat().resolvedOptions().timeZone ??
            'America/New_York'
        )
    } catch (e) {
        logger.error(
            `Problem getting timeZone from browser ${inspect(e)}. Returning ET`
        )
        return 'America/New_York'
    }
}
