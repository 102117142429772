import styles from './ContactInstructorModal.module.scss'
import React, {
    ReactElement,
    useCallback,
    useMemo,
    useRef,
    useState,
} from 'react'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal/Modal'
import notification from 'antd/lib/notification'
import { UploadOutlined } from '@ant-design/icons'
import { DisplayError } from '@/components/DisplayError/DisplayError'
import { submitFeedback } from '@/api/support'
import { NotificationKeys } from '@/utils/notificationKeys'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { useRouter } from 'next/router'
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import {
    RcFile,
    UploadChangeParam,
    UploadFile,
} from 'antd/lib/upload/interface'
import { contentTreeDepthSelector } from '@/atoms/contentTree'
import { notecardSetStateAtom } from '@/atoms/notecards/notecardSet'
import { problemSetStateAtom } from '@/atoms/practiceProblems/problemSet'
import { getActiveNotecard } from '@/frontendLogic/notecards/computedFields'
import { logger } from '@/logging/FrontendLogger'
import {
    ContentType,
    RelatedContentInfo,
    UploadedImgs,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/support/requests'
import { hashContentPath } from 'common/src/ContentPath'
import { isContactInstructorModalVisibleAtom } from '@/atoms/isContactInstructorModalVisible'
import { getSignedUrlForSupportUpload } from '@/api/privateS3'
import { getContentTypeFromDataURL } from 'common/src/api/s3/utils'
import {
    GenericButton,
    GenericButtonType,
} from '@/components/static/ui/Button/GenericButton/GenericButton'
import { TwoPartPillButton } from '@/components/utils/TwoPartPillButton/TwoPartPillButton'
import { frontendDisplayedCourseSelector } from '@/atoms/accountMaintenance/userInfo'
import Upload from 'antd/lib/upload'
import Tooltip from 'antd/lib/tooltip'
import { jepAssertNever } from 'common/src/utils/jepAssertNever'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { frontendLessonQuizStateAtom } from '@/atoms/practiceProblems/lessonQuiz'

const { TextArea } = Input

const ALLOWED_FILE_UPLOAD_TYPES = ['image/png', 'image/jpg', 'image/jpeg']
// TODO: Should merge into single component with the button?
export const ContactInstructorModal: React.FC<unknown> = (): ReactElement => {
    const router = useRouter() // parse to determine the current content showing
    const activeCourse = useRecoilValue(frontendDisplayedCourseSelector)
    const [isModalVisible, setIsModalVisible] = useRecoilState(
        isContactInstructorModalVisibleAtom
    )

    const [isRelatedToContent, setIsRelatedToContent] =
        useState<boolean>(!!activeCourse)

    const [feedback, setFeedback] = useState<string>('')
    const [errorMsg, setErrorMsg] = useState<string>('')
    const [fileList, setFileList] = useState<UploadFile[]>([])

    const uploadedImgs = useRef<UploadedImgs>({})

    const beforeUpload = useCallback((file: File): boolean | string => {
        if (!ALLOWED_FILE_UPLOAD_TYPES.includes(file.type)) {
            setErrorMsg(`${file.name} must be either jpg or png`)
            return Upload.LIST_IGNORE
        } else if (file.name in uploadedImgs.current) {
            setErrorMsg(`${file.name} has already been uploaded`)
            return Upload.LIST_IGNORE
        } else {
            setErrorMsg('')
            return true
        }
    }, [])

    const handleUploadChange = useCallback(
        (info: UploadChangeParam<UploadFile<unknown>>): void => {
            setFileList([...info.fileList])
        },
        []
    )

    const executeUpload = useCallback(async (file: RcFile): Promise<void> => {
        const { signedUrl, s3Key } = (await getSignedUrlForSupportUpload()).data
            .payload
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = async () => {
            const base64StringData = (reader.result as string).split(',')[1]
            const buffer = Buffer.from(base64StringData, 'base64')
            await fetch(signedUrl, {
                method: 'PUT',
                headers: new Headers({
                    'Content-Type': getContentTypeFromDataURL(
                        reader.result as string
                    ),
                }),
                mode: 'cors',
                body: buffer,
            })
            uploadedImgs.current[file.name] = s3Key
        }
    }, [])

    const handleRemove = useCallback((file: UploadFile<unknown>): boolean => {
        delete uploadedImgs.current[file.name]
        setErrorMsg('')
        return true
    }, [])

    const getContentType = useCallback((): ContentType => {
        if (router.asPath.startsWith('/outlines/')) {
            if (router.asPath.startsWith(UiLayoutConstant.OUTLINE_PAGE))
                return 'outlinesSpecific'
            return 'outlinesGeneral'
        } else if (router.asPath.startsWith('/notecards')) {
            if (
                router.asPath.startsWith(
                    UiLayoutConstant.NOTECARD_SET_EXTRA_PRACTICE
                ) ||
                router.asPath.startsWith(
                    UiLayoutConstant.NOTECARD_SET_RECENT_MISTAKES
                ) ||
                router.asPath.startsWith(UiLayoutConstant.NOTECARD_SET_REVIEW)
            )
                return 'notecardsSpecific'
            return 'notecardsGeneral'
        } else if (
            router.asPath.startsWith('/problems') ||
            router.asPath.startsWith('/lesson')
        ) {
            if (
                ((router.asPath.startsWith(UiLayoutConstant.PROBLEM_SET) ||
                    router.asPath.includes(
                        UiLayoutConstant.PROBLEM_SET_REVIEW
                    ) ||
                    router.asPath.includes(
                        UiLayoutConstant.SINGLE_PRACTICE_PROBLEM_PAGE
                    )) &&
                    !Object.keys(router.query)[0].includes('Overview')) ||
                router.asPath.includes('questionNumber') // for lesson page
            )
                return 'practiceProblemsSpecific'
            return 'practiceProblemsGeneral'
        } else {
            return 'other'
        }
    }, [router.asPath, router.query])

    const depth = useRecoilValue(contentTreeDepthSelector)
    const notecardSetState = useRecoilValue(notecardSetStateAtom)
    const problemSet = useRecoilValue(problemSetStateAtom)
    const frontendLessonQuizLoadable = useRecoilValueLoadable(
        frontendLessonQuizStateAtom
    )
    const getAdditionalInfoForContentType = useCallback(
        (
            contentType: ContentType
        ): { specificContentID?: string; contentSetID?: string } => {
            const splitPath = router.asPath.split('/')

            switch (contentType) {
                case 'outlinesSpecific': {
                    const contentPath = splitPath.slice(
                        splitPath.length - depth
                    )
                    return {
                        specificContentID: hashContentPath(contentPath),
                    }
                }
                case 'notecardsSpecific': {
                    if (
                        router.asPath.startsWith(
                            UiLayoutConstant.SINGLE_NOTECARD_PAGE
                        )
                    ) {
                        // specific notecard
                        const notecardID = splitPath[splitPath.length - 1]
                        return { specificContentID: notecardID }
                    }

                    if (
                        router.asPath.startsWith(
                            UiLayoutConstant.NOTECARD_SET_EXTRA_PRACTICE
                        ) ||
                        router.asPath.startsWith(
                            UiLayoutConstant.NOTECARD_SET_REVIEW
                        ) ||
                        router.asPath.startsWith(
                            UiLayoutConstant.NOTECARD_SET_RECENT_MISTAKES
                        )
                    ) {
                        // notecard as part of deck
                        return {
                            specificContentID: getActiveNotecard(
                                notecardSetState.notecardSet
                            ).id,
                            contentSetID: notecardSetState.notecardSet.id,
                        }
                    }

                    logger.error(
                        `Somehow thought notecards specific with router path name: ${router.asPath} and query: ${router.query}`
                    )
                    return {}
                }
                case 'practiceProblemsSpecific': {
                    if (
                        router.asPath.startsWith(
                            UiLayoutConstant.SINGLE_PRACTICE_PROBLEM_PAGE
                        )
                    ) {
                        // specific problem
                        const problemID = splitPath[splitPath.length - 1]
                        return { specificContentID: problemID }
                    }

                    if (
                        router.asPath.startsWith(
                            UiLayoutConstant.PROBLEM_SET
                        ) ||
                        router.asPath.startsWith(
                            UiLayoutConstant.PROBLEM_SET_REVIEW
                        )
                    ) {
                        // problem as part of problemSet
                        return {
                            specificContentID:
                                problemSet.problemSetState.problems[
                                    problemSet.problemSetState
                                        .activeProblemIndex
                                ].id,
                            contentSetID: problemSet.problemSetState.id,
                        }
                    }

                    if (router.asPath.startsWith('/lesson')) {
                        if (frontendLessonQuizLoadable.state !== 'hasValue') {
                            logger.error(
                                "frontendLessonQuizLoadable wasn't loaded"
                            )
                            return {}
                        }
                        const frontendLessonQuizState =
                            frontendLessonQuizLoadable.contents
                        if (frontendLessonQuizState === null) {
                            logger.error(
                                'frontendLessonQuizState was null in ContactInstructorModal'
                            )
                            return {}
                        }
                        const numberStr = router.query.questionNumber as string
                        let questionNumber = null
                        try {
                            questionNumber = parseInt(numberStr)
                        } catch (error) {
                            // do nothing
                        }
                        if (questionNumber === null) {
                            logger.error(
                                `Failed to parse question number from router query: ${router.query}`
                            )
                            return {}
                        }
                        return {
                            specificContentID:
                                frontendLessonQuizState.problems[
                                    questionNumber - 1
                                ].id,
                            contentSetID: 'LESSON_QUIZ',
                        }
                    }

                    logger.error(
                        `Somehow thought practice problems specific with router path name: ${router.asPath} and query: ${router.query}`
                    )
                    return {}
                }
                case 'outlinesGeneral':
                case 'notecardsGeneral':
                case 'practiceProblemsGeneral':
                case 'other':
                    return {}
                default:
                    jepAssertNever(
                        contentType,
                        'Unhandled content type to getAdditionalInfoForContentType'
                    )
            }
        },
        [depth, notecardSetState, problemSet, router]
    )
    const buildRelatedContentInfo = useCallback((): RelatedContentInfo => {
        const contentType = getContentType()
        const additionalInfo = getAdditionalInfoForContentType(contentType)
        return {
            courseName: activeCourse,
            contentType,
            ...additionalInfo,
        }
    }, [activeCourse, getAdditionalInfoForContentType, getContentType])

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const handleSubmit = useCallback(async (): Promise<void> => {
        // Validate that something should be submitted, otherwise render an error
        // Must either have uploaded an image OR submitted some text
        setIsLoading(true)
        const submittedAtLeastOneFile = !!Object.keys(uploadedImgs.current)
            .length
        const submittedAtLeastOneCharacter = !!feedback.length
        if (!(submittedAtLeastOneFile || submittedAtLeastOneCharacter)) {
            setErrorMsg(
                'You must submit your feedback in text or upload at least one file'
            )
            setIsLoading(false)
            return
        }

        // Send information
        const relatedContentInfo = isRelatedToContent
            ? buildRelatedContentInfo()
            : undefined

        await submitFeedback(
            feedback,
            router.asPath,
            relatedContentInfo,
            uploadedImgs.current
        )

        // Close modal
        setIsModalVisible(false)

        // Reset state
        setErrorMsg('')
        uploadedImgs.current = {}
        setIsRelatedToContent(true)
        setFeedback('')
        setFileList([])

        // Render confirmation of success
        notification.success({
            message: 'Feedback successfully submitted',
            description: 'Thanks!',
            duration: 10, // seconds
            onClick: () => {
                notification.destroy(
                    NotificationKeys.FEEDBACK_EMAIL_SUCCESSFULLY_SENT
                )
            },
            className: reusableCssClass.clickMe,
            key: NotificationKeys.FEEDBACK_EMAIL_SUCCESSFULLY_SENT,
        })
        setIsLoading(false)
    }, [
        buildRelatedContentInfo,
        feedback,
        isRelatedToContent,
        router.asPath,
        setIsModalVisible,
    ])

    const feedbackModal = useMemo((): ReactElement => {
        return (
            <div className={styles.feedbackModal}>
                <div className={styles.feedbackHeader}>
                    <div
                        className={styles.feedbackModalTitle}
                        id={'cypress-feedback-modal-title'}
                    >
                        Contact Instructor
                    </div>
                    <div className={styles.feedbackModalSubtitle}>
                        Submit questions / feedback / errata directly to your
                        instructor and receive a response via email
                    </div>
                </div>
                <div className={styles.feedbackModalBody}>
                    <div className={styles.feedbackModalRadioContainer}>
                        <div
                            className={
                                styles.feedbackModalRadioContainerQuestion
                            }
                        >
                            Is this feedback related to our content?
                        </div>
                        <TwoPartPillButton
                            leftSideContent={<div>Yes</div>}
                            rightSideContent={<div>No</div>}
                            leftSideOnClick={() => setIsRelatedToContent(true)}
                            rightSideOnClick={() =>
                                setIsRelatedToContent(false)
                            }
                            selectedSide={isRelatedToContent ? 'left' : 'right'}
                        />
                    </div>
                    <div className={styles.feedbackModalFreeResponseContainer}>
                        <div className={styles.feedbackModalFreeResponsePrompt}>
                            Tell us your thoughts:
                        </div>
                        <TextArea
                            value={feedback}
                            onChange={(event) => {
                                setErrorMsg('')
                                setFeedback(event.target.value)
                            }}
                            rows={4}
                            maxLength={2_000}
                            showCount={true}
                            id={'cypress-feedback-text-area'}
                        />
                    </div>
                    <div
                        className={styles.feedbackModalUploadContainer}
                        id={'cypress-upload-button'}
                    >
                        <Upload // TODO: Can't figure out how to prevent clicking on the thumbnail to open up separate tab - probably need to build our own custom component @ray
                            accept={ALLOWED_FILE_UPLOAD_TYPES.join(', ')}
                            listType={'picture'}
                            beforeUpload={beforeUpload}
                            maxCount={10}
                            onChange={handleUploadChange}
                            onRemove={handleRemove}
                            fileList={fileList}
                            // thanks to: https://stackoverflow.com/questions/51514757/action-function-is-required-with-antd-upload-control-but-i-dont-need-it
                            customRequest={async ({ file, onSuccess }) => {
                                await executeUpload(file as RcFile)
                                onSuccess('ok')
                            }}
                        >
                            <Tooltip
                                placement={'right'}
                                title={'Uploading a screenshot may be helpful'}
                            >
                                <GenericButton
                                    text={
                                        <>
                                            <UploadOutlined /> Upload{' '}
                                        </>
                                    }
                                    type={GenericButtonType.greenNoBackground}
                                    onClick={null}
                                />
                            </Tooltip>
                        </Upload>
                        <DisplayError message={errorMsg} />
                    </div>
                    <div className={styles.feedbackModalSubmitButtonContainer}>
                        <GenericButton
                            type={GenericButtonType.primaryDarkBackground}
                            text={'Send'}
                            onClick={handleSubmit}
                            loading={isLoading}
                        />
                    </div>
                </div>
            </div>
        )
    }, [
        isRelatedToContent,
        feedback,
        beforeUpload,
        handleUploadChange,
        handleRemove,
        fileList,
        errorMsg,
        handleSubmit,
        isLoading,
        executeUpload,
    ])

    return (
        <Modal
            centered
            footer={null}
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            className={styles.feedbackModalContainer}
        >
            {feedbackModal}
        </Modal>
    )
}
