import { IProblemSetMetadataResponse } from 'common/src/practiceProblems/responses'
import { atom, selector } from 'recoil'
import { getProblemSetMetadata } from '@/api/practiceProblems'
import { LoggerSelectorFamily } from '@/atoms/logger'
import { cleanProblemSetMetadata } from '@/frontendLogic/practiceProblems/clean'
import { defaultInvalidationAtom, InvalidationAtom } from '@/atoms/types'
import { resetOnSetEffect } from '@/atoms/atomEffects/resetOnSetEffect'
import { getForeverPromise } from 'common/src/utils/getForeverPromise'
import { logAtomChangesEffect } from '@/atoms/atomEffects/logAtomChangesEffect'
import {
    frontendDisplayedCourseSelector,
    userInfoStateAtom,
} from '@/atoms/accountMaintenance/userInfo'

const fetchProblemSetMetadata = async (
    courseName: string
): Promise<ProblemSetMetadata | null> => {
    const response = await getProblemSetMetadata(courseName)
    if (response.data.payload === null) {
        return null
    }

    return {
        examMetadata: cleanProblemSetMetadata(
            response.data.payload.examMetadata
        ),
        quizMetadata: cleanProblemSetMetadata(
            response.data.payload.quizMetadata
        ),
    }
}

export type ProblemSetMetadata = IProblemSetMetadataResponse
export const problemSetMetadataAtom = atom<ProblemSetMetadata | null>({
    key: 'problemSetMetadataAtom',
    default: selector<ProblemSetMetadata | null>({
        key: 'problemSetMetadataFetcher',
        get: async ({ get }) => {
            const frontendDisplayedCourse = get(frontendDisplayedCourseSelector)
            const logger = get(LoggerSelectorFamily(problemSetMetadataAtom.key))
            if (!frontendDisplayedCourse) {
                logger.warn(
                    `No frontend displayed course course, so problem set metadata will not be retrieved (returning forever promise instead)`
                )
                return getForeverPromise()
            }

            get(userInfoStateAtom) // forces update upon new login
            get(problemSetMetadataInvalidationAtom)
            logger.info(`Retrieving initial problem set metadata`)
            return fetchProblemSetMetadata(frontendDisplayedCourse)
        },
    }),
})

export const problemSetMetadataInvalidationAtom = atom<InvalidationAtom>({
    key: 'problemSetMetadataInvalidationAtom',
    default: defaultInvalidationAtom,
    effects: [
        logAtomChangesEffect('Problem Set Metadata Invalidation Atom'),
        resetOnSetEffect(problemSetMetadataAtom),
    ],
})
