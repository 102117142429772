import { BaseSetCreator } from '@/frontendLogic/SetCreator/SetCreator.types'
import { SittingSeason } from 'common/src/previousExamAnalysis/types'
import { IProblemUserStats } from 'common/src/practiceProblems/types'
import { YearToSittingsMap } from '@/components/practiceProblems/CreateQuizPage/CreateQuiz/BuildQuizSelector/ProblemType/previousExamMappings'

export enum UserHistoryProblemAttribute {
    new = 'New',
    correct = 'Correct',
    incorrect = 'Incorrect',
}

export enum ProblemTypeProblemAttribute {
    previousExam = 'Previous Exam',
    goldStandard = 'Gold Standard',
}

export interface PreviousExam {
    year: number
    sittingSeason: SittingSeason
}

// if just a year, that means both are selected according to antd
export type PreviousExamSelectionResultForAntdCascader =
    | [number, SittingSeason]
    | [number]

export interface ProblemTypeSelections {
    goldStandardOnly: boolean
    selectedYearToSittingsMap: YearToSittingsMap
}

export enum MathVsConceptualProblemAttribute {
    math = 'Math',
    conceptual = 'Conceptual',
}

export type QuizCreatorProblemAttributesType =
    | UserHistoryProblemAttribute
    | ProblemTypeProblemAttribute
    | MathVsConceptualProblemAttribute

export type UserHistoryProblemAttributeMap<
    K = UserHistoryProblemAttribute,
    V = Set<string>,
> = Map<K, V>

export type ProblemTypeProblemAttributeMap<
    K = ProblemTypeProblemAttribute,
    V = Set<string>,
> = Map<K, V>

export type MathVsConceptualProblemAttributeMap<
    K = MathVsConceptualProblemAttribute,
    V = Set<string>,
> = Map<K, V>

export type PreviousExamSittingsMap = Map<
    number,
    Map<SittingSeason, Set<string>>
>

export type PracticeProblemUserStatsMap = Map<string, IProblemUserStats>

export interface QuizCreator extends BaseSetCreator {
    userHistoryProblemAttributeMap: UserHistoryProblemAttributeMap
    problemTypeProblemAttributeMap: ProblemTypeProblemAttributeMap
    mathVsConceptualProblemAttributeMap: MathVsConceptualProblemAttributeMap
    previousExamSittingsMap: PreviousExamSittingsMap
    practiceProblemUserStatsMap: PracticeProblemUserStatsMap
}

export const ALL_USER_HISTORY_PROBLEM_ATTRIBUTES = Object.values(
    UserHistoryProblemAttribute
)

export const DEFAULT_PROBLEM_TYPE_SELECTIONS: ProblemTypeSelections = {
    goldStandardOnly: false,
    selectedYearToSittingsMap: new Map(),
}

export const ALL_PROBLEM_TYPE_PROBLEM_ATTRIBUTES = Object.values(
    MathVsConceptualProblemAttribute
)
