import { selector } from 'recoil'
import { LoggerSelectorFamily } from '@/atoms/logger'
import { getMostRecentProblemSetMetadata } from '@/api/practiceProblems'
import { IProblemSetMetadata } from 'common/src/practiceProblems/types'
import {
    frontendDisplayedCourseSelector,
    userInfoStateAtom,
} from '@/atoms/accountMaintenance/userInfo'

export const mostRecentProblemSetMetadataSelector =
    selector<IProblemSetMetadata | null>({
        key: 'mostRecentProblemSetIDAndTypeSelector',
        get: async ({ get }) => {
            const frontendDisplayedCourse = get(frontendDisplayedCourseSelector)
            const logger = get(
                LoggerSelectorFamily(mostRecentProblemSetMetadataSelector.key)
            )
            if (frontendDisplayedCourse === null) {
                logger.warn(
                    `No frontend displayed course, so most recent problem set ID / type will not be retrieved (returning null instead)`
                )
                return null
            }

            get(userInfoStateAtom) // forces update upon new login
            logger.info(`Retrieving most recent problem set ID / type`)
            return (
                await getMostRecentProblemSetMetadata(frontendDisplayedCourse)
            ).data.payload
        },
    })
