import { useRegisterSessionData } from '@/hooks/useRegisterSessionData'
import { useForceReloadAround3AMLocal } from '@/hooks/useForceReloadAround3AMLocal'
import { useRedditPixel } from '@/hooks/static/useRedditPixel'
import { useNotificationConfig } from '@/hooks/static/useNotificationConfig'
import { useSyncUserInfoWithOpenreplay } from '@/openreplay/useSyncUserInfoWithOpenreplay'
import { useSessionData } from '@/hooks/useSessionData'
import { useNetworkStatus } from '@/hooks/useNetworkStatus'
import { useLoadBigCommonAtoms } from '@/hooks/useLoadBigCommonAtoms'

export const useRunOnceOnClient = (): void => {
    useSessionData()
    useForceReloadAround3AMLocal()
    useRegisterSessionData()
    useRedditPixel()
    useNotificationConfig()
    useSyncUserInfoWithOpenreplay()
    useNetworkStatus()
    useLoadBigCommonAtoms()
}
