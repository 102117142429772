import React, { ReactElement, useEffect, useState } from 'react'
import styles from './LoggedInHeaderMobileMode.module.scss'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
    notificationsAtom,
    numUnseenNotificationsSelector,
} from '@/atoms/notifications'
import { markAsSeen } from '@/frontendLogic/notifications/markAsSeen'
import { markNotificationsAsSeen } from '@/api/notifications'
import { logger } from '@/logging/FrontendLogger'
import { registerEvent } from '@/api/analyticsEvent'
import { PaidUserAnalyticsEventType } from 'common/src/api/websiteFrontendVsWebsiteBackend/analyticsEvent/types'
import { JEPNotification } from 'common/src/api/websiteFrontendVsWebsiteBackend/notifications/types'
import { PersonalSection } from '@/components/LoggedInHeader/PersonalSection/PersonalSection'
import { LeftSidebarLoggedInMobileMode } from '@/components/layout/LeftSidebarLoggedIn/LeftSidebarLoggedinMobileMode/LeftSidebarLoggedInMobileMode'
import { SearchLink } from '@/components/search/SearchLink/SearchLink'
import {
    frontendDisplayedCourseSelector,
    userInfoStateAtom,
    userInitialsSelector,
} from '@/atoms/accountMaintenance/userInfo'

export const LoggedInHeaderMobileMode: React.FC<unknown> = (
    _props
): ReactElement => {
    const userInfo = useRecoilValue(userInfoStateAtom)
    const initials = useRecoilValue(userInitialsSelector)

    const courseName = useRecoilValue(frontendDisplayedCourseSelector)

    const [isNotificationModalVisible, setIsNotificationModalVisible] =
        useState<boolean>(false)
    const [notifications, setNotifications] =
        useRecoilState<JEPNotification[]>(notificationsAtom)
    const numUnseenNotifications = useRecoilValue(
        numUnseenNotificationsSelector
    )

    useEffect(() => {
        if (isNotificationModalVisible) {
            if (numUnseenNotifications > 0) {
                setNotifications(markAsSeen(notifications))
                markNotificationsAsSeen(courseName)
                    .then(() =>
                        logger.info(
                            'Notifications modal opened, all notifs marked as seen'
                        )
                    )
                    .catch((e) =>
                        logger.info(`Error marking notifs as seen`, e)
                    )
            }
            registerEvent(PaidUserAnalyticsEventType.OPEN_NOTIFICATIONS_MODAL)
        }
    }, [
        courseName,
        isNotificationModalVisible,
        notifications,
        numUnseenNotifications,
        setNotifications,
    ])

    return (
        <div className={styles.loggedInHeader}>
            <LeftSidebarLoggedInMobileMode />
            <div className={styles.searchContainer}>
                <SearchLink courseName={courseName} isMobileMode={true} />
            </div>
            <div className={styles.personalBox}>
                <PersonalSection
                    displayName={userInfo.displayName}
                    numUnseenNotifications={numUnseenNotifications}
                    isNotificationModalVisible={isNotificationModalVisible}
                    setIsNotificationModalVisible={
                        setIsNotificationModalVisible
                    }
                    courseName={courseName}
                    initials={initials}
                    isMobileMode={true}
                />
            </div>
        </div>
    )
}
