import React, {
    ReactElement,
    useState,
    useMemo,
    useEffect,
    useRef,
} from 'react'
import { useInterval } from '@/hooks/static/useInterval'

const CHARACTERS_PER_MINUTE = 3_000
const TYPING_INTERVAL_IN_MS = (60 / CHARACTERS_PER_MINUTE) * 1_000
export const AnimatedTyping: React.FC<{
    text: string
    onComplete?: () => void
}> = (props): ReactElement => {
    const [currentlyRenderedCharacters, setCurrentlyRenderedCharacters] =
        useState<string>('')
    useEffect(() => {
        setCurrentlyRenderedCharacters('')
    }, [props.text])
    const wasLastTransitioningRef = useRef<boolean>(false)
    const isTransitioning = useMemo(
        (): boolean => currentlyRenderedCharacters.length < props.text.length,
        [currentlyRenderedCharacters, props.text]
    )
    useEffect(() => {
        if (
            props.onComplete &&
            wasLastTransitioningRef.current &&
            !isTransitioning
        )
            props.onComplete()
        wasLastTransitioningRef.current = isTransitioning
    }, [isTransitioning, props])

    useInterval(
        () => {
            setCurrentlyRenderedCharacters(
                props.text.slice(0, currentlyRenderedCharacters.length + 1)
            )
        },
        isTransitioning ? TYPING_INTERVAL_IN_MS : null
    )

    return <RenderCharactersWithNewlines text={currentlyRenderedCharacters} />
}

const RenderCharactersWithNewlines: React.FC<{ text: string }> = (
    props
): ReactElement => {
    return (
        <>
            {props.text.split('\n').map((line, i) => (
                <span key={i}>
                    {line}
                    <br />
                </span>
            ))}
        </>
    )
}
