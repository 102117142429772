import React, {
    PropsWithChildren,
    ReactElement,
    ReactNode,
    useMemo,
} from 'react'
import styles from './ActionCard.module.scss'
import { jepAssertNever } from 'common/src/utils/jepAssertNever'

interface ActionCardProps {
    title: ReactNode
    text: ReactNode
    icon: ReactNode
    actionButtons: ReactNode[]
    type: 'primary' | 'secondary' | 'noAction'
}

// 1 or more of these must be contained in an actions card container (at bottom of file)
export const ActionCard: React.FC<ActionCardProps> = (props): ReactElement => {
    const bgClassName = useMemo((): string => {
        switch (props.type) {
            case 'primary':
                return styles.primary
            case 'secondary':
                return styles.secondary
            case 'noAction':
                return styles.noAction
            default:
                jepAssertNever(props.type, 'action card type')
        }
    }, [props.type])

    return (
        <div className={`${styles.actionCard} ${bgClassName}`}>
            <div className={styles.titleContainer}>
                <span className={styles.bigBoldText}>{props.title}</span>
            </div>
            <div className={styles.textContainer}>{props.text}</div>
            <div className={styles.iconContainer}>{props.icon}</div>
            <div className={styles.buttonContainer}>{props.actionButtons}</div>
        </div>
    )
}

export const ActionCardsContainer: React.FC<PropsWithChildren<unknown>> = (
    props
): ReactElement => {
    return <div className={styles.actionCardsContainer}>{props.children}</div>
}
