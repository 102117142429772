import { atom, selector } from 'recoil'
import {
    getNextSittingDate,
    getStudyTimeGoalInHoursForCourse,
} from '@/api/studyTime'
import { userInfoStateAtom } from '@/atoms/accountMaintenance/userInfo'
import { LoggerSelectorFamily } from '@/atoms/logger'

export const studyTimeGoalInHoursAtom = atom<number | null>({
    key: 'studyTimeGoalInHours',
    default: selector<number | null>({
        key: 'studyTimeGoalInHoursDefaultStateFetcher',
        get: async ({ get }) => {
            const courseName =
                get(userInfoStateAtom)?.frontendDisplayedCourseInfo?.name
            if (!courseName) {
                get(LoggerSelectorFamily(studyTimeGoalInHoursAtom.key)).warn(
                    'No course name, returning null'
                )
                return null
            }
            return await fetchStudyTimeGoalInHoursForCourse(courseName)
        },
    }),
})

export const fetchStudyTimeGoalInHoursForCourse = async (
    courseName: string
): Promise<number | null> => {
    return (
        (await getStudyTimeGoalInHoursForCourse(courseName)).data.payload
            ?.studyTimeGoalInHours ?? null
    )
}

export const isStudyTimeModalVisibleAtom = atom<boolean>({
    key: 'isStudyTimeModalVisibleAtom',
    default: false,
})

export const userSetNextSittingDateAtom = atom<Date | null>({
    key: 'userSetNextSittingDate',
    default: selector<Date | null>({
        key: 'userSetNextSittingDateFetcher',
        get: async ({ get }) => {
            const courseName =
                get(userInfoStateAtom)?.frontendDisplayedCourseInfo?.name
            if (!courseName) {
                get(LoggerSelectorFamily(userSetNextSittingDateAtom.key)).warn(
                    'No course name, returning null'
                )
                return null
            }
            return await fetchNextSittingDateForCourse(courseName)
        },
    }),
})

export const fetchNextSittingDateForCourse = async (
    courseName: string
): Promise<Date | null> => {
    const response = await getNextSittingDate(courseName)
    if (response?.data?.payload?.dateStr) {
        return new Date(response.data.payload.dateStr)
    }
    return null
}
