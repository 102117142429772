import React, { ReactElement } from 'react'
import Image from 'next/image'
import src from '../../../../../public/owlBranding/loadingOwl.gif'
import Spin from 'antd/lib/spin'
import Skeleton from 'antd/lib/skeleton/Skeleton'

export enum LoadingType {
    owl = 'owl',
    skeleton = 'skeleton',
    spinner = 'spinner',
}

interface LoadingImageProps {
    loadingType?: LoadingType
    numSkeletonRows?: number
    maxHeightPx?: number
    hideTitle?: boolean
}

const buildImageStyleProps = (
    maxHeightPx?: number
): {
    objectFit: 'cover'
    height: '100%'
    maxWidth: string
    maxHeight?: string
} => {
    const output: {
        objectFit: 'cover'
        height: '100%'
        maxWidth: string
        maxHeight?: string
    } = {
        objectFit: 'cover',
        height: '100%',
        maxWidth: '500px',
    }
    if (maxHeightPx) {
        output.maxWidth = 'auto'
        output.maxHeight = `${maxHeightPx}px`
    }
    return output
}

export const LoadingImage: React.FC<LoadingImageProps> = (
    props
): ReactElement => {
    switch (props.loadingType) {
        case LoadingType.skeleton:
            return (
                <Skeleton
                    active={true}
                    paragraph={{ rows: props.numSkeletonRows ?? 10 }}
                    style={{ height: '100%' }}
                    title={!props.hideTitle}
                />
            )
        case LoadingType.spinner:
            return <Spin />
        case LoadingType.owl:
        default:
            return (
                <Spin
                    indicator={
                        <Image
                            src={src}
                            alt={'Loading...'}
                            style={buildImageStyleProps(props.maxHeightPx)}
                        />
                    }
                />
            )
    }
}
