import { JEPNotificationNewCourseContentMetadata } from 'common/src/api/websiteFrontendVsWebsiteBackend/notifications/types'
import { ContentType } from 'common/src/commentThread/types'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { jepAssertNever } from 'common/src/utils/jepAssertNever'

export const buildHrefAndTextForNewCourseContent = (
    metadata: JEPNotificationNewCourseContentMetadata
): { href: string; text: string } => {
    const idString = metadata.contentIds.join('/')
    let text: string
    switch (metadata.contentType) {
        case ContentType.NOTECARD:
            text = `${metadata.contentIds.length} new ${
                metadata.contentIds.length === 1 ? 'notecard' : 'notecards'
            } added.`
            return {
                href: `${UiLayoutConstant.GENERATE_DECK_WITH_SPECIFIC_NOTECARDS_PAGE}${idString}`,
                text,
            }
        case ContentType.PRACTICE_PROBLEM:
            text = `${metadata.contentIds.length} new ${
                metadata.contentIds.length === 1
                    ? 'practice question'
                    : 'practice questions'
            } added.`
            return {
                href: `${UiLayoutConstant.GENERATE_QUIZ_WITH_SPECIFIC_PROBLEMS_PAGE}${idString}`,
                text,
            }
        default:
            jepAssertNever(
                metadata.contentType,
                'Unhandled content type to build notification'
            )
    }
}
