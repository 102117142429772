import { ApiMethod, EmptyResponse } from 'common/src/api/types'
import {
    RelatedContentInfo,
    SendSupportEmailRequest,
    SubmitEducatorInterestRequest,
    SubmitFeedbackRequest,
    UploadedImgs,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/support/requests'
import { APIResponseWrapper } from '@/api/types'
import { SendSupportEmailResponse } from 'common/src/api/websiteFrontendVsWebsiteBackend/support/responses'
import { callBackend } from '../../server/callBackend'
import { MnemonicSuggestionRequest } from '../../common/src/notecards/requests'

const PREFIX = '/wb/support/'

export const sendSupportEmail = async (
    email: string,
    name: string,
    subject: string,
    body: string,
    otherSubject: string
): APIResponseWrapper<SendSupportEmailResponse> => {
    return await callBackend<SendSupportEmailRequest, SendSupportEmailResponse>(
        PREFIX + 'sendSupportEmail',
        ApiMethod.POST,
        {
            email,
            name,
            subject,
            body,
            otherSubject,
        }
    )
}

export const submitFeedback = async (
    feedback: string,
    page: string,
    relatedContentInfo?: RelatedContentInfo,
    uploadedImgs?: UploadedImgs
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<SubmitFeedbackRequest, EmptyResponse>(
        PREFIX + 'submitFeedback',
        ApiMethod.POST,
        {
            feedback,
            page,
            relatedContentInfo,
            uploadedImgs,
        }
    )
}

export const submitEducatorInterestForm = async (input: {
    firstName: string
    lastName: string
    email: string
    programName: string
}): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<SubmitEducatorInterestRequest, EmptyResponse>(
        PREFIX + 'submitEducatorInterestForm',
        ApiMethod.POST,
        input
    )
}

export async function submitMnemonicSuggestion(
    mnemonicSuggestion: string,
    courseName: string,
    notecardID: string
): APIResponseWrapper<EmptyResponse> {
    return await callBackend<MnemonicSuggestionRequest, EmptyResponse>(
        PREFIX + 'submitMnemonicSuggestion',
        ApiMethod.POST,
        {
            id: notecardID,
            courseName,
            proposedMnemonic: mnemonicSuggestion,
        }
    )
}
