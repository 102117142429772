import { TopicToLearningObjectivesMap } from 'common/src/api/core/contentTree/responses'
import { hashContentPath } from 'common/src/ContentPath'
import { ILearningObjective } from 'common/src/LearningObjectives/types'
import {
    PracticeProblemUserMostRecentScoreData,
    ScoreMap,
    IBaseScore,
} from 'common/src/practiceProblems/scores/types'
import {
    getEmptyBaseScore,
    getMostRecentScoreFromProblemUserStats,
    mergeBaseScores,
} from 'common/src/practiceProblems/scores'
import { PracticeProblemLearningObjectivesScoreMap } from '@/frontendLogic/practiceProblems/visualizations/PracticeProblemLearningObjectives/PracticeProblemLearningObjectives.types'

export const createPracticeProblemLearningObjectivesMastery = (
    practiceProblemScoreData: PracticeProblemUserMostRecentScoreData[],
    topicToLearningObjectivesMap: Map<string, ILearningObjective[]>
): PracticeProblemLearningObjectivesScoreMap => {
    return new PracticeProblemLearningObjectivesMasteryFactory(
        practiceProblemScoreData,
        topicToLearningObjectivesMap
    ).toObj()
}

class PracticeProblemLearningObjectivesMasteryFactory {
    private readonly scoreMap: ScoreMap // keys are topicID OR topicID|learningObjectiveID

    constructor(
        practiceProblemScoreData: PracticeProblemUserMostRecentScoreData[],
        topicToLearningObjectivesMap: TopicToLearningObjectivesMap
    ) {
        this.scoreMap = this.buildScoresMap(
            practiceProblemScoreData,
            topicToLearningObjectivesMap
        )
    }

    public toObj = (): PracticeProblemLearningObjectivesScoreMap => {
        return this.scoreMap
    }

    private getScoreForArrayOfPracticeProblems = (
        practiceProblemScoreData: PracticeProblemUserMostRecentScoreData[]
    ): IBaseScore => {
        let scoreForPracticeProblems: IBaseScore = getEmptyBaseScore()
        for (const practiceProblemScoreDatum of practiceProblemScoreData) {
            scoreForPracticeProblems = mergeBaseScores(
                scoreForPracticeProblems,
                getMostRecentScoreFromProblemUserStats(
                    practiceProblemScoreDatum
                )
            )
        }
        return scoreForPracticeProblems
    }

    private buildScoresMap = (
        practiceProblemScoreData: PracticeProblemUserMostRecentScoreData[],
        topicToLearningObjectivesMap: TopicToLearningObjectivesMap
    ): ScoreMap => {
        const scoreMap: ScoreMap = new Map()
        topicToLearningObjectivesMap.forEach(
            (learningObjectives: ILearningObjective[], topic: string) => {
                // initialize empty base score at topic level
                scoreMap.set(topic, getEmptyBaseScore())

                // iterate through learning objectives associated with topic level and create new entries for topic|learningObjective
                for (const learningObjective of learningObjectives) {
                    const practiceProblemsForLearningObjectives =
                        practiceProblemScoreData.filter(
                            (practiceProblemScoreDatum) =>
                                practiceProblemScoreDatum.learningObjectiveID ===
                                learningObjective.id
                        )
                    const scoreForPracticeProblems =
                        this.getScoreForArrayOfPracticeProblems(
                            practiceProblemsForLearningObjectives
                        )
                    scoreMap.set(
                        hashContentPath([topic, learningObjective.id]),
                        scoreForPracticeProblems
                    )

                    // update top level topic score to include learning objectives' score
                    scoreMap.set(
                        topic,
                        mergeBaseScores(
                            scoreMap.get(topic) || getEmptyBaseScore(),
                            scoreForPracticeProblems
                        )
                    )
                }
            }
        )
        return scoreMap
    }
}
