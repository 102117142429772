import produce from 'immer'
import { JEPNotification } from 'common/src/api/websiteFrontendVsWebsiteBackend/notifications/types'

export const markAsSeen = produce((state: JEPNotification[]): void => {
    state.forEach((notification) => {
        if (!notification.hasBeenSeen) {
            notification.hasBeenSeen = true
        }
    })
})
