import { ApiMethod, EmptyResponse } from 'common/src/api/types'
import { ContentPath, hashContentPath } from 'common/src/ContentPath'
import { CancelToken } from 'axios'
import { ContentPathResponse } from 'common/src/api/core/contentTree/responses'
import {
    GenericContentRequest,
    SpecificContentRequest,
} from 'common/src/api/core/courses/requests'
import {
    OutlineMetadataResponse,
    NodeMetadataResponse,
    OutlineResponse,
} from 'common/src/outlines/responses'
import {
    SetOutlineReadPayload,
    ChangeStarredOutlineSectionRequest,
} from 'common/src/outlines/requests'
import { APIResponseWrapper } from '@/api/types'
import { OutlineOrSummary } from 'common/src/commentThread/types'
import { AnnotationSelection } from 'magic-text-box-lexical/src/context/AnnotatedContentContext/AnnotatedContent.types'
import { callBackend } from '../../server/callBackend'

const PREFIX = '/wb/outlines/'

export const getMostRecentlyVisitedOutlineContentPath = async (
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<ContentPathResponse> => {
    return await callBackend<GenericContentRequest, ContentPathResponse>(
        PREFIX + 'getMostRecentlyVisitedOutlineContentPath',
        ApiMethod.GET,
        {
            courseName,
        },
        cancelToken
    )
}

export const setLatestOutlineVisitedContentPath = async (
    courseName: string,
    id: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<SpecificContentRequest, EmptyResponse>(
        PREFIX + 'setLatestOutlineVisitedContentPath',
        ApiMethod.POST,
        {
            courseName,
            id,
        },
        cancelToken
    )
}

export const getOutlineMetadata = async (
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<OutlineMetadataResponse> => {
    return await callBackend<GenericContentRequest, OutlineMetadataResponse>(
        PREFIX + 'getOutlineMetadata',
        ApiMethod.GET,
        {
            courseName,
        },
        cancelToken
    )
}

export const getNodeMetadata = async (
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<NodeMetadataResponse> => {
    return await callBackend<GenericContentRequest, NodeMetadataResponse>(
        PREFIX + 'getNodeMetadata',
        ApiMethod.GET,
        {
            courseName,
        },
        cancelToken
    )
}

export const getOutline = async (
    courseName: string,
    contentPath: ContentPath,
    cancelToken: CancelToken = null
): APIResponseWrapper<OutlineResponse> => {
    return await callBackend<SpecificContentRequest, OutlineResponse>(
        PREFIX + 'getOutline',
        ApiMethod.GET,
        {
            courseName,
            id: hashContentPath(contentPath),
        },
        cancelToken
    )
}

export const addStarredOutlineSection = async (
    courseName: string,
    contentPath: ContentPath,
    type: OutlineOrSummary,
    annotationSelection: AnnotationSelection,
    cancelToken: CancelToken = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<ChangeStarredOutlineSectionRequest, EmptyResponse>(
        PREFIX + 'addStarredOutlineSection',
        ApiMethod.POST,
        {
            courseName,
            id: hashContentPath(contentPath),
            type,
            annotationSelection,
        },
        cancelToken
    )
}

export const removeStarredOutlineSection = async (
    courseName: string,
    contentPath: ContentPath,
    type: OutlineOrSummary,
    annotationSelection: AnnotationSelection,
    cancelToken: CancelToken = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<ChangeStarredOutlineSectionRequest, EmptyResponse>(
        PREFIX + 'removeStarredOutlineSection',
        ApiMethod.POST,
        {
            courseName,
            id: hashContentPath(contentPath),
            type,
            annotationSelection,
        },
        cancelToken
    )
}

export const setOutlineRead = async (
    courseName: string,
    contentPath: ContentPath,
    isRead: boolean,
    cancelToken: CancelToken = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<SetOutlineReadPayload, EmptyResponse>(
        PREFIX + 'setOutlineRead',
        ApiMethod.POST,
        {
            courseName,
            id: hashContentPath(contentPath),
            isRead,
        },
        cancelToken
    )
}
