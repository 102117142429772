import { atom, selector } from 'recoil'
import { NotecardUserMetadata } from 'common/src/notecards/types'
import { getNotecards, getNotecardUserMetadata } from '@/api/notecards'
import { LoggerSelectorFamily } from '@/atoms/logger'
import {
    FrontendNotecardSetState,
    NotecardSetMode,
    notecardSetStateAtom,
} from '@/atoms/notecards/notecardSet'
import { defaultInvalidationAtom, InvalidationAtom } from '@/atoms/types'
import { logAtomChangesEffect } from '@/atoms/atomEffects/logAtomChangesEffect'
import { resetOnSetEffect } from '@/atoms/atomEffects/resetOnSetEffect'
import {
    frontendDisplayedCourseSelector,
    userInfoStateAtom,
} from '@/atoms/accountMaintenance/userInfo'
import { DateTime } from 'luxon'
import {
    MasteryWaitDurationHours,
    NotecardMasteryPrivateToPublicMap,
    NotecardMasteryStatePrivate,
    NotecardMasteryStatePublic,
} from 'common/src/notecards/consts'
import assert from 'assert'
import { max, shuffle } from 'lodash'
import {
    ILesson,
    MAX_NUM_NOTECARDS_PER_LESSON,
} from '@/components/notecards/Lesson/lesson.types'
import { hashContentPath, unHashContentPath } from 'common/src/ContentPath'
import { getForeverPromise } from 'common/src/utils/getForeverPromise'
import { nextSittingDateSelector } from '@/atoms/nextSittingDateSelector'
import { contentTreeStateAtom } from '@/atoms/contentTree'
import { historicalNotecardSuccessRateAtom } from '@/atoms/notecards/notecardSuccessRate'
import { NotecardSuccessRate } from 'common/src/notecards/notecardSuccessRate'

const fetchNotecardUserMetadata = async (
    courseName: string
): Promise<NotecardUserMetadata[] | null> => {
    return (
        (await getNotecardUserMetadata(courseName)).data.payload
            ?.notecardMetadata ?? null
    )
}

export const notecardUserMetadataAtom = atom<NotecardUserMetadata[] | null>({
    key: 'notecardUserMetadataAtom',
    default: selector<NotecardUserMetadata[] | null>({
        key: 'notecardUserMetadataFetcher',
        get: async ({ get }) => {
            const frontendDisplayedCourse = get(frontendDisplayedCourseSelector)
            const logger = get(LoggerSelectorFamily(notecardSetStateAtom.key))
            if (!frontendDisplayedCourse) {
                logger.warn(
                    `No frontend displayed course, so notecard user metadata will not be retrieved (returning null instead)`
                )
                return null
            }
            get(userInfoStateAtom) // forces update upon new login
            get(notecardUserMetadataInvalidationAtom) // invalidates selector
            logger.info(`Retrieving initial notecard user metadata`)
            return await fetchNotecardUserMetadata(frontendDisplayedCourse)
        },
    }),
})

export const notecardUserMetadataMapSelector = selector<
    Map<string, NotecardUserMetadata>
>({
    key: 'notecardUserMetadataMapSelector',
    get: ({ get }) => {
        const map = new Map<string, NotecardUserMetadata>()
        for (const notecardUserMetadatum of get(notecardUserMetadataAtom) ??
            []) {
            map.set(notecardUserMetadatum.id, notecardUserMetadatum)
        }
        return map
    },
})

export const notecardUserMetadataInvalidationAtom = atom<InvalidationAtom>({
    key: 'notecardUserMetadataInvalidationAtom',
    default: defaultInvalidationAtom,
    effects: [
        logAtomChangesEffect('Notecard User Metadata Invalidation Atom'),
        resetOnSetEffect(notecardUserMetadataAtom),
    ],
})

export const notecardProgressSelector = selector<{
    numAttempted: number
    total: number
}>({
    key: 'notecardProgressSelector',
    get: ({ get }) => {
        const notecardUserMetadata = get(notecardUserMetadataAtom)
        if (notecardUserMetadata === null) {
            get(LoggerSelectorFamily(notecardProgressSelector.key)).warn(
                'No notecard user metadata, returning 0'
            )

            return { numAttempted: 0, total: 0 }
        }

        let numAttempted = 0
        for (const notecardMetadatum of notecardUserMetadata) {
            if (notecardMetadatum.historyData.numAttempts > 0) {
                numAttempted++
            }
        }

        return { numAttempted, total: notecardUserMetadata.length }
    },
})

const nextReviewTimeSelector = selector<
    { notecardID: string; nextReviewTime: DateTime }[]
>({
    key: 'nextReviewTimeSelector',
    get: ({ get }) => {
        const notecardUserMetadata = get(notecardUserMetadataAtom)
        if (notecardUserMetadata === null) {
            get(LoggerSelectorFamily(nextReviewTimeSelector.key)).warn(
                'No notecard user metadata, returning empty array'
            )
            return []
        }
        return notecardUserMetadata
            .filter(
                (notecardUserMetadata) =>
                    ![
                        NotecardMasteryStatePrivate.burned,
                        NotecardMasteryStatePrivate.new,
                    ].includes(notecardUserMetadata.historyData.mastery)
            )
            .map((notecardUserMetadatum) => ({
                notecardID: notecardUserMetadatum.id,
                nextReviewTime: getNextReviewTimeForNotecard(
                    notecardUserMetadatum
                ),
            }))
    },
})

export const availableReviewsSelector = selector<string[]>({
    key: 'availableReviewsSelector',
    get: ({ get }) => {
        const nextReviewTimes = get(nextReviewTimeSelector)
        const now = DateTime.now()
        return nextReviewTimes
            .filter((nextReviewTime) => nextReviewTime.nextReviewTime <= now)
            .map((nextReviewTime) => nextReviewTime.notecardID)
    },
})

export interface UpcomingReviewData {
    datetime: DateTime
    incrementalReviews: number
    cumulativeReviews: number
}

export const upcomingReviewsSelector = selector<UpcomingReviewData[]>({
    key: 'upcomingReviewsSelector',
    get: ({ get }) => {
        const nextReviewTimes = get(nextReviewTimeSelector)
        const now = DateTime.now()
        // filter on reviews that aren't ready yet and sort by time ascending
        const upcomingReviews = nextReviewTimes
            .filter((nextReviewTime) => nextReviewTime.nextReviewTime > now)
            .sort(
                (a, b) =>
                    a.nextReviewTime.toMillis() - b.nextReviewTime.toMillis()
            )
        const result: UpcomingReviewData[] = []
        for (const nextReviewTime of upcomingReviews) {
            const lastReview = result[result.length - 1]
            if (
                lastReview &&
                lastReview.datetime.equals(nextReviewTime.nextReviewTime)
            ) {
                lastReview.incrementalReviews++
                lastReview.cumulativeReviews++
            } else {
                result.push({
                    datetime: nextReviewTime.nextReviewTime,
                    incrementalReviews: 1,
                    cumulativeReviews: (lastReview?.cumulativeReviews ?? 0) + 1,
                })
            }
        }
        return result
    },
})

export const recentMistakesSelector = selector<NotecardUserMetadata[]>({
    key: 'recentMistakesSelector',
    get: ({ get }) => {
        const notecardUserMetadata = get(notecardUserMetadataAtom)
        if (notecardUserMetadata === null) {
            get(LoggerSelectorFamily(recentMistakesSelector.key)).warn(
                'No notecard user metadata, returning empty array'
            )
            return []
        }
        return notecardUserMetadata
            .filter((notecardUserMetadata) => {
                if (
                    notecardUserMetadata.historyData.mastery ===
                    NotecardMasteryStatePrivate.new
                ) {
                    return false
                }

                // if latest attempt is within the past 24 hours and the current streak is 0, keep
                const latestAttemptDate =
                    notecardUserMetadata.historyData.latestAttemptDate
                if (!latestAttemptDate) {
                    return false
                }
                const now = DateTime.now()
                const timeSinceLatestAttempt = now.diff(
                    DateTime.fromJSDate(new Date(latestAttemptDate)),
                    'hours'
                )
                return (
                    timeSinceLatestAttempt.hours <= 24 &&
                    notecardUserMetadata.historyData.currentStreak === 0
                )
            })
            .sort(
                // sort descending by ratio of correct attempts to total attempts.
                // if there is a tie, sort descending by total number of attempts
                (a, b) => {
                    const ratioA =
                        a.historyData.numCorrectAttempts /
                        a.historyData.numAttempts
                    const ratioB =
                        b.historyData.numCorrectAttempts /
                        b.historyData.numAttempts
                    if (ratioA !== ratioB) {
                        return ratioA - ratioB
                    }
                    return b.historyData.numAttempts - a.historyData.numAttempts
                }
            )
    },
})

export const recentMistakesNotecardSetStateSelector =
    selector<FrontendNotecardSetState | null>({
        key: 'recentMistakesNotecardSetSelector',
        get: async ({ get }) => {
            const recentMistakes = get(recentMistakesSelector)
            if (recentMistakes.length === 0) {
                return null
            }
            const courseName = get(frontendDisplayedCourseSelector)
            if (!courseName) {
                get(
                    LoggerSelectorFamily(
                        recentMistakesNotecardSetStateSelector.key
                    )
                ).warn('No course name, loading infinitely')
                return null
            }
            const notecardIDs = shuffle(
                recentMistakes.map((notecard) => notecard.id)
            )
            const notecardsResponse = await getNotecards(
                courseName,
                notecardIDs
            )
            if (!notecardsResponse.data.success) {
                get(
                    LoggerSelectorFamily(
                        recentMistakesNotecardSetStateSelector.key
                    )
                ).error('No notecard set, loading infinitely')
                return getForeverPromise()
            }

            return {
                notecardSet: {
                    notecards: notecardsResponse.data.payload,
                    name: 'Recent Mistakes',
                    id: 'TEMPORARY',
                    dateCreated: new Date(),
                    stateData: {
                        nextNotecardIDStack: [],
                        previousNotecardIDStack: [],
                        activeNotecardID: notecardIDs[0],
                        nextNotecardID:
                            notecardIDs.length > 1
                                ? notecardIDs[1]
                                : notecardIDs[0],
                        mostRecentNotecardIndex: 0,
                    },
                },
                mode: NotecardSetMode.recentMistakes,
                completedNotecardIDs: [],
                attemptedNotecardIDs: [],
                numAttempts: 0,
            } as FrontendNotecardSetState
        },
    })

export const nextLessonSelector = selector<Omit<ILesson, 'strategy'> | null>({
    key: 'nextLessonSelector',
    get: ({ get }) => {
        const notecardUserMetadata = get(notecardUserMetadataAtom)
        if (notecardUserMetadata === null) {
            get(LoggerSelectorFamily(nextLessonSelector.key)).warn(
                'No notecard user metadata, returning empty array'
            )
            return null
        }
        const contentPaths: Set<string> = new Set()
        const notecardIDs: string[] = []
        for (const notecard of notecardUserMetadata) {
            if (
                notecard.historyData.mastery === NotecardMasteryStatePrivate.new
            ) {
                contentPaths.add(hashContentPath(notecard.contentPath))
                notecardIDs.push(notecard.id)
            }

            const numRemainingNotecardsToday = get(
                numRemainingNotecardsForLessonsTodaySelector
            )

            // special case when 0 notecards remaining for the day, continue as normal with 5 per lesson
            if (
                notecardIDs.length ===
                Math.min(
                    numRemainingNotecardsToday <= 0
                        ? MAX_NUM_NOTECARDS_PER_LESSON
                        : numRemainingNotecardsToday,
                    MAX_NUM_NOTECARDS_PER_LESSON
                )
            ) {
                break
            }
        }

        const unhashedContentPaths = [...contentPaths].map((contentPath) =>
            unHashContentPath(contentPath)
        )
        return { contentPaths: unhashedContentPaths, notecardIDs }
    },
})

const getNextReviewTimeForNotecard = (
    notecardUserMetadata: NotecardUserMetadata
): DateTime => {
    assert(
        ![
            NotecardMasteryStatePrivate.burned,
            NotecardMasteryStatePrivate.new,
        ].includes(notecardUserMetadata.historyData.mastery)
    )
    assert(
        notecardUserMetadata.historyData.latestAttemptDate !== null,
        `Expected latest attempt date to not be null for notecard ID: ${notecardUserMetadata.id} / mastery: ${notecardUserMetadata.historyData.mastery}`
    )
    return DateTime.fromJSDate(
        new Date(notecardUserMetadata.historyData.latestAttemptDate)
    )
        .plus({
            hours: MasteryWaitDurationHours[
                notecardUserMetadata.historyData.mastery
            ],
        })
        .endOf('hour')
}

export const numNotecardsForLessonsCompletedTodaySelector = selector<number>({
    key: 'numNotecardsForLessonsCompletedTodaySelector',
    get: ({ get }) => {
        const notecardUserMetadata = get(notecardUserMetadataAtom)
        if (notecardUserMetadata === null) {
            get(
                LoggerSelectorFamily(
                    numNotecardsForLessonsCompletedTodaySelector.key
                )
            ).warn('No notecard user metadata, returning 0')
            return 0
        }

        const now = DateTime.now()
        let numLessonsCompletedToday = 0
        for (const notecardUserMetadatum of notecardUserMetadata) {
            if (
                notecardUserMetadatum.historyData.firstAttemptDate &&
                DateTime.fromJSDate(
                    new Date(notecardUserMetadatum.historyData.firstAttemptDate)
                ).hasSame(now, 'day')
            ) {
                numLessonsCompletedToday++
            }
        }
        return numLessonsCompletedToday
    },
})

export const numTargetedNotecardsForLessonsPerDaySelector = selector<number>({
    key: 'numTargetedNotecardsForLessonsPerDaySelector',
    get: ({ get }) => {
        const notecardUserMetadata = get(notecardUserMetadataAtom)
        // number of notecards in new state
        const numNewNotecards = notecardUserMetadata?.filter(
            (notecardUserMetadatum) =>
                notecardUserMetadatum.historyData.mastery ===
                NotecardMasteryStatePrivate.new
        ).length
        if (numNewNotecards === 0) return 0
        if (numNewNotecards === undefined) return 15
        // number of days remaining until exam
        const now = DateTime.now()
        const nextSittingDate = get(nextSittingDateSelector)

        if (nextSittingDate === null) return 15
        const daysRemaining = Math.ceil(
            DateTime.fromJSDate(nextSittingDate).diff(now, 'days').days
        )

        // assume we should complete all new notecards 1 month before exam date
        const numberOfDaysToFinishNewNotecards = Math.max(0, daysRemaining - 30)
        const rawTargetedLessonsPerDay = Math.ceil(
            numNewNotecards / numberOfDaysToFinishNewNotecards
        )

        // try to return at least 15 per day, but also not less than the number of new notecards
        return Math.min(Math.max(15, rawTargetedLessonsPerDay), numNewNotecards)
    },
})

export const numRemainingNotecardsForLessonsTodaySelector = selector<number>({
    key: 'numRemainingNotecardsForLessonsTodaySelector',
    get: ({ get }) => {
        const numLessonsCompletedToday = get(
            numNotecardsForLessonsCompletedTodaySelector
        )
        const numTargetedLessonsPerDay = get(
            numTargetedNotecardsForLessonsPerDaySelector
        )
        return max([
            numTargetedLessonsPerDay - numLessonsCompletedToday,
            0,
        ]) as number
    },
})

export const isNotecardHistoryEmptySelector = selector<boolean>({
    key: 'isNotecardHistoryEmptySelector',
    get: ({ get }) => {
        const notecardUserMetadata = get(notecardUserMetadataAtom)
        if (notecardUserMetadata === null) {
            get(LoggerSelectorFamily(isNotecardHistoryEmptySelector.key)).warn(
                'No notecard user metadata, returning true'
            )
            return true
        }
        return notecardUserMetadata.every(
            (notecardUserMetadatum) =>
                notecardUserMetadatum.historyData.numAttempts === 0
        )
    },
})

export const notecardSuccessRateSelector = selector<NotecardSuccessRate | null>(
    {
        key: 'notecardSuccessRateSelector',
        get: ({ get }) => {
            const notecardUserMetadata = get(notecardUserMetadataAtom)
            const logger = get(
                LoggerSelectorFamily(notecardSuccessRateSelector.key)
            )
            if (notecardUserMetadata === null) {
                logger.warn('No notecard user metadata, returning null')
                return null
            }

            const contentTree = get(contentTreeStateAtom)
            if (contentTree === null) {
                logger.warn('No content tree, returning null')
                return null
            }

            const isHistoryEmpty = get(isNotecardHistoryEmptySelector)
            if (isHistoryEmpty) {
                logger.warn('History empty, returning null')
                return null
            }

            const historicalSuccessRate = get(historicalNotecardSuccessRateAtom)
            if (!historicalSuccessRate) {
                logger.warn('No historical success rate, returning null')
                return null
            }

            // add today
            const countsByMasteryState: Record<
                NotecardMasteryStatePublic,
                number
            > = {
                [NotecardMasteryStatePublic.new]: 0,
                [NotecardMasteryStatePublic.apprentice]: 0,
                [NotecardMasteryStatePublic.guru]: 0,
                [NotecardMasteryStatePublic.master]: 0,
                [NotecardMasteryStatePublic.enlightened]: 0,
                [NotecardMasteryStatePublic.burned]: 0,
            }
            for (const notecard of notecardUserMetadata) {
                const publicMasteryState =
                    NotecardMasteryPrivateToPublicMap[
                        notecard.historyData.mastery
                    ]
                countsByMasteryState[publicMasteryState]++
            }
            const rootEntry = historicalSuccessRate.notecardHistoryMap.get('')
            if (!rootEntry) {
                logger.warn('No root entry, returning null')
                return null
            }

            for (const mastery of Object.values(NotecardMasteryStatePublic)) {
                rootEntry[mastery].push(countsByMasteryState[mastery])
            }
            // const transformed = generateSimulatedNotecardUserMetadata(
            //     {
            //         startDate: new Date('2024-01-01'),
            //         numLessonsPerDay: 15,
            //         probabilityOfSuccess: 0.8,
            //         probabilityOfFailure: 0.1,
            //     },
            //     notecardUserMetadata
            // )
            return historicalSuccessRate
        },
    }
)
