import React, { ReactElement, useMemo, useState } from 'react'
import styles from './LoggedOutHeader.module.scss'
import authenticatorStyles from '@/components/static/Authenticator/Authenticator.module.scss'
import Modal from 'antd/lib/modal/Modal'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import Link from 'next/link'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { Authenticator } from '@/components/static/Authenticator/Authenticator'
import {
    AuthenticatorOpenPurpose,
    AuthenticatorTabName,
} from '@/components/static/Authenticator/Authenticator.types'
import { LogoLoggedOut } from '@/components/static/LogoLoggedOut/LogoLoggedOut'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { useRouter } from 'next/router'
import {
    GenericButton,
    GenericButtonType,
} from '@/components/static/ui/Button/GenericButton/GenericButton'
import { useWindowSize } from '@/hooks/useWindowSize'
import Menu from 'antd/lib/menu'
import { HeaderAnnouncement } from '@/components/Header/HeaderAnnouncement/HeaderAnnouncement'
import { CourseConfigType, getCourseConfig } from 'common/src/courseConfig'

const MIN_WIDTH_PX_TO_SHOW_EXAM_UPDATES = 500
export const LoggedOutHeader: React.FC<{
    courseConfigType: CourseConfigType
}> = (props): ReactElement => {
    const [isLoginModalVisible, setIsLoginModalVisible] =
        useState<boolean>(false)
    const { width } = useWindowSize()
    const router = useRouter()
    const loggedOutHeaderSelectedKey = useMemo(():
        | 'guide'
        | 'courses'
        | 'login'
        | null => {
        if (isLoginModalVisible) return 'login'
        if (router.asPath.includes(UiLayoutConstant.COURSES_PAGE))
            return 'courses'
        if (router.asPath.includes(UiLayoutConstant.STUDY_MATERIALS_GUIDE_PAGE))
            return 'guide'
        return null
    }, [isLoginModalVisible, router.asPath])

    const menuItems = useMemo((): ItemType[] => {
        return [
            {
                label: (
                    <Link href={UiLayoutConstant.STUDY_MATERIALS_GUIDE_PAGE}>
                        Guide
                    </Link>
                ),
                key: 'guide',
                // onClick: () => setLoggedOutHeaderSelectedKey('guide'),
            },
            {
                label: (
                    <Link href={UiLayoutConstant.COURSES_PAGE}>Courses</Link>
                ),
                key: 'courses',
                // onClick: () => setLoggedOutHeaderSelectedKey('courses'),
            },
            {
                label: 'Login',
                key: 'login',
                onClick: () => {
                    setIsLoginModalVisible(true)
                    // setLoggedOutHeaderSelectedKey('login')
                },
            },
        ]
    }, [setIsLoginModalVisible])

    const authenticatorModal = useMemo((): ReactElement => {
        return (
            <Modal
                title={null}
                centered
                footer={null}
                open={isLoginModalVisible}
                onCancel={() => setIsLoginModalVisible(false)}
                className={authenticatorStyles.authenticatorModal}
            >
                <Authenticator
                    defaultTab={AuthenticatorTabName.Login}
                    setIsModalVisible={setIsLoginModalVisible}
                    routingPurpose={{
                        purpose: AuthenticatorOpenPurpose.LOGIN,
                        courseName: null,
                        accessTypes: null,
                    }}
                    courseConfigType={props.courseConfigType}
                />
            </Modal>
        )
    }, [isLoginModalVisible, props.courseConfigType])

    const allowWelcomePromoCode = useMemo((): boolean => {
        return (
            getCourseConfig(props.courseConfigType)?.allowWelcomePromoCode ??
            false
        )
    }, [props.courseConfigType])

    return (
        <>
            {authenticatorModal}
            {allowWelcomePromoCode && <HeaderAnnouncement isLoggedIn={false} />}
            <div className={styles.loggedOutHeaderOuterContainer}>
                <div className={styles.loggedOutHeaderContainer}>
                    <LogoLoggedOut />
                    <div className={`${styles.loggedOutHeaderMenu}`}>
                        {props.courseConfigType ===
                            CourseConfigType.fsaHealth &&
                            width >= MIN_WIDTH_PX_TO_SHOW_EXAM_UPDATES && (
                                <div
                                    className={
                                        reusableCssClass.centerChildrenVertically
                                    }
                                >
                                    <Link
                                        href={
                                            UiLayoutConstant.EXAM_CHANGE_GUIDE_PAGE
                                        }
                                        style={{ marginRight: 10 }}
                                    >
                                        <GenericButton
                                            type={
                                                GenericButtonType.greenNoBackground
                                            }
                                            text={
                                                width < 625
                                                    ? 'Exam Updates'
                                                    : 'Fall 2024 Exam Updates'
                                            }
                                            onClick={null}
                                        />
                                    </Link>
                                </div>
                            )}
                        <div
                            className={
                                reusableCssClass.centerChildrenVertically
                            }
                        >
                            <Menu
                                className={styles.loggedOutHeaderMenu}
                                mode={'horizontal'}
                                items={menuItems}
                                style={{ marginLeft: 'auto' }}
                                disabledOverflow={true} // hidden property that prevents menu from collapsing to ...
                                selectedKeys={[loggedOutHeaderSelectedKey]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
