import { useWindowSize } from '@/hooks/useWindowSize'

// keep in sync with @mobile-mode-breakpoint-logged-out in variables.scss
export const LOGGED_IN_MOBILE_MODE_BREAKPOINT_IN_PX = 800
export const LOGGED_OUT_MOBILE_MODE_BREAKPOINT_IN_PX = 800
export const useIsMobileMode = (isLoggedIn: boolean): boolean => {
    const { width } = useWindowSize()
    if (isLoggedIn) {
        return width <= LOGGED_IN_MOBILE_MODE_BREAKPOINT_IN_PX
    } else {
        return width <= LOGGED_OUT_MOBILE_MODE_BREAKPOINT_IN_PX
    }
}
