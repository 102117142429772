import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { WBSearchResultsNotecard } from 'common/src/elasticSearch/types'
import { AddNotecardMetadata } from '@/components/notecards/CreateNotecardSetPage/CreateNotecardSet/AddSpecificNotecard/AddSpecificNotecard.types'

export const getIndividualNotecardPagePathForNotecardID = (
    notecardID: string
): string => {
    return UiLayoutConstant.SINGLE_NOTECARD_PAGE + notecardID
}

export const convertNotecardSearchResultsToAddNotecardMetadata = (
    notecardSearchResults: WBSearchResultsNotecard[]
): AddNotecardMetadata[] => {
    return notecardSearchResults.map((notecardSearchResult) => ({
        key: notecardSearchResult.id,
        name: notecardSearchResult.displayText,
        isStarred: notecardSearchResult.isStarred,
    }))
}

export const getNextNotecardHintIndex = (
    notecardHintIndex: number | null,
    numHints: number
): number | null => {
    if (notecardHintIndex === null) {
        return 0
    } else if (notecardHintIndex >= numHints - 1) {
        return null
    } else {
        return notecardHintIndex + 1
    }
}

export const getHintDescription = (
    description: string | null,
    numHints: number
): string => {
    if (numHints === 0) {
        return 'There are no hints for this notecard.'
    }

    if (!description) {
        return 'You reached the last hint. Press H or the lightbulb to repeat the hints.'
    }

    return description
}
