// Because dates come in as strings
import produce from 'immer'
import { NotecardSetMetadata } from 'common/src/notecards/types'

export const cleanNotecardSetMetadata = produce(
    (notecardSetMetadata: NotecardSetMetadata[]): void => {
        notecardSetMetadata.forEach((notecardSetMetadatum) => {
            notecardSetMetadatum.dateCreated = new Date(
                notecardSetMetadatum.dateCreated
            )
        })
    }
)
