import React, { ReactElement, useMemo } from 'react'
import { OutlineLinkProps } from '@/components/OutlineLink/OutlineLink.types'
import Link from 'next/link'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import Tooltip from 'antd/lib/tooltip'
import { useRecoilValue } from 'recoil'
import { nodeMetadataTreeSelector } from '@/atoms/outlines/nodeMetadata'
import { getMetadataForContentPath } from '@/frontendLogic/outlines/NodeMetadataTree'
import { NodeMetadata } from 'common/src/outlines/types'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { AccessType } from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/types'
import styles from './OutlineLink.module.scss'

export const OutlineLink: React.FC<OutlineLinkProps> = (
    props
): ReactElement => {
    const href = useMemo(
        (): string =>
            `${UiLayoutConstant.OUTLINE_PAGE}${props.contentPath.join('/')}`,
        [props.contentPath]
    )

    const nodeMetadataTree = useRecoilValue(nodeMetadataTreeSelector)
    const nodeMetadata = useMemo((): NodeMetadata[] => {
        const metadata: NodeMetadata[] = []
        for (let i = 0; i < props.contentPath.length; i++) {
            metadata.push(
                getMetadataForContentPath(
                    nodeMetadataTree,
                    props.contentPath.slice(0, i + 1)
                )
            )
        }
        return metadata
    }, [nodeMetadataTree, props.contentPath])
    const title = useMemo(
        (): string =>
            nodeMetadata.map((metadatum) => metadatum.title).join(' > '),
        [nodeMetadata]
    )

    const baseComponent = useMemo((): ReactElement => {
        const isEnabled = props.accessTypes.includes(AccessType.OUTLINES)
        if (isEnabled) {
            if (props.setIsOutlineModalOpen) {
                return (
                    <div
                        className={
                            !props.preventDefaultStyling
                                ? reusableCssClass.clickableText
                                : undefined
                        }
                        onClick={() => props.setIsOutlineModalOpen(true)}
                    >
                        Outline
                    </div>
                )
            }
            return (
                <Link
                    href={href}
                    onClick={(e) => {
                        e.stopPropagation()
                        if (props.onClickSideEffect) {
                            props.onClickSideEffect(e)
                        }
                    }}
                    className={
                        !props.preventDefaultStyling
                            ? reusableCssClass.clickableText
                            : undefined
                    }
                    style={{ height: '100%' }}
                >
                    Outline
                </Link>
            )
        }

        return (
            <Tooltip title={'Must purchase to unlock'} placement={'top'}>
                <div
                    className={
                        !props.preventDefaultStyling
                            ? reusableCssClass.clickableText
                            : undefined
                    }
                >
                    Outline
                </div>
            </Tooltip>
        )
    }, [href, props])

    if (props.isAllOneLink) {
        return (
            <Link
                href={href}
                onClick={(e) => {
                    e.stopPropagation()
                    if (props.onClickSideEffect) {
                        props.onClickSideEffect(e)
                    }
                }}
            >
                {title}
            </Link>
        )
    }

    if (props.isShortForm) {
        return (
            <Tooltip title={title} placement={'topLeft'}>
                <div>{baseComponent}</div>
            </Tooltip>
        )
    }
    return (
        <div className={styles.outlineLink}>
            {baseComponent}
            <span>{title}</span>
        </div>
    )
}
