import { atom, DefaultValue, selector, selectorFamily } from 'recoil'
import dayjs, { Dayjs } from 'dayjs'
import {
    getAllStudyTimeRecords,
    getCurrentStudyTimeStatus,
} from '@/api/studyTime'
import produce from 'immer'
import { StudyTimeRecordDateFormat } from 'common/src/api/websiteFrontendVsWebsiteBackend/studyTime/types'
import { DateTime } from 'luxon'
import { min } from 'lodash'
import { todayDateStrSelector } from '@/atoms/today'
import { GetCurrentStudyTimeStatusResponse } from 'common/src/api/websiteFrontendVsWebsiteBackend/studyTime/responses'
import { getTimeZone } from '@/components/utils/getTimeZone'
import { frontendDisplayedCourseSelector } from '@/atoms/accountMaintenance/userInfo'
import { logger } from '@/logging/FrontendLogger'

// map of StudyTimeRecordDateFormat date as string -> study time for that date in seconds
export type StudyTimeRecordsMap = Map<string, number>
export const studyTimeRecordsAtom = atom<StudyTimeRecordsMap>({
    key: 'studyTimeRecordsAtom',
    default: new Map(), // fetched in useStudyTimer.ts
})

// used for updates screenshot, keeping around in case we want to use it in the future for testing purposes
// export const generateFakeStudyTimeRecordsMap = (
//     lookbackInDays: number
// ): StudyTimeRecordsMap => {
//     const map = new Map()
//     let currentDay = DateTime.now()
//     for (let i = 0; i < lookbackInDays; i++) {
//         const fakeHoursStudied = Math.random() * 5
//         map.set(
//             currentDay.toFormat(StudyTimeRecordDateFormat),
//             fakeHoursStudied * 60 * 60
//         )
//         currentDay = currentDay.minus({ days: 1 })
//     }
//     return map
// }

export const fetchAndBuildStudyTimeRecordsMap = async (
    courseName: string | undefined
): Promise<StudyTimeRecordsMap> => {
    if (!courseName) {
        logger.warn(`No course name, returning empty map.`)
        return new Map()
    }

    const allStudyTimeRecordsResponse = await getAllStudyTimeRecords(courseName)
    const allStudyTimeRecords =
        allStudyTimeRecordsResponse.data.payload?.studyTimeRecords
    if (!allStudyTimeRecords) {
        logger.warn(`No study time records in response, returning empty map.`)
        return new Map()
    }

    const map: StudyTimeRecordsMap = new Map()
    for (const studyTimeRecord of allStudyTimeRecords) {
        map.set(
            studyTimeRecord.dateStr,
            studyTimeRecord.timeSpentStudyingInSeconds
        )
    }

    return map
}

// key is in study time format
export const studyTimeRecordSelectorFamilyForSingleDay = selectorFamily<
    number,
    string
>({
    key: 'studyTimeRecordSelectorFamilyForSingleDay',
    get:
        (dateStr) =>
        ({ get }) =>
            get(studyTimeRecordsAtom).get(dateStr) || 0,
    set:
        (dateStr) =>
        ({ set }, newStudyTimeInSeconds) => {
            if (newStudyTimeInSeconds instanceof DefaultValue) return
            set(studyTimeRecordsAtom, (currentMap) =>
                updateStudyTimeRecordsMap(
                    currentMap,
                    dateStr,
                    newStudyTimeInSeconds
                )
            )
        },
})

// key is in study time format
export const studyTimeRecordSelectorFamilyForSingleMonth = selectorFamily<
    number,
    string
>({
    key: 'studyTimeRecordSelectorFamilyForSingleMonth',
    get:
        (dateStr) =>
        ({ get }) => {
            const map = get(studyTimeRecordsAtom)
            const dayJsDate = studyTimeFormatToDayJs(dateStr)
            let cumulativeSeconds = 0

            for (
                let dayIndex = 0;
                dayIndex < dayJsDate.daysInMonth();
                dayIndex++
            ) {
                const effectiveDate = dayJsDate.set('date', dayIndex)
                cumulativeSeconds +=
                    map.get(dayJsToStudyTimeFormat(effectiveDate)) || 0
            }
            return cumulativeSeconds
        },
})

export const firstStudyTimeRecordSelector = selector<Dayjs>({
    key: 'firstStudyTimeRecordSelector',
    get: ({ get }) => {
        const map = get(studyTimeRecordsAtom)
        const todayDateStr = get(todayDateStrSelector)
        const minString = Array.from(map.keys()).reduce(
            (a, b) => min([a, b]) ?? a, // min only returns undefined if array is empty
            todayDateStr
        )
        return studyTimeFormatToDayJs(minString)
    },
})

const updateStudyTimeRecordsMap = produce(
    (
        studyTimeRecordsMap: StudyTimeRecordsMap,
        dateStr: string,
        newStudyTimeInSeconds: number
    ): void => {
        studyTimeRecordsMap.set(dateStr, newStudyTimeInSeconds)
    }
)

export const dayJsToStudyTimeFormat = (dayjs: Dayjs): string =>
    DateTime.fromJSDate(dayjs.toDate()).toFormat(StudyTimeRecordDateFormat)

const studyTimeFormatToDayJs = (studyTimeFormattedDate: string): Dayjs =>
    dayjs(
        DateTime.fromFormat(
            studyTimeFormattedDate,
            StudyTimeRecordDateFormat
        ).toJSDate()
    )

export const isStudyTimeDashboardEditTimeStudiedModalVisibleAtom =
    atom<boolean>({
        key: 'isStudyTimeDashboardEditTimeStudiedModalVisibleAtom',
        default: false,
    })

export const studyTimerAtom = atom<GetCurrentStudyTimeStatusResponse>({
    key: 'studyTimerAtom',
    default: selector<GetCurrentStudyTimeStatusResponse>({
        key: 'studyTimerAtomFetcher',
        get: async ({ get }) => {
            const courseName = get(frontendDisplayedCourseSelector)
            if (!courseName) {
                return {
                    isPaused: true,
                    studyTimeForMostRecentDate: 0,
                    cumulativeStudyTime: 0,
                    reason: 'MANUAL',
                }
            }
            const response = await getCurrentStudyTimeStatus(
                courseName,
                getTimeZone(),
                true
            )

            if (!response?.data?.payload) {
                return {
                    isPaused: false,
                    studyTimeForMostRecentDate: 0,
                    cumulativeStudyTime: 0,
                }
            }
            return response.data.payload
        },
    }),
})
