import styles from './NotificationListItem.module.scss'
import React, { ReactElement } from 'react'
import {
    invalidateContentCommentThread,
    NotificationListItemProps,
} from '@/components/notification/notificationListItem/NotificationListItem.types'
import { JEPNotificationType } from 'common/src/api/websiteFrontendVsWebsiteBackend/notifications/types'
import Link from 'next/link'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { displayStringForCommentPostTime } from '@/utils/dateTime'
import { registerEvent } from '@/api/analyticsEvent'
import { PaidUserAnalyticsEventType } from 'common/src/api/websiteFrontendVsWebsiteBackend/analyticsEvent/types'
import { useNotificationData } from '@/components/notification/notificationListItem/useNotificationData'

export const NotificationListItem: React.FC<NotificationListItemProps> = (
    props
): ReactElement => {
    const { notification } = props
    const { avatar, href, textToShow } = useNotificationData(notification)

    return (
        <Link
            href={href}
            className={reusableCssClass.clickMe}
            onClick={() => {
                if (notification.type === JEPNotificationType.COMMENT) {
                    invalidateContentCommentThread(notification.metadata)
                }
                props.setIsModalVisible(false)
                registerEvent(PaidUserAnalyticsEventType.CLICK_NOTIFICATION, {
                    type: notification.type,
                })
            }}
        >
            <div className={styles.notificationListItemContainer}>
                <div className={styles.notificationListItemAvatar}>
                    {avatar}
                </div>
                <div
                    className={`${styles.notificationListItemTextContainer} ${reusableCssClass.centerChildrenVertically}`}
                >
                    <div className={styles.notificationListItemTextInfo}>
                        {textToShow}
                    </div>
                    <div className={styles.notificationListItemTextTime}>
                        {displayStringForCommentPostTime(
                            notification.createdAt
                        )}
                    </div>
                </div>
                <div
                    className={`${styles.notificationListItemDot} ${
                        notification.hasBeenSeen ? styles.hidden : ''
                    }`}
                >
                    &#9679;
                </div>
            </div>
        </Link>
    )
}
